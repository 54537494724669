var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "organism-space" }, [
    _vm.isFloorView
      ? _c("div", { staticClass: "general-elements-style" }, [
          _c("div", { staticClass: "buttons-right active" }, [
            _c(
              "div",
              {
                staticClass: "info-button",
                class: { active: _vm.open },
                on: { click: _vm.handleInfoTrigger }
              },
              [
                _c("AtomIcon", {
                  attrs: { icon: _vm.open ? "xmark" : "info", size: 20 }
                })
              ],
              1
            ),
            _c("span", { staticClass: "line-style" }),
            _c("div", { staticClass: "buttons-3D" }, [
              _c("div", { ref: "button2d", staticClass: "button-tooltip" }, [
                _c(
                  "span",
                  {
                    class: {
                      "btn-tooltip": true,
                      "change-view": true,
                      text2D: true,
                      iconTooltip: true
                    },
                    on: { click: _vm.changeViewMode2D }
                  },
                  [_vm._v("2D")]
                ),
                _c("p", { staticClass: "hidden-mobile-elements" }, [
                  _vm._v("Select 2D View Mode")
                ])
              ]),
              _c("div", { ref: "button3d", staticClass: "button-tooltip" }, [
                _c(
                  "span",
                  {
                    class: {
                      "btn-tooltip": true,
                      "change-view": true,
                      text3D: true,
                      iconTooltip: true,
                      active: true
                    },
                    on: { click: _vm.changeViewMode3D }
                  },
                  [_vm._v("3D")]
                ),
                _c("p", { staticClass: "hidden-mobile-elements" }, [
                  _vm._v("Select 3D View Mode")
                ])
              ]),
              _c("div", { ref: "buttonVt", staticClass: "button-tooltip" }, [
                _c(
                  "span",
                  {
                    class: {
                      "btn-tooltip": true,
                      "change-view": true,
                      walkThrough: true,
                      iconTooltip: true
                    },
                    on: { click: _vm.changeViewModeFirstPerson }
                  },
                  [
                    _c("AtomIcon", { attrs: { icon: "street-view", size: 20 } })
                  ],
                  1
                ),
                _c("p", { staticClass: "hidden-mobile-elements" }, [
                  _vm._v("Enter Virtual Tour")
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }