<template>
  <div class="atom-padding">
    <div :class="{'atom-gradient-background': true, 'active': isActive}" @click="handleClick">
      <div v-lazyload="{handle: handleLazyLoad, url: resourceUrl}" class="atom-project-slider-thumb" :style="{'background-image': `url(${resourceUrl})`}">
        <span>{{ title }} <b>{{ subtitle }}</b></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomProjectSliderThumb",
  props: {
    thumb: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String,
      default: ''
    },
    subtitle: {
      required: true,
      type: String,
      default: ''
    },
    thumbId: {
      required: true,
      type: String,
      default: ''
    },
    activeThumb: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  data() {
    return {
      isInView: false,
    }
  },
  computed: {
    isActive() {
      return this.$props.activeThumb === this.thumbId;
    },
    resourceUrl() {
      if (!this.isInView) return '';
      return `${this.$store.getters.cdnBase}/${this.$props.thumb}`;
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.thumbId);
    },
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-padding {
    padding: 0 0.563rem;
    @media only screen and (max-width: 767px) {
      padding: 0 10px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .atom-gradient-background {
    padding: 1px;
    background: linear-gradient(180deg, rgba(255,255,255,0.8911939775910365) 0%, rgba(255,255,255,0.5634628851540616) 50%, rgba(0,0,0,0) 100%);
    width: 7.7rem;
    height: 7.5rem;
    transition: all 0.1s ease-in-out 0s;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      width: 120px;
      height: 120px;
    }
    .atom-project-slider-thumb {
      position: relative;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      span {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: $medium;
        text-transform: uppercase;
        color: $white;
        background: linear-gradient(90deg, $blackOpacity05 0%, $blackOpacity012 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0.625rem;
        display: flex;
        flex-direction: column;
        justify-content: end;
        flex-wrap: nowrap;
        transition: all 0.1s ease-in-out 0s;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
          padding: 10px;
        }
        b {
          font-size: 1rem;
          line-height: 1.3rem;
          font-weight: $bold;
          display: block;
          transition: all 0.1s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
    &.active {
      width: 13.188rem;
      height: 22.313rem;
      @media only screen and (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
      .atom-project-slider-thumb {
        span {
          background: transparent;
          font-size: 1.5rem;
          line-height: 1.8rem;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
          }
          b {
            font-size: 1.5rem;
            line-height: 1.8rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
    &:hover {
      .atom-project-slider-thumb {
        span {
          background: transparent;
        }
      }
    }
  }
</style>