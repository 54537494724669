import { PointerCircle } from "@/components/organisms/project/building/store/3d/builders/PointerCircle";
import { isMobile } from "@/helpers/mobile/DeviceType";

export class MinimapViewport {
  camera: BABYLON.ArcRotateCamera;
  minimapRatio = 0;
  scene: BABYLON.Scene;
  minimapRTT: BABYLON.RenderTargetTexture;
  mapMat: BABYLON.StandardMaterial;
  mapPlane?: BABYLON.Mesh;
  minimapCameraPosition: BABYLON.Mesh;
  minimapCameraCone: BABYLON.Mesh;
  minimapPointer: PointerCircle = new PointerCircle();
  constructor(scene: BABYLON.Scene, floorName: string) {
    this.scene = scene;
    this.camera = new BABYLON.ArcRotateCamera(
      "Minimap",
      Math.PI / 2,
      0,
      60,
      BABYLON.Vector3.Zero(),
      this.scene
    );
    this.camera.layerMask = 0x10000000;
    this.camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

    this.mapMat = new BABYLON.StandardMaterial("texturePlane", this.scene);
    this.minimapRTT = new BABYLON.RenderTargetTexture(
      "depth",
      256,
      scene,
      false,
      true,
      BABYLON.Engine.TEXTURETYPE_UNSIGNED_BYTE,
      false,
      BABYLON.Texture.TRILINEAR_SAMPLINGMODE,
      true,
      false,
      false,
      BABYLON.Engine.TEXTUREFORMAT_RGBA
    );
    this.init(floorName);
    //// Add the minimap camera position
    this.minimapCameraPosition = BABYLON.MeshBuilder.CreateDisc(
      "minimapPosition",
      {
        radius: 0.5,
      },
      this.scene
    );
    const minimapPositionMaterial = new BABYLON.StandardMaterial(
      "minimapPositionMaterial",
      this.scene
    );
    this.minimapCameraPosition.rotation.x = Math.PI / 2;
    this.minimapCameraPosition.layerMask = 0x40000000;
    minimapPositionMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
    this.minimapCameraPosition.material = minimapPositionMaterial;
    this.minimapCameraPosition.isPickable = false;

    this.minimapCameraCone = BABYLON.MeshBuilder.CreateDisc(
      "minimapCone",
      {
        radius: 6,
        tessellation: 24,
        arc: 1 / 5,
      },
      this.scene
    );
    this.minimapCameraCone.rotation.x = Math.PI / 2;
    this.minimapCameraCone.layerMask = 0x40000000;
    this.minimapCameraCone.material = minimapPositionMaterial;
    this.minimapCameraCone.visibility = 0.5;
    this.minimapCameraCone.isPickable = false;
    //// Add pointer for the minimap
    this.minimapPointer.init(this.scene, "minimapVirtualTourPointer", 0x40000000);
    this.setMinimapPointer();
    this.updateMinimap();

    if (isMobile()) {
      this.minimapCameraPosition?.setEnabled(false);
      this.minimapCameraCone?.setEnabled(false);
      this.mapPlane?.setEnabled(false);
      this.minimapPointer?.setEnable(false);
    }
  }

  init(floorName: string) {
    const canvasWidth = this.scene.getEngine()?.getRenderingCanvas()?.width;
    const canvasHeight = this.scene.getEngine()?.getRenderingCanvas()?.height;

    if (!canvasWidth || !canvasHeight)
      return console.error("Engine not defined");

    const boundingBoxMainElement = this.scene
      .getTransformNodeByName(floorName)
      ?.getHierarchyBoundingVectors();

    if (!boundingBoxMainElement) return console.error("Floor name not found");

    this.camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    this.camera.orthoTop = boundingBoxMainElement.min.z * -1;
    this.camera.orthoBottom = boundingBoxMainElement.max.z * -1;
    this.camera.orthoLeft = boundingBoxMainElement.max.x * -1;
    this.camera.orthoRight = boundingBoxMainElement.min.x * -1;

    this.minimapRatio =
      (boundingBoxMainElement.max.x - boundingBoxMainElement.min.x) /
      (boundingBoxMainElement.max.z - boundingBoxMainElement.min.z);

    //// Minimap RTT camera
    const minimapCamera = new BABYLON.ArcRotateCamera(
      "minimapCamera",
      -Math.PI / 2,
      0,
      60,
      BABYLON.Vector3.Zero(),
      this.scene
    );
    minimapCamera.layerMask = 0x10000000;
    minimapCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
    minimapCamera.orthoTop = boundingBoxMainElement.min.z * -1;
    minimapCamera.orthoBottom = boundingBoxMainElement.max.z * -1;
    minimapCamera.orthoLeft = boundingBoxMainElement.max.x * -1;
    minimapCamera.orthoRight = boundingBoxMainElement.min.x * -1;

    this.scene.customRenderTargets.push(this.minimapRTT);
    this.minimapRTT.activeCamera = minimapCamera;
    this.minimapRTT.refreshRate = 0;
    this.minimapRTT.hasAlpha = true;

    //// Create minimap plane
    this.mapPlane = BABYLON.MeshBuilder.CreatePlane(
      "plane",
      {
        width: boundingBoxMainElement.max.x - boundingBoxMainElement.min.x,
        height: boundingBoxMainElement.max.z - boundingBoxMainElement.min.z,
      },
      this.scene
    );

    this.mapPlane.rotation.x = Math.PI / 2;
    this.mapMat.emissiveTexture = this.minimapRTT;
    this.mapMat.opacityTexture = this.minimapRTT;
    this.mapMat.disableLighting = true;

    this.mapPlane.material = this.mapMat;

    this.mapPlane.layerMask = 0x40000000;
    this.camera.layerMask = 0x40000000;

    this.resizeMinimap();

    this.mapPlane.setParent(this.camera);
    minimapCamera.dispose();
  }

  setMinimapPointer() {
    if (!this.minimapPointer.mesh)
      return console.error("Minimap pointer not properly initialized");
    this.minimapPointer.mesh.scaling.x = 2;
    this.minimapPointer.mesh.scaling.y = 2;
  }

  resizeMinimap() {
    const canvasWidth = this.scene.getEngine()?.getRenderingCanvas()?.width;
    const canvasHeight = this.scene.getEngine()?.getRenderingCanvas()?.height;
    if (!canvasWidth || !canvasHeight)
      return console.error("Engine not defined");

    let minimapWidth = 350;
    let marginTop = 20;
    const marginRight = 60;
    if (window.innerWidth < 767) {
      minimapWidth = 300;
      marginTop = 45;
    }
    if (this.minimapRatio < 1) {
      minimapWidth = 300 * this.minimapRatio;
    }
    this.camera.viewport.width = minimapWidth / canvasWidth;
    this.camera.viewport.height =
      (this.camera.viewport.width / this.minimapRatio) *
      (canvasWidth / canvasHeight);
    this.camera.viewport.x =
      1 - minimapWidth / canvasWidth - marginRight / canvasWidth;
    this.camera.viewport.y =
      1 - this.camera.viewport.height - marginTop / canvasHeight;
  }

  updateMinimap() {
    this.minimapRTT.renderList = this.scene.meshes.filter((mesh) => {
      return mesh.layerMask === 0x10000000;
    });
    this.minimapRTT.refreshRate = 0;
  }

  updatePositions(cameraPosition: BABYLON.Vector3, rotation: number, roofOffset = 25) {
    this.minimapCameraPosition.position.set(
      cameraPosition.x,
      cameraPosition.y + roofOffset,
      cameraPosition.z
    );
    this.minimapCameraCone.position.set(
      cameraPosition.x,
      cameraPosition.y + roofOffset,
      cameraPosition.z
    );
    this.minimapCameraCone.rotation.y = BABYLON.Tools.ToRadians(306) + rotation;
  }
}
