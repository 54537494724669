<template>
  <ul class="wrapper-sidebar">
    <AtomLocationType v-for="(location, index) in places" :category="location.category"
                      :category-label="location.categoryLabel" :totalNumber="location.totalNumber" :key="index"
                      @category-toggle="handleCategoryToggle">
      <ul class="street-show">
        <AtomRoute v-for="(routeTo, index) in location.routes" :markerId="`${location.category}_${routeTo.id}`"
                   :mode="routeTo.mode" :order="routeTo.order" :name="routeTo.name" :time="routeTo.time"
                   :distance="routeTo.distance" :key="index"/>
      </ul>
    </AtomLocationType>
  </ul>
</template>


<script>
import AtomLocationType from "../../atoms/project/AtomLocationType";
import AtomRoute from "../../atoms/project/AtomRoute";

export default {
  name: "MoleculeSidebarRoutes",
  components: { AtomRoute, AtomLocationType },
  props: {
    places: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  updated() {
    this.$el.querySelectorAll('.atom-location-type.active').forEach((item) => {
      const child = item.querySelector('.atom-route.atom-route-active');
      if (child) {
        child.classList.remove('atom-route-active')
      }
      item.classList.remove('active');
    });
  },
  methods: {
    handleCategoryToggle(target) {
      target.parentNode.parentNode.scrollTop = target.offsetTop;
    }
  }
}
</script>

<style lang="scss">
.street-show {
  display: none;
}
</style>