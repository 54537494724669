<template>
  <div class="section section-features-v2" :class="{ [`section${orderNo}`]: true }" :style="inlineStyles" v-if="data.enabled !== false">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
      <AtomVerticalLine/>
    </div>
    <OrganismListCircleV2 v-for="(item, index) in data.items" :title="item.title" :subtitle="item.subtitle" :background-color="item.backgroundColor" :list-circle-data="item.listCircle" :key="index" />
  </div>
</template>

<script>
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../../components/atoms/common/AtomVerticalLine";
import OrganismListCircleV2 from "@/components/organisms/common/OrganismListCircleV2.vue";
export default {
  name: "SectionFeatures",
  components: { OrganismListCircleV2, AtomVerticalLine, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    inlineStyles() {
      let styles = {};
      if (this.data.backgroundImage) {
        styles = {...{'background-image': `url(${this.data.backgroundImage})`}}
      }
      return styles
    }
  }
}
</script>

<style lang="scss">
.section-features-v2 {
  padding: 2rem 3.75rem 0;
  background-size: cover;
  box-shadow: inset 0 0 0 125rem rgba($main-color, 0.9);
  @media only screen and (max-width: 900px) {
    padding: 1.875rem 1.25rem 0;
  }
  .top-title {
    margin-bottom: 2rem;
    @media only screen and (max-width: 900px) {
      margin-bottom: 2rem;
    }
    .atom-title {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 900px) {
        margin-bottom: 1.25rem;
      }
      h2 {
        text-transform: none;
        @include font-main(3.25rem, $white !important, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1.375rem, $white !important, $regular, 1.625rem);
        }
        b {
          @include font-main(3.25rem, $white !important, $bold, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(1.375rem, $white !important, $bold, 1.625rem);
          }
        }
      }
      h3 {
        @include font-main(1.375rem, $white !important, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          @include font-main(0.875rem, $white !important, $regular, 1.188rem);
        }
        b {
          @include font-main(1.375rem, $white !important, $bold, 1.625rem);
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem, $white !important, $bold, 1.188rem);
          }
        }
      }
    }
    .atom-content-text {
      max-width: 80rem;
      @media only screen and (max-width: 900px) {
        max-width: 100%;
      }
      p {
        @include font-main(1.125rem, $white, $regular, 1.813rem);
      }
    }
  }
  .molecule-list-circle {
    margin: 0 !important;
    @media only screen and (max-width: 767px) {
      margin: 0 !important;
    }
    .atom-list-circle {
      padding: 0 3.75rem;
      margin-bottom: 2.5rem;
      @media only screen and (max-width: 900px) {
        margin-bottom: 1.875rem;
        padding: 0 1.25rem;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.875rem;
      }
      .circle {
        @media only screen and (max-width: 767px) {
          width: 2.125rem;
          height: 2.125rem;
          border-radius: 2.125rem;
          border-width: 0.188rem;
          margin-bottom: 1rem;
        }
        span {
          @media only screen and (max-width: 767px) {
            width: 1.1rem;
            height: 1.1rem;
            border-radius: 1.1rem;
          }
        }
      }
      h4 {
        @include font-main(1.375rem, $white, $medium, 1.625rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.5rem;
          @include font-main(1.1rem, $white, $medium, 1.288rem);
        }
        b {
          @include font-main(1.375rem, $white, $bold, 1.625rem);
          @media only screen and (max-width: 767px) {
            margin-bottom: 0.5rem;
            @include font-main(1.1rem, $white, $bold, 1.288rem);
          }
        }
      }
      .atom-vertical-line {
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.5rem;
        }
      }
      p {
        @include font-main(1.125rem, $white, $regular, 1.813rem);
        @media only screen and (max-width: 767px) {
          @include font-main(0.875rem, $white, $regular, 1.188rem);
        }
        b {
          @include font-main(1.125rem, $white, $medium, 1.813rem);
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem, $white, $medium, 1.188rem);
          }
        }
      }
    }
  }
}
</style>