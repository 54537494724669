var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "horizontal-menu-molecule": true,
        sm: _vm.sm,
        "no-margin-ends": _vm.noMarginEnds
      }
    },
    [
      !_vm.tabData.length
        ? _vm._t("default")
        : _vm._l(_vm.tabData, function(tab, index) {
            return _c("AtomMenuItem", {
              key: index,
              class: { active: _vm.selectedTab === tab.tabId },
              attrs: {
                label: tab.label,
                icon: tab.icon,
                "tab-id": tab.tabId,
                "on-click": _vm.handleTabClick
              }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }