export class CameraBuilder {
  buildArcRotateCamera(
    cameraName: string,
    alpha: number,
    beta: number,
    radius: number,
    target: BABYLON.Vector3,
    scene: BABYLON.Scene
  ) {
    return new BABYLON.ArcRotateCamera(cameraName, alpha, beta, radius, target, scene);
  }

  buildUniversalCamera(
    cameraName: string,
    startPosition: BABYLON.Vector3,
    scene: BABYLON.Scene
  ) {
    return new BABYLON.UniversalCamera(cameraName, startPosition, scene);
  }
}
