export class PointerCircle {
  mesh?: BABYLON.Mesh;
  yPos = 1;
  init(scene: BABYLON.Scene, name: string, layerMask: number) {
    if (!scene) return console.error("Scene not defined");
    const pointer = BABYLON.MeshBuilder.CreateDisc(
      name,
      {
        radius: 0.3,
        tessellation: 32,
      },
      scene
    );
    const texture = new BABYLON.Texture(
      "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/vt-pointer.png",
      scene
    );
    texture.hasAlpha = true;
    texture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
    texture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;

    const pointMaterial = new BABYLON.StandardMaterial("virtualTourPointer", scene);
    pointMaterial.emissiveTexture = texture;
    pointMaterial.opacityTexture = texture;
    pointMaterial.backFaceCulling = false;
    pointer.material = pointMaterial;
    pointer.isPickable = false;

    pointer.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
    this.mesh = pointer;
    this.mesh.layerMask = layerMask;
  }

  move(x: number, y: number, z: number) {
    if (!this.mesh) return console.error("Mesh pointer undefined");
    this.mesh.position.set(x, y, z);
  }

  setEnable(enable: boolean) {
    this.mesh?.setEnabled(enable);
  }
}
