<template>
  <div :class="{section: true, 'section-sustainability': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle color="rgba(0,0,0,0.6)" color2="#000" :title="data.title" :subtitle="data.subtitle" />
    </div>
    <OrganismCertifications :list-circle-data="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import OrganismCertifications from "../../../components/organisms/common/OrganismCertifications";
export default {
  name: "SectionSustainabilityV1",
  components: {OrganismCertifications, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
.section-sustainability {
  padding: 5rem 3.75rem 1.25rem;
  @media only screen and (max-width: 900px) {
    padding: 3rem 0.625rem 1.25rem;
  }
  @media only screen and (max-width: 767px) {
    padding: 1.875rem 0.625rem;
  }
  .top-title {
    margin-bottom: 3.75rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 2.5rem;
    }
    .atom-title {
      h2 {
        letter-spacing: 0.05em;
        text-transform: uppercase;
        @include font-main(0.875rem, $black, $regular, 1.063rem);
      }
      h3 {
        margin-top: 0.5rem;
        @include font-main(1.375rem, $black, $regular, 1.625rem);
      }
    }
  }
}
</style>