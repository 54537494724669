var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-building": true,
          "z-index": _vm.loadingScreenActive,
          "embed-ready": _vm.isEmbedReady,
          "matterport-space-view": _vm.addMatterportClass
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _vm.loadingScreenActive
        ? _c(
            "div",
            {
              staticClass: "organism-loader-background",
              style: {
                "background-image":
                  "url(" + _vm.resourceUrl(_vm.backgroundLoaderUrl) + ")"
              }
            },
            [
              _c("OrganismLoader", {
                attrs: { "content-loader": _vm.contentLoaderData }
              })
            ],
            1
          )
        : _vm._e(),
      _c("OrganismBuilding", {
        ref: "building",
        class: {
          active:
            _vm.drawerAvailabilityOpen ||
            _vm.drawerShortlistOpen ||
            _vm.spaceDetailsOpen
        }
      }),
      _c(
        "div",
        {
          class: {
            "drawer-buttons": true,
            active:
              _vm.drawerAvailabilityOpen ||
              _vm.drawerShortlistOpen ||
              _vm.spaceDetailsOpen
          }
        },
        [
          _vm.isSpaceRoute
            ? _c(
                "div",
                {
                  staticClass:
                    "drawer-button drawer-button-black custom-tooltip home-button",
                  on: { click: _vm.handleExpandSpaceDetails }
                },
                [
                  _c("AtomIcon", {
                    attrs: {
                      icon: _vm.spaceDetailsOpen ? "xmark" : "house",
                      color: "#fff"
                    }
                  }),
                  !_vm.spaceDetailsOpen
                    ? _c("p", { staticClass: "hidden-mobile-elements" }, [
                        _vm._v("\n        Space Details\n      ")
                      ])
                    : _c("p", { staticClass: "hidden-mobile-elements" }, [
                        _vm._v("Close")
                      ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "drawer-button drawer-button-black custom-tooltip",
              on: { click: _vm.handleExpandAvailability }
            },
            [
              _c("AtomIcon", {
                attrs: {
                  icon: _vm.drawerAvailabilityOpen ? "xmark" : "filter-list",
                  color: "#fff"
                }
              }),
              _c("p", { staticClass: "hidden-mobile-elements" }, [
                _vm._v("Filters & Portfolio")
              ])
            ],
            1
          ),
          _vm.shortlistState
            ? _c(
                "div",
                {
                  staticClass:
                    "drawer-button drawer-button-white custom-tooltip",
                  on: { click: _vm.handleExpandShortlist }
                },
                [
                  _c("AtomIcon", {
                    attrs: {
                      icon: _vm.drawerShortlistOpen
                        ? "xmark"
                        : "shortlist-icon",
                      color: "#000"
                    }
                  }),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.requestedSpaces.length))
                  ]),
                  _c("p", { staticClass: "hidden-mobile-elements" }, [
                    _vm._v("Shortlist")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "info-notification",
                      class: { active: _vm.openShortlist }
                    },
                    [
                      _c("p", [_vm._v("Space added to shortlist")]),
                      _c("span", [_vm._v("Click Here")])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.isSpaceRoute
        ? _c("OrganismSpaceDetails", {
            ref: "spacedetails-drawer",
            attrs: { "default-open": _vm.spaceDetailsOpen },
            on: { "drawer:close": _vm.handleCloseSpaceDetails }
          })
        : _vm._e(),
      _c("OrganismAvailabilityDrawer", {
        ref: "availability-drawer",
        attrs: { "default-open": _vm.drawerAvailabilityOpen },
        on: { "drawer:close": _vm.handleCloseAvailability }
      }),
      !_vm.isFloorView ? _c("OrganismSelectedSpaceMobile") : _vm._e(),
      _vm.isEmbedReady && !_vm.isSpaceEmbedRoute
        ? _c("div", { staticClass: "embed-controls" }, [
            _c(
              "div",
              {
                staticClass: "control embed-menu-trigger",
                class: { active: _vm.isMobileMenuOpen },
                on: { click: _vm.toggleEmbedSidebar }
              },
              [_vm._m(0)]
            ),
            _c(
              "div",
              {
                staticClass: "control embed-cart-trigger",
                class: { active: _vm.embedCartIsOpen },
                on: { click: _vm.toggleCartSidebar }
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("span", { staticClass: "custom-arrow" }),
                  _c("p", { staticClass: "label green-color" }, [
                    _vm._v("Offer & Space List")
                  ]),
                  _c(
                    "p",
                    { staticClass: "text" },
                    [
                      _c("AtomIcon", { attrs: { icon: "ruler" } }),
                      _c("b", [_vm._v(_vm._s(_vm.totalSpace))]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.unitOfMeasureString) +
                          " GLA\n        "
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _c("OrganismSpace", {
        attrs: { "matterport-space": _vm.data.matterportSpace }
      }),
      _c(
        "span",
        { staticClass: "scroll-element", on: { click: _vm.goToNextSection } },
        [_vm._v("Click here or scroll down for more information")]
      ),
      _c(
        "a",
        {
          staticClass: "footer-bright",
          attrs: { href: "https://brightspaces.tech/", target: "_blank" }
        },
        [_vm._v("Created by ©BrightSpaces")]
      ),
      _vm.isSpaceRoute && _vm.space && _vm.spaceName
        ? _c("div", { staticClass: "orientation-nav" }, [
            _c(
              "div",
              {
                staticClass: "molecule-building-orientation-bar",
                class: { "nav-space-details-open": _vm.spaceDetailsOpen }
              },
              [
                _c("div", { staticClass: "info-space" }, [
                  _vm.spaceName && _vm.space.space_code
                    ? _c("p", [
                        _vm._v("\n          Area: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.spaceName) +
                              " " +
                              _vm._s(_vm.space.alias || _vm.space.space_code)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.surface
                    ? _c("span", [
                        _c("b", [_vm._v(_vm._s(_vm.surface))]),
                        _vm._v(" " + _vm._s(_vm.unitOfMeasureString))
                      ])
                    : _vm._e(),
                  _vm.spaceWorkplaces
                    ? _c("span", [
                        _c("b", [_vm._v(_vm._s(_vm.spaceWorkplaces))]),
                        _vm._v(" " + _vm._s(_vm.spaceWorkplacesName))
                      ])
                    : _vm._e()
                ]),
                _c("span", { staticClass: "vertical-line" }),
                _vm.space
                  ? _c("div", { staticClass: "content-buttons" }, [
                      _vm.allowViewIn3DButton(
                        _vm.space,
                        _vm.currentSpace.floor_id
                      )
                        ? _c(
                            "div",
                            [
                              _c("AtomButton", {
                                staticClass: "explore-btn",
                                attrs: {
                                  icon: "vr",
                                  label: "Explore in 3D",
                                  "custom-label": "Explore",
                                  "on-click": function() {
                                    return _vm.exploreSpace(
                                      _vm.space.id,
                                      _vm.space.project_id
                                    )
                                  },
                                  "activate-hover-event": ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allowRequestOfferButton(
                        _vm.space,
                        _vm.currentSpace.floor_id
                      )
                        ? _c(
                            "div",
                            [
                              _c("AtomButton", {
                                staticClass: "request-offer-btn button-green",
                                attrs: {
                                  icon: "plane-paper",
                                  label: "Request Offer",
                                  "custom-label": "Request",
                                  "on-click": function() {
                                    return _vm.openRequestOfferModal(
                                      _vm.space,
                                      _vm.space.building_id
                                    )
                                  },
                                  "activate-hover-event": ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm.isSpaceRoute
        ? _c(
            "div",
            {
              staticClass: "drawer-button home-button-mobile",
              class: { active: _vm.spaceDetailsOpen },
              on: { click: _vm.handleExpandSpaceDetails }
            },
            [_c("AtomIcon", { attrs: { icon: "arrow-up", color: "#fff" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("span", { staticClass: "custom-arrow" }),
      _c("p", { staticClass: "label" }, [_c("b", [_vm._v("Spaces")])]),
      _c("p", { staticClass: "text" }, [_vm._v("Check Availability")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }