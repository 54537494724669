import { AnimationBuilder } from "@/components/organisms/project/building/store/3d/builders/AnimationBuilder";
import { InteractionManager } from "@/components/organisms/project/building/store/3d/managers/InteractionManager";
import { PinManager } from "@/components/organisms/project/building/store/3d/managers/PinManager";
import { HighlightManager } from "@/components/organisms/project/building/store/3d/managers/HighlightManager";
import { MinimapViewport } from "@/components/organisms/project/building/store/3d/builders/MinimapViewport";

export class CameraManager {
  animationBuilder: AnimationBuilder;
  cameraRadiusLimit = 0;
  defaultTarget = {
    x: 0,
    y: 0,
    z: 0,
  };
  constructor(animationBuilder: AnimationBuilder) {
    this.animationBuilder = animationBuilder;
  }

  moveUniversalCamera(
    camera: BABYLON.UniversalCamera,
    scene: BABYLON.Scene,
    target: { x: number; y: number; z: number },
    onanimationend?: () => void
  ) {
    camera.animations = this.animationBuilder.createMoveUniversalCamera(
      camera,
      {
        target,
      }
    );
    scene.beginAnimation(
      camera,
      this.animationBuilder.fromFrame,
      this.animationBuilder.toFrame,
      this.animationBuilder.isLoop,
      camera.speed * 10,
      onanimationend
    );
  }

  moveArcRotateCamera(
    camera: BABYLON.ArcRotateCamera,
    options: {
      radius: number;
      alpha: number;
      beta: number;
      target: {
        x: number;
        y: number;
        z: number;
      };
    },
    scene: BABYLON.Scene,
    onAnimationEnd?: () => void
  ) {
    camera.animations = this.animationBuilder.createMoveArcRotateCamera(
      camera,
      options
    );
    scene.beginAnimation(
      camera,
      this.animationBuilder.fromFrame,
      this.animationBuilder.toFrame,
      this.animationBuilder.isLoop,
      this.animationBuilder.speedRatio,
      onAnimationEnd
    );
  }

  moveCameraTo2D(
    camera: BABYLON.ArcRotateCamera,
    scene: BABYLON.Scene,
    onAnimationEnd?: () => void
  ) {
    const options = {
      radius: camera.radius,
      alpha: 1.57,
      beta: 0,
      target: this.defaultTarget,
    };
    this.moveArcRotateCamera(camera, options, scene, onAnimationEnd);
  }

  moveCameraTo3D(
    camera: BABYLON.ArcRotateCamera,
    scene: BABYLON.Scene,
    onAnimationEnd?: () => void
  ) {
    const options = {
      radius: camera.radius,
      alpha: camera.alpha,
      beta: 1,
      target: this.defaultTarget,
    };
    this.moveArcRotateCamera(camera, options, scene, onAnimationEnd);
  }

  zoomCamera(zoomValue: number, camera: BABYLON.ArcRotateCamera, zoomFactor: number) {
    camera.upperRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
    camera.lowerRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
  }

  lockCameraAxis(
    camera: BABYLON.ArcRotateCamera,
    upperBetaLimit: number,
    lowerBetaLimit: number
  ) {
    camera.upperBetaLimit = upperBetaLimit;
    camera.lowerBetaLimit = lowerBetaLimit;
  }

  setActiveCamera(
    interactionManager: InteractionManager,
    disabledNodes: Record<string, string>,
    cameraType: "ArcRotate" | "Universal",
    scene: BABYLON.Scene,
    cameraFirstPerson: BABYLON.UniversalCamera,
    camera3DView: BABYLON.ArcRotateCamera
  ) {
    if (cameraType == "ArcRotate") {
      //// Hide ceiling
      interactionManager.enableNodes(
        [disabledNodes["ceiling_"]],
        scene,
        false,
        false,
        false
      );
      interactionManager.detachControl(cameraFirstPerson as BABYLON.UniversalCamera);
      interactionManager.attachControl(camera3DView as BABYLON.ArcRotateCamera);
    } else if (cameraType == "Universal") {
      //// Show ceiling
      interactionManager?.enableNodes(
        [disabledNodes["ceiling_"]],
        scene,
        true,
        false,
        false
      );
      interactionManager.detachControl(camera3DView as BABYLON.ArcRotateCamera);
      interactionManager.attachControl(cameraFirstPerson as BABYLON.UniversalCamera);
    }
  }

  switchCamera(
    view: string,
    pinManager: PinManager,
    highlightManager: HighlightManager,
    scene: BABYLON.Scene,
    interactionManager: InteractionManager,
    cameraFirstPerson: BABYLON.UniversalCamera,
    camera3DView: BABYLON.ArcRotateCamera,
    minimap: MinimapViewport,
    disabledNodes: Record<string, string>,
    pointerCircleVisible: (isVisible: boolean) => void
  ) {
    switch (view) {
      case "2D":
        pinManager.enablePins(false);
        pointerCircleVisible(false);
        highlightManager.enableHighlights("highlights", scene, true);
        this.setActiveCamera(
          interactionManager,
          disabledNodes,
          "ArcRotate",
          scene,
          cameraFirstPerson,
          camera3DView
        );
        this.moveCameraTo2D(camera3DView, scene, () => {
          this.lockCameraAxis(camera3DView, 0, 0);
        });
        scene.activeCameras = [];
        scene.activeCameras?.push(camera3DView);
        scene.getMeshByName("skybox")?.setEnabled(false);
        //// Display last selected highlight
        if (highlightManager.selectedMesh)
          highlightManager.selectMesh(highlightManager.selectedMesh, scene, false);
        break;
      case "default":
        pinManager.enablePins(false);
        pointerCircleVisible(false);
        highlightManager.enableHighlights("highlights", scene, true);
        this.setActiveCamera(
          interactionManager,
          disabledNodes,
          "ArcRotate",
          scene,
          cameraFirstPerson,
          camera3DView
        );
        this.moveCameraTo3D(camera3DView, scene);
        this.lockCameraAxis(camera3DView, 1.5708, 0);
        scene.activeCameras = [];
        scene.activeCameras?.push(camera3DView);
        scene.getMeshByName("skybox")?.setEnabled(false);
        //// Display last selected highlight
        if (highlightManager.selectedMesh)
          highlightManager.selectMesh(highlightManager.selectedMesh, scene, false);
        break;
      case "firstPerson":
        pointerCircleVisible(true);
        pinManager.enablePins(true);
        this.setActiveCamera(
          interactionManager,
          disabledNodes,
          "Universal",
          scene,
          cameraFirstPerson,
          camera3DView
        );
        highlightManager.hideMesh(true);
        highlightManager.hideMesh(false);
        highlightManager.enableHighlights("highlights", scene, false);
        scene.getMeshByName("skybox")?.setEnabled(true);
        minimap.updateMinimap();
        scene.activeCameras = [];
        scene.activeCameras?.push(cameraFirstPerson);
        scene.activeCameras?.push(minimap.camera);
        break;
      default:
        this.setActiveCamera(
          interactionManager,
          disabledNodes,
          "ArcRotate",
          scene,
          cameraFirstPerson,
          camera3DView
        );
        this.moveCameraTo3D(camera3DView, scene);
    }
  }
}
