<template>
  <div class="atom-image-slider slide" v-lazyload="{handle: handleLazyLoad, url: resourceUrl(sliderImage.image)}">
    <div class="image-relative">
      <AtomLazyLoader v-if="!imageLoaded" />
      <img :src="resourceUrl('images/placeholder-for-full-size-image.png')" alt="Placeholder"/>
      <div v-if="imageLoaded" class="background-image" :style="{'background-image': `url(${resourceUrl(sliderImage.image)})`, 'background-size': backgroundSize}"></div>
    </div>
    <div v-if="sliderImage.description" class="description">{{sliderImage.description}}</div>
  </div>
</template>

<script>
import AtomLazyLoader from "../AtomLazyLoader";
export default {
  name: "AtomImageSlider",
  components: {AtomLazyLoader},
  props: {
    sliderImage: {
      required: true,
      type: Object,
      default: () => {
        return {
          image: "",
          thumb: ""
        }
      }
    },
    backgroundSize: {
      required: false,
      type: String,
      default: "cover"
    }
  },
  data() {
    return {
      isInView: false,
      imageLoaded: false,
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    handleLazyLoad() {
      this.isInView = true;
      const imageElement = new Image;
      if (imageElement) {
        imageElement.addEventListener("load", () => {
          this.imageLoaded = true;
        });
        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = this.resourceUrl(this.sliderImage.image);
      }
    }
  }
}
</script>

<style lang="scss">
  .atom-image-slider {
    position: relative;
    .lazy-loader-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .image-relative {
      position: relative;
    }
    .description {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.6);
      padding: 0.938rem;
      @include font-main(1.125rem, $white, $regular, 1.225rem);
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
</style>