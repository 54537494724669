<template>
  <div :class="{'molecule-menu-trigger': true, 'active': isOpen}" @click="toggleSidebar">
    <AtomIcon icon="bars3" :size="32" color="#000" />
    <AtomIcon icon="xmark" :size="32" color="#000" />
  </div>
</template>

<script>
import AtomIcon from "../atoms/common/AtomIcon";
import {baseConstants} from "../../store/modules";
export default {
  name: "MoleculeMenuTrigger",
  components: {AtomIcon},
  methods: {
    toggleSidebar() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR));
    }
  },
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen;
    }
  }
}
</script>

<style lang="scss">
  .molecule-menu-trigger {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background: rgba(2, 2, 2, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon {
    font-size: 0.8rem !important;
    @media only screen and (max-width: 767px) {
      font-size: 0.75rem !important;
    }
    &:before {
      margin: 0;
      width: 1.5rem;
      @media only screen and (max-width: 767px) {
        width: 2.5rem;
      }
    }
    &.icon-xmark {
      font-size: 1.125rem !important;
      display: none;
    }
  }
  &.active {
    z-index: 9999;
    background: rgba(2, 2, 2, 0.04);
    .icon {
      &.icon-xmark {
        display: block;
      }
      &.icon-bars3 {
        display: none;
      }
    }
  }
  &:hover {
    background: rgba(0,0,0,0.9);
    @media only screen and (max-width: 1200px) {
      background: $black;
    }
    .icon {
      color: $white !important;
      @media only screen and (max-width: 1200px) {
        color: $white !important;
      }
    }
  }
}
</style>