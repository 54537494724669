<template>
  <div v-if="!section"></div>
  <SectionBrochure v-else-if="section === 'Brochure'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionVideo v-else-if="section === 'Video'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionBackgroundImage v-else-if="section === 'BackgroundImage'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionInfoAndImage v-else-if="section === 'InfoAndImage'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionPostAndImage v-else-if="section === 'PostAndImage'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionDiscoverArea v-else-if="section === 'DiscoverArea'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionFewFacts v-else-if="section === 'FewFacts'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionPurposeAndValues v-else-if="section === 'PurposeAndValues'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionIconsDescriptions v-else-if="section === 'IconsDescriptions'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionExploreBuilding v-else-if="section === 'Explore'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionStatements v-else-if="section === 'Statements'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionContact v-else-if="section === 'Contact'" :data="activeDataSet" :hash="hash" :order-no="orderNo" :variant="variant" />
  <SectionSustainability v-else-if="section === 'Sustainability'" :data="activeDataSet" :hash="hash" :order-no="orderNo" :variant="variant" />
  <SectionSlider v-else-if="section === 'Slider'" :data="activeDataSet" :order-no="orderNo" />
  <SectionCustomModel v-else-if="section === 'CustomModel'" :data="activeDataSet" :hash="hash" :order-no="orderNo" />
  <SectionImageSide v-else-if="section === 'ImageSide'" :data="activeDataSet" :hash="hash" :order-no="orderNo"  />
  <SectionWhyUs v-else-if="section === 'WhyUs'" :data="activeDataSet" :hash="hash" :order-no="orderNo"  />
  <SectionTenants v-else-if="section === 'Tenants'" :data="activeDataSet" :hash="hash" :order-no="orderNo"  />
</template>

<script>
import SectionBrochure from "./section/SectionBrochure";
import SectionSlider from "./section/SectionSlider";
import SectionCustomModel from "./section/SectionCustomModel";
import SectionContact from "../project/section/SectionContact";
import SectionVideo from "../sections/SectionVideo";
import SectionInfoAndImage from "../sections/SectionInfoAndImage";
import SectionPostAndImage from "../sections/SectionPostAndImage";
import SectionFewFacts from "../sections/SectionFewFacts";
import SectionPurposeAndValues from "../sections/SectionPurposeAndValues";
import SectionBackgroundImage from "../sections/SectionBackgroundImage";
import SectionDiscoverArea from "../sections/SectionDiscoverArea";
import SectionExploreBuilding from "../sections/SectionExploreBuilding";
import SectionStatements from "../sections/SectionStatements";
import SectionImageSide from "../sections/SectionImageSide";
import SectionIconsDescriptions from "../sections/SectionIconsDescriptions";
import SectionSustainability from "../project/section/SectionSustainability";
import SectionWhyUs from "../sections/SectionWhyUs";
import SectionTenants from "../sections/SectionTenants";
export default {
  name: "SectionSelector",
  components: {
    SectionTenants,
    SectionWhyUs,
    SectionSustainability,
    SectionIconsDescriptions,
    SectionCustomModel,
    SectionSlider,
    SectionStatements,
    SectionExploreBuilding,
    SectionDiscoverArea,
    SectionBackgroundImage,
    SectionPurposeAndValues,
    SectionFewFacts,
    SectionInfoAndImage,
    SectionPostAndImage,
    SectionVideo,
    SectionContact,
    SectionBrochure,
    SectionImageSide
  },
  props: {
    section: {
      required: true,
      type: String,
      default: ""
    },
    variant: {
      required: true,
      type: String,
      default: ""
    },
    multiProject: {
      required: false,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object || Array,
      default: function () {
        return {}
      },
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    }
  },
  computed: {
    activeDataSet() {
      if (this.multiProject) {
        return this.data[this.$store.state.project.activeProject];
      }
      return this.data;
    }
  }
}
</script>