var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-list-circle" },
    [
      _c("MoleculeCertifications", [
        Object.keys(_vm.listCircleData).length > 1
          ? _c("div", [
              _c(
                "div",
                { staticClass: "row row-more-certification" },
                _vm._l(_vm.listCircleData, function(list, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col col-xs-12 col-6" },
                    [
                      _c("div", { staticClass: "content-certification" }, [
                        _c("img", {
                          attrs: { src: _vm.resourceUrl(list.icon), alt: "" }
                        }),
                        _c(
                          "div",
                          { staticClass: "content-text" },
                          _vm._l(list.lines, function(line, index) {
                            return _c("p", {
                              key: index,
                              domProps: { innerHTML: _vm._s(line) }
                            })
                          }),
                          0
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          : _c(
              "div",
              _vm._l(_vm.listCircleData, function(list, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "row row-one-certification align-items-center"
                  },
                  [
                    _c("div", { staticClass: "col col-xs-12 col-6" }, [
                      _c("img", {
                        attrs: { src: _vm.resourceUrl(list.icon), alt: "" }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "col col-xs-12 col-6" },
                      _vm._l(list.lines, function(line, index) {
                        return _c("p", {
                          key: index,
                          domProps: { innerHTML: _vm._s(line) }
                        })
                      }),
                      0
                    )
                  ]
                )
              }),
              0
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }