<template>
  <div class="simple-wrapper">
    <h4 v-if="pinInfo.pinTitle" v-html="pinInfo.pinTitle"></h4>
    <p v-if="pinInfo.pinDescription" v-html="pinInfo.pinDescription"></p>
    <div v-if="pinInfo.buttonLink" class="button-wrapper">
      <AtomCustomButton label="View more" icon="right" :href="pinInfo.buttonLink" />
    </div>
  </div>

</template>

<script>
import AtomCustomButton from "../../common/AtomCustomButton";
export default {
  name: "AtomPinSimpleText",
  components: {AtomCustomButton},
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function (){
        return {
          pinTitle: "",
          pinDescription: "",
          buttonLink:""
        }
      }
    },
  }
}
</script>

<style lang="scss">
  .button-wrapper{
    margin-top: 10px;
  }

</style>