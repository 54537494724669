var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-explore" },
    [
      _c("MoleculeExplore", [
        _c(
          "div",
          { staticClass: "row row-card-explore" },
          _vm._l(_vm.exploreCard, function(card, index) {
            return _c(
              "div",
              { key: index, staticClass: "col col-xs-12", class: _vm.columns },
              [
                _c(
                  "div",
                  {
                    staticClass: "card-explore",
                    class: { "url-hover": card.url }
                  },
                  [
                    card.url
                      ? _c("a", {
                          staticClass: "link",
                          attrs: { href: "" + card.url }
                        })
                      : _vm._e(),
                    card.time
                      ? _c(
                          "span",
                          { staticClass: "time" },
                          [
                            _c("AtomIcon", { attrs: { icon: "walking" } }),
                            _vm._v(" " + _vm._s(card.time))
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "background-image",
                      style: {
                        "background-image":
                          "url(" + _vm.resourceUrl(card.image) + ")"
                      }
                    }),
                    _c("div", { staticClass: "background-gradient" }),
                    _c("img", {
                      attrs: {
                        src: _vm.resourceUrl(
                          "images/placeholder-for-image.png"
                        ),
                        alt: "Placeholder"
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: { "bottom-title": true, "text-left": card.time }
                      },
                      [
                        _c("h6", {
                          domProps: { innerHTML: _vm._s(card.title) }
                        }),
                        card.text
                          ? _c("div", {
                              staticClass: "content",
                              domProps: { innerHTML: _vm._s(card.text) }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }