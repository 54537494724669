<template>
  <div v-if="right" class="atom-arrow-button-slider-right">
    <button  @mouseover="hovered" @mouseout="unhovered" @click="clickedNext"> › </button>
  </div>
  <div v-else class="atom-arrow-button-slider-left">
    <button  @mouseover="hovered" @mouseout="unhovered" @click="clickedPrevious"> ‹ </button>
  </div>

  <!--
  <transition appear name="hover">

  <button v-if="right" class="atom-arrow-button-slider-right" v-on:mouseover="hovered" v-on:mouseout="unhovered"> > </button>
  <button v-else class="atom-arrow-button-slider-left" v-on:mouseover="hovered" v-on:mouseout="unhovered"> < </button>


  </transition>
  -->
  <!--
<div>
  <button @click="show = !show">Toggle</button>

  <transition appear name="fade-in" :duration="999">
      <h1>Root fading in</h1>
  </transition>
</div>
-->
  <!--
  <div>
    <transition name="fade">
      <div v-show="showMessage">
        <p >This is Header</p>
        <span >This is Body</span>
      </div>
    </transition>

    <div class="block" @click="showMessage = !showMessage">
      <a class="button is-primary">Primary</a>
    </div>

  </div>
  -->
  <!--
  <div>
  <button @click="show = !show">Toggle show</button>
  <transition appear name="bounce">
    <p v-if="show">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris facilisis enim libero, at lacinia diam fermentum id. Pellentesque habitant morbi tristique senectus et netus.</p>
  </transition>
  </div>
  -->
</template>

<script>
export default {
  name: "AtomArrowButtonSlider",
  props: {
    right: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      bHovered: false,
      show: false,
      showMessage: false
    }
  },
  methods: {
    hovered: function(){
      this.bHovered = true;
      this.$el.classList.add('hovered');
    },
    unhovered: function(){
      this.bHovered = false;
      this.$el.classList.remove('hovered');
    },
    clickedPrevious: function (){
      this.$emit('Previous');
    },
    clickedNext: function (){
      this.$emit('Next');
    }
  }
}
</script>

<style lang="scss">
.atom-arrow-button-slider-right, .atom-arrow-button-slider-left{
  button{
    width: 62px;
    height: 62px;
    line-height: 58px;
    background-color: hsla(0,0%,100%,.7);
    font-size: 55px;
    font-weight: $medium;

    font-family: Montserrat,Arial,sans-serif;
  //  margin-top: 219px;
  }

  //position: absolute;
//  height: 500px;
  margin: auto;
  z-index: 10;


  @media only screen and (max-width: 1000px) {
 //   height: 450px;
    button {
   //   margin-top: 194px;
      width: 30px;
    }
  }
  @media only screen and (max-width: 850px) {
    button {
  //    margin-top: 169px;
    }
  //  height: 400px;
  }
  @media only screen and (max-width: 760px) {
    button {
   //   margin-top: 144px;
    }
  //  height: 350px;
  }
  @media only screen and (max-width: 660px) {
  //  height: 300px;
    button {
  //    margin-top: 119px;
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 580px) {
    button {
   //   margin-top: 94px;
    }
 //   height: 250px;
  }
  @media only screen and (max-width: 480px) {
    button {
    //  margin-top: 69px;
    }
  //  height: 200px;
  }
  @media only screen and (max-width: 400px) {
    button {
    //  margin-top: 44px;
    }
 //   height: 150px;
  }
  @media only screen and (max-width: 300px) {
    button {
     // margin-top: 19px;
    }
   // height: 100px;
  }
}
.atom-arrow-button-slider-right.hovered, .atom-arrow-button-slider-left.hovered{
  button {
    background-color: hsla(0, 0%, 100%, 1);
  }
}

.atom-arrow-button-slider-left{
  margin-left: 0;
}
.atom-arrow-button-slider-right{
  margin-right: 0;
}
/*
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ /*{

  opacity: 0
}
*/
/*
@keyframes fade {
  from {
    opacity: 0;
  }
}

.fade-in-enter-active {
  animation: fade 1s;
}

.fade-in-leave-active {
  animation: fade 1s reverse;
}
 */
/*
.bounce-enter-from{
  opacity: 0;
}
.bounce-enter-to{
  opacity: 1;
}

 */
.bounce-enter-active {
  /*transition: all 0.3s ease;*/
  animation: bounce-in 2s;
}
/*
.bounce-leave-from{
  opacity: 1;
}
.bounce-leave-to{
  opacity: 0;
}

 */
.bounce-leave-active {
  /*transition: all 0.3s ease;*/
  animation: bounce-in 2s reverse;
}
@keyframes bounce-in {
  0% {
    background-color: hsla(0,0%,100%,.7);
  }
  100% {
    background-color: hsla(0,0%,100%,1);
  }
}
</style>