var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-statements-slider" },
    [
      _c(
        "MoleculeStatementsSlider",
        _vm._l(_vm.sliderStatementsData, function(sliderStatement, index) {
          return _c("AtomStatementsSlider", { key: index }, [
            _c(
              "div",
              { staticClass: "quotation-mark" },
              [_c("AtomIcon", { attrs: { icon: "quotation-mark" } })],
              1
            ),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-xs-12 col-6" }, [
                _c("div", { staticClass: "image-carousel" }, [
                  _c("div", { staticClass: "border-element" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        src: _vm.resourceUrl(sliderStatement.image),
                        alt: "Image"
                      }
                    }),
                    sliderStatement.logo
                      ? _c("img", {
                          staticClass: "logo",
                          attrs: {
                            src: _vm.resourceUrl(sliderStatement.logo),
                            alt: "Logo"
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "col col-xs-12 col-6" }, [
                _c(
                  "div",
                  { staticClass: "content-slider" },
                  [
                    _c("div", { staticClass: "user-info" }, [
                      _c(
                        "div",
                        { staticClass: "icon-user" },
                        [
                          sliderStatement.userImage
                            ? _c("div", {
                                staticClass: "user-image",
                                style: {
                                  "background-image": _vm.resourceUrl(
                                    sliderStatement.userImage
                                  )
                                }
                              })
                            : _c("AtomIcon", {
                                attrs: { icon: "user-profile" }
                              })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-name" }, [
                        _c("h4", [_vm._v(_vm._s(sliderStatement.userName))]),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(sliderStatement.userJob)
                          }
                        })
                      ])
                    ]),
                    _vm._l(sliderStatement.lines, function(line, index) {
                      return _c("p", {
                        key: index,
                        domProps: { innerHTML: _vm._s(line) }
                      })
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }