<template>
  <div :class="{section: true, 'section-sustainability-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.title || data.subtitle">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <OrganismCertifications :list-circle-data="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import OrganismCertifications from "../../../components/organisms/common/OrganismCertifications";
export default {
  name: "SectionSustainabilityV2",
  components: {OrganismCertifications, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
  .section-sustainability-v2 {
    padding: 4rem 3.75rem 0;
    @media only screen and (max-width: 767px) {
      padding: 40px 10px 0;
    }
    .top-title {
      .atom-title {
        margin-bottom: 6.25rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }
        h2 {
          color: $black !important;
          font-weight: 400;
          font-size: 3.25rem;
          line-height: 3.875rem;
          text-transform: none;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
          }
          b {
            font-size: 3.25rem;
            line-height: 3.875rem;
            @media only screen and (max-width: 767px) {
              font-size: 22px;
              line-height: 26px;
            }
          }
        }
        h3 {
          color: $black !important;
          font-weight: 400;
          font-size: 1.375rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
    .organism-list-circle {
      .row-more-certification {
        margin: 0 -0.5rem;
        @media only screen and (max-width: 767px) {
          margin: 0;
        }
        .col {
          padding: 0 0.5rem;
          @media only screen and (max-width: 767px) {
            padding: 0;
          }
        }
        .content-certification {
          position: relative;
          padding-left: 12.75rem;
          min-height: 11.25rem;
          @media only screen and (max-width: 767px) {
            padding: 0;
            min-height: auto;
          }
          img {
            width: 10.125rem;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            @media only screen and (max-width: 767px) {
              position: relative;
              width: 100px;
              margin: 0 auto 20px;
            }
          }
          .content-text {
            p {
              margin: 0 0 0.625rem;
              @media only screen and (max-width: 767px) {
                margin-bottom: 5px;
              }
              &:first-child {
                color: $blueDark;
                margin-bottom: 1.5rem;
                font-weight: 400;
                font-size: 2rem;
                line-height: 2.375rem;
                @media only screen and (max-width: 767px) {
                  font-size: 18px;
                  line-height: 22px;
                  margin-bottom: 10px;
                }
                b {
                  font-size: 2rem;
                  line-height: 2.375rem;
                  @media only screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 22px;
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>