var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "organism-drawer" }, [
    _c("div", { class: { "drawer-content": true, open: _vm.isOpen } }, [
      _vm.isOpen
        ? _c(
            "div",
            {
              staticClass: "close-button",
              on: {
                click: function($event) {
                  return _vm.$emit("drawer:close")
                }
              }
            },
            [
              _c("AtomIcon", {
                attrs: { icon: "xmark", color: "#fff", size: 20 }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "drawer-content-wrapper" },
        [_vm._t("default")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }