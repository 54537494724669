<template>
  <div :class="{'horizontal-menu-molecule': true, sm, 'no-margin-ends': noMarginEnds}">
    <slot v-if="!tabData.length"></slot>
    <AtomMenuItem
        v-else
        v-for="(tab, index) in tabData"
        :key="index"
        :label="tab.label"
        :icon="tab.icon"
        :tab-id="tab.tabId"
        :on-click="handleTabClick"
        :class="{'active': selectedTab === tab.tabId}"
    />
  </div>
</template>

<script>

import AtomMenuItem from "../../atoms/common/AtomMenuItem";

export default {
  name: "MoleculeHorizontalMenu",
  components: { AtomMenuItem },
  props: {
    sm: {
      required: false,
      type: Boolean,
      default: false
    },
    noMarginEnds: {
      required: false,
      type: Boolean,
      default: false
    },
    tabData: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedOption: {
      required: false,
      type: String,
      default: "",
    }
  },
  data() {
    let defaultTab = "";
    if (this.selectedOption !== "") {
      defaultTab = this.selectedOption;
    } else if (this.$props.tabData.length) {
      defaultTab = this.$props.tabData[0].tabId;
    }

    return {
      selectedTab: defaultTab,
    }
  },
  watch: {
    selectedOption(newVal) {
      let defaultTab = "";
      if (this.selectedOption !== "") {
        defaultTab = this.selectedOption;
      } else if (this.$props.tabData.length) {
        defaultTab = this.$props.tabData[0].tabId;
      }
      this.selectedTab = defaultTab;
    }
  },
  methods: {
    handleTabClick(tabId) {
      this.selectedTab = tabId;
      this.$emit('tab-change', tabId);
    },
  }
}
</script>

<style lang="scss">
.horizontal-menu-molecule {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 3.75rem;
  padding-left: 0.625rem;
  .menu-item-atom {
    height: 3.75rem;
    padding: 0.125rem 0.5rem !important;
    border-radius: 0.188rem !important;
    @include font-main(0.938rem, $black, $medium, 1.5rem);
    span {
      white-space: nowrap;
    }
    &.router-link-exact-active, &.active {
      color: white;
      background-color: #020202 !important;
      i {
        color: white !important
      }
    }
    &:hover {
      background-color: rgba(2, 2, 2, 0.08);
    }
  }
  &.sm {
    padding-left: 0;
    height: 1.75rem;
    .menu-item-atom {
      height: 1.75rem;
      @media only screen and (max-width: 767px) {
        font-size: 0.875rem;
        span {
          font-size: 0.875rem;
        }
      }
      .icon {
        @media only screen and (max-width: 767px) {
          margin-right: 0.438rem;
          font-size: 0.875rem !important;
        }
      }
    }
  }
  &.no-margin-ends {
    .menu-item-atom {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>