<template>
  <div class="organism-location">
    <AtomImageTitle :image="locationData.image">
      <AtomTitle color="#FFFFFF" color2="#fff" :title="locationData.title" :subtitle="locationData.address" />
    </AtomImageTitle>
    <div class="button-toggle" @click="toggleSidebar">
      <span>{{showSidebar ? "Hide" : "Show"}} points of interest <AtomIcon color="#423687" :size="18" :icon="showSidebar ? 'minus' : 'plus'" /></span>
    </div>
    <MoleculeGoogleMap v-if="activeProject" ref="map" :map-config="locationData.map" :places="locationData.places" :active-center-marker-id="activeProject" />
    <div :class="{sidebar: true, active: showSidebar}">
      <div class="sidebar-locations">
        <MoleculeSidebarRoutes :places="locationData.places" />
      </div>
      <div class="search-sidebar" @click="focusSearch">
        <AtomTitle color="#272626" color2="#272626" :title="`Distance from:`" />
        <div class="result-text" v-if="hasSearchResult">{{ searchResult.addressText }}</div>
        <div class="result-text" v-if="hasSearchResult"><AtomIcon icon="driving" /> {{ searchResult.text }}</div>
        <div class="result-text" v-if="hasSearchError">{{ searchResult.text }}</div>
        <AtomTitle color="#272626" color2="#272626" :subtitle="`Please follow this format: ${ locationData.address }`" />
        <AtomInput ref="searchInput" placeholder="Insert address here" class="search-input" @input="queriedAddress = $event" :value="queriedAddress" />
        <AtomButton label="check" :disabled="searchDisabled" :on-click="handleSearch" />
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeGoogleMap from "../../molecules/common/MoleculeGoogleMap";
import AtomImageTitle from "../../atoms/common/AtomImageTitle";
import AtomTitle from "../../atoms/common/AtomTitle";
import AtomIcon from "../../atoms/common/AtomIcon";
import MoleculeSidebarRoutes from "../../molecules/project/MoleculeSidebarRoutes";
import AtomInput from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";

const isMobile = true;

export default {
  name: "OrganismLocation",
  components: {AtomButton, AtomInput, MoleculeSidebarRoutes, AtomIcon, AtomTitle, AtomImageTitle, MoleculeGoogleMap},
  props: {
    locationData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      showSidebar: !isMobile,
      queriedAddress: '',
      searchResult: {
        success: true,
        text: '',
        addressText: '',
        directionsResponse: {},
        queryResults: null,
      },
    }
  },
  computed: {
    searchDisabled() {
      return !this.queriedAddress;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    hasSearchResult() {
      return this.searchResult.text !== '' && this.searchResult.queryResults;
    },
    hasSearchError() {
      return this.searchResult.text !== '' && !this.searchResult.success;
    },
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    showDirectionsForMarkerId(id) {
      this.$refs.map.triggerMarkerById(id);
    },
    handleSearch() {
      const result = this.$refs.map.distanceFrom(this.queriedAddress);
      this.searchResult = result;
      this.$refs.searchInput.resetInput();
    },
    focusSearch() {
      this.$refs.searchInput.focus();
    }
  }
}
</script>

<style lang="scss">

.organism-location {
  position: relative;
  padding-right: 33.5rem;
  height: 53.125rem;
  @media only screen and (max-width: 900px) {
    padding-right: 25.5rem;
  }
  @media only screen and (max-width: 767px) {
    padding-right: 0;
    height: auto;
  }
  .molecule-google-map {
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      height: 40rem;
    }
  }
  .button-toggle {
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
      span {
        display: block;
        position: relative;
        padding: 0.813rem 2.5rem 0.813rem 0.938rem;
        @include font-main(1rem, #272626, $medium, 1.118rem);
        .icon {
          font-size: 1.125rem !important;
          position: absolute;
          right: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .hide-sidebar-location {
        display: none;
      }
      &.active {
        .show-sidebar-location {
          display: none;
        }
        .hide-sidebar-location {
          display: block;
        }
      }
    }
  }
  .atom-image-title {
    position: absolute;
    right: 0;
    top: 0;
    width: 33.5rem;
    @media only screen and (max-width: 900px) {
      width: 25.5rem;
    }
    @media only screen and (max-width: 767px) {
      position: relative;
      width: 100%;
    }
    .background-gradient {
      padding: 7.938rem 1.899rem 1.608rem;
      @media only screen and (max-width: 767px) {
        padding: 4.688rem 1.25rem 1.875rem;
      }
      .atom-title {
        h2 {
          @include font-main(1.375rem, $black, $medium, 1.625rem);
        }
        h3 {
          margin-top: 0.5rem;
          @include font-main(0.75rem, $black, $regular, 0.875rem);
          @media only screen and (max-width: 767px) {
            text-transform: none;
          }
        }
      }
    }
  }
  .sidebar {
    position: absolute;
    top: 12.5rem;
    right: 0;
    width: 33.5rem;
    height: 76.5%;
    transition: all 0.5s ease-in-out 0s;
    background: $white;
    z-index: 9;
    @media only screen and (max-width: 900px) {
      width: 25.5rem;
    }
    @media only screen and (max-width: 767px) {
      top: initial;
      right: initial;
      left: -110%;
      width: 100%;
      height: 40.938rem;
      overflow: hidden;
      bottom: -0.7rem;
      &.active {
        left: 0;
      }
    }
    .sidebar-locations {
      height: 22.6rem;
      overflow: hidden;
      overflow-y: auto;
      @media only screen and (max-width: 767px) {
        height: 25.625rem;
      }
    }
    .search-sidebar {
      background: rgba(228, 228, 228, 0.2);
      padding: 2.25rem 1.899rem;
      min-height: 18rem;
      @media only screen and (max-width: 767px) {
        background: #e4e4e4;
        min-height: 10.875rem;
        padding: 1.25rem;
      }
      .atom-title {
        h2 {
          text-transform: initial;
          @include font-main(1rem, $black, $medium, 1.188rem);
        }
        h3 {
          margin-top: 0.5rem;
          @include font-main(0.75rem, $black, $regular, 0.875rem);
        }
      }

      .search-input {
        margin: 0.75rem 0;
        input {
          background: transparent;
          padding: 0;
          font-size: 1rem;
          &::placeholder {
            color: $blackOpacity02;
          }
        }
      }

      .result-text {
        font-size: 0.875rem;
      }

      .button-atom {
        padding-left: 1.563rem;
        padding-right: 1.563rem;
      }
    }
  }
}
</style>