import { PinContentManager } from "@/components/organisms/project/building/store/3d/builders/PinContentManager";
import { Pin } from "@/components/organisms/project/building/store/3d/builders/Pin";
import { AnimationBuilder } from "@/components/organisms/project/building/store/3d/builders/AnimationBuilder";

export class PinManager {
  pinTexture: BABYLON.Texture;
  pinContentManager: PinContentManager;
  managedPins: Pin[] = [];

  constructor(
    pinContentManager: PinContentManager,
    url = "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/sthlm/360/plus-sign.jpg"
  ) {
    //// Texture
    this.pinTexture = new BABYLON.Texture(url);
    this.pinTexture.hasAlpha = true;
    this.pinTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;

    this.pinContentManager = pinContentManager;
  }
  addPin(
    radius: number,
    scene: BABYLON.Scene,
    data: {
      text: string;
      title?: string;
      imageSrc?: string;
      videoSrc?: string;
    },
    pinType: string,
    pinMetadata: Record<string, unknown>
  ) {
    const pin = new Pin(
      radius,
      scene,
      this.pinTexture,
      data,
      this.pinContentManager,
      pinType,
      pinMetadata
    );
    this.managedPins.push(pin);
    return pin;
  }
  togglePins() {
    this.enablePins(!this.managedPins[0].pinMesh.isEnabled());
  }
  resetPins(scene: BABYLON.Scene, animationBuilder: AnimationBuilder) {
    for (const pin of this.managedPins) {
      if (pin.isActive) {
        pin.trigger(
          {
            scene: scene,
            animationBuilder: animationBuilder,
          },
          false
        );
        pin.isActive = false;
        this.pinContentManager.dispose();
      }
    }
  }

  enablePins(isEnabled: boolean) {
    for (const pin of this.managedPins) {
      pin.pinMesh.setEnabled(isEnabled);
    }
  }

  resetPinsOrientation(position: BABYLON.Vector3) {
    for (const pin of this.managedPins) {
      pin.resetOrientation(position);
    }
  }
}
