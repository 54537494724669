import Vuex, { ActionContext } from "vuex";

export enum FloorViewMode {
  TWO_DIMENSIONAL = "2D",
  DEFAULT = "default",
  FIRST_PERSON = "firstPerson",
}

export enum CurrentView {
  BUILDING = "building",
  FLOOR = "floor",
}

export type DefaultState = {
  currentView: CurrentView;
  canvasElement: HTMLCanvasElement | null;
  cdnBase: string;
  buildingCode: string;
  project: any;
  activeProject: string;
  client: string;
  space: Record<string, any> | null;
  isInitialized: boolean;
  floorViewMode: FloorViewMode;
  fitoutData: any;
  loadingScreenActive: boolean;
  finishedLoadingAssets: boolean;
  settings: any;
  projects: any[];
  buildings: any[];
  floors: any[];
};

const defaultState: DefaultState = {
  currentView: CurrentView.BUILDING,
  canvasElement: null,
  cdnBase: "",
  buildingCode: "",
  project: {},
  activeProject: "",
  client: "",
  space: {},
  isInitialized: false,
  floorViewMode: FloorViewMode.DEFAULT,
  fitoutData: {
    initialized: false,
    data: {
      fitoutOptions: [],
      fitouts: {},
    },
  },
  loadingScreenActive: true,
  finishedLoadingAssets: false,
  settings: {},
  projects: [],
  buildings: [],
  floors: [],
};

const buildingStore = function () {
  return new Vuex.Store({
    state: defaultState,
    mutations: {
      clearSpaceData(state: DefaultState) {
        state.space = {};
      },
      setInitialState(state: DefaultState, payload: DefaultState) {
        state.currentView = payload.currentView;
        state.canvasElement = payload.canvasElement;
        state.cdnBase = payload.cdnBase;
        state.buildingCode = payload.buildingCode;
        state.project = payload.project;
        state.activeProject = payload.activeProject;
        state.client = payload.client;
        state.space = payload.space;
        state.isInitialized = true;
        state.settings = payload.settings;
        state.floorViewMode = FloorViewMode.DEFAULT;
        state.projects = payload.projects;
        state.buildings = payload.buildings;
        state.floors = payload.floors;
      },

      clearState(state: DefaultState) {
        state.currentView = CurrentView.BUILDING;
        state.canvasElement = null;
        state.cdnBase = "";
        state.buildingCode = "";
        state.project = {};
        state.activeProject = "";
        state.client = "";
        state.space = {};
        state.isInitialized = false;
        state.fitoutData = {
          initialized: false,
          data: {
            fitoutOptions: [],
            fitouts: {},
          },
        };
        state.loadingScreenActive = true;
        state.finishedLoadingAssets = false;
        state.settings = {};
        state.floorViewMode = FloorViewMode.DEFAULT;
        state.projects = [];
        state.buildings = [];
        state.floors = [];
      },
      setSpace(state: DefaultState, payload: DefaultState) {
        state.space = payload;
      },
      setCurrentView(state: DefaultState, payload: CurrentView) {
        state.currentView = payload;
      },
      setFitoutData(state: DefaultState, payload: any) {
        state.fitoutData = payload;
      },
      setLoadingScreenStatus(state: DefaultState, payload: boolean) {
        state.loadingScreenActive = payload;
      },
      setLoadingAssetsStatus(state: DefaultState, payload: boolean) {
        state.finishedLoadingAssets = payload;
      },
      setFloorViewMode(state: DefaultState, payload: FloorViewMode) {
        state.floorViewMode = payload;
      },
    },
    actions: {
      clearSpaceData({ commit }: ActionContext<any, any>) {
        commit("clearSpaceData");
      },
      changeLoadingScreenStatus(
        { commit }: ActionContext<any, any>,
        payload: boolean
      ) {
        commit("setLoadingScreenStatus", payload);
      },
      viewSpace({ commit, state }: ActionContext<any, any>, payload: any) {
        const data = {
          space: payload.space,
          floor: payload.floor,
          building: payload.building,
          floorType: payload.floorType,
          spaceData: payload.spaceData,
        };
        commit("setSpace", data);
      },

      initBuilding({ commit, state }: ActionContext<any, any>, payload: any) {
        commit("setCurrentView", CurrentView.BUILDING);
        const newState = {
          currentView: CurrentView.BUILDING,
          canvasElement: payload.canvasReference,
          cdnBase: payload.cdnBase,
          project: payload.project,
          activeProject: payload.activeProject,
          client: payload.client,
          space: state.space,
          isInitialized: true,
          settings: payload.settings,
          projects: payload.projects,
          buildings: payload.buildings,
          floors: payload.floors,
        } as DefaultState;
        commit("setInitialState", newState);
      },

      initFloor({ commit, state }: ActionContext<any, any>, payload: any) {
        commit("setCurrentView", CurrentView.FLOOR);
        commit("setInitialState", {
          currentView: CurrentView.FLOOR,
          canvasElement: payload.canvasReference,
          cdnBase: payload.cdnBase,
          project: payload.project,
          activeProject: payload.activeProject,
          client: payload.client,
          space: state.space,
          isInitialized: true,
          settings: payload.settings,
          projects: payload.projects,
          buildings: payload.buildings,
          floors: payload.floors,
          fitoutData: {},
        } as DefaultState);
      },
      destroy({ state, commit }: ActionContext<any, any>) {
        commit("clearState");
      },
      changeViewMode(
        { state, commit }: ActionContext<any, any>,
        payload: FloorViewMode
      ) {
        commit("setFloorViewMode", payload);
      },
      changeCurrentViewMode(
        { state, commit }: ActionContext<any, any>,
        payload: CurrentView
      ) {
        commit("setCurrentView", payload);
      },
    },
    modules: {},
    getters: {
      activeProjectSpacesByMeshCode(state): Record<string, any> {
        console.log("activeProjectSpacesByMeshCode", state);
        const project = state.projects.find(
          (p: { slug: string }) => p.slug === state.activeProject
        );
        if (!project) return {};
        const spacesByCode: Record<string, any> = {};
        state.project.spaces
          .filter((sp: any) => sp.project_id === project.id)
          .forEach((sp: any) => {
            const codeParts = sp.code.split("/");
            if (codeParts.length != 3) return;

            spacesByCode[
              `${codeParts[0]}_${codeParts[1]}_${codeParts[2]}`
            ] = sp;
          });
        return spacesByCode;
      },
      spacesByMeshCode(state): Record<string, any[]> {
        const project = state.projects.find(
          (p: { slug: string }) => p.slug === state.activeProject
        );
        if (!project) return {};
        const spacesByCode: Record<string, any[]> = {};
        state.project.spaces
          .filter((sp: any) => sp.project_id === project.id)
          .forEach((sp: any) => {
            const codeParts = sp.code.split("/");
            if (codeParts.length != 3) return;

            const typeGroup =
              spacesByCode[`${codeParts[0]}_${codeParts[1]}_${codeParts[2]}`] ||
              [];

            // Find existing group for this space_code
            const existingGroup = typeGroup.find(
              (group: any) =>
                Array.isArray(group) && group[0].space_code === sp.space_code
            );

            if (existingGroup) {
              // Add the space to the existing group
              existingGroup.push(sp);
            } else {
              // Create a new group for this space_code
              typeGroup.push([sp]);
            }

            spacesByCode[
              `${codeParts[0]}_${codeParts[1]}_${codeParts[2]}`
            ] = typeGroup;
          });

        return spacesByCode;
      },
    },
  });
};

const store = buildingStore();
export default store;
