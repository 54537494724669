<template>
  <div class="organism-statements-slider">
    <MoleculeStatementsSlider>
      <AtomStatementsSlider v-for="(sliderStatement, index) in sliderStatementsData" :key="index">
        <div class="quotation-mark">
          <AtomIcon icon="quotation-mark" />
        </div>
        <div class="row">
          <div class="col col-xs-12 col-6">
            <div class="image-carousel">
              <div class="border-element">
                <img class="image" :src="resourceUrl(sliderStatement.image)" alt="Image">
                <img v-if="sliderStatement.logo" class="logo" :src="resourceUrl(sliderStatement.logo)" alt="Logo">
              </div>
            </div>
          </div>
          <div class="col col-xs-12 col-6">
            <div class="content-slider">
              <div class="user-info">
                <div class="icon-user">
                  <div v-if="sliderStatement.userImage" class="user-image" :style="{'background-image': resourceUrl(sliderStatement.userImage)}"></div>
                  <AtomIcon v-else icon="user-profile" />
                </div>
                <div class="user-name">
                  <h4>{{ sliderStatement.userName }}</h4>
                  <p v-html="sliderStatement.userJob"></p>
                </div>
              </div>
              <p v-for="(line, index) in sliderStatement.lines" :key="index" v-html="line"></p>
            </div>
          </div>
        </div>
      </AtomStatementsSlider>
    </MoleculeStatementsSlider>
  </div>
</template>

<script>
import MoleculeStatementsSlider from "../../molecules/common/MoleculeStatementsSlider";
import AtomStatementsSlider from "../../atoms/common/slider/AtomStatementsSlider";
import AtomIcon from "../../atoms/common/AtomIcon";

export default {
  name: "OrganismStatementsSlider",
  components: {AtomIcon, AtomStatementsSlider, MoleculeStatementsSlider},
  props: {
    sliderStatementsData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  }
}
</script>

<style lang="scss">
  .organism-statements-slider {
    .quotation-mark {
      position: absolute;
      top: 8%;
      left: 48%;
      transform: translateX(-50%);
      @media only screen and (max-width: 767px) {
        top: 37%;
        left: 0;
        transform: none;
      }
      .icon {
        font-size: 11.75rem !important;
        opacity: 0.04;
        @media only screen and (max-width: 767px) {
          font-size: 188px !important;
        }
        &:before {
          margin: 0;
          width: auto;
        }
      }
    }
    .image-carousel {
      padding: 4.375rem 3.75rem;
      @media only screen and (max-width: 767px) {
        padding: 30px 10px;
      }
      .border-element {
        background: $white;
        box-shadow: 0 0.25rem 2.5rem $blackOpacity01;
        transform: rotate(-7deg);
        padding: 1.875rem 1.875rem 5.625rem;
        max-width: 70%;
        margin: 0 auto;
        @media only screen and (max-width: 767px) {
          box-shadow: 0 4px 40px $blackOpacity01;
          padding: 15px 15px 55px;
          max-width: 85%;
          margin-bottom: 36px;
        }
        .logo {
          width: 6.375rem;
          position: absolute;
          bottom: 2.5rem;
          left: 5.25rem;
          @media only screen and (max-width: 767px) {
            width: 80px;
            bottom: 18px;
            left: 30px;
          }
        }
      }
    }
    .content-slider {
      padding: 2rem 3.75rem 5.625rem 2.5rem;
      @media only screen and (max-width: 767px) {
        padding: 0 10px 55px;
      }
      .user-info {
        position: relative;
        margin-bottom: 2.25rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 25px;
        }
        .icon-user {
          width: 5.938rem;
          height: 5.938rem;
          border-radius: 5.938rem;
          background: rgba(20, 50, 117, 0.1);
          display: inline-block;
          vertical-align: middle;
          position: relative;
          margin-right: 1.5rem;
          @media only screen and (max-width: 767px) {
            margin-right: 20px;
            width: 90px;
            height: 90px;
            border-radius: 90px;
          }
          .icon {
            font-size: 3rem !important;
            color: $blue !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            @media only screen and (max-width: 767px) {
              font-size: 40px !important;
            }
          }
        }
      }
      .user-name {
        display: inline-block;
        vertical-align: middle;
        @media only screen and (max-width: 767px) {
          max-width: 220px;
        }
        h4 {
          font-weight: 700;
          color: $blue !important;
          font-size: 2rem;
          line-height: 1.375rem;
          margin-bottom: 0.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 22px;
            margin-bottom: 2px;
          }
        }
        p {
          margin: 0;
        }
      }
      p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        margin-bottom: 1.25rem;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 15px;
        }
        b {
          font-size: 1rem;
          line-height: 1.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
    .owl-nav {
      position: absolute;
      bottom: 18%;
      left: 50%;
      margin-left: 2.5rem;
      @media only screen and (max-width: 767px) {
        bottom: 0;
        left: 0;
        margin-left: 10px;
      }
      button {
        border: 1px solid $second-color !important;
        border-radius: 3rem;
        width: 3rem;
        height: 3rem;
        line-height: 2.4rem !important;
        @media only screen and (max-width: 767px) {
          border-radius: 48px;
          width: 48px;
          height: 48px;
          line-height: 36px !important;
        }
        span {
          color: $second-color;
          font-weight: 300;
          font-size: 3.125rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 50px !important;
          }
        }
      }
    }
  }
</style>