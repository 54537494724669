<template>
  <ul class="changeFitout">
    <li v-for="(fitout, fitName) in fitouts" @click="changeFitout(fitout.name)" :key="fitName">
      <AtomButton :label="fitout.buttonText" />
    </li>
  </ul>
</template>

<script>
import {BabylonInitializeHelper} from "../../../organisms/project/building/store/helpers";
import AtomButton from "../../common/AtomButton";

export default {
  name: "AtomPinFitoutButtons",
  components: {AtomButton},
  props: {
    fitouts:{
      required: false,
      type: Array,
      default: function () {
        return [
            {
              name:"",
              buttonText:"",
            }
        ]
      }
    }
  },
  computed:{
    babylonHelper() {
      return BabylonInitializeHelper
    },
    floorInteractions() {
      return this.babylonHelper.floorInteractionHelper();
    }
  },
  methods: {
    changeFitout(value){
      this.floorInteractions.loadFitout(value);
      this.virtualTour && this.virtualTour.updateMinimap();
    },

  }
}
</script>

<style scoped>

</style>