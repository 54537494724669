var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-title" }, [
    _c("h2", {
      style: _vm.color ? { color: _vm.color } : {},
      domProps: { innerHTML: _vm._s(_vm.title) }
    }),
    _c("h3", {
      style: _vm.color2 ? { color: _vm.color2 } : {},
      domProps: { innerHTML: _vm._s(_vm.subtitle) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }