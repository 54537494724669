<template>
  <div :class="{section: true, 'section-location-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.heading || data.description">
      <AtomTitle :title="data.heading" :subtitle="data.description" />
    </div>
    <OrganismLocation :location-data="data" />
  </div>
</template>

<script>
import OrganismLocation from "../../../../components/organisms/project/OrganismLocation";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionLocationV2",
  components: {AtomTitle, OrganismLocation},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  created() {
  }
}
</script>

<style lang="scss">
.section-location-v2 {
  padding: 4rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 2.5rem 0 0;
  }
  .top-title {
    .atom-title {
      padding: 0 3.75rem;
      margin-bottom: 3.125rem;
      @media only screen and (max-width: 900px) {
        padding: 0 0.625rem;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.875rem;
      }
      h2 {
        text-transform: none;
        @include font-main(3.25rem, $black !important, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(
                  1.375rem,
                  $black !important,
                  $regular,
                  1.625rem
          );
        }
        b {
          @include font-main(3.25rem, $black !important, $bold, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(1.375rem, $black !important, $bold, 1.625rem);
          }
        }
      }
      h3 {
        @include font-main(1.375rem, $black !important, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          @include font-main(
                  0.875rem,
                  $black !important,
                  $regular,
                  1.188rem
          );
        }
      }
    }
  }
  .organism-location {
    .atom-image-title {
      .background-gradient {
        background: transparent;
        padding: 0 1.25rem 2.188rem;
        @media only screen and (max-width: 767px) {
          padding: 0 0.625rem 1.25rem;
        }
        .atom-title {
          h2 {
            text-transform: none;
            margin-bottom: 0.25rem;
            @include font-main(2rem, $black !important, $regular, 2.375rem);
            @media only screen and (max-width: 767px) {
              @include font-main(
                      1.375rem,
                      $black !important,
                      $regular,
                      1.625rem
              );
            }
            b {
              @include font-main(2rem, $black !important, $bold, 2.375rem);
            }
          }
          h3 {
            margin: 0;
            @include font-main(1rem, $black !important, $regular, 1.188rem);
            @media only screen and (max-width: 767px) {
              @include font-main(
                      0.875rem,
                      $black !important,
                      $regular,
                      1.188rem
              );
            }
          }
        }
      }
    }
    .sidebar {
      top: 6rem;
      height: 88.8%;
      width: 33.5rem;
      z-index: 9;
      @media only screen and (max-width: 900px) {
        width: 25.5rem;
      }
      @media only screen and (max-width: 767px) {
        top: initial;
        right: initial;
        left: -110%;
        width: 100%;
        height: 40.938rem;
        overflow: hidden;
        bottom: -0.7rem;
        &.active {
          left: 0;
        }
      }
      .sidebar-locations {
        height: 24.125rem;
        margin-bottom: 1.25rem;
        @media only screen and (max-width: 767px) {
          height: 25rem;
          margin-bottom: 0.938rem;
        }
        .wrapper-sidebar {
          .atom-location-type {
            background: #f8fafc;
            .change-info-boxes {
              padding: 1.25rem 9.375rem 1.25rem 1.25rem;
              border-color: transparent;
              text-transform: uppercase;
              @include font-main(1.125rem, $black, $bold, 1.313rem);
              @media only screen and (max-width: 767px) {
                padding: 0.8rem 6.25rem 0.8rem 0.938rem;
                @include font-main(0.875rem, $black, $bold, 1.125rem);
              }
              .buttons-tabs {
                right: 3.75rem;
                @media only screen and (max-width: 767px) {
                  right: 2.5rem;
                }
                span {
                  .icon {
                    color: $blue !important;
                  }
                  &:hover,
                  &.active {
                    background: $blue;
                    .icon {
                      color: $white !important;
                    }
                  }
                }
              }
              .circle {
                right: 0.938rem;
                opacity: 0;
                transition: all 0.2s ease-in-out 0s;
                @media only screen and (max-width: 767px) {
                  right: 0.625rem;
                }
                .icon {
                  color: $black !important;
                }
              }
            }
            .atom-route {
              padding: 0.75rem 9.688rem 0.75rem 3.125rem;
              border-color: rgba(0, 0, 0, 0.05);
              @include font-main(1rem, $black, $regular, 1.188rem);
              @media only screen and (max-width: 767px) {
                padding: 0.625rem 6.563rem 0.625rem 2.5rem;
                @include font-main(0.75rem, $black, $regular, 1rem);
              }
              b {
                left: 1.25rem;
                color: $blue;
                font-weight: 400;
                transition: all 0.2s ease-in-out 0s;
                @media only screen and (max-width: 767px) {
                  left: 0.938rem;
                }
              }
              .add-time {
                right: 1.25rem;
                @media only screen and (max-width: 767px) {
                  right: 0.938rem;
                }
                .icon {
                  color: $blue !important;
                }
              }
              &:last-child {
                border: none;
                border-radius: 0 0 0.625rem 0.625rem;
                @media only screen and (max-width: 767px) {
                  border-radius: 0 0 0.625rem 0.625rem;
                }
              }
              &:hover,
              &.atom-route-active {
                background: $blue;
                color: $white;
                b {
                  color: $white;
                }
                .add-time {
                  .icon {
                    color: $white !important;
                  }
                }
              }
            }
            &:hover {
              .change-info-boxes {
                .circle {
                  opacity: 1;
                }
              }
            }
            &.active {
              background: #f5f5f5;
              border-radius: 0.625rem;
              margin: 0.25rem 0;
              @media only screen and (max-width: 767px) {
                border-radius: 0.625rem;
                margin: 0.25rem 0;
              }
              .change-info-boxes {
                border-color: rgba(0, 0, 0, 0.05);
                .circle {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .search-sidebar {
        padding: 2.25rem 1.25rem;
        @media only screen and (max-width: 767px) {
          padding: 0.938rem;
        }
        .search-input {
          @media only screen and (max-width: 767px) {
            margin: 0.75rem 0;
          }
        }
      }
    }
  }
}
</style>