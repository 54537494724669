<template>
  <li v-if="transportationType === mode" class="atom-route" :data-marker="markerId" :data-mode="mode"
      @click="drawRouteOnItemClicked">
    <b>{{ orderDisplay }}</b>
    {{ name }}
    <span class="add-time">
        <AtomIcon :icon="mode" :size="14"/>
        {{ time }} &nbsp; {{ distance }}
      </span>
  </li>
</template>

<script>
import AtomIcon from "../common/AtomIcon";
import {isMobile} from "../../../helpers/mobile/DeviceType";

export default {
  name: "AtomRoute",
  components: { AtomIcon },
  props: {
    markerId: {
      required: false,
      type: String,
      default: "",
    },
    mode: {
      required: false,
      type: String,
      default: "",
    },
    order: {
      required: false,
      type: Number,
      default: 0,
    },
    name: {
      required: false,
      type: String,
      default: "",
    },
    time: {
      required: false,
      type: String,
      default: "0s",
    },
    distance: {
      required: false,
      type: String,
      default: "0km",
    }
  },
  computed: {
    orderDisplay() {
      return this.order.toString().padStart(2, "0");
      // return this.order.toString();
    },
    transportationType() {
      return this.$parent.filters.transportationType
    }
  },
  methods: {
    drawRouteOnItemClicked() {

      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem;
        });
      };
      const target = this.$el;
      target.classList.toggle('atom-route-active');
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('atom-route-active');
      });

      this.$parent.$parent.$parent.$refs.map.calcRoute(this.markerId, this.mode);
      if(isMobile()) {
        this.$parent.$parent.$parent.toggleSidebar();
      }
    }
  }
}
</script>

<style lang="scss">
.atom-route-active {
  background: $second-color;
  color: $white;
  .add-time {
    color: $white !important;
  }
}
.atom-route {
  padding: 0.75rem 12rem 0.75rem 4rem;
  position: relative;
  border-bottom: 0.063rem solid $blackOpacity005;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  @include font-main(0.85rem, $black, $regular, 1.188rem);
  @media only screen and (max-width: 767px) {
    padding: 0.625rem 7.938rem 0.625rem 3.125rem;
  }
  &.active {
    display: none;
  }
  b {
    position: absolute;
    left: 1.899rem;
    top: 0.75rem;
    @include font-main(1rem, $main-color, $regular, 1.188rem);
    @media only screen and (max-width: 767px) {
      left: 0.938rem;
      @include font-main(0.75rem, $main-color, $regular, 1rem);
    }
  }
  .add-time {
    position: absolute;
    top: 0.75rem;
    right: 1.899rem;
    transition: all 0.2s ease-in-out 0s;
    @include font-main(1rem, $black, $regular, 1.188rem);
    @media only screen and (max-width: 767px) {
      right: 0.625rem;
      @include font-main(0.75rem, $black, $regular, 1rem);
    }
    .icon {
      font-size: 0.875rem !important;
      color: $main-color !important;
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.125rem;
      margin-right: 0.625rem;
      @media only screen and (max-width: 767px) {
        margin-right: 0.313rem;
      }
    }
  }
  &:hover,
  &.active {
    background: $second-color;
    color: $white;
    .add-time {
      color: $white;
    }
  }
}
</style>