<template>
  <div class="organism-list-circle-v2" :class="backgroundColor">
    <AtomTitle :title="title" :subtitle="subtitle" />
    <MoleculeListCircle>
      <AtomListCircle v-for="(list, index2) in listCircleData" :title="list.title" :text="list.text" :column-number="list.columnNumber" :tablet-column-number="list.tabletColumnNumber" :mobile-column-number="list.mobileColumnNumber" :key="index2" />
    </MoleculeListCircle>
  </div>
</template>

<script>
import MoleculeListCircle from "../../molecules/common/MoleculeListCircle";
import AtomListCircle from "../../atoms/portfolio/AtomListCircle";
import AtomTitle from "@/components/atoms/common/AtomTitle.vue";
export default {
  name: "OrganismListCircleV2",
  components: {AtomTitle, AtomListCircle, MoleculeListCircle},
  props: {
    title: {
      required: false,
      type: String,
      default: ""
    },
    subtitle: {
      required: false,
      type: String,
      default: ""
    },
    backgroundColor: {
      required: false,
      type: String,
      default: ""
    },
    listCircleData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  }
}
</script>

<style lang="scss">
  .organism-list-circle-v2 {
    display: flex;
    flex-direction: column;
    flex: 100%;
    margin: 0 -3.75rem;
    padding-top: 2.5rem;
    border-bottom: 1px solid $white;
    @media only screen and (max-width: 900px) {
      margin: 0 -1.25rem;
      padding-top: 1.875rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .atom-title {
      padding: 0 3.75rem;
      margin-bottom: 2.5rem;
      @media only screen and (max-width: 900px) {
        margin-bottom: 1.25rem;
        padding: 0 1.25rem;
      }
      h2 {
        text-transform: none;
        @include font-main(2.25rem, $white !important, $regular, 2.875rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.5rem;
          @include font-main(1.1rem, $white !important, $regular, 1.288rem);
        }
        b {
          @include font-main(2.25rem, $white !important, $bold, 2.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(1.1rem, $white !important, $bold, 1.288rem);
          }
        }
      }
      h3 {
        @include font-main(1.125rem, $white !important, $regular, 1.813rem);
        @media only screen and (max-width: 767px) {
          @include font-main(0.875rem, $white !important, $regular, 1.188rem);
        }
        b {
          @include font-main(1.125rem, $white !important, $bold, 1.813rem);
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem, $white !important, $bold, 1.188rem);
          }
        }
      }
    }
    &.background-white {
      background: $white;
      .atom-title {
        h2 {
          color: $black !important;
          @media only screen and (max-width: 767px) {
            color: $black !important;
          }
          b {
            color: $black !important;
            @media only screen and (max-width: 767px) {
              color: $black !important;
            }
          }
        }
        h3 {
          color: $black !important;
          @media only screen and (max-width: 767px) {
            color: $black !important;
          }
          b {
            color: $black !important;
            @media only screen and (max-width: 767px) {
              color: $black !important;
            }
          }
        }
      }
      .molecule-list-circle {
        .atom-list-circle {
          .circle {
            border-color: $main-color !important;
          }
        }
        h4, p {
          color: $black !important;
          @media only screen and (max-width: 767px) {
            color: $black !important;
          }
          b {
            color: $black !important;
            @media only screen and (max-width: 767px) {
              color: $black !important;
            }
          }
        }
      }
    }
    &.background-black {
      background: $black;
      .atom-title {
        h2 {
          color: $white !important;
          @media only screen and (max-width: 767px) {
            color: $white !important;
          }
          b {
            color: $white !important;
            @media only screen and (max-width: 767px) {
              color: $white !important;
            }
          }
        }
        h3 {
          color: $white !important;
          @media only screen and (max-width: 767px) {
            color: $white !important;
          }
          b {
            color: $white !important;
            @media only screen and (max-width: 767px) {
              color: $white !important;
            }
          }
        }
      }
      .molecule-list-circle {
        .atom-list-circle {
          .circle {
            border-color: $white !important;
          }
        }
        h4, p {
          color: $white !important;
          @media only screen and (max-width: 767px) {
            color: $white !important;
          }
          b {
            color: $white !important;
            @media only screen and (max-width: 767px) {
              color: $white !important;
            }
          }
        }
      }
    }
   }
</style>