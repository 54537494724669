var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "molecule-slider-slide" },
    [
      _c("transition", { attrs: { name: _vm.direction } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visibleSlide === _vm.index,
                expression: "visibleSlide === index"
              }
            ]
          },
          [
            _vm.imageSrc
              ? _c("img", { attrs: { src: _vm.imageSrc, alt: "" } })
              : _c("div", [
                  _c("div", { staticClass: "text-slide" }, [
                    _c(
                      "div",
                      { staticClass: "titles" },
                      _vm._l(_vm.titles, function(title) {
                        return _c("h2", { key: title }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(title) +
                              "\n            "
                          )
                        ])
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "descriptions" },
                      _vm._l(_vm.descriptions, function(description) {
                        return _c("p", { key: description }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(description) +
                              "\n            "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }