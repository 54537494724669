<template>
  <div :class="{section: true, 'section-purpose-and-values': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row align-items-center">
      <div class="col col-xs-12 col-6">
        <div class="top-title">
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
          <p>{{ data.text }}</p>
        </div>
      </div>
      <div class="col col-xs-12 col-6">
        <MoleculeIconListWithTexts :data-list="data.contents" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import MoleculeIconListWithTexts from "../../components/molecules/common/MoleculeIconListWithTexts";

export default {
  name: "SectionPurposeAndValues",
  components: {MoleculeIconListWithTexts, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="scss">
  .section-purpose-and-values {
    border-bottom: 1px solid #ECF5F8;
    padding: 4.688rem 3.75rem 3.75rem;
    @media only screen and (max-width: 767px) {
      padding: 35px 10px 20px;
    }
    .top-title {
      max-width: 45.313rem;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
      .atom-title {
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
        h2 {
          color: $black !important;
          font-weight: 400;
          font-size: 3.25rem;
          line-height: 3.875rem;
          text-transform: none;
          margin-bottom: 5.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 20px;
          }
          b {
            font-size: 3.25rem;
            line-height: 3.875rem;
            @media only screen and (max-width: 767px) {
              font-size: 22px;
              line-height: 26px;
            }
          }
        }
        h3 {
          color: $black !important;
          font-weight: 300;
          font-size: 2.75rem;
          line-height: 3.25rem;
          margin-bottom: 1.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }
        }
      }
      p {
        font-weight: 300;
        font-size: 2rem;
        line-height: 2.375rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
</style>