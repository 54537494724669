var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col atom-list-circle",
      class:
        ((_obj = {}),
        (_obj["col-" + _vm.columnNumber] = true),
        (_obj["col-sm-" + _vm.tabletColumnNumber] = true),
        (_obj["col-xs-" + _vm.mobileColumnNumber] = true),
        _obj)
    },
    [
      _vm._m(0),
      _c("h4", { domProps: { innerHTML: _vm._s(_vm.title) } }),
      _c("AtomVerticalLine"),
      _c("AtomContentText", { attrs: { color: "#fff", text: "" + _vm.text } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "circle" }, [_c("span")])
  }
]
render._withStripped = true

export { render, staticRenderFns }