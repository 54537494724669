<template>
  <div :class="{section: true, 'section-features': true, [`section${orderNo}`]: true}" :style="inlineStyles" v-if="data.enabled !== false">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle color2="#fff" :title="data.title" :subtitle="data.subtitle" />
      <AtomVerticalLine/>
    </div>
    <OrganismListCircle :list-circle-data="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../../components/atoms/common/AtomVerticalLine";
import OrganismListCircle from "../../../../components/organisms/common/OrganismListCircle";
export default {
  name: "SectionFeatures",
  components: {OrganismListCircle, AtomVerticalLine, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    inlineStyles() {
      let styles = {};
      if (this.data.backgroundImage) {
        styles = {...{'background-image': `url(${this.data.backgroundImage})`}}
      }
      return styles
    }
  }
}
</script>

<style lang="scss">
.section-features {
  padding: 4.375rem 3.75rem 0;
  background-size: cover;
  box-shadow: inset 0 0 0 125rem rgba($main-color, 0.9);
  @media only screen and (max-width: 900px) {
    padding: 1.875rem 1.25rem;
  }
  .top-title {
    margin-bottom: 5.938rem;
    @media only screen and (max-width: 900px) {
      margin-bottom: 2.5rem;
    }
    .atom-title {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 900px) {
        margin-bottom: 1.25rem;
      }
      h2 {
        letter-spacing: 0.05em;
        @include font-main(0.875rem, $second-color, $medium, 1.063rem);
      }
      h3 {
        margin-top: 0.5rem;
        @include font-main(1.375rem, $white, $medium, 1.625rem);
      }
    }
    .atom-vertical-line {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 900px) {
        margin-bottom: 1.25rem;
      }
    }
    .atom-content-text {
      max-width: 80rem;
      @media only screen and (max-width: 900px) {
        max-width: 100%;
      }
      p {
        @include font-main(1.125rem, $white, $regular, 1.813rem);
      }
    }
  }
  .atom-list-circle {
    h4 {
      @include font-main(1.375rem, $white, $medium, 1.625rem);
    }
    p {
      @include font-main(1.125rem, $white, $regular, 1.813rem);
    }
  }
}
</style>