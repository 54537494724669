<template>
  <div class="section-side-image" :class="{'with-image-on-right': withImageOnTheRight, 'testimonials-style': testimonialsFormat, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="inner">
      <div class="image" :class="{'contain': imageContain}" :style="{backgroundImage: `url(${assetUrl})`}"></div>
      <div class="content">
        <div v-if="hasTitle" class="title" v-html="data.title"></div>
        <div class="text">
          <AtomIcon icon="quotation-mark" v-if="testimonialsFormat" />
          <p v-for="(item, i) in data.description" :key="i" v-html="item"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../components/atoms/common/AtomIcon";
export default {
  name: "SectionImageSide",
  components: {AtomIcon},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    }
  },
  computed: {
    hasTitle() {
      return this.data.title && this.data.title !== ''
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    withImageOnTheRight() {
      return this.data.imagePosition === 'right';
    },
    testimonialsFormat() {
      return this.data.testimonialsStyle === true;
    },
    imageContain() {
      return this.data.imageSize === 'contain';
    },
    assetUrl() {
      if (!this.data.image) return '';
      if (this.data.image.includes('://')) {
        return this.data.image
      }
      return `${this.cdnBase}/${this.data.image}`
    }
  }
}
</script>

<style lang="scss">
.section-side-image {
  padding-top: 0.625rem;
  .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: revert;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin: 5px -10px 0;
    }
    .content {
      flex: 1;
      flex-basis: 40%;
      display: flex;
      padding: 1.563rem 10rem;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      @media only screen and (max-width: 767px) {
        padding: 30px 20px 45px;
      }
      .title {
        font-weight: 700;
        color: $blueDark;
        font-size: 2rem;
        line-height: 2.2rem;
        margin-bottom: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
      .text {
        p {
          font-weight: 400;
          color: $blueDark;
          font-size: 1rem;
          line-height: 1.375rem;
          margin-bottom: 0.5rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;
          }
          b {
            font-size: 1rem;
            line-height: 1.375rem;
            @media only screen and (max-width: 767px) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .image {
      flex: 1;
      min-height: 60vh;
      flex-basis: 60%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media only screen and (max-width: 991px) {
        min-height: 250px;
      }
      &.contain {
        background-position: center center;
        background-size: contain;
      }
    }
  }
  &.with-image-on-right {
    .inner {
      flex-direction: row-reverse;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  &.testimonials-style {
    .inner {
      .content {
        overflow: hidden;
        flex-basis: 66.666667%;
        .text {
          position: relative;
          .icon-quotation-mark {
            font-size: 11.75rem !important;
            opacity: 0.04;
            position: absolute;
            left: -3rem;
            top: -3rem;
            @media only screen and (max-width: 767px) {
              font-size: 120px !important;
              left: -40px;
              top: -20px;
            }
            &:before {
              width: auto;
              margin: 0;
            }
          }
          p {
            font-size: 1.375rem;
            line-height: 1.875rem;
            margin-bottom: 1rem;
            @media only screen and (max-width: 767px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 5px;
            }
            b {
              font-size: 1.375rem;
              line-height: 1.875rem;
              @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
          .author {
            display: block;
            font-size: 1.125rem;
            color: $second-color;
            font-weight: 700;
            line-height: 1.5rem;
            margin-top: 1.563rem;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 18px;
              margin-top: 15px;
            }
          }
        }
      }
      .image {
        flex-basis: 33.333333%;
      }
    }
  }
}
</style>