var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.enabled !== false
    ? _c(
        "div",
        {
          staticClass: "section section-features-v2",
          class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
          style: _vm.inlineStyles
        },
        [
          _c("a", {
            staticClass: "navigation-target",
            attrs: { id: _vm.hash, href: "javascript:void(0);" }
          }),
          _c(
            "div",
            { staticClass: "top-title" },
            [
              _c("AtomTitle", {
                attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle }
              }),
              _c("AtomVerticalLine")
            ],
            1
          ),
          _vm._l(_vm.data.items, function(item, index) {
            return _c("OrganismListCircleV2", {
              key: index,
              attrs: {
                title: item.title,
                subtitle: item.subtitle,
                "background-color": item.backgroundColor,
                "list-circle-data": item.listCircle
              }
            })
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }