import { DefaultState, FloorViewMode } from "./index";
import {
  ArcRotateCamera,
  AssetsManager,
  Engine,
  Scene,
  Vector3,
  Tools,
  Color3,
  Color4,
  FreeCamera,
  Mesh,
  BoundingInfo,
  MeshBuilder,
  BaseTexture,
  StandardMaterial,
  AbstractMesh,
  TransformNode,
  Matrix,
  ActionManager,
  ExecuteCodeAction,
} from "babylonjs";
import helper from "./Helper";
import { FloorHighlightsHelper } from "@/components/organisms/project/building/store/FloorHighlightsHelper";
import {
  CameraHelper,
  EngineHelper,
  SceneHelper,
} from "@/components/organisms/project/building/store/helpers";
import { Material } from "babylonjs/Materials/material";
import { PBRMaterial } from "babylonjs/Materials/PBR/pbrMaterial";
import {
  AdvancedDynamicTexture,
  Button,
  Control,
  Ellipse,
  TextBlock,
} from "babylonjs-gui";
import store from "@/components/organisms/project/building/store";
import { device } from "@/helpers/mobile";
import constants from "@/store/modules/requestOffer/constants";

type FloorInteractionHelperOpts = {
  space: any;
  cdnBase: string;
  client: string;
  activeProject: string;
  settings: any;
};

class FloorInteractionHelper {
  _isVue: boolean;
  highlightHelper: FloorHighlightsHelper | null;
  engine: Engine | undefined;
  scene: Scene | undefined;
  cameras: {
    dollHouse: ArcRotateCamera | null;
    firstPerson: FreeCamera | null;
  };
  floorSettings: Record<string, any>;
  space: Record<string, any> | null;
  activeFitout: any;
  activeProject: any;
  customModelSettings: any;
  addDemoFunctions = 0;
  colorPanel?: any;
  _activeHighlight: any;
  _onHighlightTrigger = (spaceId: string) => {};
  previousHighlightMesh?: AbstractMesh;
  store: any;

  constructor(
    {
      space,
      cdnBase,
      client,
      activeProject,
      settings,
    }: FloorInteractionHelperOpts,
    highlightHelper: FloorHighlightsHelper,
    engine: EngineHelper | null,
    scene: SceneHelper | null,
    cameras: CameraHelper | null
  ) {
    this._isVue = true;
    this.highlightHelper = highlightHelper;
    this.engine = engine?.get();
    this.scene = scene?.get();
    this.cameras = {
      dollHouse: cameras?.getDollHouseCamera() as ArcRotateCamera,
      firstPerson: cameras?.getFirstPersonCamera() as FreeCamera,
    };
    this.space = space || {
      space: "",
      floor: "",
      building: "",
      floorType: "",
    };
    this.addDemoFunctions = activeProject == "dufour" ? 0 : 1;
    this.customModelSettings = settings.customModel;

    this.activeProject = activeProject;
    const floorType = space?.floorType.split(".")[0];
    const settingsFloor = settings.floor;
    this.floorSettings = {
      floorCode: `${floorType}`,
      path: `${cdnBase}/objects/${client}/${activeProject}/${
        settingsFloor.versionPath ? `${settingsFloor.versionPath}/` : ""
      }`,
      floorModel: this.space?.floorType,
      groundHeight: false,
      meshGroups: {
        ceiling: {
          node: null,
          meshes: [],
          meshesIDs: [],
        },
      },
      lod: {
        textures: settingsFloor.lod ? settingsFloor.lod.textures : false,
      },
      spaces: {
        pattern: `"${settingsFloor.spaces.pattern.replace(
          "%floorCode%",
          floorType
        )}"`,
        spaceOpacity: settingsFloor.spaces.spaceOpacity,
      },
      separators: {
        nodePattern: `"${settingsFloor.separators.nodePattern.replace(
          "%floorCode%",
          floorType
        )}"`,
        pattern: `"${settingsFloor.separators.pattern.replace(
          "%floorCode%",
          floorType
        )}"`,
        selector: `"${settingsFloor.separators.selector}"`,
      },
      camera: {
        alpha: Tools.ToRadians(settingsFloor.camera.alpha),
        beta: Tools.ToRadians(settingsFloor.camera.beta),
        radius: settingsFloor.camera.radius,
        fov: settingsFloor.camera.fov,
        target: new Vector3(...settingsFloor.camera.target),
        radiusUpperLimit: settingsFloor.camera.radiusUpperLimit,
        radiusLowerLimit: settingsFloor.camera.radiusLowerLimit,
        topView: {
          beta: Tools.ToRadians(settingsFloor.camera.topView.beta),
        },
      },
      light: {
        intensity: settingsFloor.light.intensity,
        groundColor: Color3.FromHexString(settingsFloor.light.groundColor),
      },
      hdr: {
        env: `${cdnBase}${settingsFloor.hdr.env}`,
      },
      activeSpaces: space?.space.split(";"),
      floorFitouts: `fitouts_${floorType}_${space?.space.split(";").join("_")}`,
      floorFitoutsModel: `fitouts_${floorType}_${space?.space
        .split(";")
        .join("_")}.gltf`,
      fitoutsInfo: {},
      fitoutsDate: settingsFloor!.fitoutsInfo || null,
      viewMode: "default",
    };
    this.activeFitout = {};
    this._activeHighlight = null;
    //this.camera = helper.createVirtualTourCamera()
  }

  loadObjects() {
    if (!this.scene) {
      throw new Error(
        "Scene is null when calling FloorInteractionHelper.loadObjects"
      );
    }
    const texturesToProcess: { texture: any; materials: any[] }[] = [];
    const assetsManager = new AssetsManager(this.scene);
    assetsManager.addMeshTask(
      this.floorSettings.floorCode,
      null,
      this.floorSettings.path,
      this.floorSettings.floorModel
    ).onSuccess = (task) => {
      task.loadedMeshes.forEach((mesh) => {
        if (
          mesh.material &&
          mesh.material.name.indexOf("carpet") === -1 &&
          mesh.material.name.indexOf("corefloor") === -1 &&
          mesh.material.name.indexOf("bathroomTiles") === -1 &&
          mesh.material.name.indexOf("_floor") === -1 &&
          mesh.material.name.indexOf("Floor") === -1
        ) {
          mesh.isPickable = false;
        }
        if (
          mesh.material &&
          mesh.isPickable &&
          (this.floorSettings.groundHeight === false ||
            this.floorSettings.groundHeight > mesh.absolutePosition.y)
        ) {
          this.floorSettings.groundHeight = mesh.absolutePosition.y;
        }
      });
      const highlightsNode = this.scene!.getTransformNodeByName(
        "highlights_" + task.name
      );
      const measurementsNode = this.scene!.getTransformNodeByName(
        "measurement_" + task.name
      );
      const ceilingNode = this.scene!.getTransformNodeByName(
        "ceiling_" + task.name
      );

      if (ceilingNode) {
        ceilingNode.setEnabled(false);
        const ceilingChilds = ceilingNode.getChildMeshes(false);
        for (let i = 0; i < ceilingChilds.length; i++) {
          this.floorSettings.meshGroups.ceiling.meshesIDs.push(
            ceilingChilds[i].uniqueId
          );
        }
        this.floorSettings.meshGroups.ceiling.node = ceilingNode;
        this.floorSettings.meshGroups.ceiling.meshes = ceilingChilds;
      }

      const environmentNode = this.scene!.getTransformNodeByName(
        "environment_" + task.name
      );

      if (environmentNode) {
        environmentNode.setEnabled(false);
        const environmentChilds = environmentNode.getChildMeshes(false);
        for (let i = 0; i < environmentChilds.length; i++) {
          environmentChilds[i].isPickable = false;
        }
      }

      if (measurementsNode) {
        measurementsNode.setEnabled(false);
      }

      if (highlightsNode) {
        const highlights = highlightsNode.getChildMeshes(false);
        for (let i = 0; i < highlights.length; i++) {
          const highlight = highlights[i];

          highlight.visibility = 0;
          highlight.isPickable = false;
        }
      }
      const separatorsNode = this.scene!.getTransformNodeByName(
        eval(this.floorSettings.separators.nodePattern)
      );
      if (separatorsNode) {
        let separators;

        if (this.floorSettings.separators.selector === "node") {
          separators = separatorsNode.getChildMeshes();
        } else {
          separators = separatorsNode.getChildTransformNodes(true);
        }

        separators.forEach((separator, index) => {
          const separatorSpaces = separator.name
            .replace(
              new RegExp(eval(this.floorSettings.separators.pattern), "g"),
              ""
            )
            .replace(/_primitive[0-9]*/g, "")
            .split("_");
          const difference = separatorSpaces.filter(
            (x) => !this.floorSettings.activeSpaces.includes(x)
          );
          if (
            difference.length === 0 ||
            separatorSpaces.length === difference.length
          ) {
            separator.setEnabled(false);
          }
        });
      }

      const toiletNode = this.scene!.getTransformNodeByName(
        "toilet_" + task.name
      );
      if (toiletNode) {
        const toilets = toiletNode.getChildTransformNodes(true);
        for (let i = 0; i < toilets.length; i++) {
          toilets[i].setEnabled(false);
        }
      }

      const staircaseNode = this.scene!.getTransformNodeByName(
        "staircase_" + task.name
      );
      if (staircaseNode) {
        const staircases = staircaseNode.getChildTransformNodes(true);
        for (let i = 0; i < staircases.length; i++) {
          staircases[i].setEnabled(false);
        }
      }

      const showExtraElements: any = {
        b1_floor0: {
          s1: ["s1_toilet_b1_floor0", "s1_staircase"],
          s2: ["s2_toilet_b1_floor0", "s2_staircase"],
          s3: ["s3_toilet_b1_floor0", "s3_staircase"],
          s4: ["s4_toilet_b1_floor0", "s4_staircase"],
          s5: ["s5_toilet_b1_floor0", "s5_staircase"],
          s6: ["s6_toilet_b1_floor0", "s6_staircase"],
          s7: ["s7_toilet_b1_floor0", "s7_staircase"],
          s8: ["s8_toilet_b1_floor0", "s8_staircase"],
          s1_s2: ["s1_toilet_b1_floor0", "s1_staircase"],
          s3_s4: ["s4_toilet_b1_floor0", "s4_staircase"],
          s5_s6: ["s5_toilet_b1_floor0", "s5_staircase"],
          s7_s8: ["s8_toilet_b1_floor0", "s8_staircase"],
          s1_s2_s3_s4: [
            "s1_toilet_b1_floor0",
            "s1_staircase",
            "s4_toilet_b1_floor0",
          ],
          s5_s6_s7_s8: [
            "s5_toilet_b1_floor0",
            "s8_toilet_b1_floor0",
            "s8_staircase",
          ],
          s1_s2_s3_s4_s5_s6_s7_s8: [
            "s1_toilet_b1_floor0",
            "s8_toilet_b1_floor0",
            "s1_staircase",
            "s8_staircase",
          ],
          s3_s4_s5: ["s3_toilet_b1_floor0", "s3_staircase"], //
          s3_s4_s5_s6: ["s6_toilet_b1_floor0", "s6_staircase"],
          s2_s3_s4_s5_s6_s7_s8: ["s8_toilet_b1_floor0", "s8_staircase"],
          s2_s3_s4_s5_s6: ["s6_toilet_b1_floor0", "s6_staircase"],
          s2_s3_s4: ["s4_toilet_b1_floor0", "s4_staircase"],
          s1_s2_s3_s4_s5_s6_s7: ["s1_toilet_b1_floor0", "s1_staircase"],
          s1_s2_s3_s4_s5_s6: [
            "s1_toilet_b1_floor0",
            "s1_staircase",
            "s6_toilet_b1_floor0",
            "s6_staircase",
          ],
          s1_s2_s3_s4_s5: ["s1_toilet_b1_floor0", "s1_staircase"],
          s1_s2_s3: ["s1_toilet_b1_floor0", "s1_staircase"],
          s6_s7_s8: ["s8_toilet_b1_floor0", "s8_staircase"],
          s5_s6_s7: ["s5_toilet_b1_floor0", "s5_staircase"],
          s4_s5_s6_s7_s8: ["s8_toilet_b1_floor0", "s8_staircase"],
          s4_s5_s6: ["s6_toilet_b1_floor0", "s6_staircase"],
          s3_s4_s5_s6_s7_s8: [
            "s3_toilet_b1_floor0",
            "s3_staircase",
            "s8_toilet_b1_floor0",
            "s8_staircase",
          ],
          s3_s4_s5_s6_s7: ["s3_toilet_b1_floor0", "s3_staircase"],
        },
        b1_floor1: {
          s9: ["s9_toilet_b1_floor1", "s9_staircase"],
          s10: ["s10_toilet_b1_floor1", "s10_s11_staircase"],
          s11: ["s11_toilet_b1_floor1", "s10_s11_staircase"],
          s12: ["s12_toilet_b1_floor1", "s12_s13_staircase"],
          s13: ["s13_toilet_b1_floor1", "s12_s13_staircase"],
          s14: ["s14_toilet_b1_floor1", "s14_s15_staircase"],
          s15: ["s15_toilet_b1_floor1", "s14_s15_staircase"],
          s16: ["s16_toilet_b1_floor1", "s16_staircase"],
          s9_s10: ["s9_toilet_b1_floor1", "s9_staircase"],
          s11_s12: ["s12_toilet_b1_floor1", "s12_s13_staircase"],
          s13_s14: ["s13_toilet_b1_floor1", "s12_s13_staircase"],
          s15_s16: ["s16_toilet_b1_floor1", "s16_staircase"],
          s9_s10_s11_s12: [
            "s9_toilet_b1_floor1",
            "s9_staircase",
            "s12_toilet_b1_floor1",
          ],
          s13_s14_s15_s16: [
            "s13_toilet_b1_floor1",
            "s16_toilet_b1_floor1",
            "s16_staircase",
          ],
          s9_s10_s11_s12_s13_s14_s15_s16: [
            "s9_toilet_b1_floor1",
            "s16_toilet_b1_floor1",
            "s9_staircase",
            "s16_staircase",
          ],
          s11_s12_s13: ["s11_toilet_b1_floor1", "s10_s11_staircase"], //
          s11_s12_s13_s14: ["s14_toilet_b1_floor1", "s14_s15_staircase"],
          s10_s11_s12_s13_s14_s15_s16: [
            "s16_toilet_b1_floor1",
            "s16_staircase",
          ],
          s10_s11_s12_s13_s14: ["s14_toilet_b1_floor1", "s14_s15_staircase"],
          s10_s11_s12: ["s12_toilet_b1_floor1", "s12_staircase"],
          s9_s10_s11_s12_s13_s14_s15: ["s9_toilet_b1_floor1", "s9_staircase"],
          s9_s10_s11_s12_s13_s14: [
            "s9_toilet_b1_floor1",
            "s9_staircase",
            "s14_toilet_b1_floor1",
            "s14_staircase",
          ],
          s9_s10_s11_s12_s13: ["s9_toilet_b1_floor1", "s9_staircase"],
          s9_s10_s11: ["s9_toilet_b1_floor1", "s9_staircase"],
          s14_s15_s16: ["s16_toilet_b1_floor1", "s16_staircase"],
          s13_s14_s15: ["s13_toilet_b1_floor1", "s13_staircase"],
          s12_s13_s14_s15_s16: ["s16_toilet_b1_floor1", "s16_staircase"],
          s12_s13_s14: ["s14_toilet_b1_floor1", "s14_staircase"],
          s11_s12_s13_s6_s15_s16: [
            "s11_toilet_b1_floor1",
            "s11_staircase",
            "s16_toilet_b1_floor1",
            "s16_staircase",
          ],
          s11_s12_s13_s6_s15: ["s11_toilet_b1_floor1", "s11_staircase"],
        },
      };

      if (showExtraElements[task.name]) {
        if (
          showExtraElements[task.name][
            this.floorSettings.activeSpaces.join("_")
          ]
        ) {
          for (
            let i = 0;
            i <
            showExtraElements[task.name][
              this.floorSettings.activeSpaces.join("_")
            ].length;
            i++
          ) {
            this.scene!.getTransformNodeByName(
              showExtraElements[task.name][
                this.floorSettings.activeSpaces.join("_")
              ][i]
            )!.setEnabled(true);
          }
        }
      }
      const opacityValue = this.floorSettings.spaces.spaceOpacity
        ? this.floorSettings.spaces.spaceOpacity
        : 0.2;
      this.setSpacesVisibility(opacityValue);

      const self = this;
      const hightlightsUnitsNode = this.scene!.getTransformNodeByName(
        "highlights"
      );

      const redMat = new StandardMaterial("redMat", this.scene);
      const greenMat = new StandardMaterial("greenMat", this.scene);
      const _getCookie = this.getCookie;

      redMat.emissiveColor = Color3.FromHexString("#b40202");
      redMat.alpha = 0.7;
      greenMat.emissiveColor = Color3.FromHexString("#7be010");
      greenMat.alpha = 0.7;

      if (hightlightsUnitsNode) {
        hightlightsUnitsNode.getChildMeshes().forEach((hightlight: any) => {
          hightlight.visibility = 0;
          let isActiveHighlight = true;

          isActiveHighlight = true;
          //hightlight.material = redMat;

          //  const visibleHighlights = settings.highlights.buildings[payload.activeProject].visibleHighlights;
          // if(visibleHighlights && visibleHighlights.length){
          //     visibleHighlights.forEach((h:any)=>{
          //         if (!hightlight.name.includes(h)){
          //             isActiveHighlight = false;
          //         }
          //     })
          // }

          //if(isActiveHighlight){
          hightlight.metadata = {};
          hightlight.metadata.isAvailable = true;

          hightlight.isPickable = true;
          hightlight.actionManager = new ActionManager(this.scene);

          // hightlight.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
          //     if (device.isDesktop()){
          //         ev.meshUnderPointer!.visibility = 1;
          //     }
          // }));
          // hightlight.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
          //     if ( device.isDesktop() && (ev.meshUnderPointer!.name !== self._activeHighlight) ){
          //         ev.meshUnderPointer!.visibility = 0;
          //     }
          // }));

          hightlight.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, function (ev) {
              if (!ev.meshUnderPointer) {
                return;
              }
              const spaceName = ev.meshUnderPointer.name.replace("hl_", "");

              self.onHighlightTrigger(ev.meshUnderPointer.name);

              if (
                _getCookie("cookieHideInfoPopupCanvasSpaceClick") === null &&
                document.querySelector(".space-click-info")
              ) {
                $(".space-click-info").remove();
                document.cookie =
                  "cookieHideInfoPopupCanvasSpaceClick=hideInfoPopupCanvasSpaceClick; expires=Sun, 1 Jan 9033 00:00:00 UTC; path=/";
              }

              if (device.isDesktop()) {
                hightlightsUnitsNode
                  .getChildMeshes()
                  .forEach((highlightMesh: any) => {
                    if (highlightMesh.name !== ev.meshUnderPointer?.name) {
                      highlightMesh.visibility = 0;
                    }
                  });
                if (self._activeHighlight !== ev.meshUnderPointer.name) {
                  self.hightlightSpace(spaceName);
                } else {
                  self.resetHighlight();
                  self.destroyHighlightTooltip();
                }
              } else {
                (ev.meshUnderPointer as any).isClicked = !(ev.meshUnderPointer as any)
                  .isClicked;
                if ((ev.meshUnderPointer as any).isClicked) {
                  hightlightsUnitsNode
                    .getChildMeshes()
                    .forEach((highlightMesh: any) => {
                      highlightMesh.visibility = 0;
                      if (highlightMesh.name !== ev.meshUnderPointer?.name) {
                        highlightMesh.isClicked = false;
                      }
                    });
                  ev.meshUnderPointer.visibility = 1;
                  self.hightlightSpace(spaceName);
                } else {
                  ev.meshUnderPointer.visibility = 0;
                  self.resetHighlight();
                  self.destroyHighlightTooltip();
                }
              }
            })
          );

          hightlight.setEnabled(true);
          hightlight.visibility = 0;
          //}
        });
      }
      // }
    };

    assetsManager.addMeshTask(
      this.floorSettings.floorFitouts,
      null,
      this.floorSettings.path,
      this.floorSettings.floorFitoutsModel
    ).onSuccess = (task) => {
      task.loadedMeshes.forEach((mesh) => {
        if (
          mesh.material &&
          mesh.material.name.indexOf("carpet") === -1 &&
          mesh.material.name.indexOf("corefloor") === -1 &&
          mesh.material.name.indexOf("bathroomTiles") === -1 &&
          mesh.material.name.indexOf("_floor") === -1 &&
          mesh.material.name.indexOf("Floor") === -1
        ) {
          mesh.isPickable = false;
        }
      });

      // const fitoutsNode = this.scene!.getTransformNodeByName(this.floorSettings.floorFitouts);
      // const fitouts = fitoutsNode!.getChildTransformNodes(true);
      // let itemList = '';
      // for (let i = 0; i < fitouts.length; i++) {
      //     let name = 'Fitout ' + (i + 1);
      //     if (this.floorSettings.fitoutsInfo[this.floorSettings.floorFitouts]) {
      //         if (this.floorSettings.fitoutsInfo[this.floorSettings.floorFitouts][fitouts[i].name]) {
      //             const fitoutInfo = this.floorSettings.fitoutsInfo[this.floorSettings.floorFitouts][fitouts[i].name];
      //             name = fitoutInfo.name;
      //             this.space!.fitoutsLegends[fitouts[i].name] = {};
      //             this.space!.fitoutsLegends[fitouts[i].name]['html'] = '';
      //             this.space!.fitoutsLegends[fitouts[i].name]['html'] += '<h6>Fitout Information</h6><span class="closeCustomTooltip"><i class="far fa-times-circle"></i></span>';
      //             if (fitoutInfo.legend.title) {
      //                 this.space!.fitoutsLegends[fitouts[i].name]['html'] += '<h5>' + fitoutInfo.legend.title + '</h5>';
      //             }
      //             this.space!.fitoutsLegends[fitouts[i].name]['html'] += '<ul>';
      //             for (let r = 0; r < fitoutInfo.legend.rows.length; r++) {
      //                 this.space!.fitoutsLegends[fitouts[i].name]['html'] += '<li><span class="width60">' + fitoutInfo.legend.rows[r].title + '</span><span class="width40 text-right"><b>' + fitoutInfo.legend.rows[r].value + '</b></span></li>';
      //             }
      //             this.space!.fitoutsLegends[fitouts[i].name]['html'] += '</ul>';
      //             $('.canvasBtnsWrapper .legends').append('<div class="fitoutLegend" data-fitout="' + fitouts[i].name + '">' + this.space!.fitoutsLegends[fitouts[i].name]['html'] + '</div>' );
      //         }
      //     }
      //
      //     itemList = '<option value="' + fitouts[i].name + '" data-fitout="' + fitouts[i].name + '" data-fitout-no="fitout' + (i + 1) + '">' + name + '</option>';
      //     fitouts[i].setEnabled(false);
      //     $('#fit-out').append(itemList);
      // }
    };

    assetsManager.onTasksDoneObservable.add(() => {
      const materials: any = this.scene!.materials;
      let meshMap;
      let lowQualityTexture;

      // REMOVED THUMBSTICK CONTROL
      // REMOVED THUMBSTICK CONTROL
      // REMOVED THUMBSTICK CONTROL
      // //Set gravity for the scene (G force like, on Y-axis)
      // this.scene!.gravity = new Vector3(0, -0.9, 0);
      // // Enable Collisions
      // this.scene!.collisionsEnabled = true;
      //
      // //Then apply collisions and gravity to the active camera
      // this.cameras.firstPerson!.checkCollisions = true;
      // this.cameras.firstPerson!.applyGravity = true;
      //
      // //Set the ellipsoid around the camera (e.g. your player's size)
      // this.cameras.firstPerson!.ellipsoid = new Vector3(.8, .8, .8);
      // // this.cameras.firstPerson!.minZ = 0.1   Was commented

      //finally, say which mesh will be collisionable
      // this.scene?.meshes.forEach((mesh:any) => {
      //     if (
      //         // mesh.name.toLowerCase().includes('window')
      //         mesh.name.toLowerCase().includes('window3')
      //         || mesh.name.toLowerCase().includes('floor')
      //     ){
      //         mesh.checkCollisions = true;
      //     }
      // })

      for (let i = 0; i < materials.length; i++) {
        lowQualityTexture = true;
        if (
          materials[i].name.indexOf("_shadow") > -1 ||
          materials[i].name.indexOf("_Shadow") > -1
        ) {
          if (this.activeProject !== "sthlm") {
            materials[i].alphaMode = 4;
            materials[i].transparencyMode = 2;
            materials[i].unlit = true;
            materials[i].useAlphaFromAlbedoTexture = true;
            if (materials[i].albedoTexture) {
              materials[i].albedoTexture.hasAlpha = true;
            }
            if (!materials[i].albedoTexture && materials[i].emissiveTexture) {
              materials[i].albedoTexture = materials[i].emissiveTexture;
              materials[i].emissiveTexture = null;
              materials[i].emissiveIntensity = 0;
            }
          }
        } else {
          if (
            this.floorSettings.lod &&
            this.floorSettings.lod.textures === true
          ) {
            const textures = materials[i].getActiveTextures();
            if (textures) {
              meshMap = materials[i].getBindedMeshes();
              for (let j = 0; j < meshMap.length; j++) {
                if (meshMap[j].visibility === 1) {
                  lowQualityTexture = false;
                  break;
                }
              }
              if (!lowQualityTexture) {
                for (let k = 0; k < textures.length; k++) {
                  if (
                    textures[k].url &&
                    textures[k].url.indexOf(this.floorSettings.path) > -1
                  ) {
                    Tools.LoadFile(
                      this.floorSettings.path +
                        "hi" +
                        textures[k].url.substring(
                          textures[k].url.indexOf(this.floorSettings.path) +
                            this.floorSettings.path.length
                        ),
                      (data, responseURL) => {
                        const name =
                          this.floorSettings.path +
                          "hi" +
                          textures[k].url.substring(
                            textures[k].url.indexOf(this.floorSettings.path) +
                              this.floorSettings.path.length
                          );
                        const dataUrl = "data:" + name;
                        textures[k].updateURL(dataUrl, data);
                      },
                      null || undefined,
                      null || undefined,
                      true
                    );
                  }
                }
              }
            } else {
              console.log("No textures: skipping");
            }
          }
        }
      }

      const floorType = this.space?.floorType.split(".")[0];
      const pinsVTNode = this.scene!.getTransformNodeByName(`pinsWT`);
      if (pinsVTNode) {
        this.highlightHelper?.init(pinsVTNode, false);
      }

      const highlightTransformNodes = this.scene!.getTransformNodeByName(
        `highlights_${floorType}`
      );
      if (highlightTransformNodes) {
        this.highlightHelper?.init(highlightTransformNodes, true);
      }

      this.engine!.runRenderLoop(() => {
        this.scene!.render();
      });
    });
    assetsManager.loadAsync();
    ``;
    this.scene.onPointerMove = () => {
      this.pointerMove(this.scene!, this.cameras.dollHouse as any);
    };
  }

  onHighlightTrigger(meshName: string) {
    const meshParts = meshName.split("_");
    const building = meshParts[1];
    const floor = meshParts[2];
    const spaceCode = meshParts[3];
    // const spacesByCode =
    //   store.getters.spacesByMeshCode?.[
    //     `${building}_${floor}_${spaceCode}`
    //   ]?.[0];
    // if (!spacesByCode?.length) return;
    // const space = spacesByCode.find((sp: any) => sp.available && sp.is_public);
    // if (!space) return;

    const space =
      store.getters.activeProjectSpacesByMeshCode[
        `${building}_${floor}_${spaceCode}`
      ];
    if (!space || space.available === false) return;

    const buildingObj = store.state.buildings.find(
      (b: any) => b.code === building
    );
    const floorObj = store.state.floors.find((f: any) => f.code === floor);
    const spaceData = {
      ...space,
      building: buildingObj,
      floor: floorObj,
    };

    const requestOffer = this.store.getters.constants.requestOffer;
    this.store.dispatch(
      requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
      null,
      { root: true }
    );
    this.store.dispatch(
      requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
      spaceData
    );

    const modal = this.store.getters.constants.modal;
    this.store.dispatch(modal.withNamespace(modal.action.CHANGE_MODAL_STATE), {
      showModal: true,
      modalType: "request-offer",
    });

    document.body.classList.add("disable-scroll");
    return this._onHighlightTrigger(spaceCode);
  }

  getFitoutDetails() {
    const details: any = {
      fitouts: {},
      fitoutOptions: [],
    };
    const fitoutsNode = this.scene!.getTransformNodeByName(
      this.floorSettings.floorFitouts
    );
    if (fitoutsNode === null) return details;

    const fitouts = fitoutsNode!.getChildTransformNodes(true);
    fitouts.forEach((fitout, index) => {
      let label = `Fitout ${index + 1}`;
      if (this.floorSettings.fitoutsDate) {
        this.floorSettings.fitoutsDate.find(function (fit: any) {
          if (fit.id === fitout.name) {
            label = fit.name;
          }
        });
      }
      const option = {
        label: label,
        name: fitout.name,
        value: fitout.name,
      };
      fitout.setEnabled(false);
      details.fitouts[fitout.name] = fitout;
      details.fitoutOptions.push(option);
    });
    const mat1 = this.scene?.getMaterialById("_floor_int1Visible");
    const mat2 = this.scene?.getMaterialById(
      "BAKETOOL_Fitout1_Int_s1_Floor_Visible.001"
    );
    if (this.addDemoFunctions == 0) {
      const mats: PBRMaterial[] = [mat1 as PBRMaterial, mat2 as PBRMaterial];
      this.setUpChairs();
      this.createColorPicker(mats);
      this.addDemoFunctions++;
    }

    return details;
  }

  calculateBoundingInfo(meshes: Mesh[]) {
    let boundingInfo = new BoundingInfo(Vector3.Zero(), Vector3.Zero());
    let min = Vector3.Zero();
    let max = Vector3.Zero();
    meshes.forEach((mesh) => {
      mesh.refreshBoundingInfo();
      // this.scene!.getBoundingBoxRenderer().frontColor.set(1, 0, 0);
      // this.scene!.getBoundingBoxRenderer().backColor.set(1, 0, 0);
      mesh.showBoundingBox = true;
      // mesh.edgesColor = new Color4(66,66,66, 1);
      // mesh.c = new Color4(66,66,66, 1);
      // mesh.showSubMeshesBoundingBox = true;
      const bounds = mesh.getHierarchyBoundingVectors();
      boundingInfo = new BoundingInfo(bounds.min, bounds.max);
      min = Vector3.Minimize(min, boundingInfo.minimum.add(mesh.position));
      max = Vector3.Maximize(max, boundingInfo.maximum.add(mesh.position));
    });
    return new BoundingInfo(min, max);

    // let boundingInfo = meshes[0].getBoundingInfo();
    // let min = boundingInfo.minimum.add(meshes[0].position);
    // let max = boundingInfo.maximum.add(meshes[0].position);
    // for(let i=1; i<meshes.length; i++){
    //     //const bounds = meshes[i].getBoundingInfo();
    //     boundingInfo = meshes[i].getBoundingInfo();
    //     min = Vector3.Minimize(min, boundingInfo.minimum.add(meshes[i].position));
    //     max = Vector3.Maximize(max, boundingInfo.maximum.add(meshes[i].position));
    // }
    // return new BoundingInfo(min, max);
  }

  calculateBoundingInfoFromVector3(coords: Vector3[]) {
    let totalX = 0;
    let totalY = 0;
    let totalZ = 0;
    coords.forEach((coord) => {
      totalX += coord.x;
      totalY += coord.y;
      totalZ += coord.z;
    });
    return new Vector3(
      totalX / coords.length,
      totalY / coords.length,
      totalZ / coords.length
    );
  }

  getCookie(name: string) {
    const dc = document.cookie;
    const prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    let end;
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
      end = document.cookie.indexOf(";", begin);
      if (end == -1) end = dc.length;
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  setSpacesVisibility(value: number) {
    if (
      this.scene!.getTransformNodeByName(
        "spaces_" + this.floorSettings.floorCode
      )
    ) {
      let spacesNodes = this.scene!.getTransformNodeByName(
        "spaces_" + this.floorSettings.floorCode
      )!.getChildTransformNodes(true);
      if (!spacesNodes) {
        spacesNodes = this.scene!.getTransformNodeByName(
          "_" + this.floorSettings.floorCode
        )!.getChildTransformNodes(true);
      }
      let spaceCount = 0;
      const centerPoints: Vector3[] = [];
      spacesNodes.forEach((node, index) => {
        if (
          !this.floorSettings.activeSpaces.includes(
            node.name.replace(
              new RegExp(eval(this.floorSettings.spaces.pattern), "g"),
              ""
            )
          )
        ) {
          const childMeshes = node.getChildMeshes(false);
          childMeshes.forEach((mesh, j) => {
            if (!mesh.isAnInstance) {
              mesh.isPickable = true;
              if (mesh.isVisible) {
                mesh.visibility = value;
              }
            }
          });
          spaceCount++;
        } else {
          const childMeshes = node.getChildMeshes();
          let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
          let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;
          for (let i = 0; i < childMeshes.length; i++) {
            const meshMin = childMeshes[i].getBoundingInfo().boundingBox
              .minimumWorld;
            const meshMax = childMeshes[i].getBoundingInfo().boundingBox
              .maximumWorld;

            min = Vector3.Minimize(min, meshMin);
            max = Vector3.Maximize(max, meshMax);
          }
          const mParent = new Mesh("parentMesh", this.scene);
          mParent.setBoundingInfo(new BoundingInfo(min, max));
          centerPoints.push(mParent.getBoundingInfo().boundingBox.center);
        }
      });

      if (centerPoints.length) {
        const newCameraTarget = this.calculateBoundingInfoFromVector3(
          centerPoints
        );
        // const sphere = MeshBuilder.CreateSphere("sphere", {diameterX: 5, diameterY: 5, diameterZ: 5}, this.scene);
        // sphere.position = newCameraTarget;
        this.cameras.dollHouse?.setTarget(newCameraTarget);
      }

      if (spacesNodes.length > 0 && spacesNodes.length === spaceCount) {
        spacesNodes.forEach((node, index) => {
          if (
            !this.floorSettings.activeSpaces.includes(
              node.name.replace(
                new RegExp(eval(this.floorSettings.spaces.pattern), "g"),
                ""
              )
            )
          ) {
            const childMeshes = node.getChildMeshes(false);
            childMeshes.forEach((mesh, j) => {
              if (!mesh.isAnInstance) {
                mesh.isPickable = true;
                if (mesh.isVisible) {
                  mesh.visibility = 1;
                }
              }
            });
          }
        });
      }
    }
  }

  changeViewMode(viewMode: FloorViewMode) {
    const self = this;
    if (this.colorPanel) {
      this.colorPanel.isVisible = true;
    }

    if (this.floorSettings.viewMode === viewMode) return;

    if (this.floorSettings.viewMode === FloorViewMode.FIRST_PERSON) {
      //this.virtualTourHelper?.deactivate();
      // if($(window).width() >= 991) {
      //     zoom.show();
      // }
      //this.highlights.showPins(true);
      // if ($('#fitOut option:selected').data('fitout')) {
      //     this.highlights.showFitoutPins(false)
      // }
    }
    switch (viewMode) {
      case FloorViewMode.DEFAULT:
        this.cameras.dollHouse!.upperBetaLimit = Math.PI / 2;
        helper.move(
          this.cameras.dollHouse!,
          false,
          this.cameras.dollHouse!.target,
          this.cameras.dollHouse!.alpha,
          this.floorSettings.camera.beta,
          false,
          () => {
            this.cameras.dollHouse!.upperBetaLimit = Math.PI / 2;
          }
        );
        self.scene!.onPointerMove = () => {
          this.pointerMove(this.scene!, this.cameras.dollHouse as any);
        };
        break;
      case FloorViewMode.TWO_DIMENSIONAL:
        helper.move(
          this.cameras.dollHouse!,
          false,
          this.cameras.dollHouse!.target,
          this.cameras.dollHouse!.alpha,
          this.floorSettings.camera.topView.beta,

          false,
          () => {
            this.cameras.dollHouse!.upperBetaLimit = 0;
          }
        );
        self.scene!.onPointerMove = () => {
          this.pointerMove(this.scene!, this.cameras.dollHouse as any);
        };
        break;
      case FloorViewMode.FIRST_PERSON:
        //this.virtualTourHelper!.activate();
        //zoom.hide();
        //this.highlightHelper.showPins(false);
        //this.highlightHelper!.hideHighlights();
        //$('.pinInfo').hide();
        break;
      // case '2DEdit':
      //     break;
    }
    this.floorSettings.viewMode = viewMode;
  }

  loadFitout(fitoutName: string) {
    if (!fitoutName) {
      const nodeFitouts = this.scene!.getTransformNodeByName(
        this.floorSettings.floorFitouts
      );
      nodeFitouts?.getChildTransformNodes(true).forEach((fitout) => {
        fitout.setEnabled(false);
      });
      return;
    }

    if (
      this.activeFitout &&
      this.activeFitout.name &&
      this.activeFitout.name !== fitoutName
    ) {
      this.activeFitout.setEnabled(false);
    }

    this.activeFitout = this.scene!.getTransformNodeByName(fitoutName);

    if (!this.activeFitout) {
      return;
    }
    this.activeFitout!.setEnabled(true);

    // if (this.viewMode !== 'virtualTour') {
    //     this.highlights.showFitoutPins(true);
    //     if (e.detail.fitoutName) {
    //         this.highlights.showFitoutPins(false);
    //     }
    //     this.highlights.hideHighlights();
    // }
  }

  setZoomValue(value: number) {
    this.cameras.dollHouse!.fov = 0.6333333333333333 - value / 100;
  }

  setUpChairs() {
    const chairs = this.scene?.getNodeByName("group_chairs")?.getChildren();
    if (!chairs || chairs.length < 2) throw Error("Toggle chairs not loaded");
    for (const child of chairs) {
      (child as Mesh).isPickable = true;
    }
    this.switchChair();
    this.changeCurrentChairColor();
    this.resetColors();
  }

  switchChair() {
    const chairs = this.scene?.getNodeByName("group_chairs")?.getChildren();
    if (!chairs || chairs.length < 2) throw Error("Toggle chairs not loaded");
    if ((chairs[0] as Mesh).isVisible) {
      (chairs[0] as Mesh).isVisible = false;
      (chairs[1] as Mesh).isVisible = true;
    } else {
      (chairs[0] as Mesh).isVisible = true;
      (chairs[1] as Mesh).isVisible = false;
    }
  }

  changeCurrentChairColor() {
    const chairs = this.scene?.getNodeByName("group_chairs")?.getChildren();
    if (!chairs || chairs.length < 2) throw Error("Toggle chairs not loaded");
    if ((chairs[0] as Mesh).isVisible) {
      ((chairs[0] as Mesh).material as PBRMaterial).emissiveColor = new Color3(
        0,
        1,
        0
      );
    } else {
      ((chairs[1] as Mesh).material as PBRMaterial).emissiveColor = new Color3(
        0,
        1,
        0
      );
    }
  }

  resetColors() {
    const chairs = this.scene?.getNodeByName("group_chairs")?.getChildren();
    if (!chairs || chairs.length < 2) throw Error("Toggle chairs not loaded");
    ((chairs[0] as Mesh).material as PBRMaterial).emissiveColor = new Color3(
      0,
      0,
      0
    );
    ((chairs[1] as Mesh).material as PBRMaterial).emissiveColor = new Color3(
      0,
      0,
      0
    );
  }

  createColorPicker(meshMats: PBRMaterial[]) {
    if (meshMats.length == 0) return;
    // GUI
    const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI(
      "UI"
    );

    this.colorPanel = new BABYLON.GUI.StackPanel();
    this.colorPanel.width = "122px";
    this.colorPanel.isVertical = true;
    this.colorPanel.horizontalAlignment =
      BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.colorPanel.verticalAlignment =
      BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP;
    advancedTexture.addControl(this.colorPanel);

    const textBlock = new BABYLON.GUI.TextBlock();
    textBlock.text = "Change floor color:";
    textBlock.height = "20px";
    textBlock.fontSize = "10px";
    this.colorPanel.addControl(textBlock);

    const colorPicker = new BABYLON.GUI.ColorPicker();
    colorPicker.value = meshMats[0].emissiveColor;
    colorPicker.height = "108px";
    colorPicker.width = "108px";
    colorPicker.horizontalAlignment =
      BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    colorPicker.onValueChangedObservable.add(function (value) {
      // value is a color3
      for (const mat of meshMats) {
        mat.emissiveColor.copyFrom(value);
      }
    });

    this.colorPanel.addControl(colorPicker);

    const textBlock2 = new BABYLON.GUI.TextBlock();
    textBlock2.text = "to revert to\noriginal floor color\nplease select black";
    textBlock2.height = "40px";
    textBlock2.fontSize = "10px";
    this.colorPanel.addControl(textBlock2);
  }

  pointerMove(scene: Scene, camera: ArcRotateCamera) {
    if (!device.isDesktop()) return;
    if (
      this.previousHighlightMesh &&
      this.previousHighlightMesh?.name !== this._activeHighlight
    ) {
      this.highlightMesh(this.previousHighlightMesh.name, 0);
    }
    const raycast = scene.createPickingRay(
      scene.pointerX,
      scene.pointerY,
      Matrix.Identity(),
      camera,
      false
    );
    const { pickedMesh } = scene.pickWithRay(raycast) || {};
    if (!pickedMesh) {
      this.setPreviousHighlightedMesh(undefined);
      this.destroyHighlightTooltip();
      this.resetHighlights();
      return;
    }

    if (pickedMesh.name !== this.previousHighlightMesh?.name) {
      this.destroyHighlightTooltip();
      this.generateHighlightTooltip(pickedMesh);
      this.highlightMesh(pickedMesh.name, 1);
      if (
        this.getCookie("cookieHideInfoPopupCanvasSpaceHover") === null &&
        document.querySelector(".space-hover-info")
      ) {
        $(".space-hover-info").remove();
        document.cookie =
          "cookieHideInfoPopupCanvasSpaceHover=hideInfoPopupCanvasSpaceHover; expires=Sun, 1 Jan 9033 00:00:00 UTC; path=/";
      }
    }
    if (
      pickedMesh.parent?.name == "highlights" &&
      pickedMesh.metadata.isAvailable
    ) {
      this.highlightMesh(pickedMesh.name, 1);
      if (pickedMesh.name !== this._activeHighlight) {
        this.setPreviousHighlightedMesh(pickedMesh);
      }
    } else {
      this.setPreviousHighlightedMesh(undefined);
    }
  }

  highlightMesh(meshName: string, isActive: 1 | 0) {
    const mesh = this.scene!.getMeshByName(meshName);
    if (!mesh) {
      return;
    }

    mesh.visibility = isActive;
  }

  setActiveMesh(mesh: AbstractMesh | undefined) {
    this._activeHighlight = mesh?.name;
  }

  setPreviousHighlightedMesh(mesh: AbstractMesh | undefined) {
    this.previousHighlightMesh = mesh;
  }

  generateHighlightTooltip(mesh: AbstractMesh) {
    // const spaceCode = mesh.name.replace('hl_', '');
    const meshParts = mesh.name.split("_");
    const building = meshParts[1];
    const floor = meshParts[2];
    const spaceCode = meshParts[3];
    // const spacesByCode =
    //   store.getters.spacesByMeshCode?.[
    //     `${building}_${floor}_${spaceCode}`
    //   ]?.[0];
    // if (!spacesByCode?.length) return;
    // const space = spacesByCode.find((sp: any) => sp.available && sp.is_public);
    // if (!space) return;

    const space =
      store.getters.activeProjectSpacesByMeshCode[
        `${building}_${floor}_${spaceCode}`
      ];
    if (!space) return;

    const coordinates = Vector3.Project(
      mesh.absolutePosition,
      Matrix.Identity(),
      this.scene!.getTransformMatrix(),
      this.cameras.dollHouse!.viewport.toGlobal(
        this.engine!.getRenderWidth(),
        this.engine!.getRenderHeight()
      )
    );
    // TODO: Fix this at some point
    // The problem is that the general store cannot be imported in here at the moment

    // TODO: get floor from api on the space or get the floor code from vuex state, by filtering state.floors((f) => f.id === space.floor_id)
    const currency = "€";
    $("<div>", {
      class: "space-info-tooltip",
      id: `space-info-tooltip-ID`,
    })
      .css({
        top: `${coordinates._y}px`,
        left: `${coordinates._x + 20}px`,
      })
      .html(
        `
            <div class="space-info">
                <div><span class="value">Office ${
                  space.alias || space.space_code
                }</span></div>
                <div class="column-elements">
                    <div><span class="value">${space.sqm}</span> sqm</div>
                    <div> &nbsp; • &nbsp; </div>
                    <div><span class="value">${space.people}</span> People</div>
                </div>
            </div>
        `
      )
      .appendTo("body");
  }

  destroyHighlightTooltip() {
    $(`.space-info-tooltip`).remove();
  }

  hightlightSpace(spaceName: string) {
    const [buildingCode, spaceCode] = spaceName.split("_");
    // const space = store.getters.activeProjectSpacesByMeshCode[spaceName];
    // space.building = buildingCode;
    // store.dispatch('viewSpace', {
    //     space: spaceCode,
    //     building: buildingCode,
    //     floor: "abc",
    //     spaceData: space
    // });

    //this.setSpace(space);
    this._activeHighlight = `hl_${spaceName}`;
    this.destroyHighlightTooltip();
    const activeMesh = this.scene?.getMeshByName(this._activeHighlight);
    if (activeMesh) {
      this.generateHighlightTooltip(activeMesh);
    }

    //this.floorHighlightHelper()?.disablePins(false);
  }

  resetHighlight() {
    //this.buildingInteractionHelper()?.resetHighlightVisibility();
    this._activeHighlight = null;
    //this.floorHighlightHelper()?.disablePins(true);
  }

  resetHighlights() {
    const hightlightsUnitsNode = this.scene!.getTransformNodeByName(
      "highlights"
    );
    if (hightlightsUnitsNode) {
      hightlightsUnitsNode.getChildMeshes().forEach((highlightMesh: any) => {
        highlightMesh.visibility = 0;
        highlightMesh.isClicked = false;
      });
      this.setPreviousHighlightedMesh(undefined);
      this._activeHighlight = null;
    }
  }
}

export default FloorInteractionHelper;

export { FloorInteractionHelperOpts };
