var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-toggle" }, [
    _c(
      "span",
      {
        staticClass: "toggle-element",
        class: { enabled: _vm.toggleValue },
        on: { click: _vm.toggle }
      },
      [_c("span", { staticClass: "inner" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }