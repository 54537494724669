<template>
  <div class="col atom-list-circle" :class="{[`col-${columnNumber}`]: true, [`col-sm-${tabletColumnNumber}`]: true, [`col-xs-${mobileColumnNumber}`]: true}">
    <span class="circle"><span></span></span>
    <h4 v-html="title"></h4>
    <AtomVerticalLine/>
    <AtomContentText color="#fff" :text="`${text}`" />
  </div>
</template>

<script>
import AtomVerticalLine from "../common/AtomVerticalLine";
import AtomContentText from "../common/AtomContentText";
export default {
  name: "AtomListCircle",
  components: {AtomContentText, AtomVerticalLine},
  props: {
    title: {
      required: true,
      type: String,
      default: ''
    },
    text: {
      required: true,
      type: String,
      default: ''
    },
    mobileColumnNumber: {
      required: false,
      type: [Number, String],
      default: 12
    },
    tabletColumnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    columnNumber: {
      required: false,
      type: [Number, String],
      default: 3
    },
  }
}
</script>

<style lang="scss" scoped>
  .atom-list-circle {
    margin-bottom: 4.375rem;
    padding: 0 2.188rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 1.875rem;
      padding: 0 1.2rem;
    }
    .circle {
      width: 3.125rem;
      height: 3.125rem;
      border: 0.188rem solid $white;
      border-radius: 3.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 1200px) {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 1.875rem;
      }
      @media only screen and (max-width: 767px) {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 3.125rem;
        border-width: 0.188rem;
        margin-bottom: 1.563rem;
      }
      span {
        display: block;
        width: 1.875rem;
        height: 1.875rem;
        background: $second-color;
        border-radius: 1.875rem;
        @media only screen and (max-width: 1200px) {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 1.25rem;
        }
        @media only screen and (max-width: 767px) {
          width: 1.875rem;
          height: 1.875rem;
          border-radius: 1.875rem;
        }
      }
    }
    h4 {
      margin-bottom: 1.25rem;
      @include font-main(1.5rem, $white, $medium, 1.813rem);
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.25rem;
        @include font-main(1.375rem, $white, $medium, 1.625rem);
      }
      b {
        @include font-main(1.5rem, $white, $bold, 1.813rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 1.25rem;
          @include font-main(1.375rem, $white, $bold, 1.625rem);
        }
      }
    }
    .atom-vertical-line {
      margin-bottom: 0.938rem;
    }
  }
</style>