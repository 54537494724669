var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section-side-image",
      class:
        ((_obj = {
          "with-image-on-right": _vm.withImageOnTheRight,
          "testimonials-style": _vm.testimonialsFormat
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("div", { staticClass: "inner" }, [
        _c("div", {
          staticClass: "image",
          class: { contain: _vm.imageContain },
          style: { backgroundImage: "url(" + _vm.assetUrl + ")" }
        }),
        _c("div", { staticClass: "content" }, [
          _vm.hasTitle
            ? _c("div", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(_vm.data.title) }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "text" },
            [
              _vm.testimonialsFormat
                ? _c("AtomIcon", { attrs: { icon: "quotation-mark" } })
                : _vm._e(),
              _vm._l(_vm.data.description, function(item, i) {
                return _c("p", {
                  key: i,
                  domProps: { innerHTML: _vm._s(item) }
                })
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }