<template>
  <div class="lazy-loader-wrapper" :style="{minHeight: `${height}px`, backgroundColor: backgroundColor}">
    <div class="lazy-loader">
      <div :style="{background: loaderColor}"></div>
      <div :style="{background: loaderColor}"></div>
      <div :style="{background: loaderColor}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomLazyLoader",
  props: {
    height: {
      required: false,
      type: Number,
      default: 100
    },
    backgroundColor: {
      required: false,
      type: String,
      default: '#000'
    },
    loaderColor: {
      required: false,
      type: String,
      default: 'rgba(255,255,255,0.25)'
    }
  }
}
</script>

<style lang="scss">
@keyframes lazy-loader {
  0% {
    top: 0.5rem;
    height: 4rem;
  }
  50%, 100% {
    top: 1.5rem;
    height: 2rem;
  }
}
.lazy-loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .lazy-loader {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
  }

  .lazy-loader div {
    display: inline-block;
    position: absolute;
    left: 0.5rem;
    width: 1rem;
    animation: lazy-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  .lazy-loader div:nth-child(1) {
    left: 0.5rem;
    animation-delay: -0.24s;
  }

  .lazy-loader div:nth-child(2) {
    left: 2rem;
    animation-delay: -0.12s;
  }

  .lazy-loader div:nth-child(3) {
    left: 3.5rem;
    animation-delay: 0;
  }
}
</style>