<template>
  <button :class="{'button-atom': true}" @click="onClick" :disabled="disabled">
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
    <span>{{label}}</span>
  </button>
</template>

<script>
import AtomIcon from "./AtomIcon";
export default {
  name: "AtomButton",
  components: {AtomIcon},
  props: {
    label: {
      required: false,
      type: String,
      default: "Submit",
    },
    icon: {
      required: false,
      type: String,
      default: "",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    onClick: {
      required: false,
      type: Function,
      default: () => { console.log("default action"); }
    }
  }
}
</script>

<style lang="scss">
.button-atom {
  display: inline-block;
  padding: 0.75rem 0.625rem;
  background: $main-color;
  border: 0.125rem solid $main-color;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    padding: 0.688rem 1.25rem 0.563rem;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out 0s;
    @include font-main(1rem, $white, $semiBold, 1.25rem);
    @media only screen and (max-width: 767px) {
      @include font-main(0.875rem, $white, $semiBold, 1.125rem);
    }
  }
  .icon {
    font-size: 0.875rem !important;
    color: $white !important;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.313rem;
  }
  &:hover {
    background: $second-color;
    border-color: $second-color;
  }
  &:disabled {
    background: #F0F0F0;
    border-color: #F0F0F0;
    cursor: not-allowed;
  }
}
</style>