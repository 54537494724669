var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-space-details" },
    [
      _c(
        "OrganismDrawer",
        {
          ref: "drawer",
          attrs: { "default-open": _vm.defaultOpen },
          on: {
            "drawer:close": function($event) {
              return _vm.$emit("drawer:close")
            }
          }
        },
        [
          _c("div", { staticClass: "main-content" }, [
            _c("div", { staticClass: "title-box" }, [
              _vm.space
                ? _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.space.alias || _vm.space.space_code))
                  ])
                : _vm._e(),
              _vm.surface
                ? _c(
                    "span",
                    { staticClass: "item-text" },
                    [
                      _c("AtomIcon", { attrs: { icon: "ruler-vertical" } }),
                      _c("b", [_vm._v(_vm._s(_vm.surface))]),
                      _vm._v("\n          " + _vm._s(_vm.unitOfMeasureString))
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm.space
              ? _c("div", { staticClass: "content-box" }, [
                  _c("div", { staticClass: "space-info" }, [
                    _c("div", { staticClass: "column-space-info" }, [
                      _c(
                        "span",
                        { staticClass: "item-text" },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              color: "#000",
                              size: 16,
                              icon: "chair-office"
                            }
                          }),
                          _c("b", [_vm._v(_vm._s(_vm.space.workplaces))]),
                          _c("br"),
                          _vm._v("\n              Workstations\n            ")
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "column-space-info" }, [
                      _c(
                        "span",
                        { staticClass: "item-text" },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              color: "#000",
                              size: 16,
                              icon: "ruler-vertical"
                            }
                          }),
                          _c("b", [_vm._v(_vm._s(_vm.surface))]),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.unitOfMeasureString) +
                              "\n            "
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "column-space-info" }, [
                      _c(
                        "span",
                        { staticClass: "item-text" },
                        [
                          _c("AtomIcon", {
                            attrs: { color: "#000", size: 16, icon: "stairs" }
                          }),
                          _c("b", [_vm._v(_vm._s(_vm.spaceFloor.no))]),
                          _c("br"),
                          _vm._v("\n              Floor\n            ")
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm.space && _vm.space.is_public
                    ? _c(
                        "div",
                        { staticClass: "content-buttons" },
                        [
                          _c("AtomButton", {
                            staticClass: "request-offer-btn button-green",
                            attrs: {
                              label: "Request Offer",
                              "custom-label": "Request",
                              icon: "plane-paper",
                              "on-click": function() {
                                return _vm.openRequestOfferModal()
                              },
                              "activate-hover-event": ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "surface-details" }, [
            _c("span", { staticClass: "title" }, [_vm._v("Space details")]),
            _c("div", { staticClass: "unit-wrapper" }, [
              _c("span", { staticClass: "unit-title" }, [
                _vm._v(_vm._s(_vm.spaceName))
              ])
            ]),
            _c("div", { staticClass: "unit-wrapper" }, [
              _c("span", { staticClass: "unit-title" }, [
                _vm._v("Available space")
              ]),
              _c("div", [
                _c("span", { staticClass: "unit-surface" }, [
                  _c("b", [_vm._v(_vm._s(_vm.surface))])
                ]),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.unitOfMeasureString) +
                    "\n        "
                )
              ])
            ]),
            _c("div", { staticClass: "unit-wrapper" }, [
              _c("span", { staticClass: "unit-title" }, [
                _vm._v(_vm._s(_vm.spaceWorkplacesName))
              ]),
              _c("div", [
                _c("span", { staticClass: "unit-surface" }, [
                  _c("b", [_vm._v(_vm._s(_vm.spaceWorkplaces))])
                ])
              ])
            ])
          ]),
          _vm.floorPlanDownloadActive
            ? _c("div", { staticClass: "floor-plan-button-wrapper" }, [
                _c(
                  "a",
                  {
                    staticClass: "floor-plan-button",
                    attrs: {
                      href: "" + _vm.resourceUrl,
                      download: "floorPlan.pdf",
                      target: "_blank"
                    }
                  },
                  [
                    _c("AtomButton", {
                      staticClass: "floor-plan-button",
                      attrs: { label: "Download Floor Plan" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }