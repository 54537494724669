<template>
  <div class="video-pin" v-lazyload="{handle: handleLazyLoad, url: resourceUrl}">
    <iframe v-if="resourceUrl && isHostedVideo" :src="resourceUrl"></iframe>
    <video v-if="resourceUrl && !isHostedVideo" width="100%" height="158" controls autoplay loop muted style="pointer-events: none;" >
      <source :src="resourceUrl" type="video/mp4">
    </video>
    <div class="simple-wrapper">
      <h4 v-if="pinInfo.pinTitle" v-html="pinInfo.pinTitle"></h4>
      <p v-if="pinInfo.pinDescription" v-html="pinInfo.pinDescription"></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "AtomPinVideo",
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function (){
        return {
          pinTitle: "",
          pinDescription: "",
          videoSrc: ""
        }
      }
    },
  },
  computed: {
    isHostedVideo() {
      if(!this.resourceUrl.includes('youtube') && !this.resourceUrl.includes('vimeo')) {
        return false;
      }
      return true;
    },
    resourceUrl() {
      if (!this.isInView) return '';
      if (this.pinInfo.videoSrc.includes('://')) {
        if(this.pinInfo.videoSrc.includes('youtube')){
          return this.pinInfo.videoSrc + '?enablejsapi=1' // we need to add this to be able to stop/pause a youtube video programmatically.
        }
        return this.pinInfo.videoSrc
      }
      return `${this.$store.getters.cdnBase}/${this.pinInfo.videoSrc}`
    }
  },
  data() {
    return {
      isInView: false,
    }
  },
  mounted() {
    this.isInView = true;
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss">
  .video-pin {
    video {
      border-radius: 5px 5px 0 0;
    }
    iframe {
      border: none;
      width: 100%;
      background: $black;
    }
  }
</style>