<template>
  <div class="organism-image-info">
    <MoleculeImageInfo>
      <div
        v-for="(list, index) in listImageInfo"
        :key="index"
        class="row image-info align-items-center"
        :class="{ 'image-right': list.imageRight }">
        <div v-if="list.image" class="col col-xs-12 col-4">
          <img :src="resourceUrl(list.image)" alt="" />
        </div>
        <div v-if="list.rightTitle" class="col col-xs-12 col-4 background-content-right">
          <div class="content-info">
            <h5 v-html="list.rightTitle"></h5>
            <ul>
              <li v-for="(rightLine, index) in list.rightLines" :key="index">
                <p v-html="rightLine.text"></p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col col-xs-12 col-8">
          <div class="content-info">
            <h5 v-html="list.title"></h5>
            <p v-for="(line, index) in list.lines" :key="index" v-html="line"></p>
          </div>
        </div>
      </div>
    </MoleculeImageInfo>
  </div>
</template>

<script>
import MoleculeImageInfo from "@/components/molecules/common/MoleculeImageInfo.vue";

export default {
  name: 'OrganismImageInfo',
  components: {MoleculeImageInfo},
  props: {
    listImageInfo: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    }
  }
}
</script>

<style lang="scss">
.organism-image-info {
  .image-info {
    margin-bottom: 3.5rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 2.5rem;
    }
    &.image-right {
      flex-direction: row-reverse;
      @media only screen and (max-width: 767px) {
        flex-direction: initial;
      }
    }
  }
  .col {
    margin-bottom: 1.25rem;
  }
  .content-info {
    padding: 3.75rem;
    @media only screen and (max-width: 767px) {
      padding: 1.25rem 0;
    }
    img {
      display: block;
    }
    h5 {
      margin: 0 0 1.75rem;
      @include font-main(3.25rem, $black, $regular, 3.875rem);
      @media only screen and (max-width: 767px) {
        margin: 0 0 0.938rem;
        @include font-main(1.375rem, $black, $regular, 1.625rem);
      }
      b {
        @include font-main(3.25rem, $black, $bold, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1.375rem, $black, $bold, 1.625rem);
        }
      }
    }
    p {
      margin: 0.875rem 0;
      @include font-main(1rem, $black, $regular, 1.625rem);
      @media only screen and (max-width: 767px) {
        margin: 0.313rem 0;
        @include font-main(1rem, $black, $regular, 1.625rem);
      }
      h4 {
        margin-top: 2rem;
        @include font-main(1.2rem, $black, $bold, 1.625rem);
        @media only screen and (max-width: 767px) {
          margin-top: 1rem;
          @include font-main(1.2rem, $black, $bold, 1.625rem);
        }
      }
      b {
        @include font-main(1rem, $black, $bold, 1.625rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1rem, $black, $bold, 1.625rem);
        }
      }
      .button-atom {
        margin-top: 1.5rem;
        @media only screen and (max-width: 767px) {
          margin-top: 1rem;
        }
      }
    }
  }
  .background-content-right {
    background: $main-color;
    .content-info {
      @media only screen and (max-width: 767px) {
        padding: 1.25rem;
      }
      h5 {
        color: $white;
      }
      ul {
        list-style: none;
        p {
          position: relative;
          margin: 0.3rem 0;
          max-width: 20rem;
          line-height: 1.5rem;
          color: $white;
          @media only screen and (max-width: 767px) {
            margin: 0.125rem 0;
            max-width: 100%;
            line-height: 1.25rem;
          }
          b {
            position: absolute;
            right: 0;
            line-height: 1.5rem;
            color: $white;
            @media only screen and (max-width: 767px) {
              line-height: 1.25rem;
            }
          }
        }
      }
    }
  }
}
</style>
