var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-parking-access" },
    [
      !_vm.isInView
        ? _c("AtomLazyLoader", { attrs: { height: 525 } })
        : _vm._e(),
      _c(
        "picture",
        {
          directives: [
            {
              name: "lazyload",
              rawName: "v-lazyload",
              value: { handle: _vm.handleLazyLoad, url: _vm.assetUrl },
              expression: "{handle: handleLazyLoad, url: assetUrl}"
            }
          ]
        },
        [
          _c("source", { attrs: { srcset: _vm.assetUrl } }),
          _vm.assetUrl
            ? _c("img", { attrs: { src: _vm.assetUrl, alt: "Image Parking" } })
            : _vm._e()
        ]
      ),
      _vm._l(_vm.parkingAccess, function(building, index) {
        return _c(
          "div",
          { key: index, staticClass: "wrapper-parking-access" },
          [
            _c(
              "div",
              {
                staticClass: "parking-access",
                class: { "more-parking": building.marginLeft },
                style: { "margin-left": building.marginLeft }
              },
              [
                _c("p", { domProps: { innerHTML: _vm._s(building.name) } }),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.playEntries("" + building.slug)
                        }
                      }
                    },
                    [_vm._v("Entries")]
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.playExits("" + building.slug)
                        }
                      }
                    },
                    [_vm._v("Exits")]
                  )
                ])
              ]
            ),
            _vm.showEntries[building.slug]
              ? _c(
                  "div",
                  {
                    staticClass: "animation",
                    attrs: { id: "animation-entries" }
                  },
                  [
                    _c("AtomLottieAnimation", {
                      attrs: {
                        options: {
                          loop: false,
                          autoplay: false,
                          animationData: building.animations.entries,
                          rendererSettings: {
                            progressiveLoad: false
                          }
                        }
                      },
                      on: {
                        animCreated: function($event) {
                          return _vm.handleAnimation(
                            $event,
                            building.slug + "-entries"
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showExits[building.slug]
              ? _c(
                  "div",
                  {
                    staticClass: "animation",
                    attrs: { id: "animation-exits" }
                  },
                  [
                    _c("AtomLottieAnimation", {
                      attrs: {
                        options: {
                          loop: false,
                          autoplay: false,
                          animationData: building.animations.exits,
                          rendererSettings: {
                            progressiveLoad: false
                          }
                        }
                      },
                      on: {
                        animCreated: function($event) {
                          return _vm.handleAnimation(
                            $event,
                            building.slug + "-exits"
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }