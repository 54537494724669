var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-tabs-technical-specs-v2" },
    [
      _c("div", { staticClass: "row tabs-elements align-items-end" }, [
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c(
            "div",
            { staticClass: "sub-tabs" },
            _vm._l(_vm.subTabs, function(subTab, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "sub-tab-trigger",
                  class: { active: _vm.activeSubTab === subTab.subTabId },
                  on: {
                    click: function($event) {
                      return _vm.handleTabChange(subTab.subTabId)
                    }
                  }
                },
                [_vm._v(_vm._s(subTab.subTabLabel))]
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "text-right" }, [_vm._t("default")], 2)
        ])
      ]),
      _vm._l(_vm.subTabs, function(subTab, index) {
        return _c("div", { key: index }, [
          _vm.activeSubTab === subTab.subTabId
            ? _c(
                "div",
                [
                  subTab.statementTitle
                    ? _c("div", { staticClass: "row statements" }, [
                        _c("div", { staticClass: "col col-12" }, [
                          _c(
                            "div",
                            { staticClass: "statement" },
                            [
                              subTab.statementName
                                ? _c("AtomIcon", {
                                    attrs: { icon: "quotation-mark" }
                                  })
                                : _vm._e(),
                              _c("AtomTitle", {
                                attrs: {
                                  title: subTab.statementTitle,
                                  subtitle: subTab.statementText
                                }
                              }),
                              _c("div", { staticClass: "person-info" }, [
                                _c("h5", [
                                  _vm._v(_vm._s(subTab.statementName))
                                ]),
                                _c("h6", [
                                  _vm._v(_vm._s(subTab.statementCompany))
                                ])
                              ])
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("OrganismTechnicalSpecsV2", {
                    attrs: {
                      "bullet-technical-data": subTab.items,
                      "image-specs": subTab.image
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }