import $ from 'jquery';
import {
    ArcRotateCamera,
    Scene,
    CubicEase,
    Animation,
    EasingFunction, Engine, TransformNode, Nullable, Mesh, ActionManager, ExecuteCodeAction, StandardMaterial, Texture, MeshBuilder, Color3, Vector3, Matrix, Angle, FreeCamera
} from "babylonjs";
import {AdvancedDynamicTexture, Button, Ellipse, TextBlock} from "babylonjs-gui";
import {DefaultState, FloorViewMode} from "@/components/organisms/project/building/store/index";
import {CameraHelper, EngineHelper, SceneHelper} from "@/components/organisms/project/building/store/helpers";
import {device} from "@/helpers/mobile";

type FloorHighlightsHelperOpts = {
    space: any
}

class FloorHighlightsHelper {
    _isVue: boolean;
    engine: Engine | undefined;
    scene: Scene | undefined;
    cameras: {
        dollHouse: ArcRotateCamera | null,
        firstPerson: FreeCamera | null
    };
    space: Record<string, any> | null;
    isEnabled: boolean;
    pins: Record<string, any>;
    mainNode: Record<string, any> | undefined;
    advancedTexture: Record<string, any> | undefined;
    highlightNodes: Record<string, any> | undefined;
    visiblePins: boolean;
    pinMeshes:any;
    activePinMesh: any;
    pinsInfo:any;
    activeCateg: any;
    pinsData: any;


    constructor({space}: FloorHighlightsHelperOpts, engine: EngineHelper | null, scene: SceneHelper | null, cameras: CameraHelper | null, pinsInfo:any) {
        this._isVue = true;
        this.engine = engine?.get();
        this.scene = scene?.get();
        this.cameras = {
            dollHouse: cameras?.getDollHouseCamera() as ArcRotateCamera,
            firstPerson: cameras?.getFirstPersonCamera() as FreeCamera
        };
        this.space = space;
        this.isEnabled = false;
        this.pins = {};
        this.visiblePins = false;
        this.pinMeshes=[];
        this.activePinMesh = null;
        this.pinsInfo = pinsInfo;
        this.activeCateg = '';
    }

    init(highlightTransformNode: Record<string, any>, isFloor:boolean) {
        this.mainNode = highlightTransformNode;
        const vtName = highlightTransformNode.name.includes('WT') ? 'VirtualTour' : '';
        this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.advancedTexture.useInvalidateRectOptimization = false;
        this.highlightNodes = this.mainNode.getChildTransformNodes(true);
        console.log('highlightNodes >> ', this.highlightNodes!)
        const pinMeshesParent = !this.scene?.getTransformNodeByName(`pinMeshesParent${vtName}`) ? new TransformNode(`pinMeshesParent${vtName}`) : this.scene?.getTransformNodeByName(`pinMeshesParent${vtName}`);
        for (let i = 0; i < this.highlightNodes!.length; i++) {
            const childMeshes = this.highlightNodes![i].getChildMeshes(true);
            childMeshes.sort();
            if (childMeshes[0]) {
                //this.generatePin('+', childMeshes[0], `highlights_${floorType}` , childMeshes[0].name);
                //this.createPinMesh(childMeshes[0].name, childMeshes[0], pinMeshesParent, this.pinsInfo, isFloor);
            }
        }
        if (this.highlightNodes!.length && pinMeshesParent!.name === 'pinMeshesParentVirtualTour'){
            this.highlightNodes!.forEach((highlight:any)=>{
                this.createVirtualTourPinMesh(highlight.name, highlight, pinMeshesParent, this.pinsInfo, isFloor);
            })
        }
        if (this.pinsData){
            this.pinsData.forEach((pindata:any)=>{
                if (pindata && pindata.location){
                    this.createSimplePinMesh(pindata, pinMeshesParent, this.pinsInfo);
                }
            })
        }
        this.hideHighlights();

        this.isEnabled = true;
        this.visiblePins = true;
    }

    generatePin(buttonText: string, meshToLink: Nullable<TransformNode>, parentNode: string, pinName: string) {
        let hightlightName = '';
        let changeOk = false;
        const pin = Button.CreateSimpleButton(pinName, '');
        pin.width = "24px";
        pin.height = "24px";
        pin.cornerRadius = 100;
        pin.thickness = 0;
        pin.background = "rgba(0,0,0,0)";
        pin.hoverCursor = "pointer";
        pin.isPointerBlocker = true;
        this.advancedTexture!.addControl(pin);

        const ellipse = new Ellipse();
        ellipse.width = "24px"
        ellipse.height = "24px";
        ellipse.color = "#ffffff";
        ellipse.background = "#000000";

        const textPin = new TextBlock();
        textPin.text = "+";
        textPin.color = "white";
        textPin.fontSize = 24;

        pin.addControl(ellipse);
        pin.addControl(textPin);

        pin.linkWithMesh(meshToLink);

        pin.onPointerEnterObservable.add(() => {
            changeOk = true;
            this.hideHighlights();
            this.resetPinRotation();
            this.showPinDescription(pinName);

            if (hightlightName === pinName) {
                this.setVisibility(parentNode, hightlightName, 1);
                changeOk = true;
            }

            this.setVisibility(parentNode, pinName, 1);
            const child = pin._children ? pin._children[1] : {};
            (child as any)._background = "#4CC66D" ;
            this.animatePin(pin, pin.rotation, .8);
        });
        pin.onPointerClickObservable.add(() => {
            this.animatePin(pin, pin.rotation, .8);
            if (hightlightName !== pinName) {
                this.setVisibility(parentNode, hightlightName, 0);
                hightlightName = pinName;
            }

            changeOk = false;
            //for mobile
            this.setVisibility(parentNode, pinName, 1);
            this.showPinDescription(pinName);
            //for mobile
        });
        pin.onPointerOutObservable.add(() => {
            this.animatePin(pin, pin.rotation, .8);
            if (changeOk) {
                this.setVisibility(parentNode, pinName, 0);
                $('.pinInfo').stop().fadeOut();
                this.animatePin(pin, pin.rotation, 0);
            }
            const child = pin._children ? pin._children[1] : {};
            changeOk ? (child as any)._background = "#000000" : (child as any)._background = "#4CC66D"
        });

        this.pins[pinName] = pin;

    }

    createPinMesh(meshName:any, meshToLink:any, pinMeshesParent:any, customMeshSettings:any, isFloor:boolean){
        const self = this;
        const spriteMaterial = new StandardMaterial("spriteMat", self.scene as any);
        const spriteTexture = new Texture('https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/sthlm/360/plus-sign.jpg', self.scene as any);
        spriteTexture.hasAlpha = true;
        spriteTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
        spriteMaterial.emissiveTexture = spriteTexture;
        spriteMaterial.opacityTexture = spriteTexture;
        spriteMaterial.backFaceCulling = false;
        //const sprite = BABYLON.MeshBuilder.CreatePlane(`sprite_${meshName}`, { size:0.6, sideOrientation: Mesh.DOUBLESIDE }, self.scene as any) as any;
        const sprite = MeshBuilder.CreateDisc(`sprite_${meshName}`, { radius:1, sideOrientation: Mesh.DOUBLESIDE }, self.scene as any) as any;
        sprite.material = spriteMaterial;
        sprite.position = meshToLink.getBoundingInfo().boundingBox.centerWorld;
        sprite.position.y = sprite.position.y + meshToLink.getBoundingInfo().boundingBox.extendSize._z/100;
        //TODO:change position and scaling
        if (isFloor){
            sprite.position.y = 4;
        }
        let scale =  new BABYLON.Vector3(.7,.7,.7);
        if(device.isMobile() || device.isTablet()){
            scale = new BABYLON.Vector3(0.01,0.01,0.01);
        }
        sprite.scaling = scale;
        sprite.meshToLink = meshToLink;
        sprite.isPickable = true;
        sprite.parent= pinMeshesParent;

        if(customMeshSettings){
            customMeshSettings.forEach((section:any)=>{
                if (section.pins){
                    section.pins.forEach((pin:any)=>{
                        if (pin.pinId === meshName){
                            sprite.category = section.category;
                            sprite.viewMode = `${FloorViewMode.DEFAULT}_${FloorViewMode.TWO_DIMENSIONAL}`
                        }
                    })
                }
                if (section.virtualTourPins){
                    section.virtualTourPins.forEach((pin:any)=>{
                        if (pin.pinId === meshName){
                            sprite.category = section.category;
                            sprite.viewMode = FloorViewMode.FIRST_PERSON;
                            sprite.scaling = new BABYLON.Vector3(.1,.1,.1);
                        }
                    })
                }
            })
        }

        sprite.actionManager = new ActionManager(this.scene!);
        sprite.enablePointerMoveEvents = true;
        sprite.billboardMode = Mesh.BILLBOARDMODE_ALL;

        const pinRotationAnimationForward = new Animation("pinRotationAnimationForward", "wAng", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        const keyFrames = [];

        const frame = 10;
        keyFrames.push({
            frame: 0,
            value: 0
        });
        keyFrames.push({
            frame: frame,
            value: Math.PI/4
        });
        pinRotationAnimationForward.setKeys(keyFrames);
        sprite.material.emissiveTexture.animations.push(pinRotationAnimationForward);

        function hoverPinMesh(pinmesh: any){
            pinmesh!.material!.emissiveColor = new Color3(0.298,0.776,0.427);
            self.showPinMeshDescription(sprite, true);
            self.setHighlightVisibility(pinmesh.meshToLink, 1);
            self.scene?.beginAnimation(sprite.material.emissiveTexture, 0, frame, false, 3, ()=>{
                if (isFloor){
                    sprite.position.y = 4;
                }
            });
        }

        function unhoverPinMesh(pinmesh: any){
            self.showPinMeshDescription(sprite, false);
            self.setHighlightVisibility(pinmesh.meshToLink, 0);
            pinmesh!.material!.emissiveColor = new Color3(0,0,0);
            self.scene?.beginAnimation(pinmesh.material.emissiveTexture, frame, 0, false, 3, ()=>{
                if (isFloor){
                    sprite.position.y = 4;
                }
            });
        }

        if (!customMeshSettings){
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
                if (!sprite.spriteActive){
                    hoverPinMesh(ev.meshUnderPointer);
                    self.pinMeshes.forEach((pinMesh:any) =>{
                        if (pinMesh.spriteActive){
                            pinMesh.spriteActive = false;
                            self.setHighlightVisibility(pinMesh.meshToLink, 0);
                            pinMesh!.material!.emissiveColor = new Color3(0,0,0);
                            self.scene?.beginAnimation(pinMesh.material.emissiveTexture, frame, 0, false, 3);
                        }
                    })
                }
            }));
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
                if (!sprite.spriteActive){
                    unhoverPinMesh(ev.meshUnderPointer);
                }
            }));
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function(ev){
                sprite.spriteActive ? sprite.spriteActive = !sprite.spriteActive : sprite.spriteActive= true;
                self.pinMeshes.forEach((pinMesh:any) =>{
                    if (pinMesh.spriteActive && pinMesh != sprite){
                        //
                    }
                });
            }));
        } else {
            // Virtual Tour pins
            const theScene:any = self.scene;
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
                theScene!.isCursorActive = false;
                self.showPinMeshDescription(sprite, true);
                if(sprite.viewMode !== FloorViewMode.FIRST_PERSON){
                    self.setHighlightVisibility(sprite.meshToLink, 1);
                }
            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
                theScene!.isCursorActive = true;
                if(sprite.viewMode === FloorViewMode.FIRST_PERSON && sprite.isClicked){
                    self.showPinMeshDescription(sprite, true);

                } else {
                    self.showPinMeshDescription(sprite, false);
                    self.setHighlightVisibility(sprite.meshToLink, 0);
                }

            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function(ev){
                sprite.spriteActive = true;
                sprite.isClicked = true;

                self.pinMeshes.forEach((pinMesh:any)=>{
                    if(sprite!=pinMesh){
                        pinMesh.isClicked = false;
                        self.activateSidebarItem(pinMesh, false);
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON){
                            self.showPinMeshDescription(pinMesh, false);
                            self.setHighlightVisibility(pinMesh.meshToLink, 0);
                        }else{
                            pinMesh.isClicked = false;
                        }
                    }

                    if (sprite.category === pinMesh.category){
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            pinMesh.material!.emissiveColor = new Color3(0.298, 0.776, 0.427);

                            if (pinMesh.material.emissiveTexture.wAng < Math.PI / 4) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, 0, frame, false, 3);
                            }
                        }
                    } else {
                        pinMesh.material!.emissiveColor = new Color3(0,0,0);
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            if (pinMesh.material.emissiveTexture.wAng > 0) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, frame, 0, false, 3);
                            }
                        }
                    }
                })

                self.activateSidebarItem(sprite, true);
                if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                    self.showPinMeshDescription(sprite, true);
                    self.setHighlightVisibility(sprite.meshToLink, 1);
                }
            }));
        }

        sprite.setEnabled(true);
        sprite.isVisible = true;
        if (sprite.viewMode){
            sprite.isVisible = sprite.viewMode && sprite.viewMode !== FloorViewMode.FIRST_PERSON;
        }

        this.pinMeshes!.push(sprite);

    }

    createVirtualTourPinMesh(meshName:any, meshToLink:any, pinMeshesParent:any, customMeshSettings:any, isFloor:boolean){
        const self = this;
        const spriteMaterial = new StandardMaterial("spriteMat", self.scene as any);
        const spriteTexture = new Texture('https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/all/plus-sign.jpg', self.scene as any);
        spriteTexture.hasAlpha = true;
        spriteTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
        spriteMaterial.emissiveTexture = spriteTexture;
        spriteMaterial.opacityTexture = spriteTexture;
        spriteMaterial.backFaceCulling = false;
        const sprite = MeshBuilder.CreateDisc(`sprite_${meshName}`, { radius:1, sideOrientation: Mesh.DOUBLESIDE }, self.scene as any) as any;
        sprite.material = spriteMaterial;
        sprite.position = meshToLink.getBoundingInfo().boundingBox.centerWorld;
        sprite.position.y = sprite.position.y + meshToLink.getBoundingInfo().boundingBox.extendSize._z/100;

        let scale =  new Vector3(.7,.7,.7);
        if(device.isMobile() || device.isTablet()){
            scale = new Vector3(0.01,0.01,0.01);
        }
        sprite.scaling = scale;
        sprite.meshToLink = meshToLink;
        sprite.isPickable = true;
        sprite.parent= pinMeshesParent;

        sprite.category = 'category';
        sprite.viewMode = FloorViewMode.FIRST_PERSON;
        sprite.scaling = new Vector3(.1,.1,.1);

        sprite.actionManager = new ActionManager(this.scene!);
        sprite.enablePointerMoveEvents = true;
        sprite.billboardMode = Mesh.BILLBOARDMODE_ALL;

        const pinRotationAnimationForward = new Animation("pinRotationAnimationForward", "wAng", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        const keyFrames = [];

        const frame = 10;
        keyFrames.push({
            frame: 0,
            value: 0
        });
        keyFrames.push({
            frame: frame,
            value: Math.PI/4
        });
        pinRotationAnimationForward.setKeys(keyFrames);
        sprite.material.emissiveTexture.animations.push(pinRotationAnimationForward);

            // Virtual Tour pins
            const theScene:any = self.scene;
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
                console.log('mouse enter')
                theScene!.isCursorActive = false;
                self.showPinMeshDescription(sprite, true);
            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
                console.log('mose leave')
                theScene!.isCursorActive = true;
                if(sprite.viewMode === FloorViewMode.FIRST_PERSON && sprite.isClicked){
                    self.showPinMeshDescription(sprite, true);

                } else {
                    self.showPinMeshDescription(sprite, false);
                    self.setHighlightVisibility(sprite.meshToLink, 0);
                }

            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function(ev){
                sprite.spriteActive = true;
                sprite.isClicked = true;

                self.pinMeshes.forEach((pinMesh:any)=>{
                    if(sprite!=pinMesh){
                        pinMesh.isClicked = false;
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON){
                            self.showPinMeshDescription(pinMesh, false);
                            self.setHighlightVisibility(pinMesh.meshToLink, 0);
                        }else{
                            pinMesh.isClicked = false;
                        }
                    }

                    if (sprite.category === pinMesh.category){
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            pinMesh.material!.emissiveColor = new Color3(0.298, 0.776, 0.427);

                            if (pinMesh.material.emissiveTexture.wAng < Math.PI / 4) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, 0, frame, false, 3);
                            }
                        }
                    } else {
                        pinMesh.material!.emissiveColor = new Color3(0,0,0);
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            if (pinMesh.material.emissiveTexture.wAng > 0) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, frame, 0, false, 3);
                            }
                        }
                    }
                })

                if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                    self.showPinMeshDescription(sprite, true);
                    self.setHighlightVisibility(sprite.meshToLink, 1);
                }
            }));


        sprite.setEnabled(true);
        sprite.isVisible = true;
        if (sprite.viewMode){
            sprite.isVisible = sprite.viewMode && sprite.viewMode !== FloorViewMode.FIRST_PERSON;
        }

        this.pinMeshes!.push(sprite);

    }

    createSimplePinMesh(pinData:any, pinMeshesParent:any, customMeshSettings:any){
        const self = this;
        const spriteMaterial = new StandardMaterial("spriteMat", self.scene as any);
        const spriteTexture = new Texture('https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/sthlm/360/plus-sign.jpg', self.scene as any);
        spriteTexture.hasAlpha = true;
        spriteTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
        spriteMaterial.emissiveTexture = spriteTexture;
        spriteMaterial.opacityTexture = spriteTexture;
        spriteMaterial.backFaceCulling = false;

        const sprite = MeshBuilder.CreateDisc(`sprite_${pinData.highlightAnchor}`, { radius:1, sideOrientation: Mesh.DOUBLESIDE }, self.scene as any) as any;
        sprite.material = spriteMaterial;
        //sprite.position = meshToLink.getBoundingInfo().boundingBox.centerWorld;
        sprite.position = new Vector3(...pinData.location);
        //sprite.position.y = sprite.position.y + meshToLink.getBoundingInfo().boundingBox.extendSize._z/100;
        //TODO:change position and scaling
        //sprite.position.y = 4;
        let scale =  new Vector3(.3,.3,.3);
        if (pinData.scale){
            scale =  new Vector3(...pinData.scale.desktop);
        }
        if(device.isMobile() || device.isTablet()){
            scale = new Vector3(0.01,0.01,0.01);
            if (pinData.scale){
                scale =  new Vector3(...pinData.scale.mobile);
            }
        }
        sprite.scaling = scale;
        //sprite.meshToLink = meshToLink;
        sprite.isPickable = true;
        sprite.parent= pinMeshesParent;

        // if(customMeshSettings){
        //     customMeshSettings.forEach((section:any)=>{
        //         if (section.pins){
        //             section.pins.forEach((pin:any)=>{
        //                 if (pin.pinId === meshName){
        //                     sprite.category = section.category;
        //                     sprite.viewMode = `${FloorViewMode.DEFAULT}_${FloorViewMode.TWO_DIMENSIONAL}`
        //                 }
        //             })
        //         }
        //         if (section.virtualTourPins){
        //             section.virtualTourPins.forEach((pin:any)=>{
        //                 if (pin.pinId === meshName){
        //                     sprite.category = section.category;
        //                     sprite.viewMode = FloorViewMode.FIRST_PERSON;
        //                     sprite.scaling = new Vector3(.1,.1,.1);
        //                 }
        //             })
        //         }
        //     })
        // }

        sprite.actionManager = new ActionManager(this.scene!);
        sprite.enablePointerMoveEvents = true;
        sprite.billboardMode = Mesh.BILLBOARDMODE_ALL;

        const pinRotationAnimationForward = new Animation("pinRotationAnimationForward", "wAng", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        const keyFrames = [];

        const frame = 10;
        keyFrames.push({
            frame: 0,
            value: 0
        });
        keyFrames.push({
            frame: frame,
            value: Math.PI/4
        });
        pinRotationAnimationForward.setKeys(keyFrames);
        sprite.material.emissiveTexture.animations.push(pinRotationAnimationForward);

        function hoverPinMesh(pinmesh: any){
            pinmesh!.material!.emissiveColor = new Color3(0.298,0.776,0.427);
            self.showPinMeshDescription(sprite, true);
            //self.setHighlightVisibility(pinmesh.meshToLink, 1);
            self.scene?.beginAnimation(sprite.material.emissiveTexture, 0, frame, false, 3);
        }

        function unhoverPinMesh(pinmesh: any){
            self.showPinMeshDescription(sprite, false);
            //self.setHighlightVisibility(pinmesh.meshToLink, 0);
            pinmesh!.material!.emissiveColor = new Color3(0,0,0);
            self.scene?.beginAnimation(pinmesh.material.emissiveTexture, frame, 0, false, 3);
        }

        if (!customMeshSettings){
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
                if (!sprite.spriteActive){
                    hoverPinMesh(ev.meshUnderPointer);
                    self.pinMeshes.forEach((pinMesh:any) =>{
                        if (pinMesh.spriteActive){
                            pinMesh.spriteActive = false;
                            //self.setHighlightVisibility(pinMesh.meshToLink, 0);
                            pinMesh!.material!.emissiveColor = new Color3(0,0,0);
                            self.scene?.beginAnimation(pinMesh.material.emissiveTexture, frame, 0, false, 3);
                        }
                    })
                }
            }));
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
                if (!sprite.spriteActive){
                    unhoverPinMesh(ev.meshUnderPointer);
                }
            }));
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function(ev){
                sprite.spriteActive ? sprite.spriteActive = !sprite.spriteActive : sprite.spriteActive= true;
                self.pinMeshes.forEach((pinMesh:any) =>{
                    if (pinMesh.spriteActive && pinMesh != sprite){
                        //
                    }
                });
            }));
        } else {
            // Virtual Tour pins
            const theScene:any = self.scene;
            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function(ev){
                theScene!.isCursorActive = false;
                self.showPinMeshDescription(sprite, true);
                if(sprite.viewMode !== FloorViewMode.FIRST_PERSON){
                    //self.setHighlightVisibility(sprite.meshToLink, 1);
                }
            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function(ev){
                theScene!.isCursorActive = true;
                if(sprite.viewMode === FloorViewMode.FIRST_PERSON && sprite.isClicked){
                    self.showPinMeshDescription(sprite, true);

                } else {
                    self.showPinMeshDescription(sprite, false);
                    //self.setHighlightVisibility(sprite.meshToLink, 0);
                }

            }));

            sprite.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function(ev){
                sprite.spriteActive = true;
                sprite.isClicked = true;

                self.pinMeshes.forEach((pinMesh:any)=>{
                    if(sprite!=pinMesh){
                        pinMesh.isClicked = false;
                        self.activateSidebarItem(pinMesh, false);
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON){
                            self.showPinMeshDescription(pinMesh, false);
                            // self.setHighlightVisibility(pinMesh.meshToLink, 0);
                        }else{
                            pinMesh.isClicked = false;
                        }
                    }

                    if (sprite.category === pinMesh.category){
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            pinMesh.material!.emissiveColor = new Color3(0.298, 0.776, 0.427);

                            if (pinMesh.material.emissiveTexture.wAng < Math.PI / 4) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, 0, frame, false, 3);
                            }
                        }
                    } else {
                        pinMesh.material!.emissiveColor = new Color3(0,0,0);
                        if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                            if (pinMesh.material.emissiveTexture.wAng > 0) {
                                theScene.beginAnimation(pinMesh.material.emissiveTexture, frame, 0, false, 3);
                            }
                        }
                    }
                })

                self.activateSidebarItem(sprite, true);
                if(sprite.viewMode !== FloorViewMode.FIRST_PERSON) {
                    self.showPinMeshDescription(sprite, true);
                    // self.setHighlightVisibility(sprite.meshToLink, 1);
                }
            }));
        }

        sprite.setEnabled(true);
        sprite.isVisible = true;
        if (sprite.viewMode){
            sprite.isVisible = sprite.viewMode && sprite.viewMode !== FloorViewMode.FIRST_PERSON;
        }

        this.pinMeshes!.push(sprite);

    }


    setVisibility(parentNode: string, pinName: string, vIndex: number) {
        if (this.isEnabled === false) return;
        if (parentNode && this.scene) {
            const nameIndex = pinName.replace(/[0-9]/g, '');
            const highlightElements = this.scene.getTransformNodeByName(parentNode)?.getChildMeshes(false);
            if (highlightElements) {
                for (let i = 0; i < highlightElements.length; i++) {
                    highlightElements[i].isPickable = false;
                    if (highlightElements[i].name.indexOf(nameIndex) !== -1) {
                        const ease = new CubicEase();
                        ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
                        Animation.CreateAndStartAnimation('highlightAnimation2', highlightElements[i], 'visibility', 10, 2, highlightElements[i].visibility, vIndex, 0, ease);
                    }
                }
            }
        }
    }

    setHighlightVisibility(pinRef: any, vIndex: number) {
        if (this.isEnabled === false) return;
        if (this.scene) {
            const nameIndex = pinRef.name.includes('fitout') ? pinRef.name : pinRef.name.replace(/[0-9]/g, '');
            // const nameIndex = pinRef.name.replace(/[0-9]/g, '');
            if (this.highlightNodes) {
                this.highlightNodes.forEach((highlightNode:any) => {
                    highlightNode.isPickable = false;
                    highlightNode.getChildMeshes().forEach((highlightMesh:any) => {
                        if(highlightMesh.name.includes(nameIndex)){
                            // const ease = new CubicEase();
                            // ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
                            highlightMesh.visibility = vIndex;
                            //Animation.CreateAndStartAnimation('highlightAnimation0', highlightMesh, 'visibility', 10, 2, highlightMesh.visibility, vIndex, 0, ease);
                        }
                    })
                })

            }
        }
    }

    showPinDescription(pinName: string) {
        if (this.isEnabled === false) return;
        const pinTop = this.pins[pinName].topInPixels;
        const pinLeft = this.pins[pinName].leftInPixels;
        const toolTip = $('.pinInfo[data-pin="' + pinName + '"]');
        const pinBoxHeight = toolTip.outerHeight() || 0;
        const pinBoxWidth = toolTip.outerWidth() || 0;

        //$('.pinInfo').stop().fadeOut();
        toolTip.stop().fadeIn('fast').css({
            'top': pinTop - pinBoxHeight - 10,
            'left': pinLeft - (pinBoxWidth - 25) / 2
        });
    }

    showPinMeshDescription(pinMesh: any, showDescription:boolean) {
        if (this.isEnabled === false) return;
        $('.pinInfo').stop().fadeOut();
        if (!showDescription) return;

        const transformMatrix = this.scene!.getTransformMatrix();
        const position = pinMesh.absolutePosition;
        const viewport =  this.cameras.dollHouse!.viewport;

        const coordinates = Vector3.Project(
            position,
            Matrix.Identity(),
            transformMatrix,
            viewport.toGlobal(
                this.engine!.getRenderWidth(),
                this.engine!.getRenderHeight(),
            ));
        const pinTop = coordinates._y;
        const pinLeft = coordinates._x;

        const toolTip = $('.pinInfo[data-pin="' + pinMesh!.name.replace('sprite_', '') + '"]');

        toolTip.stop().fadeIn();

        const pinBoxHeight = toolTip.outerHeight() || 0;
        const pinBoxWidth = toolTip.outerWidth() || 0;

            toolTip.stop().fadeIn('fast').css({
                'top': pinTop - pinBoxHeight - 20,
                'left': pinLeft - (pinBoxWidth) / 2
            });

    }

    showPins(value:boolean) {
        if (this.isEnabled === false) {
            this.visiblePins = false;
            return;
        }

        for (let i = 0; i < this.highlightNodes!.length; i++) {
            const childMeshes = this.highlightNodes![i].getChildMeshes(true);
            childMeshes.sort();
            this.pins[childMeshes[0].name].isVisible = value;
        };
        this.visiblePins = value;
    }

    showPinMeshes(value:boolean, viewMode:string) {
        if (this.isEnabled === false) {
            this.visiblePins = false;
            return;
        }

        this.pinMeshes.forEach((pinMesh: any) => {
            // change pin position
            //pinMesh.position.y=4;
            pinMesh.isVisible = !value;
            if(pinMesh.viewMode && pinMesh.viewMode.includes(viewMode)){
                pinMesh.isVisible = value;
            }
        })

        this.visiblePins = value;
    }


    showFitoutPins(value: boolean) {
        if (this.isEnabled === false) return;
        for (let i = 0; i < this.highlightNodes!.length; i++) {
            const childMeshes = this.highlightNodes![i].getChildMeshes(true);
            childMeshes.sort();
            if ((childMeshes[0].name.indexOf('Fitout') > -1)) {
                this.pins[childMeshes[0].name].isVisible = value;
            }
        }
    }

    hideHighlights() {
        if (this.isEnabled === false) return;
        const highlightElements = this.mainNode!.getChildMeshes(false);
        for (let i = 0; i < highlightElements.length; i++) {
            const ease = new CubicEase();
            highlightElements[i].visibility = 0;
            ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            //Animation.CreateAndStartAnimation('highlightAnimation1', highlightElements[i], 'visibility', 10, 2, highlightElements[i].visibility, 0, 0, ease);
        }
    }

    animatePin(pinElement: any, rotationStart: number, rotationEnd: number) {
        const ease = new CubicEase();
        ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        pinElement.getScene = () => { return this.scene; };
        Animation.CreateAndStartAnimation('pinAnimation', pinElement, '_rotation', 10, 2, rotationStart, rotationEnd, 0, ease);
    }

    resetPinRotation() {
        const pinsElements = this.pins;
        if (pinsElements) {
            for (const prop in pinsElements) {
                const pinElement = pinsElements[prop];
                if (pinElement._rotation) {
                    this.animatePin(pinElement, pinElement._rotation, 0);
                    pinElement._children[1].background = '#000';
                }
            }
        }
    }

    disablePins(value:boolean){
        const pinsElements = this.pinMeshes;
        if (pinsElements){
            const vIndex = value ? 1 : .1;
            pinsElements.forEach((pin:any) =>{
                pin.isPickable = value;
                pin.visibility = vIndex;
            })
        }

    }

    activatePin(pin:any, activate:boolean){
        const self = this;

        const frame = 10;
        const keyFrames = [];
        keyFrames.push({
            frame: 0,
            value: 0
        });
        keyFrames.push({
            frame: frame,
            value: Math.PI/4
        });

        const selectedPinMesh:any = this.scene?.getMeshByName('sprite_'+pin.pinId);
        selectedPinMesh!.spriteActive ? selectedPinMesh!.spriteActive = !selectedPinMesh!.spriteActive : selectedPinMesh!.spriteActive= true;

        self.pinMeshes.forEach((pinMesh:any) =>{
            //self.scene?.beginAnimation(pinMesh!.material.emissiveTexture, 0, 0, false, 3);
            //pinMesh!.material!.emissiveColor = new Color3(0,0,0);
        });

        self.showPinMeshDescription(selectedPinMesh, activate);
        if (!activate){
            return
        }
        selectedPinMesh!.material!.emissiveColor = new Color3(0.298,0.776,0.427);

        self.setHighlightVisibility(selectedPinMesh!.meshToLink, 1);
        // self.scene?.beginAnimation(selectedPinMesh!.material!.emissiveTexture, 0, frame, false, 3);
    }

    showSpecificPins(pinSection:any, activate:any){
        const self = this;
        const frame = 10;
        const keyFrames = [];
        keyFrames.push({
            frame: 0,
            value: 0
        });
        keyFrames.push({
            frame: frame,
            value: Math.PI/4
        });

        self.hideHighlights();
        self.showPinMeshDescription('pin', false)
        self.pinMeshes.forEach((pinMesh:any) =>{
            pinMesh!.material!.emissiveColor = new Color3(0,0,0);
            pinMesh!.material!.emissiveTexture.wAng = 0;
        });
        if (!activate){
            return
        }
        if (pinSection.pins){
            pinSection.pins.forEach((pin:any)=>{
                if (pin.pinId) {
                    const selectedPinMesh:any = this.scene?.getMeshByName('sprite_'+pin.pinId);
                    if(selectedPinMesh!.viewMode !== FloorViewMode.FIRST_PERSON){
                        selectedPinMesh!.material!.emissiveColor = new Color3(0.298,0.776,0.427);
                        selectedPinMesh!.spriteActive = true;
                        self.scene?.beginAnimation(selectedPinMesh!.material!.emissiveTexture, 0, frame, false, 3);
                    }

                    // selectedPinMesh!.category = pinSection.category

                }
            })
        }
    }

    goToSpecificPin(pin:any){
        const self= this;
        const pinMesh = this.scene?.getMeshByName(`sprite_${pin.pinId}`);
        $('.pinInfo').stop().fadeOut();
        const ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
        const cameraPosition = new Vector3(pin.position.x, pin.position.y + 0.2, pin.position.z);
        Animation.CreateAndStartAnimation('at8', self.cameras!.firstPerson as any, 'position', 30, 30, self.cameras!.firstPerson!.position, cameraPosition, 0, ease as any);
        const cameraRotation = new Vector3(Angle.FromDegrees(pin.rotation.x%360).radians(), Angle.FromDegrees(pin.rotation.y%360).radians(), 0);
        Animation.CreateAndStartAnimation('at9', self.cameras!.firstPerson as any, 'rotation', 30, 30, self.cameras!.firstPerson!.rotation, cameraRotation, 0, ease as any, () => {
            if (pinMesh){
                setTimeout(()=>{
                    self.showPinMeshDescription(pinMesh, true);
                }, 200)

            }
        });
    }

    activateSidebarItem(spriteItem:any, activate:boolean){
        const floorNodes = document.querySelectorAll('.row-floor');
        floorNodes.forEach(floorNonde=>{
            floorNonde.classList.remove('activation');
        });

        const floorInfoList = document.querySelectorAll('.floor-info-parent');
        floorInfoList.forEach(floorItem=>{
            floorItem.querySelectorAll('.row-floor').forEach(rowFloor=>{
                rowFloor.classList.remove('active')
            })
            floorItem.querySelectorAll('.trigger-click-view-space').forEach(elemView=>{
                elemView.classList.remove('activated')
            })
            const siblingSpacesContainer:any = floorItem.querySelector('.show-all-spaces');
            if (siblingSpacesContainer) {
                siblingSpacesContainer.style.display = 'none';
            }
        })

        const pinId = spriteItem.id.replace('sprite_', '')
        const sidebarItem:any = document.getElementById(pinId);
        if(sidebarItem && !sidebarItem!.classList.contains('activation')){
            sidebarItem!.classList.add('activation');
            sidebarItem!.parentNode!.style.display = 'block';
            sidebarItem!.closest('.floor-info-parent')!.querySelector('.parent-floor')!.classList.add('active')
            this.activeCateg = sidebarItem!.classList.contains('activation') ? spriteItem.category : '';
        }
        // sidebarItem!.classList.toggle('activation');


    }

    showSidebarCategoryItems(category:any){
        const defaultElements = document.querySelectorAll(`[data-viewmode="defaultPin"]`)
        const virtualTourElements = document.querySelectorAll(`[data-viewmode="virtualTourPin"]`)
        if (category !== FloorViewMode.FIRST_PERSON){
            defaultElements.forEach((item:any)=>{
                item.style.display = 'block';
            });
            virtualTourElements.forEach((item:any)=>{
                item.style.display = 'none';
            });
        } else {
            virtualTourElements.forEach((item:any)=>{
                item.style.display = 'block';
            });
            defaultElements.forEach((item:any)=>{
                item.style.display = 'none';
            });
        }

    }

    showFitputPins(space:any){
        if (space.length) {
            const spacesArray = space.split("_");
            this.pinMeshes.forEach((pinmesh:any) => {
                if (pinmesh.name.includes('fitout') && !spacesArray.some(function (v:any) {
                    return pinmesh.name.indexOf(v) >= 0;
                })) {
                    pinmesh.setEnabled(false);
                } else {
                    pinmesh.setEnabled(true);
                }
            })
        } else {
            this.pinMeshes.forEach((pinmesh:any) => {
                if (pinmesh.name.includes('fitout')){
                    pinmesh.setEnabled(false);
                }
            })
        }

    }

}


export {
    FloorHighlightsHelper,
    FloorHighlightsHelperOpts
};