var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-content-slider-brochure" }, [
    _c(
      "div",
      { ref: "carousel", staticClass: "owl-carousel owl-theme" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }