var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "molecule-icon-list-with-texts",
      class: { "center-content": _vm.centerContent }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.dataList, function(item, i) {
          var _obj
          return _c(
            "div",
            {
              key: i,
              staticClass: "col",
              class:
                ((_obj = {}),
                (_obj["col-" + _vm.columnNumber] = true),
                (_obj["col-sm-" + _vm.tabletColumnNumber] = true),
                (_obj["col-xs-" + _vm.mobileColumnNumber] = true),
                _obj)
            },
            [
              _c("AtomIconAndText", {
                attrs: {
                  icon: item.icon,
                  "google-icon": item.googleIcon,
                  "icon-image": item.iconImage,
                  title: item.title,
                  description: item.description,
                  "border-color": item.borderColor
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }