<template>
  <div :class="{section: true, 'section-specs': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <AtomImageTitle :image="data.image">
      <AtomTitle color2="#fff" :title="data.title" :subtitle="data.subtitle" />
    </AtomImageTitle>
    <OrganismTechnicalSpecs :bullet-technical-data="data.items" :image-specs="data.imageSpecs" />
  </div>
</template>

<script>
import AtomImageTitle from "../../../../components/atoms/common/AtomImageTitle";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import OrganismTechnicalSpecs from "../../../../components/organisms/project/OrganismTechnicalSpecs";
export default {
  name: "SectionSpecsV1",
  components: {OrganismTechnicalSpecs, AtomTitle, AtomImageTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
  .section-specs {
    .atom-title {
      h2 {
        letter-spacing: 0.05em;
        @include font-main(0.875rem, $white, $medium, 1.063rem);
      }
      h3 {
        margin-top: 0.5rem;
        @include font-main(1.375rem, $white, $medium, 1.625rem);
      }
    }
    .image-technical-specs {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>