var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-image-info" },
    [
      _c(
        "MoleculeImageInfo",
        _vm._l(_vm.listImageInfo, function(list, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "row image-info align-items-center",
              class: { "image-right": list.imageRight }
            },
            [
              list.image
                ? _c("div", { staticClass: "col col-xs-12 col-4" }, [
                    _c("img", {
                      attrs: { src: _vm.resourceUrl(list.image), alt: "" }
                    })
                  ])
                : _vm._e(),
              list.rightTitle
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col col-xs-12 col-4 background-content-right"
                    },
                    [
                      _c("div", { staticClass: "content-info" }, [
                        _c("h5", {
                          domProps: { innerHTML: _vm._s(list.rightTitle) }
                        }),
                        _c(
                          "ul",
                          _vm._l(list.rightLines, function(rightLine, index) {
                            return _c("li", { key: index }, [
                              _c("p", {
                                domProps: { innerHTML: _vm._s(rightLine.text) }
                              })
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "col col-xs-12 col-8" }, [
                _c(
                  "div",
                  { staticClass: "content-info" },
                  [
                    _c("h5", { domProps: { innerHTML: _vm._s(list.title) } }),
                    _vm._l(list.lines, function(line, index) {
                      return _c("p", {
                        key: index,
                        domProps: { innerHTML: _vm._s(line) }
                      })
                    })
                  ],
                  2
                )
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }