<template>
<!--  <div-->
<!--    class="pinInfo"-->
<!--    :class="pinInfo.className"-->
<!--    :data-pin="pinInfo.highlightAnchor"-->
<!--    :id="pinInfo.highlightAnchor"-->
<!--  >-->
<!--    <AtomPinInfoContent :pin-info="pinInfo"></AtomPinInfoContent>-->
<!--    <AtomPinFitoutButtons v-if="pinInfo.fitouts" :fitouts="pinInfo.fitouts" />-->
<!--    <div class="button-request-offer" v-if="pinInfo.requestOffer">-->
<!--      <a-->
<!--        id="request-offer-btn"-->
<!--        :class="{ 'btn-green': true, disabled: false }"-->
<!--        href="javascript:void(0)"-->
<!--        @click="openRequestOfferModal"-->
<!--        >Request Offer</a-->
<!--      >-->
<!--    </div>-->
<!--  </div>-->

  <div class="pinInfo">
    <AtomPinInfoContent :pin-info="pinInfo"></AtomPinInfoContent>
    <AtomPinFitoutButtons v-if="pinInfo.fitouts" :fitouts="pinInfo.fitouts" />
    <div class="button-request-offer" v-if="pinInfo.requestOffer">
      <a  id="request-offer-btn"
          :class="{ 'btn-green': true, disabled: false }"
          href="javascript:void(0)"
          @click="openRequestOfferModal"
      >Request Offer</a
      >
    </div>
  </div>

</template>

<script>
import AtomPinFitoutButtons from "../../../atoms/project/building/AtomPinFitoutButtons";
import AtomPinInfoContent from "../../../atoms/project/building/AtomPinInfoContent";

export default {
  name: "MoleculePinInfoContent",
  components: { AtomPinInfoContent, AtomPinFitoutButtons },
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          highlightAnchor: "",
          pinTitle: "",
          pinDescription: "",
        };
      },
    },
  },
  computed: {
    spaces() {
      return this.$store.state.project?.project?.spaces || [];
    },
    pinSpaceRef() {
      let space = null;
      if (this.pinInfo.highlightAnchor) {
        const floorNo = this.pinInfo.floor || -1;
        const floor = this.$store.state.project?.project?.floors?.find(
          (f) => f.no === floorNo
        );
        const spaceCodeParts = this.pinInfo.highlightAnchor.split("_");
        const spaceCode = spaceCodeParts[1] || "";
        if (this.spaces && this.spaces.length > 0 && floor) {
          space = this.spaces.find(
            (s) => s.space_code === spaceCode && s.floor_id === floor.id
          );
        }
      }
      return space;
    },
    pinBuildingRef() {
      const buildings = this.$store.state.project?.project?.buildings || [];
      if (
        this.pinSpaceRef &&
        this.pinSpaceRef.building_id &&
        buildings &&
        buildings.length > 0
      ) {
        return buildings.find((b) => b.id === this.pinSpaceRef.building_id);
      }
      return null;
    },
    pinFloorRef() {
      const floors = this.$store.state.project?.project?.floors || [];
      if (
        this.pinSpaceRef &&
        this.pinSpaceRef.floor_id &&
        floors &&
        floors.length > 0
      ) {
        return floors.find((f) => f.id === this.pinSpaceRef.floor_id);
      }
      return null;
    },
  },
  methods: {
    openRequestOfferModal() {
      if (!this.pinSpaceRef || this.pinSpaceRef.available === false) return;
      const spaceData = {
        ...this.pinSpaceRef,
        building: this.pinBuildingRef,
        floor: this.pinFloorRef,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;

      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
        null,
        { root: true }
      );
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );

      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        { showModal: true, modalType: "request-offer" }
      );
      document.body.classList.add("disable-scroll");
    },
  },
};
</script>

<style lang="scss">
.pinInfo {
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  color: black;
  font-size: 12px;
  border-radius: 5px;
  padding: 0;
  z-index: 99;
  max-width: 280px;
  box-shadow: 0 0 10px $blackOpacity012;
  .simple-wrapper {
    padding: 15px 15px 10px;

    @media only screen and (max-width: 1200px) {
      padding: 0;
    }
  }
  p {
    margin-top: 5px;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  h4 {
    text-transform: uppercase;
    margin: 0;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  &.pinInfoVT {
    margin-top: -25px;
    @media only screen and (max-width: 1200px) {
      padding: 0;
    }
    .simple-wrapper {
      padding: 20px 20px 16px;
    }
    h4 {
      font-weight: $medium;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }
    p {
      font-weight: $regular;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
    }
    .button-request-offer {
      padding: 0 20px 20px;
      a {
        width: 100%;
        font-weight: $medium;
        font-size: 14px;
        line-height: 17px;
        background: $black;
        border-radius: 0;
        padding: 8px 10px;
      }
    }
    &:after {
      content: "";
      display: block;
      border-style: solid;
      height: 0;
      left: 50%;
      top: 100%;
      width: 0;
      position: absolute;
      border-color: $white transparent transparent transparent;
      border-width: 12px 10px 0 10px;
      transform: translateX(-50%);
    }
  }
}
.pinButton {
  color: white;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}
.pinText {
  padding: 0px 20px 16px;
}
.pinTextTitle {
  padding: 20px 20px 16px;
}
</style>
