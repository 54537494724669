var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "organism-image-slider-v2",
      class: { "no-videos": !(_vm.hasImages && _vm.hasVideos) }
    },
    [
      _vm.hasImages && _vm.hasVideos
        ? _c("div", { staticClass: "change-carousel" }, [
            _c(
              "span",
              {
                staticClass: "button-change photoButton active",
                attrs: { "data-type": "image" },
                on: { click: _vm.handlePhotoClick }
              },
              [_vm._v("Photo")]
            ),
            _c(
              "span",
              {
                staticClass: "button-change videoButton",
                attrs: { "data-type": "video" },
                on: { click: _vm.handleVideoClick }
              },
              [_vm._v("Video")]
            )
          ])
        : _vm._e(),
      _c("MoleculeImageSlider", {
        ref: "slider",
        attrs: { "slider-data": _vm.sliderData, "type-of": _vm.typeOf },
        on: { initialized: _vm.handleCarouselInitialized }
      }),
      _vm.carouselInitialized
        ? _c("MoleculeImageThumbSlider", {
            ref: "thumbSlider",
            attrs: { thumbnails: _vm.thumbData, typeOf: _vm.typeOf },
            on: { "thumb-change": _vm.handleThumbChange }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }