import {device} from './../../../../../helpers/mobile/index'
import {
    ArcRotateCamera,
    UniversalCamera,
    Camera,
    Vector3, Scene, CubicEase, EasingFunction,
    StandardMaterial,
    MeshBuilder,
    Mesh, AbstractMesh, Engine,
    Texture,
    RenderTargetTexture,
    CubeTexture,
    Color3,
    PointerEventTypes,
    PointerInfo,
    EventState,
    Viewport,
    Matrix,
    Animation,
    PickingInfo,
    Tools,
    ICameraInput,
    FreeCamera
} from "babylonjs";
import {AdvancedDynamicTexture,
    Control,
    Ellipse
} from "babylonjs-gui";

import FloorInteractionHelper from "@/components/organisms/project/building/store/FloorInteractionHelper";
import {CameraHelper, EngineHelper, SceneHelper} from "@/components/organisms/project/building/store/helpers";


type VirtualTourHelperOpts = {
    canvasElement: HTMLCanvasElement | null,
    space: any,
    settings: any,
    cdnBase: string,
};

class VirtualTourHelper {
    _isVue: boolean;
    canvas: HTMLCanvasElement | null;
    space: Record<string, any> | null;
    engine: Engine | undefined;
    scene: Scene | undefined;
    cameras: {
        dollHouse: ArcRotateCamera | undefined,
        firstPerson: FreeCamera | undefined
    }
    isActive: boolean;
    settings: Record<string, any>;
    skyBox?: Record<string, any>;
    pointer: Mesh | undefined;
    dynamicMinimap: boolean;
    minimapWasInitialized: boolean;
    initPosition: Vector3;
    initTarget: Vector3;
    cameraHeightLock: number;
    points: any;
    minimapCamera: Record<string, any> | undefined;
    minimapRTT: Record<string, any> | undefined;
    minimapPosition: Record<string, any> | undefined;
    minimapCone: Record<string, any> | undefined;
    viewportCamera: ArcRotateCamera | null | undefined;
    minimapRatio: number;
    minimapPointer:  Record<string, any> | undefined;
    meshGroups: any;
    basePath: any;
    floorSettings: any;
    vtInputsSettings: any;
    floorInteractionHelper: FloorInteractionHelper
    cameraIsAllowedToMove: boolean = true

    constructor({canvasElement, space, cdnBase, settings}: VirtualTourHelperOpts, floorInteractionHelper: FloorInteractionHelper, engine: EngineHelper | null, scene: SceneHelper | null, cameras: CameraHelper | null) {
        this._isVue = true;
        this.engine = engine?.get();
        this.scene = scene?.get();
        this.canvas = canvasElement;
        this.space = space;
        this.isActive = false;
        (this.scene as any)!.isCursorActive = false;
        this.basePath = cdnBase;
        this.floorSettings = settings.floor;
        this.minimapWasInitialized = false;
        const floorType = space?.floorType.split(".")[0];
        this.meshGroups = floorInteractionHelper.floorSettings.meshGroups;
        this.floorInteractionHelper = floorInteractionHelper
        this.settings = {
            floorCode: `${floorType}`,
            startingPoints: {
                [floorType]:{
                    [this.space!.space] : {'position': new Vector3(
                        this.space!.spaceData.camera_position_x,
                            this.space!.spaceData.camera_position_y,
                            this.space!.spaceData.camera_position_z),
                        'target': new Vector3(
                            this.space!.spaceData.focus_target_x,
                            this.space!.spaceData.focus_target_y,
                            this.space!.spaceData.focus_target_z)}
                }
            },
            skyboxTexture: this.floorSettings.virtualTour.skyboxTexture,
            dynamicMinimap: {
                isEnabled: this.floorSettings.virtualTour.dynamicMinimap.isEnabled
            },
            groundHeight: floorInteractionHelper.floorSettings.groundHeight,
            dualLevelHeight: settings.floor.virtualTour.camera.dualLevelHeight,
            gHeight: settings.floor.virtualTour.camera.gHeight
        };

        this.cameras = {
            dollHouse: cameras?.getDollHouseCamera() as ArcRotateCamera,
            firstPerson: cameras?.getFirstPersonCamera() as FreeCamera | undefined
        };
        //this.camera = this.createCamera('virtualTour', this.settings.camera.position, this.settings.camera.target);
        this.dynamicMinimap = !device.isMobile() ? (this.settings.dynamicMinimap && this.settings.dynamicMinimap.isEnabled === true) : false;
        this.initPosition = new Vector3(0, 0, 0);
        this.initTarget = new Vector3(1, 0, 0);
        this.cameraHeightLock = this.floorSettings.virtualTour.camera.height;
        this.points = false;
        this.minimapRatio  = 0;
        this.minimapPointer;
        this.vtInputsSettings={};
        this.resizeMinimapViewport = this.resizeMinimapViewport.bind(this);
    }

    init() {
        const startingPoints = this.settings.startingPoints;
        const floorCode = this.settings ? this.settings.floorCode : '';
        const space = this.space ? this.space.space : '';
        if (typeof startingPoints[floorCode] !== 'undefined' || typeof startingPoints[floorCode][space] !== 'undefined') {
            //TODO:resolve startingpoint bug
            const startingPosition = startingPoints[floorCode][space].position;
            const startingTarget = startingPoints[floorCode][space].target;
            this.initPosition = startingPosition;
            this.cameraHeightLock = startingPosition.y;
            if(typeof startingTarget !== 'undefined'){
                this.initTarget = startingTarget;
            }
            if (typeof startingPoints[floorCode][space].points !== 'undefined') {
                this.points = startingPoints[floorCode][space].points;

                if(this.points.length > 0) {
                    this.initPosition = this.points[0].position;
                    this.initTarget = this.points[0].target;
                    this.cameraHeightLock = this.points[0].position.y;
                }
            }
        } else {
            console.log('No initial position found for floor ' + this.settings!.floorCode + ' space ' + this.space!.space);
        }
        if(!this.skyBox)
            this.skyBox = this.generateSkyBox();
        if(!this.pointer)
            this.pointer = this.createPointer();

        this.cameras.dollHouse!.position.x += 0.001;
        this.addEvents();
        this.initMinimap();
        this.updateMinimap();
    }

    destroy() {
        this.canvas!.removeEventListener('pointerdown', () => {});
        this.canvas!.removeEventListener('pointerup', () => {});
        this.canvas!.removeEventListener('pointermove', () => {});
        document.removeEventListener('fitout:changed', () => {});
    }

    initMinimap(){
        if (device.isMobile() || this.minimapWasInitialized) return;
        this.minimapWasInitialized = true;
        this.minimapCamera = new ArcRotateCamera("minimapCamera", -Math.PI/2, 0, 60, Vector3.Zero(), this.scene as any);
        if (!this.minimapCamera){
            return;
        }
        this.minimapCamera.layerMask = 0x10000000;
        this.minimapCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        const boundingBoxMainElement = this.scene!.getTransformNodeByName(this.settings!.floorCode)!.getHierarchyBoundingVectors();
        this.minimapCamera.orthoTop = boundingBoxMainElement.max.z;
        this.minimapCamera.orthoBottom = boundingBoxMainElement.min.z;
        this.minimapCamera.orthoLeft = boundingBoxMainElement.min.x;
        this.minimapCamera.orthoRight = boundingBoxMainElement.max.x;

        this.minimapRTT = new RenderTargetTexture("depth", 2048, this.scene as any, true, true);
        this.scene!.customRenderTargets.push(this.minimapRTT as RenderTargetTexture);
        this.minimapRTT.refreshRate = -1;
        this.minimapRTT.activeCamera = this.minimapCamera;
        this.minimapRTT.hasAlpha=true;

        const butback = MeshBuilder.CreatePlane("plane", { width: boundingBoxMainElement.max.x - boundingBoxMainElement.min.x, height: boundingBoxMainElement.max.z - boundingBoxMainElement.min.z}, this.scene as any);
        butback.rotation.x = Math.PI/2;
        butback.position = new Vector3((boundingBoxMainElement.max.x + boundingBoxMainElement.min.x) / 2, this.settings.groundHeight, (boundingBoxMainElement.max.z + boundingBoxMainElement.min.z) / 2)
        const butbackmat: any = new StandardMaterial("texturePlane", this.scene as any);
        butbackmat.emissiveTexture = this.minimapRTT;
        butbackmat.opacityTexture = this.minimapRTT;
        butbackmat.disableLighting = true;
        butback.material = butbackmat;
        butback.layerMask = 0x40000000;

        this.minimapPosition = MeshBuilder.CreateDisc("minimapPosition",{radius: .5});
        this.minimapPosition.position = new Vector3(0, this.settings.groundHeight + .1, 0);
        this.minimapPosition.rotation.x = Math.PI/2;
        this.minimapPosition.layerMask = 0x40000000;
        const minimapPositionMaterial = new StandardMaterial("minimapPositionMaterial", this.scene as any);
        minimapPositionMaterial.emissiveColor = new Color3(1, 1, 1);
        this.minimapPosition.material = minimapPositionMaterial;

        this.minimapCone = MeshBuilder.CreateDisc("minimapCone", {radius: 6, tessellation: 24, arc: 1 / 5});
        this.minimapCone.position = new Vector3(0, this.settings.groundHeight + .1, 0);
        this.minimapCone.rotation.x = Math.PI/2;
        this.minimapCone.layerMask = 0x40000000;
        this.minimapCone.material = minimapPositionMaterial;
        this.minimapCone.visibility = .5;
        this.minimapCone.isPickable = false;
        this.minimapCone.renderingGroupId = 1;

        this.viewportCamera = new ArcRotateCamera("viewportCamera", Math.PI/2, 0, this.settings.dynamicMinimap.radius || 60 , Vector3.Zero(), this.scene as any);
        this.viewportCamera.layerMask = 0x40000000;
        this.viewportCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.viewportCamera.orthoTop = boundingBoxMainElement.min.z * -1;
        this.viewportCamera.orthoBottom = boundingBoxMainElement.max.z * -1;
        this.viewportCamera.orthoLeft = boundingBoxMainElement.max.x * -1;
        this.viewportCamera.orthoRight = boundingBoxMainElement.min.x * -1;
        this.minimapRatio = (boundingBoxMainElement.max.x - boundingBoxMainElement.min.x) / (boundingBoxMainElement.max.z - boundingBoxMainElement.min.z);
        this.viewportCamera.viewport = new Viewport(0, 0, .2, .2 );
        this.resizeMinimapViewport();
        this.minimapPointer = this.pointer!.clone();
        this.minimapPointer!.name = 'minimapPointer';
        this.minimapPointer!.setEnabled(true);
        this.minimapPointer!.layerMask = 0x40000000;
        this.minimapPointer!.scaling.x = 2;
        this.minimapPointer!.scaling.y = 2;
        this.minimapPointer!.renderingGroupId = 1;
    }

    updateMinimap(e?: Event){
        if(this.dynamicMinimap && this.minimapWasInitialized) {
            this.minimapRTT!.renderList = this.scene!.meshes.filter((mesh: any) => {
                return mesh.layerMask === 0xFFFFFFF &&
                    !this.meshGroups.ceiling.meshesIDs.includes(mesh.uniqueId) &&
                    mesh.uniqueId !== this.pointer!.uniqueId &&
                    mesh.uniqueId !== this.skyBox?.uniqueId &&
                    mesh.isEnabled();
            });
            this.minimapRTT!.refreshRate = -1;
        }
    }

    resizeMinimapViewport(){
        if (device.isMobile()) return;

        const canvasWidth = this.canvas!.width;
        const canvasHeight = this.canvas!.height;
        let minimapWidth = 310;
        let marginTop = 20;
        const marginRight = 20;
        if(window.innerWidth < 767){
            minimapWidth = 240;
            marginTop = 45;
        }
        if(this.minimapRatio < 1){
            minimapWidth = 240 * this.minimapRatio
        }
        this.viewportCamera!.viewport.width = minimapWidth/canvasWidth;
        this.viewportCamera!.viewport.height = this.viewportCamera!.viewport.width / this.minimapRatio * (canvasWidth/canvasHeight);
        this.viewportCamera!.viewport.x = 1 - minimapWidth/canvasWidth - marginRight/canvasWidth;
        this.viewportCamera!.viewport.y = 1 - this.viewportCamera!.viewport.height - marginTop/canvasHeight;
    }

    onFloorLoad() {
        if(this.dynamicMinimap) {
            this.initMinimap();
        }
    }

    createCamera(name:string, position:Vector3, target:Vector3) {
        let camera: ArcRotateCamera | FreeCamera | any;
        if (this.scene!.getCameraByName(name)) {
            camera = this.scene!.getCameraByName(name);
        } else {
            camera = new FreeCamera(
                name,
                position,
                this.scene as any
            );
        }
        camera!.minZ = 0.1;
        camera!.maxZ = 1000;
        camera!.setTarget(target);
        camera!.speed = 0.1;

        camera!.inputs.attached.touch.touchAngularSensibility = -6000;

        return camera;
    }

    generateSkyBox() {
        const skyBox = MeshBuilder.CreateBox("skyBox", {size: 1000}, this.scene as any);
        skyBox.setEnabled(false);
        const skyBoxMaterial = new StandardMaterial("skyBox", this.scene as any);
        skyBoxMaterial.backFaceCulling = false;
        const activeFloorCode = this.space!.floor;
        const activeSkyboxTexture = this.settings.floorSkyboxTexture && this.settings.floorSkyboxTexture[activeFloorCode] ? this.settings.floorSkyboxTexture[activeFloorCode] : this.settings.skyboxTexture;
        //  ../../../../../assets/images/textures/buildings/one/360
        skyBoxMaterial.reflectionTexture = new CubeTexture(this.basePath + activeSkyboxTexture, this.scene as any)
        // skyBoxMaterial.reflectionTexture = new CubeTexture('https://cdn-bs-public.s3.eu-central-1.amazonaws.com/textures/buildings/one/360/', this.scene as any);
        skyBoxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
        skyBoxMaterial.diffuseColor = new Color3(0, 0, 0);
        skyBoxMaterial.specularColor = new Color3(0, 0, 0);
        skyBoxMaterial.disableLighting = true;
        skyBox.scaling.x = -1;
        skyBox.scaling.z = -1;
        skyBox.material = skyBoxMaterial;
        skyBox.isPickable = false;
        return skyBox
    }

    createPointer() {
        const pointer = MeshBuilder.CreateDisc('virtualTourPointer', {
            radius: .3,
            tessellation: 64,
            sideOrientation: Mesh.DOUBLESIDE
        }, this.scene as any);
        const texture = new Texture("https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/vt-pointer.png", this.scene as Scene);
        texture.hasAlpha = true;
        texture.wrapU = Texture.CLAMP_ADDRESSMODE;
        texture.wrapV = Texture.CLAMP_ADDRESSMODE;

        const pointMaterial = new StandardMaterial("virtualTourPointer", this.scene as any);
        pointMaterial.emissiveTexture = texture
        pointMaterial.opacityTexture = texture
        pointMaterial.backFaceCulling = false;
        pointer.material = pointMaterial;
        pointer.isPickable = false;

        pointer.rotation = new Vector3(Math.PI / 2, 0, 0);
        pointer.position = new Vector3(27, -1.05, -7);
        pointer.setEnabled(false);

        return pointer;
    }

    addEvents() {
        this.scene!.onPointerMove =  ()=> {
            if ((this.scene as any)!.isCursorActive) {
                this.movePointer();
            }
        }

        let isMouseDown = false;
        this.canvas!.addEventListener('pointerdown',  ()=> {
            isMouseDown = true;
        });
        this.canvas!.addEventListener('pointerup',  ()=> {
            if (isMouseDown) {
                //console.log('mouse down ')
                if ((this.scene as any)!.isCursorActive) {
                    //console.log('cursor active')
                    this.movePointer();
                    this.moveToClick();
                }
            }
        });

        this.canvas!.addEventListener('pointermove', function (e) {
            if (isMouseDown) {
                isMouseDown = false;
            }
        });

        this.scene!.onPointerObservable.add( //TODO: change
(eventData: PointerInfo, eventState: EventState)=> {
            switch (eventData.type) {
                case PointerEventTypes.POINTERDOWN:
                    // eslint-disable-next-line no-case-declarations
                    const raycast = this.scene!.createPickingRay(this.scene!.pointerX, this.scene!.pointerY, Matrix.Identity(), this.cameras!.firstPerson!, false);
                    // eslint-disable-next-line no-case-declarations
                    const hits = this.scene!.pickWithRay(raycast)
                    this.cameraIsAllowedToMove = true
                    if(hits?.pickedMesh?.id == "Steelcase_Gesture_Coconut.001" || hits?.pickedMesh?.id == "BL|Chair Task Bene Ryia Black_curve_.022"){
                        this.floorInteractionHelper.switchChair()
                        this.cameraIsAllowedToMove = false
                    }
                    break;
                case PointerEventTypes.POINTERUP:
                    break;
                case PointerEventTypes.POINTERTAP:
                    if ((this.scene as any)!.isCursorActive) {
                        this.moveToClick();
                    }
                    break;
                case PointerEventTypes.POINTERDOUBLETAP:
                    break;
            }
        });



        if(this.dynamicMinimap) {
            document.addEventListener('fitout:changed', this.updateMinimap);
            this.engine!.onResizeObservable.add(this.resizeMinimapViewport);
        }

        const adt = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        let xAddPos = 0;
        let yAddPos = 0;
        const sideJoystickOffset = 150;
        const bottomJoystickOffset = -260;
        let translateTransform;


        const leftThumbContainer = makeThumbArea("leftThumb", 2, "blue", null);
        leftThumbContainer.height = "200px";
        leftThumbContainer.width = "200px";
        leftThumbContainer.isPointerBlocker = true;
        leftThumbContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        leftThumbContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        leftThumbContainer.alpha = 0.4;
        leftThumbContainer.left = sideJoystickOffset;
        leftThumbContainer.top = bottomJoystickOffset;

        const leftInnerThumbContainer = makeThumbArea("leftInnterThumb", 4, "blue", null);
        leftInnerThumbContainer.height = "80px";
        leftInnerThumbContainer.width = "80px";
        leftInnerThumbContainer.isPointerBlocker = true;
        leftInnerThumbContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        leftInnerThumbContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;


        const leftPuck = makeThumbArea("leftPuck",0, "blue", "blue");
        leftPuck.height = "60px";
        leftPuck.width = "60px";
        leftPuck.isPointerBlocker = true;
        leftPuck.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        leftPuck.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;


        leftThumbContainer.onPointerDownObservable.add(function(coordinates) {
            leftPuck.isVisible = true;
            leftPuck.left = coordinates.x-(leftThumbContainer._currentMeasure.width*.5)-sideJoystickOffset;
            leftPuck.top = ((adt as any)._canvas.height - coordinates.y-(leftThumbContainer._currentMeasure.height*.5)+bottomJoystickOffset)*-1;
            (leftPuck as any).isDown = true;
            leftThumbContainer.alpha = 0.9;
        });

        leftThumbContainer.onPointerUpObservable.add(function(coordinates) {
            xAddPos = 0;
            yAddPos = 0;
            (leftPuck as any).isDown = false;
            leftPuck.isVisible = false;
            leftThumbContainer.alpha = 0.4;
        });


        leftThumbContainer.onPointerMoveObservable.add(function(coordinates) {
            if ((leftPuck as any).isDown) {
                xAddPos = coordinates.x-(leftThumbContainer._currentMeasure.width*.5)-sideJoystickOffset;
                yAddPos = (adt as any)._canvas.height - coordinates.y-(leftThumbContainer._currentMeasure.height*.5)+bottomJoystickOffset;
                leftPuck.left = xAddPos;
                leftPuck.top = yAddPos*-1;
            }
        });

        // REMOVED THUMBSTICK CONTROL
        // adt.addControl(leftThumbContainer);
        leftThumbContainer.addControl(leftInnerThumbContainer);
        leftThumbContainer.addControl(leftPuck);
        leftPuck.isVisible = false;



        // REMOVED THUMBSTICK CONTROL
        // this.cameras.firstPerson!.attachControl(this.canvas, true)

        this.scene!.registerBeforeRender(()=> {
            if((this.scene as any)!.isCursorActive){
                // limit camera up/down movement y

                this.cameras.firstPerson!.rotation.x = (this.cameras.firstPerson!.rotation.x >=0 ) ? (Math.min(Math.PI/8, this.cameras.firstPerson!.rotation.x%(Math.PI*2) )) : (Math.max(-Math.PI/12, this.cameras.firstPerson!.rotation.x%(Math.PI*2)));
                this.cameras.firstPerson!.rotation.y = this.cameras.firstPerson!.rotation.y%(Math.PI*2);
                this.cameras.firstPerson!.rotation.z = this.cameras.firstPerson!.rotation.z%(Math.PI*2);

                // change
                this.cameras.firstPerson!.position.y = this.cameraHeightLock;
                if(this.dynamicMinimap){
                    this.minimapPosition!.position.x = this.cameras.firstPerson!.position.x
                    this.minimapPosition!.position.z = this.cameras.firstPerson!.position.z
                    this.minimapCone!.position.x = this.cameras.firstPerson!.position.x
                    this.minimapCone!.position.z = this.cameras.firstPerson!.position.z
                    this.minimapCone!.rotation.y = Tools.ToRadians(306) + this.cameras.firstPerson!.rotation.y;
                }
            }
            // REMOVED THUMBSTICK CONTROL
            // Thumbstick
            // translateTransform = Vector3.TransformCoordinates(new Vector3(xAddPos/3000, 0, yAddPos/3000), Matrix.RotationY(this.cameras.firstPerson!.rotation.y));
            // this.cameras.firstPerson!.cameraDirection.addInPlace(translateTransform as any);
        });

        function makeThumbArea(name:any, thickness:any, color:any, background:any){
            const rect = new Ellipse();
            rect.name = name;
            rect.thickness = thickness;
            rect.color = color;
            rect.background = background;
            rect.paddingLeft = "0px";
            rect.paddingRight = "0px";
            rect.paddingTop = "0px";
            rect.paddingBottom = "0px";

            return rect;
        }


    }

    movePointer() {
        // if (device.isMobile()){
        //     return;
        // };
        let hits;
        let tempMaterial = '';
        if(this.dynamicMinimap) {
            const minimapRay = this.scene!.createPickingRay(this.scene!.pointerX, this.scene!.pointerY, Matrix.Identity(), this.viewportCamera as any, false);
            hits = this.scene!.multiPickWithRay(minimapRay, (mesh: AbstractMesh) => { return true; });
            if (hits) {
                hits.forEach((hit: PickingInfo) => {
                    tempMaterial = hit.pickedMesh!.material!.name.replace(this.settings.floorCode, '');
                    if ((tempMaterial.indexOf('carpet') > -1 ||
                        tempMaterial.indexOf('corefloor') > -1 ||
                        tempMaterial.indexOf('bathroomTiles') > -1 ||
                        tempMaterial === 'Material' ||
                        tempMaterial.indexOf('_floor') > -1 ||
                        tempMaterial.indexOf('Floor') > -1) && !hit.pickedMesh!.name.includes('sprite') && !(hit.pickedMesh!.name.includes('Beam')) && !(hit.pickedMesh!.name.includes('Wall'))) {
                        if (hit.pickedMesh!.visibility === 1) {
                            this.pointer!.position.x = hit.pickedPoint!.x;
                            this.pointer!.position.z = hit.pickedPoint!.z;
                            this.pointer!.position.y = hit.pickedPoint!.y + 0.01;
                            this.minimapPointer!.position.x = hit.pickedPoint!.x;
                            this.minimapPointer!.position.z = hit.pickedPoint!.z;
                            return;
                        }
                    }
                })
            }
        }


        const ray = this.scene!.createPickingRay(this.scene!.pointerX, this.scene!.pointerY, Matrix.Identity(), this.cameras.firstPerson as any);
        hits = this.scene!.multiPickWithRay(ray, (mesh: AbstractMesh) => { return true; });

        if (hits) {
            hits.forEach((hit: PickingInfo) => {
                tempMaterial = hit.pickedMesh!.material!.name.replace(this.settings.floorCode, '');
                if ((tempMaterial.indexOf('carpet') > -1 ||
                    tempMaterial.indexOf('corefloor') > -1 ||
                    tempMaterial.indexOf('bathroomTiles') > -1 ||
                    hit.pickedMesh!.material!.name === 'Material'  ||
                    tempMaterial.indexOf('_floor') > -1 ||
                    tempMaterial.indexOf('Floor') > -1) && !(hit.pickedMesh!.name.includes('Beam'))&& !(hit.pickedMesh!.name.includes('Wall'))) {
                    if (hit.pickedMesh!.visibility === 1) {
                        (this.scene as any)!.isCursorActive = true;
                        this.pointer!.position.x = hit.pickedPoint!.x;
                        this.pointer!.position.z = hit.pickedPoint!.z;
                        this.pointer!.position.y = hit.pickedPoint!.y + 0.01;
                        if(this.dynamicMinimap) {
                            this.minimapPointer!.position.x = hit.pickedPoint!.x;
                            this.minimapPointer!.position.z = hit.pickedPoint!.z;
                        }
                        return;
                    }
                }
            });
        }
    }

    moveToClick() {
        if(this.cameraIsAllowedToMove){
            this.animateCameraToPos(this.pointer!.position.clone());
        }
        //console.log('this.pointer!.position', this.pointer!.position)
    }

    animateCameraToPos(newPosition:Vector3, speed = 30, frameCount = 30) {

        const ease = new CubicEase();
        ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        newPosition.y += .2;
        Animation.CreateAndStartAnimation('at8', this.cameras.firstPerson as any, 'position', speed, frameCount, this.cameras.firstPerson!.position, newPosition, 0, ease);
    }

    activate() {
        if(this.floorInteractionHelper.colorPanel){
            this.floorInteractionHelper.colorPanel.isVisible = false
        }

        this.init();

        (this.scene as any)!.isCursorActive = true;
        this.scene!.activeCamera!.detachControl();
        // console.log('this.initPosition', this.initPosition)
        this.moveCamera(this.initPosition);
        this.cameras.firstPerson!.target = (this.initTarget as any);

        this.scene!.activeCameras = [];

        this.scene!.activeCameras.push(this.cameras.firstPerson as any);
        this.cameras.firstPerson!.attachControl(this.canvas, true);
        this.cameras.firstPerson!.position.y = this.cameraHeightLock;


        if(this.dynamicMinimap){
            this.updateMinimap();
            this.scene!.activeCameras!.push(this.viewportCamera as any);
        }
        this.replaceVTCameraInputs(this.floorSettings.virtualTour.camera.speed, this.floorSettings.virtualTour.camera.angularSpeed);

       const ceilingNode = this.scene!.getTransformNodeByName('ceiling_' + this.settings.floorCode);
        if (ceilingNode) {
            ceilingNode.setEnabled(true);
        }

        this.skyBox?.setEnabled(true);

        const environmentNode = this.scene!.getTransformNodeByName('environment_' + this.settings!.floorCode);

        if (environmentNode) {
            environmentNode.setEnabled(true);
        }
        this.pointer!.setEnabled(true);
        $('.vtPoints').addClass('active');
    }

    deactivate() {
        this.destroy();
        (this.scene as any)!.isCursorActive = false;
        this.cameras.firstPerson!.detachControl();
        this.scene!.activeCameras = [];
        (this.scene as any)!.activeCamera = this.cameras.dollHouse;
        (this.scene as any)!.activeCamera!.attachControl(true);
        this.pointer!.setEnabled(false);

        const ceilingNode = this.scene!.getTransformNodeByName('ceiling_' + this.settings!.floorCode);

        if (ceilingNode) {
            ceilingNode.setEnabled(false);
        }

        const environmentNode = this.scene!.getTransformNodeByName('environment_' + this.settings!.floorCode);
        if (environmentNode) {
            environmentNode.setEnabled(false);
        }
        this.skyBox?.setEnabled(false);

        //$('.vtPoints').removeClass('active');
    }

    moveCamera(newPosition:Vector3, withAnimation = false) {
        if (withAnimation) {
            this.animateCameraToPos(newPosition)
        } else {
            this.cameras.firstPerson!.position = (newPosition as any);
        }
    }

    moveToPoint(pointIndex:number){
        const point = this.points[pointIndex];
        if((this.scene as any)!.isCursorActive) {
            this.cameras.firstPerson!.position = point.position;
            this.cameraHeightLock = point.position.y;
            this.cameras.firstPerson!.target = point.target;
        }
    }

    replaceVTCameraInputs(speed: number = 0.006, angularSpeed: number = 0.012){
        const self = this;
        const activeVTCamera = (this.cameras.firstPerson as any);
        activeVTCamera!.speed = speed
        activeVTCamera!.angularSpeed = angularSpeed
        activeVTCamera!.angle = Math.PI/2;
        activeVTCamera!.direction = new Vector3(Math.cos(activeVTCamera!.angle), 0, Math.sin(activeVTCamera!.angle));
        //First remove the default management.
        activeVTCamera!.inputs.removeByType("FreeCameraKeyboardMoveInput");
        //Key Input Manager To Use Keys to Move Forward and BackWard and Look to the Left or Right

        const FreeCameraKeyboardWalkInput : any  = function () {
        }

        //Add attachment controls
        FreeCameraKeyboardWalkInput.prototype.attachControl = function (noPreventDefault:any) {
            const engine = activeVTCamera!.getEngine();
            const element = engine.getInputElement();

            this._keys =  [];
            this.keysUp =  [38, 87];
            this.keysDown =  [40, 83];
            this.keysLeft =  [81];
            this.keysRight =  [69];
            this.keysRotateLeft =  [37, 65];
            this.keysRotateRight =  [39, 68];

            if (!this._onKeyDown) {
                element!.tabIndex = 1;
                this._onKeyDown = (evt:any)=> {
                    if (this.keysUp.indexOf(evt.keyCode) !== -1 ||
                        this.keysDown.indexOf(evt.keyCode) !== -1 ||
                        this.keysRotateLeft.indexOf(evt.keyCode) !== -1 ||
                        this.keysLeft.indexOf(evt.keyCode) !== -1 ||
                        this.keysRight.indexOf(evt.keyCode) !== -1 ||
                        this.keysRotateRight.indexOf(evt.keyCode) !== -1) {
                        const index = this._keys.indexOf(evt.keyCode);
                        if (index === -1) {
                            this._keys.push(evt.keyCode);
                        }
                        if (!noPreventDefault) {
                            evt.preventDefault();
                        }
                    }
                };
                this._onKeyUp =  (evt:any)=> {
                    if (this.keysUp.indexOf(evt.keyCode) !== -1 ||
                        this.keysDown.indexOf(evt.keyCode) !== -1 ||
                        this.keysLeft.indexOf(evt.keyCode) !== -1 ||
                        this.keysRight.indexOf(evt.keyCode) !== -1 ||
                        this.keysRotateLeft.indexOf(evt.keyCode) !== -1 ||
                        this.keysRotateRight.indexOf(evt.keyCode) !== -1) {
                        const index = this._keys.indexOf(evt.keyCode);
                        if (index >= 0) {
                            this._keys.splice(index, 1);
                        }
                        if (!noPreventDefault) {
                            evt.preventDefault();
                        }
                    }
                };
                element!.addEventListener("keydown", this._onKeyDown, false);
                element!.addEventListener("keyup", this._onKeyUp, false);
            }
        };

        //Add detachment controls
        FreeCameraKeyboardWalkInput.prototype.detachControl = function () {
            const engine = activeVTCamera!.getEngine();
            const element = engine.getInputElement();
            if (this._onKeyDown) {
                element!.removeEventListener("keydown", this._onKeyDown);
                element!.removeEventListener("keyup", this._onKeyUp);
                Tools.UnregisterTopRootEvents(window,[
                    { name: "blur", handler: this._onLostFocus }
                ]);
                this._keys = [];
                this._onKeyDown = null;
                this._onKeyUp = null;
            }
        };

        activeVTCamera!.onAfterCheckInputsObservable.add(() => {
            activeVTCamera!.cameraDirection.y = 0;
        });

        //Keys movement control by checking inputs
        FreeCameraKeyboardWalkInput.prototype.checkInputs = function () {
            if (this._onKeyDown) {
                const camera = activeVTCamera;
                for (let index = 0; index < this._keys.length; index++) {
                    const keyCode = this._keys[index];
                    const speed = camera!.speed;
                    if (this.keysRotateLeft.indexOf(keyCode) !== -1) {
                        camera!.rotation.y -= camera!.angularSpeed;
                        camera!.direction.copyFromFloats(0, 0, 0);
                    }
                    else if (this.keysUp.indexOf(keyCode) !== -1) {
                        camera!.direction.y= 0;
                        camera!.direction.copyFromFloats(0, 0, speed);
                    }
                    else if (this.keysRight.indexOf(keyCode) !== -1) {
                        camera!.direction.copyFromFloats(speed, 0, 0);
                    }
                    else if (this.keysLeft.indexOf(keyCode) !== -1) {
                        camera!.direction.copyFromFloats(-speed, 0, 0);
                    }
                    else if (this.keysRotateRight.indexOf(keyCode) !== -1) {
                        camera!.rotation.y += camera!.angularSpeed;
                        camera!.direction.copyFromFloats(0, 0, 0);
                    }
                    else if (this.keysDown.indexOf(keyCode) !== -1) {
                        camera!.direction.copyFromFloats(0, 0, -speed);
                    }
                    //    if (camera.getScene().useRightHandedSystem) {
                    //       camera.direction.z *= -1;
                    // }
                    camera!.getViewMatrix().invertToRef(camera!._cameraTransformMatrix);
                    Vector3.TransformNormalToRef(camera!.direction, camera!._cameraTransformMatrix, camera!._transformedDirection);
                    camera!.cameraDirection.addInPlace(camera!._transformedDirection);
                }
            }
        };

        //Add the onLostFocus function
        FreeCameraKeyboardWalkInput.prototype._onLostFocus = function (e:any) {
            this._keys = [];
        };

        //Add the two required functions for the control Name
        FreeCameraKeyboardWalkInput.prototype.getClassName = function () {
            return "FreeCameraKeyboardWalkInput";
        };

        FreeCameraKeyboardWalkInput.prototype.getSimpleName = function () {
            return "keyboard";
        };
        //Add the new keys input manager to the camera.
        activeVTCamera!.inputs.add(new FreeCameraKeyboardWalkInput());

    }

    updateStartingPoint(level:any){
        if (!this.settings.dualLevelHeight && this.settings.gHeight){
            return;
        }
        //const initPositionOverride = { ...this.initPosition } as Vector3;
        //initPositionOverride._y = this.settings.dualLevelHeight;
        const gHeight = this.settings.groundHeight ? this.settings.groundHeight : this.settings.gHeight;
        switch (level) {
            case 'M':
                //this.cameraHeightLock = initPositionOverride._y;
                //this.initPosition.y = this.settings.dualLevelHeight
                this.cameras.firstPerson!.position = this.initPosition;
                //this.cameraHeightLock = initPositionOverride._y;
                this.cameraHeightLock = this.settings.dualLevelHeight;
                break;
            case 'G':
                this.cameras.firstPerson!.position = this.initPosition;
                this.cameraHeightLock = gHeight;
                break;
            default:
                this.cameras.firstPerson!.position = this.initPosition;
                this.cameraHeightLock = gHeight;
        }
    }
}


export {
    VirtualTourHelper,
    VirtualTourHelperOpts
};