<template>
  <div class="molecule-icon-list-with-texts" :class="{ 'center-content': centerContent }">
    <div class="row">
      <div class="col" :class="{[`col-${columnNumber}`]: true, [`col-sm-${tabletColumnNumber}`]: true, [`col-xs-${mobileColumnNumber}`]: true}" v-for="(item, i) in dataList" :key="i">
        <AtomIconAndText :icon="item.icon" :google-icon="item.googleIcon" :icon-image="item.iconImage" :title="item.title" :description="item.description" :border-color="item.borderColor" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomIconAndText from "../../atoms/common/AtomIconAndText";

export default {
  name: "MoleculeIconListWithTexts",
  components: {AtomIconAndText},
  props: {
    dataList: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
    mobileColumnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    tabletColumnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    columnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    centerContent: {
      required: false,
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss">
.molecule-icon-list-with-texts {
  .row {
    margin: 0 -1.25rem;
    @media only screen and (max-width: 767px) {
      margin: 30px -10px 0;
    }
    .col {
      padding: 0 1.25rem;
      @media only screen and (max-width: 767px) {
        padding: 0 20px;
      }
    }
  }
  &.center-content {
    .box-content {
      .border-color {
        margin: 0 auto 1rem !important;
        @media only screen and (max-width: 767px) {
          margin: 0 auto 0.625rem !important;
        }
      }
      .title {
        text-align: center !important;
      }
    }
  }
}
</style>