<template>
  <div :class="{section: true, 'section-contact': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContactForm />
    <OrganismTeam :team-data="data" />
  </div>
</template>

<script>
import OrganismTeam from "../../../components/organisms/portfolio/OrganismTeam";
import OrganismContactForm from "../../../components/organisms/project/OrganismContactForm";
export default {
  name: "SectionContactV1",
  components: {OrganismContactForm, OrganismTeam},
  props: {
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
.section-contact {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .organism-team {
    padding: 2.5rem 1.25rem 2.813rem;
    margin: 0 -0.625rem;
    background: $black;
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
    }
    .molecule-team {
      margin: 0 -0.313rem;
      @media only screen and (max-width: 767px) {
        margin: 0 -20px;
      }
      .atom-padding {
        margin-bottom: 0.625rem;
        padding: 0 0.313rem;
        @media only screen and (max-width: 767px) {
          padding: 0 20px;
          margin-bottom: 20px;
        }
      }
      .image-team {
        height: 25.5rem;
        overflow: hidden;
        @media only screen and (max-width: 767px) {
          height: 300px;
        }
      }
    }
  }
}
</style>