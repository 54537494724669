<template>
  <div
      class="section section-image-info"
      :class="{
        [`section${orderNo}`]: true
      }">
      <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
      <OrganismImageInfo :list-image-info="data.items" />
  </div>
</template>

<script>
import OrganismImageInfo from "@/components/organisms/common/OrganismImageInfo.vue";

export default {
  name: 'SectionImageInfo',
  components: {OrganismImageInfo},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
</script>

<style lang="scss">
.section-image-info {
  padding: 3rem 0 0;
  @media only screen and (max-width: 767px) {
    padding: 20px 0 0;
  }
}
</style>
