<template>
  <div class="pin-info-wrapper">
    <MoleculePinInfoContent/>
  </div>
</template>

<script>

import MoleculePinInfoContent from "../../../molecules/project/building/MoleculePinInfoContent";

export default {
  name: "OrganismPinInfoBox",
  components: {MoleculePinInfoContent},
  props: {
    hightlight: {
      required: false,
      type: String,
      default: "",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
    pinData: {
      required: false,
      type: Array || null,
      default: [
        {
          highlightAnchor: "highlightStairsExterior0",
          pinTitle: "Exterior emergency staircase",
          pinDescription: "",
          type:""
        }
      ],
    },
  },

  data(){
    return {

    }
  }
}
</script>

<style lang="scss">
  .picture-popup {
    width: 30%;
    float: left;
  }

  .text-popup {
    width: 70%;
    float: right;
    padding-left: 15px;
  }

  @media only screen and (max-width: 1200px) {
    .pinInfo {
      padding: 10px 15px;
    }
    .pinInfo .picture-popup {
      width: 20%;
    }
    .pinInfo .picture-popup img {
      display: block;
    }
    .pinInfo .text-popup {
      width: 80%;
    }
  }

</style>