<template>
  <div class="molecule-image-slider carousel-box">
    <div class="owl-carousel owl-theme" ref="carousel" v-if="typeOf === 'image'">
      <AtomImageSlider v-for="(sliderImage, index) in sliderData.images" :slider-image="sliderImage" :key="index" :background-size="sliderImage.backgroundSize" />
    </div>
    <div class="owl-carousel owl-theme" ref="carousel" v-else >
      <AtomVideoSlider v-for="(sliderVideo, index) in sliderData.videos" :slider-video="sliderVideo" :key="index" />
    </div>
  </div>
</template>

<script>
import AtomImageSlider from "../../atoms/common/slider/AtomImageSlider";
import AtomVideoSlider from "../../atoms/common/slider/AtomVideoSlider";

export default {
  name: "MoleculeImageSlider",
  components: {AtomVideoSlider, AtomImageSlider},
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    typeOf: {
      required: true,
      type: String,
      default: function () {
        return 'image';
      }
    }
  },
  data() {
    return {
      instance: null,
    }
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      const carouselOpts = {
        items: 1,
        nav: true,
        margin: 20,
        dotsContainer: '#owl-custom-dots'
      };
      this.instance = window.$(this.$refs.carousel).owlCarousel(carouselOpts);
      this.instance.on('change.owl.carousel', (data) => {
        this.$parent.$refs.thumbSlider.changeActiveThumb(data.property.value);
      });
      this.$emit('initialized', true);
    },
    destroyCarousel() {
      if (!this.instance) return;
      this.instance.trigger('destroy.owl.carousel');
      this.instance.off('change.owl.carousel');
      this.instance = null;
      this.$emit('initialized', false);
    },
    refreshCarousel() {
      this.instance.trigger('refresh.owl.carousel');
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/carousel';

.molecule-image-slider {

}
</style>