var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-list-circle-v2", class: _vm.backgroundColor },
    [
      _c("AtomTitle", { attrs: { title: _vm.title, subtitle: _vm.subtitle } }),
      _c(
        "MoleculeListCircle",
        _vm._l(_vm.listCircleData, function(list, index2) {
          return _c("AtomListCircle", {
            key: index2,
            attrs: {
              title: list.title,
              text: list.text,
              "column-number": list.columnNumber,
              "tablet-column-number": list.tabletColumnNumber,
              "mobile-column-number": list.mobileColumnNumber
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }