<template>
  <div class="molecule-image-info">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MoleculeImageInfo'
}
</script>

<style lang="scss" scoped>
.molecule-image-info {
}
</style>
