export class AnimationBuilder {
  fps = 60;
  speedRatio = 4;
  isLoop = false;
  fromFrame = 0;
  toFrame = 100;

  createAnimation(property: string, fromValue: number, toValue: number) {
    const ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

    const animation = BABYLON.Animation.CreateAnimation(
      property,
        BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      this.fps,
      ease
    );
    animation.setKeys([
      {
        frame: this.fromFrame,
        value: fromValue,
      },
      {
        frame: this.toFrame,
        value: toValue,
      },
    ]);
    return animation;
  }

  createMoveArcRotateCamera(
    camera: BABYLON.ArcRotateCamera,
    properties: {
      radius: number;
      alpha: number;
      beta: number;
      target: { x: number; y: number; z: number };
    }
  ) {
    return [
      this.createAnimation("radius", camera.radius, properties.radius),
      this.createAnimation("alpha", camera.alpha, properties.alpha),
      this.createAnimation("beta", camera.beta, properties.beta),
      this.createAnimation("target.x", camera.target.x, properties.target.x),
      this.createAnimation("target.y", camera.target.y, properties.target.y),
      this.createAnimation("target.z", camera.target.z, properties.target.z),
    ];
  }

  createMoveUniversalCamera(
    camera: BABYLON.UniversalCamera,
    properties: { target: { x: number; y: number; z: number } }
  ) {
    return [
      this.createAnimation(
        "position.x",
        camera.position.x,
        properties.target.x
      ),
      this.createAnimation(
        "position.y",
        camera.position.y,
        properties.target.y
      ),
      this.createAnimation(
        "position.z",
        camera.position.z,
        properties.target.z
      ),
    ];
  }
}
