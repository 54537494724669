import { requestOfferConstants } from "@/store/modules/requestOffer";
import { MinimapViewport } from "@/components/organisms/project/building/store/3d/builders/MinimapViewport";

export class PinContentManager {
  activeDiv?: HTMLDivElement;
  cdnBase;
  engine: BABYLON.Engine;
  scene: BABYLON.Scene;
  store: any;

  constructor(
    cdnBase: string,
    engine: BABYLON.Engine,
    scene: BABYLON.Scene,
    store: any
  ) {
    this.cdnBase = cdnBase;
    this.engine = engine;
    this.scene = scene;
    this.store = store;
  }

  generateBox(...cssClass: string[]) {
    if (this.activeDiv) this.dispose();
    this.activeDiv = document.createElement("div");
    this.activeDiv.style.position = "absolute";
    this.activeDiv.style.zIndex = "10";
    this.activeDiv.style.visibility = "hidden";
    //// Add css
    this.activeDiv.classList.add(...cssClass);
    document.body.appendChild(this.activeDiv);
  }

  addTitle(title: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const titleHTML = document.createElement("h4");
    titleHTML.innerText = title;
    //// Add css
    titleHTML.classList.add(...cssClass);
    this.activeDiv.appendChild(titleHTML);
  }

  addText(text: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const textHtlm = document.createElement("p");
    textHtlm.innerHTML = text;
    //// Add css
    textHtlm.classList.add(...cssClass);
    this.activeDiv.appendChild(textHtlm);
  }

  addImg(imgSrc: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const imgHtml = document.createElement("img");
    imgHtml.src = this.cdnBase + "/" + imgSrc;
    imgHtml.alt = "pin image";
    //// Add css
    imgHtml.classList.add(...cssClass);
    this.activeDiv.appendChild(imgHtml);
  }

  addVideo(videoSrc: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const wrapper = document.createElement("div");
    //// Add css
    wrapper.classList.add(...cssClass);
    const videoHtml = document.createElement("iframe");
    videoHtml.src = videoSrc;
    wrapper.appendChild(videoHtml);
    this.activeDiv.appendChild(wrapper);
  }

  addRequestButton(...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const wrapper = document.createElement("div");
    wrapper.classList.add(...cssClass);
    ////Add button
    const btn = document.createElement("a");
    btn.classList.add("pinButton");
    btn.innerText = "Request Offer";
    btn.href = "javascript:void(0)";
    wrapper.appendChild(btn);
    this.activeDiv.appendChild(wrapper);
  }

  addAttributes(args: {
    spaceId: string;
    floorCode: string;
    buildingSlug: string;
  }) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const pinBox: HTMLDivElement | null = document.querySelector(
      ".pinInfo.pinInfoVT"
    );

    if (pinBox) {
      pinBox.setAttribute(`data-spaceId`, `${args.spaceId}`);
      pinBox.setAttribute(`data-floorCode`, `${args.floorCode}`);
      pinBox.setAttribute(`data-buildingSlug`, `${args.buildingSlug}`);
    }
  }

  addRequestOfferBtnClickEvent() {
    if (!this.activeDiv) return console.error("No pin info div found");
    const requestOfferBtn: HTMLDivElement | null = document.querySelector(
      ".pinButton"
    );
    if (!requestOfferBtn) return;
    requestOfferBtn.addEventListener("pointerdown", (event: MouseEvent) => {
      const pinBox: HTMLDivElement | null = document.querySelector(
        ".pinInfo.pinInfoVT"
      );
      if (pinBox) {
        this.store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.CLEAR_STATE
          ),
          null,
          { root: true }
        );
        const spaceData = {
          spaceId: pinBox.getAttribute(`data-spaceId`),
          floor: pinBox.getAttribute(`data-floorCode`),
          building: pinBox.getAttribute(`data-buildingSlug`),
        };
        this.store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.ADD_SPACE
          ),
          spaceData
        );
        // this.store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.CHANGE_CART_SPACES_STATE), true);
        const modal = this.store.getters.constants.modal;
        this.store.dispatch(
          modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
          { showModal: true, modalType: "request-offer" }
        );
        document.body.classList.add("disable-scroll");
      }
    });
  }

  reposition(pinMesh: BABYLON.Mesh, yOffset: number) {
    if (!this.activeDiv || !this.scene.activeCameras)
      return console.error("No pin info div found");
    // this.scene.cameraToUseForPointers = this.scene.activeCameras[0];
    const coordinates = BABYLON.Vector3.Project(
      pinMesh.absolutePosition as BABYLON.DeepImmutableObject<BABYLON.Vector3>,
      BABYLON.Matrix.Identity(),
      this.scene.activeCameras[0].getTransformationMatrix(),
      this.scene.activeCameras[0].viewport.toGlobal(
        this.engine.getRenderWidth(),
        this.engine.getRenderHeight()
      )
    );

    const pinTop = coordinates.y;
    const pinLeft = coordinates.x;
    const rect = this.activeDiv.getBoundingClientRect();

    this.activeDiv.style.top = pinTop + yOffset + "px";
    this.activeDiv.style.left = pinLeft - rect.width / 2 + "px";
    //// Pin box info z-index
    this.activeDiv.style.zIndex = "90909";
  }

  dispose() {
    if (!this.activeDiv) return;
    this.activeDiv.remove();
    delete this.activeDiv;
  }

  show(pinMess: BABYLON.Mesh, yOffset: number) {
    if (!this.activeDiv) return;
    this.reposition(pinMess, yOffset);
    this.activeDiv.style.visibility = "visible";
  }
}
