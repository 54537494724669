<template>
  <div class="row atom-facts">
    <div class="col col-xs-12" :class="columns" v-for="(fact, index) in facts" :key="index">
      <div class="box-fact">
        <h5 v-html="fact.title"></h5>
        <p v-html="fact.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomFacts",
  props: {
    facts: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
    columns: {
      required: false,
      type: String,
      default: 'col-4'
    }
  }
}
</script>

<style lang="scss">
  .atom-facts {
    margin: 0 -1rem;
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
    & > .col {
      padding: 0 1rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
    .box-fact {
      max-width: 30rem;
      margin: 0 auto 1.25rem;
      @media only screen and (max-width: 767px) {
        margin: 0 auto 0.625rem;
        max-width: 20rem;
      }
      h5 {
        @include font-main(4.5rem, $second-color, $bold, 5.5rem);
        @media only screen and (max-width: 767px) {
          @include font-main(2.5rem, $second-color, $bold, 3.75rem);
        }
      }
      p {
        @include font-main(1.5rem, $black, $light, 2.1rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1rem, $black, $light, 1.375rem);
        }
        span {
          display: block;
          margin-bottom: 0.625rem;
          @include font-main(1.7rem, $black, $regular, 2.2rem);
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem, $black, $regular, 1.25rem);
          }
        }
      }
    }
  }
</style>