<template>
  <div class="molecule-contact-form">
    <form ref="contactForm" @submit="submitForm" action="javascript:void(0);">
      <div class="row">
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="companyNumber" @input="companyNumber = $event" placeholder="Company Registration Number"/>
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="firstName" @input="firstName = $event" placeholder="First Name*" :error="errors.firstName"/>
            </div>
          </div>
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="companyName" @input="companyName = $event" placeholder="Company Name*" :error="errors.companyName"/>
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="lastName" @input="lastName = $event" placeholder="Last Name*" :error="errors.lastName"/>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput :type="inputTypes.TEXT" :value="companyAddress" @input="companyAddress = $event" placeholder="Company Address"/>
            </div>
          </div>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="phone" @input="phone = $event" placeholder="Phone Number*" :error="errors.phone"/>
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput :type="inputTypes.TEXT" :value="email" @input="email = $event" placeholder="Email*" :error="errors.email"/>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput :type="inputTypes.TEXTAREA" :value="message" @input="message = $event" placeholder="Message"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom-form align-items-center">
        <div class="col col-xs-12 col-6">
          <AtomCheck id="checkbox-contact-footer" ref="check" class="row-checkbox" label="I agree to the Terms & Conditions and Privacy Policy (*)" :error="errors.agree"/>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="button-contact-form text-right">
            <AtomButton label="Send" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AtomInput, {EnumInputTypes} from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";
import AtomCheck from "../../atoms/common/AtomCheck";
import config from "../../../config";

export default {
  name: "MoleculeContactForm",
  components: {
    AtomCheck,
    AtomButton,
    AtomInput
  },
  data() {
    return {
      errors: {},
      companyNumber: "",
      companyName: "",
      firstName: "",
      lastName: "",
      companyAddress: "",
      phone: "",
      email: "",
      message: "",
      inputTypes: EnumInputTypes,
    };
  },
  computed: {
    client() {
      return config.CLIENT
    },
    activeProjectSlug() {
      return this.$store.state?.project?.activeProject || null;
    },
    activeProject() {
      if (!this.activeProjectSlug) return;
      return this.$store?.state?.project?.projects?.find((p) => p.slug === this.activeProjectSlug) || null;
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      if (this.validateInputs() !== null) return;

      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          'event': 'contact_request_submit',
          'category': 'contact_request',
          'label': 'contact_request'
        });
      }

      const payload = {
        offerData: {
          projectId: this.activeProject?.id || -1,
          landlordId: this.activeProject?.landlord_id || -1,
          name: this.companyName,
          companyName: this.companyName,
          companyRegistrationNumber: this.companyNumber === "" ? "-" : this.companyNumber,
          companyAddress: this.companyAddress === "" ? "-" : this.companyAddress,
          receivedOn: new Date(),
          message: this.message === "" ? null : this.message,
        },
        selectedSpaces: [],
        contactData: {
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phone,
          email: this.email,
          visible: true,
        },
      };
      payload.offerData.noVisit = true;
      payload.offerData.interval = "No interval";
      payload.offerData.proposedVisitDate = null;
      this.$request.POST(this.$urls.offering.contact(), payload).then((res) => {
        if (res.error) {
          alert(res.error.message);
          return;
        }
        this.$refs.contactForm.reset();
        // if (this.client === 'one' && config.RUNTIME_ENV === 'production') {
        //   if ('gtag_report_conversion' in window) {
        //     window.gtag_report_conversion();
        //   }
        //   if ('lintrk' in window) {
        //     window.lintrk('track', {conversion_id: 4403868});
        //   }
        // }
        alert("Message sent!");
        this.$refs.check.checked = false;
      }).catch((e) => {
        alert(e.message);
      })
    },

    validateInputs() {
      this.errors = {};
      if (this.companyName === '') {
        this.errors.companyName = "Company name is required."
      }
      if (this.firstName === '') {
        this.errors.firstName = "First Name is required."
      }
      if (this.lastName === '') {
        this.errors.lastName = "Last Name is required."
      }
      if (this.phone === '' || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = "Phone is required."
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid."
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = "Please agree to the Terms and Conditions first."
      }
      if (Object.keys(this.errors).length === 0) {
        return null;
      }
      return this.errors;
    },

    validEmail: function (email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
  }
}
</script>

<style lang="scss">
.molecule-contact-form {
  margin-top: 1.875rem;
  .error-message {
    padding: 0.625rem 0 0.938rem;
    width: 100%;
    @include font-main(0.875rem, red, $regular, 1.063rem);
  }
  .row {
    margin: 0 -0.75rem;
    @media only screen and (max-width: 767px) {
      margin: 0 -0.625rem;
    }
    .col {
      padding: 0 0.75rem;
      @media only screen and (max-width: 767px) {
        padding: 0 0.625rem;
      }
    }
  }
  .bottom-form {
    margin-top: 1.25rem;
    @media only screen and (max-width: 767px) {
      margin-top: 0.625rem;
    }
  }
  .input-atom-wrapper {
    margin-bottom: 0.938rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0.625rem;
    }
    input,
    textarea {
      margin-bottom: 0.313rem;
    }
  }
  .button-atom {
    width: 7.5rem;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: auto;
    }
  }
}
</style>