<template>
  <span class="atom-vertical-line" :style="(color ? {'background-color': color} : {})"></span>
</template>

<script>
export default {
  name: "AtomVerticalLine",
  props: {
    color: {
      required: false,
      type: String,
      default: ""
    }
  },
}
</script>

<style lang="scss" scoped>
  .atom-vertical-line {
    display: block;
    width: 5.625rem;
    height: 0.063rem;
    background-color: $second-color;
  }
</style>