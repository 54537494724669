import store from "@/components/organisms/project/building/store";
import { requestOfferConstants, spaceConstants } from "@/store/modules";

export class HighlightManager {
  highlightedMesh?: BABYLON.Mesh;
  selectedMesh?: BABYLON.Mesh;
  highlightedTooltip?: JQuery;
  selectedTooltip?: JQuery;
  store?: any;
  addCookieHoverSpace?: () => void;
  addCookieClickSpace?: () => void;

  enableHighlights(nodeName: string, scene: BABYLON.Scene, isEnabled: boolean) {
    const node = scene.getNodeByName(nodeName);
    if (!node) throw Error("Node not defined");
    node.setEnabled(isEnabled);
  }

  showMesh(mesh: BABYLON.Mesh, scene: BABYLON.Scene) {
    //// Check if space is available
    const space = this.checkSpaceAvailability(mesh);
    if (!space) return;
    if (
      mesh == this.highlightedMesh ||
      scene.activeCamera?.name != "ArcRotateCamera" ||
      mesh == this.selectedMesh
    ) {
      return;
    }
    this.hideMesh(false);
    mesh.visibility = 1;
    if (mesh != this.selectedMesh) this.highlightedMesh = mesh;

    this.highlightedTooltip = this.generateTooltip(
      mesh,
      scene,
      space
    ) as JQuery;
    //// Remove pop messageson first highlight
    if (this.addCookieHoverSpace) {
      this.addCookieHoverSpace();
      this.addCookieHoverSpace = undefined;
    }
  }

  requestOffer(mesh: BABYLON.Mesh) {
    //// Check if space is available
    this.updateStore(mesh);
    // this.store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.CHANGE_CART_SPACES_STATE), true);
    const modal = this.store.getters.constants.modal;
    this.store.dispatch(modal.withNamespace(modal.action.CHANGE_MODAL_STATE), {
      showModal: true,
      modalType: "request-offer",
    });
    document.body.classList.add("disable-scroll");
    //// Remove pop messages on first request offer
    if (this.addCookieClickSpace) {
      this.addCookieClickSpace();
      this.addCookieClickSpace = undefined;
    }
  }

  hideMesh(isSelected: boolean) {
    if (this.highlightedTooltip) {
      this.removeHighlightTooltip();
    }
    if (isSelected && this.selectedMesh) {
      this.selectedMesh.visibility = 0;
      if (this.selectedTooltip) {
        this.removeSelectedTooltip();
      }
    } else if (
      this.highlightedMesh &&
      this.highlightedMesh != this.selectedMesh
    ) {
      this.highlightedMesh.visibility = 0;
      delete this.highlightedMesh;
    }
  }

  getSpaceTypeName(name: string) {
    let spaceType = "Other Type";
    if (!name) return spaceType;
    if (
      ["Team office", "Management office", "Executive office"].includes(name)
    ) {
      spaceType = "Office";
    } else if (name === "Meeting room") {
      spaceType = "Meeting Room";
    } else if (name === "Open Space") {
      spaceType = "Open Space";
    }
    return spaceType;
  }
  generateTooltip(
    mesh: BABYLON.Mesh,
    scene: BABYLON.Scene,
    space: Record<string, any>
  ) {
    if (!scene.activeCameras) return console.error("Scene camera not defined");
    const coordinates = this.getCoords(mesh, scene) as BABYLON.Vector3;
    // TODO: get floor from api on the space or get the floor code from vuex state, by filtering state.floors((f) => f.id === space.floor_id)

    const spaceType = this.getSpaceTypeName(space.space_type.name);
    let label = space.alias || space.space_code;
    if (space.alias.includes("-")) {
      label = space.alias.split("-")[0];
    }
    return $("<div>", {
      class: "space-info-tooltip",
      id: `space-info-tooltip-ID`,
    })
      .css({
        top: `${coordinates._y}px`,
        left: `${coordinates._x + 20}px`,
      })
      .html(
        `
            <div class="space-info">
                <div><span class="value">${spaceType} ${label}</span></div>
                <div class="column-elements">
                    <div><span class="value">${space.sqm}</span> sqm</div>
                    <div> &nbsp; • &nbsp; </div>
                    <div><span class="value">${space.people}</span> People</div>
                </div>
                <div class="horizontal-line"></div>
                <div><span class="value">Workspaces</span></div>
                <div class="column-elements">
                    <div>Min <span class="value"> ${
                      space.min_workplaces || 0
                    } </span></div>
                    <div> &nbsp; - &nbsp; </div> 
                    <div>Max <span class="value"> ${
                      space.workplaces || 0
                    } </span></div> 
                </div>
            </div>
        `
      )
      .appendTo("body");
  }

  removeHighlightTooltip() {
    if (!this.highlightedTooltip) return;
    this.highlightedTooltip.remove();
  }
  removeSelectedTooltip() {
    if (!this.selectedTooltip) return;
    this.selectedTooltip.remove();
  }
  //// Highlights mesh based on id
  findMeshById(id: string, parentNode: BABYLON.TransformNode) {
    const children = parentNode.getChildMeshes();
    for (const mesh of children) {
      const meshParts = mesh.name.split("_");
      const spaceCode = meshParts[3];
      if (spaceCode == id) {
        return mesh;
      }
    }
  }

  repositionToolTip(scene: BABYLON.Scene) {
    //// Reposition selected tooltip
    if (!this.selectedTooltip || !this.selectedMesh) return;
    const coordinatesSelect = this.getCoords(
      this.selectedMesh,
      scene
    ) as BABYLON.Vector3;
    this.selectedTooltip[0].style.top = coordinatesSelect.y + "px";
    this.selectedTooltip[0].style.left = coordinatesSelect.x + "px";
    //// Reposition highlighted tooltip
    if (!this.highlightedTooltip || !this.highlightedMesh) return;
    const coordinatesHighligh = this.getCoords(
      this.highlightedMesh,
      scene
    ) as BABYLON.Vector3;
    this.highlightedTooltip[0].style.top = coordinatesHighligh.y + "px";
    this.highlightedTooltip[0].style.left = coordinatesHighligh.x + "px";
  }

  selectMesh(
    mesh: BABYLON.Mesh,
    scene: BABYLON.Scene,
    requestOffer = true,
    hasTooltip = true
  ) {
    //// Check if space is available
    const space = this.checkSpaceAvailability(mesh);
    if (!space) return;
    if (mesh == this.selectedMesh && requestOffer) {
      this.requestOffer(mesh);
      return;
    } else {
      this.updateStore(mesh);
    }
    this.hideMesh(true);
    mesh.visibility = 1;
    this.selectedMesh = mesh;
    if (hasTooltip) {
      // TO DO: find a better solution for multiple cameras problems
      setTimeout(() => {
        if (this.selectedTooltip) this.removeSelectedTooltip();
        this.selectedTooltip = this.generateTooltip(
          mesh,
          scene,
          space
        ) as JQuery;
      }, 50);
    }
    //// Remove pop messages on first highlight
    if (this.addCookieHoverSpace) {
      this.addCookieHoverSpace();
      this.addCookieHoverSpace = undefined;
    }
  }

  checkSpaceAvailability(mesh: BABYLON.Mesh) {
    const meshParts = mesh.name.split("_");
    const building = meshParts[1];
    const floor = meshParts[2];
    const spaceCode = meshParts[3];
    const spacesByCode =
      store.getters.spacesByMeshCode?.[
        `${building}_${floor}_${spaceCode}`
      ]?.[0];
    if (!spacesByCode?.length) return;
    return spacesByCode.find((sp: any) => sp.available && sp.is_public);
  }

  getCoords(mesh: BABYLON.Mesh, scene: BABYLON.Scene) {
    if (!scene.activeCameras) return;
    const engine = scene.getEngine();
    const hardwareScaling = engine.getHardwareScalingLevel();
    return BABYLON.Vector3.Project(
      BABYLON.Vector3.Zero(),
      mesh.getWorldMatrix(),
      scene.activeCameras[0].getTransformationMatrix(),
      scene.activeCameras[0].viewport.toGlobal(
        engine.getRenderWidth(true) * hardwareScaling,
        engine.getRenderHeight(true) * hardwareScaling
      )
    );
  }

  updateStore(mesh: BABYLON.Mesh) {
    const meshParts = mesh.name.split("_");
    const building = meshParts[1];
    const floor = meshParts[2];
    const spaceCode = meshParts[3];
    const spaceData = {
      spaceId: spaceCode,
      floor: floor,
      building: building,
    };
    if (!spaceData || !this.store)
      return console.error("Space or Store are undefined");

    const storeBuilding = this.store.state.base?.landlordDetails?.buildings?.find(
      (b: any) => b.code === building
    );
    if (!storeBuilding) return;
    const storeFloor = this.store.state.base?.landlordDetails?.floors?.find(
      (fl: any) => fl.building_id === storeBuilding.id && fl.code === floor
    );
    if (!storeFloor) return;
    const storeSpace = this.store.state.base?.landlordDetails?.spaces?.find(
      (sp: any) =>
        sp.building_id === storeBuilding.id &&
        sp.floor_id === storeFloor.id &&
        sp.space_code === spaceCode &&
        sp.available &&
        sp.is_public
    );
    if (!storeSpace) return;

    this.store.dispatch(
      spaceConstants.withNamespace(spaceConstants.action.TOGGLE_SPACE_VIEW),
      true
    );
    this.store.dispatch(
      spaceConstants.withNamespace(spaceConstants.action.CHANGE_CURRENT_SPACE),
      storeSpace.id
    );

    this.store.dispatch(
      requestOfferConstants.withNamespace(
        requestOfferConstants.action.CLEAR_STATE
      ),
      null,
      { root: true }
    );
    this.store.dispatch(
      requestOfferConstants.withNamespace(
        requestOfferConstants.action.ADD_SPACE
      ),
      spaceData
    );
  }
}
