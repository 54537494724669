<template>
  <div class="organism-list-circle">
    <MoleculeCertifications>
      <div v-if="Object.keys(listCircleData).length > 1">
        <div class="row row-more-certification">
          <div class="col col-xs-12 col-6" v-for="(list, index) in listCircleData" :key="index">
            <div class="content-certification">
              <img :src="resourceUrl(list.icon)" alt="">
              <div class="content-text">
                <p v-for="(line, index) in list.lines" :key="index" v-html="line"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row row-one-certification align-items-center" v-for="(list, index) in listCircleData" :key="index">
          <div class="col col-xs-12 col-6">
            <img :src="resourceUrl(list.icon)" alt="">
          </div>
          <div class="col col-xs-12 col-6">
            <p v-for="(line, index) in list.lines" :key="index" v-html="line"></p>
          </div>
        </div>
      </div>
    </MoleculeCertifications>
  </div>
</template>

<script>
import MoleculeCertifications from "../../molecules/common/MoleculeCertifications";
export default {
  name: "OrganismCertifications",
  components: {MoleculeCertifications},
  props: {
    listCircleData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-list-circle {
    .row-one-certification {
      flex-direction: row-reverse;
      .col {
        margin-bottom: 3.75rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 1.875rem;
        }
      }
      img {
        width: 12.5rem;
        display: block;
        margin: 0 auto;
        @media only screen and (max-width: 767px) {
          width: 7.5rem;
          margin: 0 auto 0.938rem;
        }
      }
      p {
        margin: 0.875rem 0;
        @include font-main(1rem, $black, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          margin: 0.313rem 0;
          @include font-main(0.875rem, $black, $regular, 1.375rem);
        }
      }
    }
    .row-more-certification {
      margin: 0 -1.875rem;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }
      .col {
        padding: 0 1.875rem;
        margin-bottom: 3.75rem;
        @media only screen and (max-width: 767px) {
          padding: 0;
          margin-bottom: 1.875rem;
        }
      }
      img {
        width: 10.125rem;
        display: block;
        margin-bottom: 3.125rem;
        @media only screen and (max-width: 767px) {
          width: 7.5rem;
          margin: 0 auto 1.25rem;
        }
      }
      p {
        margin: 0.875rem 0;
        @include font-main(1rem, $black, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          margin: 0.313rem 0;
          @include font-main(0.875rem, $black, $regular, 1.375rem);
        }
      }
    }
  }
</style>