<template>
  <div class="parent-project">
    <div class="project-more-details">
      <div class="project-image" v-if="project && project.image">
        <img :src="resourceUrl(project.image)" :alt="project.name">
      </div>
      <div class="project-spaces">
        <div class="buildings-info">
          <div class="row list-info">
            <div class="col col-adaptive list-column">
              <p class="title">Buildings</p>
              <p class="total-number" >{{ buildings.length }}</p>
            </div>
            <div class="col col-adaptive list-column">
              <p class="title">Floors</p>
              <p class="total-number">{{ this.getBuildingsMaxFloors() }}</p>
            </div>
          </div>
        </div>
        <div class="apartments-info">
          <h4>Address</h4>
        </div>
        <div class="description-building" v-if="project && project.address">
          <p v-html="project.address"></p>
        </div>
        <div class="apartments-info">
          <h4>Description</h4>
        </div>
        <div class="description-building" v-if="project && project.description">
          <p v-html="project.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MoleculePopupResult",
  components: {},
  props: {
    project: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    activeProjectSlug() {
      return this.$store.state.project.activeProjectSlug;
    },
    activeProject() {
      if (!this.activeProjectSlug) return;
      return this.$store?.state?.project?.projects?.find((p) => p.slug === this.activeProjectSlug) || null;
    },
    projects() {
      return this.$store.state.base.landlordDetails?.projects || [];
    },
    buildings() {
      return this.$store.state.base.landlordDetails?.buildings?.filter((bl)=> {
        return bl.project_id === this.project.id
      }) || []
    },
    floors() {
      return this.$store.state.base.landlordDetails?.floors?.filter((fl)=> {
        return this.buildings.some(bl=>bl.id === fl.building_id)
      }) || []
    }
  },
  methods: {
    getBuildingsMaxFloors() {
      let floors = this.floors;
      let maxFloor = 0;
      if(floors){
        floors.forEach(floor => {
          maxFloor = floor.no > maxFloor ? floor.no : maxFloor;
        })
      }
      return maxFloor;
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${ this.$store.getters.cdnBase }/${ img }`;
    },
  }
}
</script>

<style lang="scss">
.parent-project {
  .project-title-and-address {
    cursor: pointer;
    background: #F5F7F8;
    padding: 0.75rem 3.438rem 0.75rem 1.25rem;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
    &.action-disabled {
      pointer-events: none;
    }
    .title {
      letter-spacing: -0.02em;
      margin-bottom: 0.25rem;
      @include font-main(1.375rem, $black, $medium, 1.625rem);
    }
    .address {
      letter-spacing: -0.02em;
      @include font-main(1rem, $black, $regular, 1.188rem);
    }
    .more-details-trigger {
      position: absolute;
      top: 0.75rem;
      right: 1.1rem;
      .icon {
        font-size: 1rem !important;
      }
    }
    &:hover {
      background: #EAEAEA;
    }
  }
  .project-more-details {
    .project-image {
      img {
        display: block;
      }
    }
    .project-spaces {
      padding: 1rem 1.25rem 1.188rem;
      .btn-black {
        cursor: pointer;
        display: block;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 0.5rem 1.25rem;
        background: $black;
        margin-bottom: 0.75rem;
        @include font-main(0.875rem, $white, $medium, 1.125rem);
        .icon {
          font-size: 1rem !important;
          display: inline-block;
          vertical-align: middle;
          margin-top: -0.125rem;
          margin-right: 0.375rem;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .btn-grey {
        display: block;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 0.5rem 1.25rem;
        background: #E2EAEC;
        margin-bottom: 0.75rem;
        @include font-main(0.875rem, $black, $medium, 1.125rem);
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .available-number {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 0.01em;
    background: #23CD9A;
    border-radius: 6.25rem;
    padding: 0.1rem 0.438rem 0.05rem;
    margin-left: 0.5rem;
    margin-top: -0.063rem;
    @include font-main(0.875rem, $white, $bold, 1.125rem);
    @media only screen and (max-width: 767px) {
      padding: 0 0.438rem;
    }
  }
  .unavailable-number {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 0.01em;
    background: #E2E9EC;
    border-radius: 6.25rem;
    padding: 0.1rem 0.438rem 0.05rem;
    margin-left: 0.5rem;
    margin-top: -0.063rem;
    @include font-main(0.875rem, $white, $bold, 1.125rem);
    @media only screen and (max-width: 767px) {
      padding: 0 0.438rem;
    }
  }
  .list-info {
    margin: 0 -0.313px;
    .list-column {
      padding: 0 0.313px;
      .title {
        letter-spacing: -0.02em;
        margin-bottom: 0.313rem;
        @include font-main(1rem, $black, $medium, 1.188rem);
      }
      .total-number {
        @include font-main(1.375rem, $black, $bold, 1.625rem);
        .available-number {
          margin-left: 0;
          margin-top: -0.188rem;
        }
      }
    }
  }

  .buildings-info {
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 0.063rem solid #E9EDEF;
  }

  .apartments-info {
    margin-bottom: 0.3rem;
    h4 {
      letter-spacing: -0.02em;
      @include font-main(1.375rem, $black, $medium, 1.625rem);
    }
  }

  .description-building {
    margin-bottom: 1rem;
    p {
      @include font-main(0.813rem, #333333, $regular, 1.25rem);
      b {
        @include font-main(0.813rem, #333333, $bold, 1.25rem);
      }
    }
  }

  &.active {
    .project-title-and-address {
      background: transparent;
      .more-details-trigger {
        top: 1rem;
        transform: rotate(180deg);
      }
    }
    .project-more-details {
      display: block;
    }
  }
}
</style>