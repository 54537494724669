var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-purpose-and-values": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c(
            "div",
            { staticClass: "top-title" },
            [
              _c("AtomTitle", {
                attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle }
              }),
              _c("p", [_vm._v(_vm._s(_vm.data.text))])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "col col-xs-12 col-6" },
          [
            _c("MoleculeIconListWithTexts", {
              attrs: { "data-list": _vm.data.contents }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }