import { CameraManager } from "@/components/organisms/project/building/store/3d/managers/CameraManager";
import { PointerCircle } from "@/components/organisms/project/building/store/3d/builders/PointerCircle";
import { Pin } from "@/components/organisms/project/building/store/3d/builders/Pin";
import { HighlightManager } from "@/components/organisms/project/building/store/3d/managers/HighlightManager";
import {isDesktop} from "@/helpers/mobile/DeviceType";

export class EventManager {
  cameraManager: CameraManager;
  isPointerDown = false;
  ray?: BABYLON.Ray;
  lastPickedPin: Pin | null = null;
  pointerMoveListener?: () => void;
  pointerDownListener?: () => void;
  pointerUpListener?: () => void;
  isDragOn = false;
  enableHighlights = true;
  pointerEvent?: BABYLON.PointerInfo;
  constructor(cameraManager: CameraManager) {
    this.cameraManager = cameraManager;
  }

  addVirtualTourCameraEvents(
    cameras: Array<BABYLON.UniversalCamera | BABYLON.ArcRotateCamera>,
    scene: BABYLON.Scene
  ) {
    scene.onPointerObservable.add((eventData: BABYLON.PointerInfo) => {
      const rect = scene.getEngine().getRenderingCanvasClientRect();
      if (!rect) return;
      this.pointerEvent = eventData;
      scene.cameraToUseForPointers = this.checkWhichCameraToUse(scene, cameras);
      if (eventData.type === BABYLON.PointerEventTypes.POINTERDOWN) {
        this.isPointerDown = true;
      } else if (
        eventData.type === BABYLON.PointerEventTypes.POINTERPICK &&
        eventData.pickInfo
      ) {
        if (this.isPointerDown) {
          //// Move on click
          if (eventData.pickInfo && eventData.pickInfo.pickedPoint) {
            //// Prevent movement if user hits a pin
            if (
              eventData.pickInfo.pickedMesh &&
              eventData.pickInfo.pickedMesh.name == "pin"
            )
              return;
            if (scene.activeCameras![0].name == "UniversalCamera") {
              this.cameraManager.moveUniversalCamera(
                cameras[0] as BABYLON.UniversalCamera,
                scene,
                {
                  x: eventData.pickInfo.pickedPoint.x,
                  y: cameras[0].metadata.y,
                  z: eventData.pickInfo.pickedPoint.z,
                }
              );
            }
          }
        }
      }
    });
  }

  addPointerMoveEvents(
    scene: BABYLON.Scene,
    functions: Array<{
      funct: (
        hit: BABYLON.PickingInfo,
        options: Record<string, unknown>
      ) => void;
      options: Record<string, unknown>;
    }>
  ) {
    this.pointerMoveListener = () => {
      //// Check if it's a drag event or not
      if (this.isDragOn) {
        //// Prevent highlights from triggering at the end of the drag
        this.enableHighlights = false;
        return;
      }
      //// To see based on which camera to move pointer circle
      let activeCamera = scene
        .activeCameras?.[0] as BABYLON.Nullable<BABYLON.Camera>;
      if (scene.activeCameras && scene.activeCameras.length > 1) {
        const rect = scene.getEngine().getRenderingCanvasClientRect();
        if (!rect || !this.pointerEvent) return;
        activeCamera = this.checkWhichCameraToUse(
          scene,
          scene.activeCameras as Array<BABYLON.Camera>
        );
      }

      //// Create ray
      this.ray = scene.createPickingRay(
        scene.pointerX,
        scene.pointerY,
        BABYLON.Matrix.Identity(),
        activeCamera
      );
      const hit = scene.pickWithRay(this.ray);
      for (const func of functions) {
        func.funct(hit as BABYLON.PickingInfo, func.options);
      }
    };
    const htmlElment = scene.getEngine()?.getRenderingCanvas();
    if (htmlElment) {
      htmlElment.addEventListener("pointermove", this.pointerMoveListener);
    }
  }

  addPointerDownEvents(
    scene: BABYLON.Scene,
    functions: Array<{
      funct: (
        hit: BABYLON.PickingInfo,
        options: Record<string, unknown>
      ) => void;
      options: Record<string, unknown>;
    }>
  ) {
    this.pointerDownListener = () => {
      this.isDragOn = true;
      //// Create ray
      this.ray = scene.createPickingRay(
        scene.pointerX,
        scene.pointerY,
        BABYLON.Matrix.Identity(),
        scene.activeCameras?.[0] as BABYLON.Nullable<BABYLON.Camera>
      );
      const hit = scene.pickWithRay(this.ray);
      for (const func of functions) {
        func.funct(hit as BABYLON.PickingInfo, func.options);
      }
    };
    const htmlElment = scene.getEngine()?.getRenderingCanvas();
    if (htmlElment) {
      htmlElment.addEventListener("pointerdown", this.pointerDownListener);
    }
  }

  addPointerUpEvents(
    scene: BABYLON.Scene,
    functions: Array<{
      funct: (
        hit: BABYLON.PickingInfo,
        options: Record<string, unknown>
      ) => void;
      options: Record<string, unknown>;
    }>
  ) {
    this.pointerUpListener = () => {
      this.isDragOn = false;
      if (this.enableHighlights) {
        //// Create ray
        this.ray = scene.createPickingRay(
          scene.pointerX,
          scene.pointerY,
          BABYLON.Matrix.Identity(),
          scene.activeCameras?.[0] as BABYLON.Nullable<BABYLON.Camera>
        );
        const hit = scene.pickWithRay(this.ray);
        for (const func of functions) {
          func.funct(hit as BABYLON.PickingInfo, func.options);
        }
      }
      setTimeout(() => {
        this.enableHighlights = true;
      }, 100);
    };

    const htmlElment = scene.getEngine()?.getRenderingCanvas();
    if (htmlElment) {
      htmlElment.addEventListener("pointerup", this.pointerUpListener);
    }
  }

  removePointerEvents() {
    if (this.pointerMoveListener)
      document.removeEventListener("pointermove", this.pointerMoveListener);
    if (this.pointerDownListener)
      document.removeEventListener("pointermove", this.pointerDownListener);
    if (this.pointerUpListener) {
      document.removeEventListener("pointerup", this.pointerUpListener);
    }
  }

  pinEvent(hit: BABYLON.PickingInfo, options: Record<string, unknown>) {
    if (!options.scene || !options.animationBuilder)
      return console.error("Scene or Animation Builder not defined");
    if (hit.pickedMesh?.name == "pin") {
      //// Check to see if pin is already opened
      if (hit.pickedMesh.metadata.pin == this.lastPickedPin) return;
      //// Reset previous tapped pin
      this.lastPickedPin?.trigger(options, false);
      //// Activate new pin
      const pin = hit.pickedMesh.metadata.pin as Pin;
      pin.trigger(options, true);
      this.lastPickedPin = pin;
    } else {
      if (this.lastPickedPin && !this.lastPickedPin.isActive) {
        this.lastPickedPin.trigger(options, false);
        this.lastPickedPin = null;
      }
    }
  }

  pinOpenEvent(hit: BABYLON.PickingInfo, options: Record<string, unknown>) {
    if (hit.pickedMesh?.name == "pin") {
      const pin = hit.pickedMesh.metadata.pin as Pin;
      pin.isActive = !pin.isActive;
      //// reset previous tapped pin
      if (this.lastPickedPin && !this.lastPickedPin.isActive && pin != this.lastPickedPin) {
        this.lastPickedPin.isActive = false;
        this.lastPickedPin.trigger(options, false);
        this.lastPickedPin = null;
      }
      this.lastPickedPin = pin;
      //// Trigger for mobile that ignores mouse over
      if (!isDesktop()) {
        pin.trigger(options, pin.isActive);
      }
    }
  }

  vtPointerEvent(hit: BABYLON.PickingInfo, options: Record<string, unknown>) {
    if (!options.pointerCircle || !options.pointerY)
      return console.error("Pointer circle is null");
    if (hit?.pickedPoint) {
      (options.pointerCircle as PointerCircle).move(
        hit?.pickedPoint.x,
        (options.pointerCircle as PointerCircle).yPos as number,
        hit?.pickedPoint.z
      );
      (options.minimapPointerCircle as PointerCircle).move(
        hit?.pickedPoint.x,
        options.pointerY as number,
        hit?.pickedPoint.z
      );
    }
  }

  floorHighlightEvent(
    hit: BABYLON.PickingInfo,
    options: Record<string, unknown>
  ) {
    (options.highlightManager as HighlightManager).hideMesh(false);
    if (hit.pickedMesh && hit.pickedMesh?.parent?.name == "highlights") {
      (options.highlightManager as HighlightManager).showMesh(
        hit.pickedMesh as BABYLON.Mesh,
        options.scene as BABYLON.Scene
      );
    }
  }

  floorHighlightSelect(
    hit: BABYLON.PickingInfo,
    options: Record<string, unknown>
  ) {
    if (hit.pickedMesh && hit.pickedMesh?.parent?.name == "highlights") {
      (options.highlightManager as HighlightManager).selectMesh(
        hit.pickedMesh as BABYLON.Mesh,
        options.scene as BABYLON.Scene
      );
    }
  }

  dispose() {
    this.removePointerEvents();
  }

  checkWhichCameraToUse(scene: BABYLON.Scene, cameras: Array<BABYLON.Camera>) {
    const raycast = scene.createPickingRay(
      scene.pointerX,
      scene.pointerY,
      BABYLON.Matrix.Identity(),
      cameras[1],
      false
    );
    const test = scene.pickWithRay(raycast);
    if (test?.pickedMesh && test?.pickedMesh.name != "SkyBox") {
      return cameras[1];
    } else {
      return cameras[0];
    }
  }

  castRay(
    scene: BABYLON.Scene,
    camera: BABYLON.UniversalCamera,
    axis: BABYLON.Vector3,
    collisionNodeName: string,
    length = 10
  ) {
    this.ray = new BABYLON.Ray(camera.position, axis, length);

    const hit = scene.pickWithRay(this.ray);
    if (
      hit &&
      hit.pickedMesh &&
      hit.pickedMesh.parent &&
      hit.pickedMesh.parent.name == collisionNodeName
    ) {
      return hit.distance;
    }
  }
}
