var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "changeFitout" },
    _vm._l(_vm.fitouts, function(fitout, fitName) {
      return _c(
        "li",
        {
          key: fitName,
          on: {
            click: function($event) {
              return _vm.changeFitout(fitout.name)
            }
          }
        },
        [_c("AtomButton", { attrs: { label: fitout.buttonText } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }