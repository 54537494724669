<template>
  <div class="atom-title">
    <h2 :style="(color ? {'color': color} : {})" v-html="title"></h2>
    <h3 :style="(color2 ? {'color': color2} : {})" v-html="subtitle"></h3>
  </div>
</template>

<script>
export default
{
  name: "AtomTitle",
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    },
    color: {
      required: false,
      type: String,
      default: ''
    },
    color2: {
      required: false,
      type: String,
      default: ''
    },
    disableColorProps: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.atom-title {
  h2 {
    text-transform: uppercase;
    @include font-main(4rem, $second-color, $light, 4.125rem);
    @media only screen and (max-width: 767px) {
      @include font-main(2rem, $second-color, $light, 2.438rem);
    }
  }
  h3 {
    @include font-main(4rem, $second-color, $medium, 4.125rem);
    @media only screen and (max-width: 767px) {
      @include font-main(2rem, $second-color, $medium, 2.438rem);
    }
  }
}
</style>