var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-slider": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _vm.data.images
        ? _c("organism-slider", {
            attrs: { images: _vm.data.images, slidess: [] }
          })
        : _vm._e(),
      _vm.data.slidess
        ? _c("organism-slider", {
            attrs: { slidess: _vm.data.slidess, images: [] }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }