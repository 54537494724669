var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-icons-descriptions": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("MoleculeIconListWithTexts", {
        attrs: {
          "data-list": _vm.data.contents,
          "column-number": _vm.data.columnNumber,
          "tablet-column-number": _vm.data.tabletColumnNumber,
          "mobile-column-number": _vm.data.mobileColumnNumber,
          "center-content": _vm.data.centerContent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }