var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "atom-bullet-specs": true, active: _vm.isActive },
      style: {
        top: _vm.topPostion,
        right: _vm.rightPostion,
        bottom: _vm.bottomPostion,
        left: _vm.leftPostion
      },
      attrs: { id: "" + _vm.id },
      on: { click: _vm.handleClick }
    },
    [
      _c("span", { staticClass: "bullet" }),
      _c("AtomIcon", { attrs: { color: "#fff", size: 16, icon: "plus" } }),
      _c("AtomIcon", { attrs: { color: "#fff", size: 30, icon: "minus" } }),
      _c(
        "div",
        {
          staticClass: "hidden-element",
          style: {
            top: _vm.showTop,
            right: _vm.showRight,
            bottom: _vm.showBottom,
            left: _vm.showLeft,
            transform: _vm.showCenter
          }
        },
        [
          _vm.image
            ? _c("div", { staticClass: "image" }, [
                _c("img", {
                  attrs: { src: _vm.resourceUrl(_vm.image), alt: _vm.category }
                })
              ])
            : _vm._e(),
          _c("div", { staticClass: "content-info" }, [
            _c("div", {
              staticClass: "content",
              domProps: { innerHTML: _vm._s(_vm.text) }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }