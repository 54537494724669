import {
    ActionManager,
    ArcRotateCamera, AssetsManager, Color3,
    Color4,
    CubeTexture,
    Engine,
    EngineOptions, ExecuteCodeAction, FreeCamera, HemisphericLight,
    Scene, StandardMaterial,
    Tools, TransformNode,
    Vector3
} from "babylonjs";
import "babylonjs-loaders";
import {device} from './../../../../../helpers/mobile/index'

import helper from "@/components/organisms/project/building/store/Helper";
import $ from "jquery";
import {VirtualTourHelper} from "@/components/organisms/project/building/store/VirtualTourHelper";
import FloorInteractionHelper from "@/components/organisms/project/building/store/FloorInteractionHelper";
import BuildingInteractionHelper from "@/components/organisms/project/building/store/BuildingInteractionHelper";
import {FloorHighlightsHelper} from "@/components/organisms/project/building/store/FloorHighlightsHelper";
import {CurrentView, FloorViewMode} from "@/components/organisms/project/building/store";

class SceneHelper {
    _isVue: boolean;
    _scene: Scene | null;
    constructor() {
        this._isVue = true;
        this._scene = null;
    }
    new(engine: Engine) {
        this._scene = new Scene(engine);
    }
    get():Scene {
        return this._scene as Scene;
    }
    destroy() {
        this._scene?.dispose();
        this._scene = null;
    }
}

class EngineHelper {
    _isVue: boolean;
    _engine: Engine | null;
    constructor() {
        this._isVue = true;
        this._engine = null;
    }
    new(canvas: HTMLCanvasElement, opts: EngineOptions) {
        this._engine = new Engine(canvas, true, opts);
        this._engine.loadingScreen.displayLoadingUI = function () {
            const self: any = this;
            self._loadingDiv = document.createElement("div");
        };
    }
    get(): Engine {
        return this._engine as Engine;
    }
    destroy() {
        this._engine?.dispose();
        this._engine = null;
    }
}

class CameraHelper {
    _isVue: boolean;
    _dollHouse: ArcRotateCamera | null;
    _firstPerson: FreeCamera | null;
    constructor() {
        this._isVue = true;
        this._dollHouse = null;
        this._firstPerson = null;
    }
    setDollHouseCamera(camera: ArcRotateCamera) {
        this._dollHouse = camera;
    }
    getDollHouseCamera():ArcRotateCamera {
        return this._dollHouse as ArcRotateCamera;
    }
    setFirstPersonCamera(camera: FreeCamera) {
        this._firstPerson = camera;
    }
    getFirstPersonCamera():FreeCamera {
        return this._firstPerson as FreeCamera;
    }
    destroy() {
        this._dollHouse = null;
        this._firstPerson = null;
    }
}

class BabylonInitializeHelperDefinition {
    _isVue: boolean;
    _canvasElement: HTMLCanvasElement | null;
    _engineHelper: EngineHelper;
    _sceneHelper: SceneHelper;
    _cameraHelper: CameraHelper;
    _buildingInteractionHelper: BuildingInteractionHelper | null;
    _floorHighlightHelper: FloorHighlightsHelper | null;
    _floorInteractionHelper: FloorInteractionHelper | null;
    _virtualTourHelper: VirtualTourHelper | null;
    _settings: any;
    _space: any;
    _cdnBase: string;
    _activeProject: string;
    _client: string;
    _floorSpaceData: any;
    _fitoutDetails: {
        fitoutOptions: [],
        fitouts: Record<string, unknown>
    };
    _pinsData: any;
    _activeHighlight: any;
    _pinsInfo: any;
    _projects: any[];
    _buildings: any[];
    _floors: any[];
    _onHighlightTrigger = (spaceId: string) => {};

    constructor() {
        this._isVue = true;
        this._canvasElement = null;
        this._engineHelper = new EngineHelper();
        this._sceneHelper = new SceneHelper();
        this._cameraHelper = new CameraHelper();
        this._buildingInteractionHelper = null;
        this._floorHighlightHelper = null;
        this._floorInteractionHelper = null;
        this._virtualTourHelper = null;
        this._cdnBase = '';
        this._activeProject = '';
        this._client = '';
        this._floorSpaceData = {
            space: '',
            building: '',
            floor: '',
            floorType: '',
            spaceData: {}
        };
        this._fitoutDetails = {
            fitoutOptions: [],
            fitouts: {}
        };
        this._projects = [];
        this._buildings = [];
        this._floors = [];
    }

    engineHelper() {
        return this._engineHelper;
    }

    sceneHelper() {
        return this._sceneHelper;
    }

    cameraHelper() {
        return this._cameraHelper;
    }

    onHighlightTrigger(spaceId: string) {
        return this._onHighlightTrigger(spaceId);
    }

    fitoutDetails() {
        return this._fitoutDetails;
    }

    setSpace(space: any) {
        this._space = space;
        this._buildingInteractionHelper = new BuildingInteractionHelper({
            settings: this._settings,
            space: space
        }, this.engineHelper(), this.sceneHelper());
    }

    activateDebugger(){
        this.sceneHelper().get().debugLayer.show();
    }

    initBuilding(payload: any, execWhenReadyFunc = () => {}, lodTexturesUpdate = () => {}) {
        const self = this;
        this._canvasElement = payload.canvasReference;
        this._space = payload.spaceData;
        this._cdnBase = payload.cdnBase;
        this._activeProject = payload.activeProject;
        this._client = payload.client;

        helper.lodTexturesUpdate = lodTexturesUpdate;

        const engineOptions: EngineOptions = {
            preserveDrawingBuffer: true,
            stencil: true,
        };
        this._pinsData = payload.pinsData;
        this.engineHelper().new(payload.canvasReference, engineOptions);

        this._settings = payload.settings;
        const settings: any = payload.settings.building;
        const settingsFloor: any = payload.settings.floor;

        this.sceneHelper().new(this.engineHelper().get());

        const valueRadiusLowerLimit = settings.camera.radiusLowerLimitMobile ? settings.camera.radiusLowerLimitMobile :  settings.camera.radiusLowerLimit;
        const radiusLowerLimit = !device.isDesktop() ? valueRadiusLowerLimit : settings.camera.radiusLowerLimit;

        const dollHouse = helper.createDefaultCamera('defaultCamera', {
            alpha: Tools.ToRadians(settings.camera.alpha),
            beta: Tools.ToRadians(settings.camera.beta),
            radius: settings.camera.radius,
            fov: settings.camera.fov,
            target: new Vector3(...settings.camera.target),
            radiusLowerLimit: radiusLowerLimit,
            radiusUpperLimit: settings.camera.radiusUpperLimit,
            panningDistanceLimit: settings.camera.panningDistanceLimit
        }, this.sceneHelper().get(), payload.canvasReference);
        this.cameraHelper()?.setDollHouseCamera(dollHouse);

        const firstPerson = helper.createVirtualTourCamera(
            'virtualTour',
            new Vector3(...settingsFloor.virtualTour.camera.position),
            new Vector3(...settingsFloor.virtualTour.camera.target),
            this.sceneHelper().get()
        );
        this.cameraHelper()?.setFirstPersonCamera(firstPerson);

        if (settings.hdr && settings.hdr.texture) {
            const hdrTexture = CubeTexture.CreateFromPrefilteredData(`${payload.cdnBase}${settings.hdr.texture}`, this.sceneHelper().get());
            hdrTexture.gammaSpace = false;
            this.sceneHelper().get().environmentTexture = hdrTexture;
            if (settings.hdr.level) {
                hdrTexture.level = settings.hdr.level
            }
        }
        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        if(settings.scene && settings.scene.clearColor){
            this.sceneHelper().get().clearColor = new Color4(...settings.scene.clearColor);
        };
        if(settings.scene && settings.scene.environmentIntensity){
            this.sceneHelper().get().environmentIntensity = settings.scene.environmentIntensity;
        };
        if(settings.scene && settings.scene.imageProcessingConfiguration){
            if(settings.scene.imageProcessingConfiguration.contrast){
                this.sceneHelper().get().imageProcessingConfiguration.contrast = settings.scene.imageProcessingConfiguration.contrast;
            };
            if(settings.scene.imageProcessingConfiguration.vignetteEnabled){
                this.sceneHelper().get().imageProcessingConfiguration.vignetteEnabled = settings.scene.imageProcessingConfiguration.vignetteEnabled;
                if(settings.scene.imageProcessingConfiguration.vignetteWeight){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteWeight = settings.scene.imageProcessingConfiguration.vignetteWeight;
                }
                if(settings.scene.imageProcessingConfiguration.vignetteCameraFov){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteCameraFov = settings.scene.imageProcessingConfiguration.vignetteCameraFov;
                }
            }
        };

        //load object
        const assetsManager = new AssetsManager(this.sceneHelper().get());
        assetsManager.addMeshTask('project',
            null,
            `${payload.cdnBase}/objects/${payload.client}/${payload.activeProject}/project/${settings.versionPath ? `${settings.versionPath}/` : ''}`,
            'building.gltf').onSuccess = function (task) {
            const hightlightsNode = self.sceneHelper()?.get().getTransformNodeByName('highlights');
            if (hightlightsNode) {
                hightlightsNode.getChildMeshes().forEach((hightlight: any) => {
                    hightlight.setEnabled(false)
                })
            }
        };
        assetsManager.onTaskErrorObservable.add(function (task) {
            console.log('task error', task)
        });
        assetsManager.onTasksDoneObservable.add(function () {
            // change here
            const highlightTransformNodes = self.sceneHelper().get().getTransformNodeByName(`highlights_${self._activeProject}`) ? self.sceneHelper().get().getTransformNodeByName(`highlights_${self._activeProject}`) : new TransformNode(`highlights_${self._activeProject}`); ;
            if (highlightTransformNodes) {
                self._floorHighlightHelper?.init(highlightTransformNodes, false);
                // self._floorHighlightHelper?.init(highlightTransformNodes, 'EQ1_building', payload.pinsVisibilityRestrictions);
            }

            if (highlightTransformNodes) {
                const highlights = highlightTransformNodes.getChildMeshes(false);
                for (let i = 0; i < highlights.length; i++) {
                    const highlight = highlights[i];
                    highlight.visibility = 0;
                    // highlight.isPickable = false;
                }
            }


        });
        assetsManager.load();
        //load object

        const oldCamPosition = Vector3.Zero();
        this.sceneHelper().get().beforeRender = () => {
            this.engineHelper()?.get().resize();

            const dollHouseCam = this.cameraHelper()?.getDollHouseCamera();
            this.cameraHelper().getDollHouseCamera().alpha = this.cameraHelper()?.getDollHouseCamera().alpha%(Math.PI*2);

            // dollHouseCam!.rotation.x = (dollHouseCam!.rotation.x >=0 ) ? (Math.min(Math.PI/8, dollHouseCam!.rotation.x%(Math.PI*2) )) : (Math.max(-Math.PI/12, dollHouseCam!.rotation.x%(Math.PI*2)));
            // dollHouseCam!.rotation.y = dollHouseCam!.rotation.y%(Math.PI*2);
            // dollHouseCam!.rotation.z = dollHouseCam!.rotation.z%(Math.PI*2);
            // const alphaE = dollHouseCam.alpha > 0 ? dollHouseCam.alpha/6.28*360%360 : 360 + dollHouseCam.alpha/6.28*360%360;
            // const betaE = dollHouseCam.beta > 0 ? dollHouseCam.beta/6.28*360%360 : 360 + dollHouseCam.beta/6.28*360%360;

            // if(this.floorHighlightHelper()?.pinsVisibilityRestrictions?.length){
            //     this.floorHighlightHelper()?.pinsVisibilityRestrictions?.forEach((pin: any)=> {
            //         if(this.floorHighlightHelper()?.visiblePins){
            //             const activePins =  this._floorHighlightHelper?.pins;
            //             for (const key in activePins) {
            //                 if (`${activePins[key].name}` === pin.highlightAnchor){
            //                     activePins[key].isVisible = alphaE >= pin.visibleAngles.xAxis.minAngle && alphaE <= pin.visibleAngles.xAxis.maxAngle && betaE >= pin.visibleAngles.yAxis.minAngle && betaE <= pin.visibleAngles.yAxis.maxAngle
            //                 }
            //             }
            //         }
            //     })
            // }

            // this.cameraHelper().getDollHouseCamera().position.x = this.cameraHelper().getDollHouseCamera().position.x > 5  ? 5 : this.cameraHelper().getDollHouseCamera().position.x
            // this.cameraHelper().getDollHouseCamera().position.x = this.cameraHelper().getDollHouseCamera().position.x < -5  ? -5 : this.cameraHelper().getDollHouseCamera().position.x
            //
            // this.cameraHelper().getDollHouseCamera().position.y = this.cameraHelper().getDollHouseCamera().position.y > 0  ? 0 : this.cameraHelper().getDollHouseCamera().position.y
            // this.cameraHelper().getDollHouseCamera().position.y = this.cameraHelper().getDollHouseCamera().position.y < 0  ? 0 : this.cameraHelper().getDollHouseCamera().position.y
            //
            // this.cameraHelper().getDollHouseCamera().position.z = this.cameraHelper().getDollHouseCamera().position.z > 5  ? 5 : this.cameraHelper().getDollHouseCamera().position.z
            // this.cameraHelper().getDollHouseCamera().position.z = this.cameraHelper().getDollHouseCamera().position.z < -5  ? -5 : this.cameraHelper().getDollHouseCamera().position.z

            const position = this.cameraHelper()?.getDollHouseCamera().position;
            if (position && !position.equals(oldCamPosition)) {
                //const selector = $('.pinInfo');
                // if (selector.is(':visible')) {
                //     selector.hide();
                //     this._floorHighlightHelper?.hideHighlights();
                //     this._floorHighlightHelper?.resetPinRotation();
                // }
                const selector = $('.pinInfo');
                if (selector.is(':visible')) {
                    selector.hide();
                    this._floorHighlightHelper?.hideHighlights();
                    this._floorHighlightHelper?.resetPinRotation();
                    this._floorHighlightHelper?.pinMeshes.forEach((pinMesh:any) =>{
                        if (pinMesh.spriteActive){
                            pinMesh.spriteActive = false;

                            this._floorHighlightHelper?.showPinMeshDescription(pinMesh, false);
                            // this._floorHighlightHelper?.setHighlightVisibility(pinMesh.meshToLink, 0);
                            pinMesh!.material!.emissiveColor = new Color3(0,0,0);
                            pinMesh!.material.emissiveTexture.wAng = 0;
                        }
                    })
                }

                oldCamPosition.copyFrom(position);
            }

        };
        this.sceneHelper().get().executeWhenReady(() => {
            execWhenReadyFunc();
            if (payload.lodEnabled) {
                helper.lodTextures(this.sceneHelper().get(), this._buildingInteractionHelper?.settings.path);
            }
        });

        try {
            this.initHelpers('building');
        } catch (e) {
            console.error(e);
        }

        if (settings.light) {
            const light = new HemisphericLight('light73', new Vector3(0, 1, 0), this.sceneHelper().get());
            if(settings.light.groundColor) {
                light.groundColor = Color3.FromHexString(settings.light.groundColor);
            }
            if(settings.light.intensity) {
                light.intensity = settings.light.intensity;
            }
        }

        this._floorHighlightHelper!.pinsData = this._pinsData;
    }

    initFloor(payload: any, execWhenReadyFunc = () => {}, lodTexturesUpdate = () => {}) {
        this._canvasElement = payload.canvasReference;
        this._space = payload.spaceData;
        this._cdnBase = payload.cdnBase;
        this._activeProject = payload.activeProject;
        this._client = payload.client;
        this._pinsData = payload.pinsData;
        this._floorSpaceData = {
            space: payload.space,
            building: payload.building,
            floor: payload.floor,
            floorType: payload.floorType,
            spaceData: payload.spaceData,
        }
        this._projects = payload.projects;
        this._buildings = payload.buildings;
        this._floors = payload.floors;
        this._projects = payload.projects
        this._buildings = payload.buildings
        this._floors = payload.floors

        helper.lodTexturesUpdate = lodTexturesUpdate;

        const engineOptions: EngineOptions = {
            preserveDrawingBuffer: true,
            stencil: true
        };

        this.engineHelper().new(payload.canvasReference, engineOptions);
        this.sceneHelper().new(this.engineHelper().get());
        // this.sceneHelper().get().debugLayer.show();

        console.log('payload', payload)
        this._settings = payload.settings;
        const settings: any = payload.settings.floor;

        if (settings.hdr) {
            let hdrTexture: any = {};
            if (settings.hdr.texture) {
                hdrTexture = CubeTexture.CreateFromPrefilteredData(`${payload.cdnBase}${settings.hdr.texture}`, this.sceneHelper().get());
                hdrTexture.gammaSpace = false;
            } else if (settings.hdr.env) {
                hdrTexture = CubeTexture.CreateFromPrefilteredData(`${payload.cdnBase}${settings.hdr.env}`, this.sceneHelper().get());
            }
            this.sceneHelper().get().environmentTexture = hdrTexture as CubeTexture;
            if (settings.hdr.level) {
                hdrTexture.level = settings.hdr.level
            }
        }

        this.sceneHelper().get().ambientColor = new Color3(0, 0, 0);
        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        if(settings.scene && settings.scene.clearColor){
            this.sceneHelper().get().clearColor = new Color4(...settings.scene.clearColor);
        }
        if(settings.scene && settings.scene.environmentIntensity){
            this.sceneHelper().get().environmentIntensity = settings.scene.environmentIntensity;
        }
        if(settings.scene && settings.scene.imageProcessingConfiguration){
            if(settings.scene.imageProcessingConfiguration.contrast){
                this.sceneHelper().get().imageProcessingConfiguration.contrast = settings.scene.imageProcessingConfiguration.contrast;
            }
            if(settings.scene.imageProcessingConfiguration.vignetteEnabled){
                this.sceneHelper().get().imageProcessingConfiguration.vignetteEnabled = settings.scene.imageProcessingConfiguration.vignetteEnabled;
                if(settings.scene.imageProcessingConfiguration.vignetteWeight){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteWeight = settings.scene.imageProcessingConfiguration.vignetteWeight;
                }
                if(settings.scene.imageProcessingConfiguration.vignetteCameraFov){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteCameraFov = settings.scene.imageProcessingConfiguration.vignetteCameraFov;
                }
            }
        }

        if (settings.light) {
            const light = new HemisphericLight("HemiLight", new Vector3(0, 1, 0), this.sceneHelper().get());
            if (settings.light.groundColor) {
                light.groundColor = Color3.FromHexString(settings.light.groundColor);
            }
            if (settings.light.intensity) {
                light.intensity = settings.light.intensity;
            }
        }

        const valueRadiusLowerLimit = settings.camera.radiusLowerLimitMobile ? settings.camera.radiusLowerLimitMobile :  settings.camera.radiusLowerLimit;
        const radiusLowerLimit = !device.isDesktop() ? valueRadiusLowerLimit : settings.camera.radiusLowerLimit;

        const dollHouse = helper.createDefaultCamera('defaultCamera', {
            alpha: Tools.ToRadians(settings.camera.alpha),
            beta: Tools.ToRadians(settings.camera.beta),
            radius: settings.camera.radius,
            fov: settings.camera.fov,
            target: new Vector3(...settings.camera.target),
            radiusUpperLimit: settings.camera.radiusUpperLimit,
            radiusLowerLimit: radiusLowerLimit,
            panningDistanceLimit: settings.camera.panningDistanceLimit
        }, this.sceneHelper().get(), payload.canvasReference);
        this.cameraHelper()?.setDollHouseCamera(dollHouse);
        const firstPerson = helper.createVirtualTourCamera(
            'virtualTour',
            new Vector3(...settings.virtualTour.camera.position),
            new Vector3(...settings.virtualTour.camera.target),
            this.sceneHelper().get()
        );
        if (settings.virtualTour.camera.fov){
            firstPerson.fov = settings.virtualTour.camera.fov;
        }
        this.cameraHelper()?.setFirstPersonCamera(firstPerson);

        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        try {
            this.initHelpers('floor');
        } catch (e) {
            console.error(e);
        }
        // const oldCamPosition = Vector3.Zero();
        // this.sceneHelper().get().beforeRender = () => {
        //     this.engineHelper()?.get().resize();
        //     const position = this.cameraHelper()?.getDollHouseCamera().position;
        //     this.cameraHelper().getDollHouseCamera().alpha = this.cameraHelper()?.getDollHouseCamera().alpha%(Math.PI*2);
        //     if (position && !position.equals(oldCamPosition)) {
        //         const selector = $('.pinInfo');
        //         if (selector.is(':visible')) {
        //             selector.hide();
        //             this._floorHighlightHelper?.hideHighlights();
        //             this._floorHighlightHelper?.resetPinRotation();
        //             this._floorHighlightHelper?.pinMeshes.forEach((pinMesh:any) =>{
        //                 if (pinMesh.spriteActive){
        //                     pinMesh.spriteActive = false;
        //
        //                     this._floorHighlightHelper?.showPinMeshDescription(pinMesh, false);
        //                     // this._floorHighlightHelper?.setHighlightVisibility(pinMesh.meshToLink, 0);
        //                     pinMesh!.material!.emissiveColor = new Color3(0,0,0);
        //                     pinMesh!.material.emissiveTexture.wAng = 0;
        //                 }
        //             })
        //         }
        //         this.destroyHighlightTooltip();
        //         this.floorInteractionHelper()?.resetHighlights();
        //         oldCamPosition.copyFrom(position);
        //     }
        // }


        const oldCamPosition = Vector3.Zero();
        const oldCamPosition1 = Vector3.Zero();
        const oldCamRotation = Vector3.Zero();
        this.sceneHelper().get().beforeRender = () => {
            this.engineHelper()?.get().resize();
            const position = this.cameraHelper()?.getDollHouseCamera().position;
            this.cameraHelper().getDollHouseCamera().alpha = this.cameraHelper()?.getDollHouseCamera().alpha%(Math.PI*2);
            const positionVT = this.cameraHelper()?.getFirstPersonCamera().position;
            const rotationVT = this.cameraHelper()?.getFirstPersonCamera().rotation;
            if ((position && !position.equals(oldCamPosition)) || (positionVT && !positionVT.equals(oldCamPosition1)) || (rotationVT && !rotationVT.equals(oldCamRotation)) ) {
                const selector = $('.pinInfo');
                if (selector.is(':visible')) {
                    // selector.hide();
                    // this._floorHighlightHelper?.hideHighlights();
                    // this._floorHighlightHelper?.pinMeshes.forEach((pinMesh:any) =>{
                    //     if (pinMesh.spriteActive){
                    //         this._floorHighlightHelper?.showPinMeshDescription(pinMesh, false);
                    //     }
                    // })

                    selector.hide();
                    this._floorHighlightHelper?.hideHighlights();
                    this._floorHighlightHelper?.resetPinRotation();
                    this._floorHighlightHelper?.pinMeshes.forEach((pinMesh:any) =>{
                        if (pinMesh.spriteActive){
                            pinMesh.spriteActive = false;

                            this._floorHighlightHelper?.showPinMeshDescription(pinMesh, false);
                            // this._floorHighlightHelper?.setHighlightVisibility(pinMesh.meshToLink, 0);
                            pinMesh!.material!.emissiveColor = new Color3(0,0,0);
                            pinMesh!.material.emissiveTexture.wAng = 0;
                        }
                    })
                }
                this.destroyHighlightTooltip();
                this.floorInteractionHelper()?.resetHighlights();

                oldCamPosition.copyFrom(position);
                oldCamPosition1.copyFrom(positionVT);
                oldCamRotation.copyFrom(rotationVT);
            }
        }


        console.log('initFloor')
        this.sceneHelper().get().executeWhenReady(() => {
            console.log('scene ready')
            this._fitoutDetails = this._floorInteractionHelper?.getFitoutDetails();
            execWhenReadyFunc();
            if (payload.lodEnabled) {
                helper.lodTextures(this.sceneHelper().get(), this._floorInteractionHelper?.floorSettings.path);
            }
        });

        this._floorInteractionHelper?.loadObjects();

        this._floorHighlightHelper!.pinsData = this._pinsData;

        //this._floorHighlightHelper?.showPins(true);
        //this._floorHighlightHelper?.showPinMeshes(true, FloorViewMode.TWO_DIMENSIONAL);
    }

    initCustomModel(payload: any, execWhenReadyFunc = () => {}) {
        this._canvasElement = payload.canvasReference;
        this._cdnBase = payload.cdnBase;
        this._activeProject = payload.activeProject;
        this._client = payload.client;
        this._floorSpaceData = {
            space: payload.space,
            building: payload.building,
            floor: payload.floor,
            floorType: payload.floorType,
            spaceData: payload.spaceData,
        };
        this._pinsInfo = payload.pinsInfo;

        const engineOptions: EngineOptions = {
            preserveDrawingBuffer: true,
            stencil: true
        };

        //this.engineHelper().new(payload.canvasReference, engineOptions);
        this.sceneHelper().new(this.engineHelper().get());

        this._settings = payload.settings;
        const settings: any = payload.settings.floor;

        if (settings.hdr) {
            let hdrTexture: any = {};
            if (settings.hdr.texture) {
                hdrTexture = CubeTexture.CreateFromPrefilteredData(`${payload.cdnBase}${settings.hdr.texture}`, this.sceneHelper().get());
                hdrTexture.gammaSpace = false;
            } else if (settings.hdr.env) {
                hdrTexture = CubeTexture.CreateFromPrefilteredData(`${payload.cdnBase}${settings.hdr.env}`, this.sceneHelper().get());
            }
            this.sceneHelper().get().environmentTexture = hdrTexture as CubeTexture;
            if (settings.hdr.level) {
                hdrTexture.level = settings.hdr.level
            }
        }

        this.sceneHelper().get().ambientColor = new Color3(0, 0, 0);
        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        if(settings.scene && settings.scene.clearColor){
            this.sceneHelper().get().clearColor = new Color4(...settings.scene.clearColor);
        };
        if(settings.scene && settings.scene.environmentIntensity){
            this.sceneHelper().get().environmentIntensity = settings.scene.environmentIntensity;
        };
        if(settings.scene && settings.scene.imageProcessingConfiguration){
            if(settings.scene.imageProcessingConfiguration.contrast){
                this.sceneHelper().get().imageProcessingConfiguration.contrast = settings.scene.imageProcessingConfiguration.contrast;
            };
            if(settings.scene.imageProcessingConfiguration.vignetteEnabled){
                this.sceneHelper().get().imageProcessingConfiguration.vignetteEnabled = settings.scene.imageProcessingConfiguration.vignetteEnabled;
                if(settings.scene.imageProcessingConfiguration.vignetteWeight){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteWeight = settings.scene.imageProcessingConfiguration.vignetteWeight;
                }
                if(settings.scene.imageProcessingConfiguration.vignetteCameraFov){
                    this.sceneHelper().get().imageProcessingConfiguration.vignetteCameraFov = settings.scene.imageProcessingConfiguration.vignetteCameraFov;
                }
            }
        };

        if (settings.light) {
            const light = new HemisphericLight("HemiLight", new Vector3(0, 1, 0), this.sceneHelper().get());
            if (settings.light.groundColor) {
                light.groundColor = Color3.FromHexString(settings.light.groundColor);
            }
            if (settings.light.intensity) {
                light.intensity = settings.light.intensity;
            }
        }

        const valueRadiusLowerLimit = settings.camera.radiusLowerLimitMobile ? settings.camera.radiusLowerLimitMobile :  settings.camera.radiusLowerLimit;
        const radiusLowerLimit = !device.isDesktop() ? valueRadiusLowerLimit : settings.camera.radiusLowerLimit;

        const dollHouse = helper.createDefaultCamera('defaultCamera', {
            alpha: Tools.ToRadians(settings.camera.alpha),
            beta: Tools.ToRadians(settings.camera.beta),
            radius: settings.camera.radius,
            fov: settings.camera.fov,
            target: new Vector3(...settings.camera.target),
            radiusUpperLimit: settings.camera.radiusUpperLimit,
            radiusLowerLimit: radiusLowerLimit,
            panningDistanceLimit: settings.camera.panningDistanceLimit
        }, this.sceneHelper().get(), payload.canvasReference);
        this.cameraHelper()?.setDollHouseCamera(dollHouse);
        const firstPerson = helper.createVirtualTourCamera(
            'virtualTour',
            new Vector3(...settings.virtualTour.camera.position),
            new Vector3(...settings.virtualTour.camera.target),
            this.sceneHelper().get()
        );
        this.cameraHelper()?.setFirstPersonCamera(firstPerson);

        // custom model debugger
        //this.sceneHelper().get().debugLayer.show();

        this.sceneHelper().get().clearColor = new Color4(0, 0, 0, 0);

        try {
            this.initHelpers('floor');
        } catch (e) {
            console.error(e);
        }
        const oldCamPosition = Vector3.Zero();
        const oldCamPosition1 = Vector3.Zero();
        const oldCamRotation = Vector3.Zero();
        this.sceneHelper().get().beforeRender = () => {
            this.engineHelper()?.get().resize();
            const position = this.cameraHelper()?.getDollHouseCamera().position;
            this.cameraHelper().getDollHouseCamera().alpha = this.cameraHelper()?.getDollHouseCamera().alpha%(Math.PI*2);
            const positionVT = this.cameraHelper()?.getFirstPersonCamera().position;
            const rotationVT = this.cameraHelper()?.getFirstPersonCamera().rotation;
            if ((position && !position.equals(oldCamPosition)) || (positionVT && !positionVT.equals(oldCamPosition1)) || (rotationVT && !rotationVT.equals(oldCamRotation)) ) {
                const selector = $('.pinInfo');
                if (selector.is(':visible')) {
                    selector.hide();
                    this._floorHighlightHelper?.hideHighlights();
                    this._floorHighlightHelper?.pinMeshes.forEach((pinMesh:any) =>{
                        if (pinMesh.spriteActive){
                            this._floorHighlightHelper?.showPinMeshDescription(pinMesh, false);
                        }
                    })
                }
                oldCamPosition.copyFrom(position);
                oldCamPosition1.copyFrom(positionVT);
                oldCamRotation.copyFrom(rotationVT);
            }
        }
        this.sceneHelper().get().executeWhenReady(() => {
            this._fitoutDetails = this._floorInteractionHelper?.getFitoutDetails();

            if (settings.activeFitout && settings.activeFitout.name && this._fitoutDetails.fitoutOptions){
                this._fitoutDetails.fitoutOptions.forEach(opt=>{
                    if(opt['name'] === settings.activeFitout.name){
                        this._floorInteractionHelper?.loadFitout(settings.activeFitout.name);
                        return
                    }
                })
            }

            execWhenReadyFunc();
        });

        this._floorInteractionHelper?.loadObjects();

        this._floorHighlightHelper?.showPins(true);
    }

    run() {
        console.log('run');
        const self = this;
        this.engineHelper()?.get().runRenderLoop(function () {
            self.sceneHelper()?.get().render();
        });
    }

    destroy() {
        this.cameraHelper() && this.cameraHelper().destroy();
        this.sceneHelper() && this.sceneHelper().destroy();
        this.engineHelper() && this.engineHelper().destroy();
        if (this._canvasElement) {
            const context = this._canvasElement.getContext('webgl2') || this._canvasElement.getContext('webgl');
            if (context) {
                const red = 1;
                const green = 1;
                const blue = 1;
                const alpha = 0;
                context.clearColor(red, green, blue, alpha);
                context.clear(context.COLOR_BUFFER_BIT);
            }
        }
        this._canvasElement = null;
        this.destroyHelpers();
    }

    initHelpers(type: string = 'building') {
        if (['building'].includes(type)) {
            // Load building helpers
        }
     //   if (['floor'].includes(type)) {
            // Load floor helpers

            this._floorHighlightHelper = new FloorHighlightsHelper({
                space: this._space
            }, this.engineHelper(), this.sceneHelper(), this.cameraHelper(), this._pinsInfo);
            this._floorInteractionHelper = new FloorInteractionHelper({
                space: this._floorSpaceData,
                settings: this._settings,
                cdnBase: this._cdnBase,
                activeProject: this._activeProject,
                client: this._client,
            }, this._floorHighlightHelper, this.engineHelper(), this.sceneHelper(), this.cameraHelper());

            this._virtualTourHelper = new VirtualTourHelper({
                canvasElement: this._canvasElement,
                space: this._floorSpaceData,
                cdnBase: this._cdnBase,
                settings: this._settings
            }, this._floorInteractionHelper, this.engineHelper(), this.sceneHelper(), this.cameraHelper());
     //   }
    }

    destroyHelpers() {
        this._buildingInteractionHelper = null;
        this._floorHighlightHelper = null;
        this._floorInteractionHelper = null;
        this._virtualTourHelper = null;
    }

    buildingInteractionHelper() {
        return this._buildingInteractionHelper;
    }
    floorHighlightHelper() {
        return this._floorHighlightHelper;
    }
    floorInteractionHelper() {
        return this._floorInteractionHelper;
    }
    virtualTourHelper() {
        return this._virtualTourHelper;
    }

    destroyHighlightTooltip() {
        $(`.space-info-tooltip`).remove();
    }


}

const BabylonInitializeHelper = new BabylonInitializeHelperDefinition();

export {
    EngineHelper,
    SceneHelper,
    CameraHelper,
    BabylonInitializeHelper
};