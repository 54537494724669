<template>
  <div :class="{'organism-sidebar': true, 'open': isOpen}">
    <slot></slot>
  </div>
</template>

<script>
import {baseConstants} from '../../store/modules'
export default {
  name: "OrganismSidebar",
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen;
    }
  }
}
</script>

<style lang="scss">
  .organism-sidebar {
    .subtitle {
      color: rgba(0,0,0, 0.3);
      margin-bottom: 1.563rem;
      font-size: 1.4rem;
      @media only screen and (max-width: 767px) {
        font-size: 1.125rem;
      }
      &::after, &::before {
        display: inline-block;
        content: "";
        border-top: 0.2rem solid rgba(0,0,0, 0.3);
        width: 4rem;
        margin: 0;
        transform: translateY(-100%);
        @media only screen and (max-width: 767px) {
          width: 0.938rem;
          border-top: 0.188rem solid rgba(0,0,0, 0.3);
        }
      }
      &::after {
        margin-left: 1rem;
        @media only screen and (max-width: 767px) {
          margin-left: 0.313rem;
        }
      }
      &::before {
        margin-right: 1rem;
        @media only screen and (max-width: 767px) {
          margin-right: 0.313rem;
        }
      }
    }
    .separator {
      margin: 1.563rem 0;
      border: 0.125rem solid rgba(0,0,0,0.05);
    }
    .menu {
      position: absolute;
      top: 50%;
      left: 6rem;
      text-align: left;
      transform: translateY(-50%);
      @media only screen and (max-width: 900px) {
        left: 1.75rem;
      }
      @media only screen and (max-width: 767px) {
        left: 0;
        width: 100%;
        padding: 0 1.25rem;
        text-align: center;
      }
      .hidden-lg {
        @media only screen and (max-width: 767px) {
          margin-top: 0.313rem;
        }
      }
      .sidebar-link {
        position: relative;
        display: block;
        margin-top: 0.5rem;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        @include font-main(2.5rem, #2d2750, $regular, 2.5rem);
        @media only screen and (max-width: 767px) {
          margin-top: 0.313rem;
          @include font-main(1.125rem, #2d2750, $regular, 1.375rem);
        }
        &:first-child {
          margin-top: 0;
        }
        &:hover {
          color: #909090;
        }
      }
    }
    .social-links {
      position: absolute;
      bottom: 8rem;
      left: 6rem;
      text-align: left;
      @media only screen and (max-width: 900px) {
        left: 1.75rem;
      }
      @media only screen and (max-width: 767px) {
        bottom: 6.25rem;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .icon {
        font-size: 1.5rem !important;
      }
      a {
        position: relative;
        text-decoration: none;
        @include font-main(0.875rem, $black, $regular, 1.3rem);
        @media only screen and (max-width: 767px) {
          @include font-main(0.75rem, $black, $regular, 1rem);
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #909090;
        }
      }
    }
  }
</style>