var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "link-atom-custom",
      attrs: { href: "" + _vm.href, target: "_blank" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.label))]),
      _vm.icon
        ? _c("AtomIcon", { attrs: { icon: _vm.icon, size: 14 } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }