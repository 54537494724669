var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-team" },
    [
      _c(
        "MoleculeTeam",
        _vm._l(_vm.teamData, function(member, index) {
          return _c("AtomTeam", {
            key: index,
            attrs: {
              image: _vm.resourceUrl(member.image),
              name: member.name,
              urlLinkedin: member.urlLinkedin,
              nr: member.nr,
              position: member.position,
              email: member.email
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }