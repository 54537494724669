var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-availability-drawer" },
    [
      _c(
        "OrganismDrawer",
        {
          ref: "drawer",
          attrs: { "default-open": _vm.defaultOpen },
          on: {
            "drawer:close": function($event) {
              return _vm.$emit("drawer:close")
            }
          }
        },
        [
          _c("MoleculeHorizontalMenu", {
            staticClass: "horizontal-menu",
            attrs: {
              sm: "",
              "tab-data": _vm.tabData,
              "selected-option": _vm.activeTab
            },
            on: { "tab-change": _vm.handleTabChange }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab === "coworking",
                  expression: "activeTab === 'coworking'"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "top-content" },
                [_c("MoleculeFilters")],
                1
              ),
              Object.keys(_vm.finalStructureSpaces).length
                ? _c(
                    "div",
                    { staticClass: "bottom-content" },
                    _vm._l(_vm.finalStructureSpaces, function(building) {
                      return _c("AtomCollapsibleItem", {
                        key: "building_" + building.id,
                        ref: "building_" + building.id,
                        refInFor: true,
                        staticClass: "parent-box",
                        attrs: { item: building },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "title-box" }, [
                                    _c("p", { staticClass: "title-text" }, [
                                      _vm._v(_vm._s(building.title))
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "circle-sum float-right" },
                                      [_vm._v(_vm._s(building.noSpacesResult))]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "content",
                              fn: function() {
                                return _vm._l(building.child, function(floor) {
                                  return _c("AtomCollapsibleItem", {
                                    key: "floor_" + floor.id,
                                    ref: "floor_" + floor.id,
                                    refInFor: true,
                                    class: {
                                      "child-box": true,
                                      "spaces-leased": floor.fullyLeased
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "title-box",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        flex: "3"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "title title-child"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(floor.title)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "subtitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              floor.subtitle
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "flex-end",
                                                        flex: "1"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fully-leased"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              floor.fullyLeased
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "circle-sum"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              floor.noSpacesResult
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "content",
                                          fn: function() {
                                            return _vm._l(floor.child, function(
                                              spaceTypeData,
                                              spaceTypeName
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key:
                                                    floor.id +
                                                    "-" +
                                                    spaceTypeName
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "category-title"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            spaceTypeName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    spaceTypeData,
                                                    function(
                                                      spacesArr,
                                                      childKey
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            "spaces-type-data-" +
                                                            childKey
                                                        },
                                                        [
                                                          spacesArr.length >
                                                            1 &&
                                                          _vm.getFirstAvailableItem(
                                                            spacesArr
                                                          )
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  spacesArr,
                                                                  function(
                                                                    nestedSpaceInfo,
                                                                    kkey
                                                                  ) {
                                                                    return _c(
                                                                      "AtomCollapsibleItem",
                                                                      {
                                                                        key:
                                                                          "nested-space-info-" +
                                                                          kkey,
                                                                        ref:
                                                                          building
                                                                            .buildingDetails
                                                                            .code +
                                                                          "_" +
                                                                          nestedSpaceInfo
                                                                            .spaceDetails
                                                                            .space_code +
                                                                          "_" +
                                                                          nestedSpaceInfo
                                                                            .spaceDetails
                                                                            .id,
                                                                        refInFor: true,
                                                                        class: {
                                                                          "subchild-box": true,
                                                                          "space-leased":
                                                                            nestedSpaceInfo.isLeased
                                                                        },
                                                                        attrs: {
                                                                          "is-default-open": _vm.checkCollapseEvent(
                                                                            building
                                                                              .buildingDetails
                                                                              .code +
                                                                              "_" +
                                                                              nestedSpaceInfo
                                                                                .spaceDetails
                                                                                .space_code +
                                                                              "_" +
                                                                              nestedSpaceInfo
                                                                                .spaceDetails
                                                                                .id,
                                                                            true
                                                                          )
                                                                        },
                                                                        on: {
                                                                          toggle: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.viewOrHideSpace(
                                                                              $event,
                                                                              nestedSpaceInfo
                                                                                .spaceDetails
                                                                                .space_code,
                                                                              building
                                                                                .buildingDetails
                                                                                .code,
                                                                              null,
                                                                              nestedSpaceInfo.spaceDetails,
                                                                              true
                                                                            )
                                                                          }
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "title",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "title-box"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "title"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                nestedSpaceInfo.title
                                                                                              )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "surface"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  nestedSpaceInfo
                                                                                                    .spaceDetails
                                                                                                    .sqm
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                              sqm"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      !nestedSpaceInfo.isLeased
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "surface"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      nestedSpaceInfo
                                                                                                        .spaceDetails
                                                                                                        .workplaces
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                "\n                              " +
                                                                                                  _vm._s(
                                                                                                    nestedSpaceInfo
                                                                                                      .spaceDetails
                                                                                                      .workplaces >
                                                                                                      1
                                                                                                      ? "workplaces"
                                                                                                      : "workplace"
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      nestedSpaceInfo.isLeased
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                              - " +
                                                                                                  _vm._s(
                                                                                                    nestedSpaceInfo.isLeased
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e()
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            },
                                                                            {
                                                                              key:
                                                                                "content",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "content-box"
                                                                                        },
                                                                                        [
                                                                                          (nestedSpaceInfo
                                                                                            .spaceDetails
                                                                                            .available ||
                                                                                            nestedSpaceInfo
                                                                                              .spaceDetails
                                                                                              .is_public) &&
                                                                                          _vm.shortlistState
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "content-buttons"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "AtomButton",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "explore-btn",
                                                                                                      attrs: {
                                                                                                        icon:
                                                                                                          "vr",
                                                                                                        label:
                                                                                                          "Explore in 3D",
                                                                                                        "custom-label":
                                                                                                          "Explore",
                                                                                                        "on-click": function() {
                                                                                                          return _vm.exploreSpace(
                                                                                                            nestedSpaceInfo.id
                                                                                                          )
                                                                                                        },
                                                                                                        "activate-hover-event":
                                                                                                          ""
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          (nestedSpaceInfo
                                                                                            .spaceDetails
                                                                                            .available ||
                                                                                            nestedSpaceInfo
                                                                                              .spaceDetails
                                                                                              .is_public) &&
                                                                                          !_vm.shortlistState
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "content-buttons"
                                                                                                },
                                                                                                [
                                                                                                  _vm.allowViewIn3DButton(
                                                                                                    nestedSpaceInfo.spaceDetails,
                                                                                                    _vm
                                                                                                      .currentSpace
                                                                                                      .floor_id
                                                                                                  )
                                                                                                    ? _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "AtomButton",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "explore-btn",
                                                                                                              attrs: {
                                                                                                                icon:
                                                                                                                  "vr",
                                                                                                                label:
                                                                                                                  "Explore in 3D",
                                                                                                                "custom-label":
                                                                                                                  "Explore",
                                                                                                                "on-click": function() {
                                                                                                                  return _vm.exploreSpace(
                                                                                                                    nestedSpaceInfo.id,
                                                                                                                    nestedSpaceInfo
                                                                                                                      .spaceDetails
                                                                                                                      .project_id
                                                                                                                  )
                                                                                                                },
                                                                                                                "activate-hover-event":
                                                                                                                  ""
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm.allowRequestOfferButton(
                                                                                                    nestedSpaceInfo.spaceDetails,
                                                                                                    _vm
                                                                                                      .currentSpace
                                                                                                      .floor_id
                                                                                                  )
                                                                                                    ? _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "AtomButton",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "request-offer-btn button-green",
                                                                                                              attrs: {
                                                                                                                icon:
                                                                                                                  "plane-paper",
                                                                                                                label:
                                                                                                                  "Request Offer",
                                                                                                                "custom-label":
                                                                                                                  "Request",
                                                                                                                "on-click": function() {
                                                                                                                  return _vm.openRequestOfferModal(
                                                                                                                    nestedSpaceInfo.spaceDetails,
                                                                                                                    building.buildingDetails
                                                                                                                  )
                                                                                                                },
                                                                                                                "activate-hover-event":
                                                                                                                  ""
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e()
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          spacesArr.length === 1
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "AtomCollapsibleItem",
                                                                    {
                                                                      ref:
                                                                        building
                                                                          .buildingDetails
                                                                          .code +
                                                                        "_" +
                                                                        spacesArr[0]
                                                                          .spaceDetails
                                                                          .space_code,
                                                                      refInFor: true,
                                                                      class: {
                                                                        "subchild-box": true,
                                                                        "space-leased":
                                                                          spacesArr[0]
                                                                            .isLeased
                                                                      },
                                                                      attrs: {
                                                                        "is-default-open": _vm.checkCollapseEvent(
                                                                          building
                                                                            .buildingDetails
                                                                            .code +
                                                                            "_" +
                                                                            spacesArr[0]
                                                                              .spaceDetails
                                                                              .space_code
                                                                        )
                                                                      },
                                                                      on: {
                                                                        toggle: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewOrHideSpace(
                                                                            $event,
                                                                            spacesArr[0]
                                                                              .spaceDetails
                                                                              .space_code,
                                                                            building
                                                                              .buildingDetails
                                                                              .code,
                                                                            null,
                                                                            spacesArr[0]
                                                                              .spaceDetails,
                                                                            false
                                                                          )
                                                                        }
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "title",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "title-box"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "title"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            spacesArr[0]
                                                                                              .title
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "surface"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                spacesArr[0]
                                                                                                  .spaceDetails
                                                                                                  .sqm
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                              sqm"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    !spacesArr[0]
                                                                                      .isLeased
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "surface"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "b",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    spacesArr[0]
                                                                                                      .spaceDetails
                                                                                                      .workplaces
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  spacesArr[0]
                                                                                                    .spaceDetails
                                                                                                    .workplaces >
                                                                                                    1
                                                                                                    ? "workplaces"
                                                                                                    : "workplace"
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    spacesArr[0]
                                                                                      .isLeased
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              - " +
                                                                                                _vm._s(
                                                                                                  spacesArr[0]
                                                                                                    .isLeased
                                                                                                )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          },
                                                                          {
                                                                            key:
                                                                              "content",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "content-box"
                                                                                  },
                                                                                  [
                                                                                    spacesArr[0]
                                                                                      .spaceDetails
                                                                                      .available ||
                                                                                    spacesArr[0]
                                                                                      .spaceDetails
                                                                                      .is_public
                                                                                      ? _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "content-buttons"
                                                                                          },
                                                                                          [
                                                                                            _vm.allowViewIn3DButton(
                                                                                              spacesArr[0]
                                                                                                .spaceDetails,
                                                                                              _vm
                                                                                                .currentSpace
                                                                                                .floor_id
                                                                                            )
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "AtomButton",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "explore-btn",
                                                                                                        attrs: {
                                                                                                          icon:
                                                                                                            "vr",
                                                                                                          label:
                                                                                                            "Explore in 3D",
                                                                                                          "custom-label":
                                                                                                            "Explore",
                                                                                                          "on-click": function() {
                                                                                                            return _vm.exploreSpace(
                                                                                                              spacesArr[0]
                                                                                                                .id,
                                                                                                              spacesArr[0]
                                                                                                                .spaceDetails
                                                                                                                .project_id
                                                                                                            )
                                                                                                          },
                                                                                                          "activate-hover-event":
                                                                                                            ""
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm.allowRequestOfferButton(
                                                                                              spacesArr[0]
                                                                                                .spaceDetails,
                                                                                              _vm
                                                                                                .currentSpace
                                                                                                .floor_id
                                                                                            )
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "AtomButton",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "request-offer-btn button-green",
                                                                                                        attrs: {
                                                                                                          icon:
                                                                                                            "plane-paper",
                                                                                                          label:
                                                                                                            "Request Offer",
                                                                                                          "custom-label":
                                                                                                            "Request",
                                                                                                          "on-click": function() {
                                                                                                            return _vm.openRequestOfferModal(
                                                                                                              spacesArr[0]
                                                                                                                .spaceDetails,
                                                                                                              building.buildingDetails
                                                                                                            )
                                                                                                          },
                                                                                                          "activate-hover-event":
                                                                                                            ""
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e()
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            })
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                })
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm.activeTab === "project-info"
            ? _c(
                "div",
                { staticClass: "project-info" },
                [
                  _c("MoleculePopupResult", {
                    attrs: { project: _vm.currentProject }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }