var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.right
    ? _c("div", { staticClass: "atom-arrow-button-slider-right" }, [
        _c(
          "button",
          {
            on: {
              mouseover: _vm.hovered,
              mouseout: _vm.unhovered,
              click: _vm.clickedNext
            }
          },
          [_vm._v(" › ")]
        )
      ])
    : _c("div", { staticClass: "atom-arrow-button-slider-left" }, [
        _c(
          "button",
          {
            on: {
              mouseover: _vm.hovered,
              mouseout: _vm.unhovered,
              click: _vm.clickedPrevious
            }
          },
          [_vm._v(" ‹ ")]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }