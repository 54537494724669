<template>
  <div
    :class="{
      section: true,
      'section-building': true,
      'z-index': loadingScreenActive,
      'embed-ready': isEmbedReady,
      'matterport-space-view': addMatterportClass,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      v-if="loadingScreenActive"
      class="organism-loader-background"
      :style="{
        'background-image': `url(${resourceUrl(backgroundLoaderUrl)})`,
      }"
    >
      <OrganismLoader :content-loader="contentLoaderData" />
    </div>
    <OrganismBuilding
      ref="building"
      :class="{
        active:
          drawerAvailabilityOpen || drawerShortlistOpen || spaceDetailsOpen,
      }"
    />
    <div
      :class="{
        'drawer-buttons': true,
        active:
          drawerAvailabilityOpen || drawerShortlistOpen || spaceDetailsOpen,
      }"
    >
      <div
        class="drawer-button drawer-button-black custom-tooltip home-button"
        @click="handleExpandSpaceDetails"
        v-if="isSpaceRoute"
      >
        <AtomIcon :icon="spaceDetailsOpen ? 'xmark' : 'house'" color="#fff" />
        <p v-if="!spaceDetailsOpen" class="hidden-mobile-elements">
          Space Details
        </p>
        <p v-else class="hidden-mobile-elements">Close</p>
      </div>
      <div
        class="drawer-button drawer-button-black custom-tooltip"
        @click="handleExpandAvailability"
      >
        <AtomIcon
          :icon="drawerAvailabilityOpen ? 'xmark' : 'filter-list'"
          color="#fff"
        />
        <p class="hidden-mobile-elements">Filters & Portfolio</p>
      </div>
      <div
        v-if="shortlistState"
        class="drawer-button drawer-button-white custom-tooltip"
        @click="handleExpandShortlist"
      >
        <AtomIcon
          :icon="drawerShortlistOpen ? 'xmark' : 'shortlist-icon'"
          color="#000"
        />
        <span class="number">{{ requestedSpaces.length }}</span>
        <p class="hidden-mobile-elements">Shortlist</p>
        <div class="info-notification" :class="{ active: openShortlist }">
          <p>Space added to shortlist</p>
          <span>Click Here</span>
        </div>
      </div>
    </div>

    <OrganismSpaceDetails
      ref="spacedetails-drawer"
      :default-open="spaceDetailsOpen"
      v-if="isSpaceRoute"
      @drawer:close="handleCloseSpaceDetails"
    />
    <OrganismAvailabilityDrawer
      ref="availability-drawer"
      :default-open="drawerAvailabilityOpen"
      @drawer:close="handleCloseAvailability"
    />
    <!--    <OrganismShortlistDrawer-->
    <!--        ref="shortlist-drawer"-->
    <!--        :defaultOpen="drawerShortlistOpen"-->
    <!--        v-on:toggleAvailability="handleExpandAvailability"-->
    <!--        @drawer:close="handleCloseShortlist"-->
    <!--    />-->

    <OrganismSelectedSpaceMobile v-if="!isFloorView" />
    <div class="embed-controls" v-if="isEmbedReady && !isSpaceEmbedRoute">
      <div
        class="control embed-menu-trigger"
        :class="{ active: isMobileMenuOpen }"
        @click="toggleEmbedSidebar"
      >
        <div class="description">
          <span class="custom-arrow"></span>
          <p class="label"><b>Spaces</b></p>
          <p class="text">Check Availability</p>
        </div>
      </div>
      <div
        class="control embed-cart-trigger"
        :class="{ active: embedCartIsOpen }"
        @click="toggleCartSidebar"
      >
        <div class="description">
          <span class="custom-arrow"></span>
          <p class="label green-color">Offer & Space List</p>
          <p class="text">
            <AtomIcon icon="ruler" />
            <b>{{ totalSpace }}</b>
            {{ unitOfMeasureString }} GLA
          </p>
        </div>
      </div>
    </div>
    <OrganismSpace :matterport-space="data.matterportSpace" />

    <span class="scroll-element" @click="goToNextSection"
      >Click here or scroll down for more information</span
    >

    <a class="footer-bright" href="https://brightspaces.tech/" target="_blank"
      >Created by ©BrightSpaces</a
    >

    <div class="orientation-nav" v-if="isSpaceRoute && space && spaceName">
      <div
        class="molecule-building-orientation-bar"
        :class="{ 'nav-space-details-open': spaceDetailsOpen }"
      >
        <div class="info-space">
          <p v-if="spaceName && space.space_code">
            Area: <b>{{ spaceName }} {{ space.alias || space.space_code }}</b>
          </p>
          <span v-if="surface"
            ><b>{{ surface }}</b> {{ unitOfMeasureString }}</span
          >
          <span v-if="spaceWorkplaces"
            ><b>{{ spaceWorkplaces }}</b> {{ spaceWorkplacesName }}</span
          >
        </div>
        <span class="vertical-line"></span>
        <div class="content-buttons" v-if="space">
          <div v-if="allowViewIn3DButton(space, currentSpace.floor_id)">
            <AtomButton
              class="explore-btn"
              icon="vr"
              label="Explore in 3D"
              custom-label="Explore"
              :on-click="() => exploreSpace(space.id, space.project_id)"
              activate-hover-event
            />
          </div>
          <div v-if="allowRequestOfferButton(space, currentSpace.floor_id)">
            <AtomButton
              class="request-offer-btn button-green"
              icon="plane-paper"
              label="Request Offer"
              custom-label="Request"
              :on-click="() => openRequestOfferModal(space, space.building_id)"
              activate-hover-event
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isSpaceRoute"
      class="drawer-button home-button-mobile"
      :class="{ active: spaceDetailsOpen }"
      @click="handleExpandSpaceDetails"
    >
      <AtomIcon icon="arrow-up" color="#fff" />
    </div>
  </div>
</template>

<script>
import OrganismBuilding from "../../../components/organisms/project/building/OrganismBuilding";
import OrganismSpace from "../../../components/organisms/project/building/OrganismSpace";
import OrganismLoader from "../../../components/organisms/project/OrganismLoader";
import { CurrentView } from "../../../components/organisms/project/building/store";
import AtomIcon from "../../../components/atoms/common/AtomIcon";
import { smoothScrollToTargetId } from "../../../helpers/util";
import OrganismSelectedSpaceMobile from "../../../components/organisms/project/building/OrganismSelectedSpaceMobile";
import OrganismAvailabilityDrawer from "../../../components/organisms/common/availability/OrganismAvailabilityDrawer";
import OrganismSpaceDetails from "../../../components/organisms/common/availability/OrganismSpaceDetails";
import { BabylonSceneManager } from "../../../components/organisms/project/building/store/3d/managers/SceneManager";
import AtomButton from "@/components/atoms/common/AtomButton";

export default {
  name: "SectionBuilding",
  components: {
    AtomButton,
    OrganismSpaceDetails,
    OrganismAvailabilityDrawer,
    OrganismSelectedSpaceMobile,
    AtomIcon,
    OrganismLoader,
    OrganismSpace,
    OrganismBuilding,
  },
  props: {
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {
          backgroundLoader: "",
          contentLoader: [],
          matterportSpace: [],
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    const routeNames = [
      "Space",
      "SpecificProjectSpace",
      "SpecificProjectSpaceEmbed",
      "SpecificSpace",
      "SpecificSpaceEmbed",
    ];
    const isSpaceRoute = routeNames.includes(this.$route.name);
    return {
      isFloorView: false,
      loadingScreenActive: true,
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      headerHeight: 0,
      embedCartIsOpen: false,
      hasMounted: false,
      drawerAvailabilityOpen: !isSpaceRoute,
      drawerShortlistOpen: false,
      spaceDetailsOpen: isSpaceRoute,
    };
  },
  computed: {
    activeProjectSlug() {
      return this.$store.state.project?.activeProject || null;
    },
    activeProject() {
      return (
        this.$store.state.base.landlordDetails?.projects.find(
          (item) => item.slug === this.activeProjectSlug
        ) || null
      );
    },
    activeSpace() {
      return (
        parseInt(this.$route.params.spaceId) ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot
      );
    },
    shortlistState() {
      return this.$store.getters?.getShortlist || false;
    },
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen;
    },
    contentLoaderData() {
      return this.data.contentLoader;
    },
    backgroundLoaderUrl() {
      return this.data.backgroundLoader;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
    isSpaceEmbedRoute() {
      return ["SpecificSpaceEmbed", "SpecificProjectSpaceEmbed"].includes(
        this.$route.name
      );
    },
    isSpaceRoute() {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      return routeNames.includes(this.$route.name);
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    totalSpace() {
      let total = 0;
      this.requestedSpaces.forEach((s) => {
        total += s.sqm;
      });
      return total;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    addMatterportClass() {
      return (
        (this.$store.state.base?.meta?.general?.matterportEnabled &&
          this.isFloorView) ||
        false
      );
    },
    openShortlist() {
      return this.$store.state?.universe?.shortlistInfo?.open || false;
    },
    currentSpaceId() {
      return this.$store.state.space.currentSpaceId;
    },
    landlordDetails() {
      return this.$store.state.base.landlordDetails;
    },
    space() {
      let space = null;
      const spaceId =
        this.currentSpaceId ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot;
      if (spaceId) {
        space = this.landlordDetails?.spaces?.find(
          (s) => s.id === parseInt(spaceId)
        );
        if (!this.sceneManager) return;
        if (!space || !space.space_code) return;
        this.sceneManager.highlightSpace(space.space_code);
      } else {
        if (!this.sceneManager) return;
        this.sceneManager.highlightSpace(null);
      }
      return space;
    },
    currentSpace() {
      return this.landlordDetails?.spaces?.find(
        (s) => s.id === this.activeSpace
      );
    },
    surface() {
      const s = this.space?.sqm || 0;
      if (s === 0) return;
      return `${s}`;
    },
    spaceName() {
      let spaceType = "Other Type";
      if (!this.space?.space_type) return spaceType;
      const spaceTypeName = this.space.space_type.name || "";
      if (spaceTypeName.toLowerCase().includes("office")) {
        spaceType = "Office";
      } else if (spaceTypeName === "Meeting room") {
        spaceType = "Meeting Room";
      } else if (spaceTypeName === "Open Space") {
        spaceType = "Open Space";
      }
      return spaceType;
    },
    spaceWorkplaces() {
      return this.space?.workplaces || 0;
    },
    spaceWorkplacesName() {
      if (this.spaceWorkplaces === 0) return `No workstations`;
      if (this.spaceWorkplaces === 1) return `Workstation`;
      if (this.spaceWorkplaces > 1) return `Workstations`;
    },
  },
  methods: {
    allowViewIn3DButton(space, currentSpaceFloorId) {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      if (!routeNames.includes(this.$route.name)) return true;
      return !(
        this.activeProject.id === space.project_id &&
        space.floor_id === currentSpaceFloorId
      );
    },
    allowRequestOfferButton(space, currentSpaceFloorId) {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      if (!routeNames.includes(this.$route.name)) return true;
      return (
        this.activeProject.id === space.project_id &&
        space.floor_id === currentSpaceFloorId
      );
    },
    openRequestOfferModal(space, building) {
      const buildingStore =
        this.$store.state.base?.landlordDetails?.buildings?.find(
          (b) => b.id === building
        ) || null;
      const spaceFloor =
        this.$store.state.base?.landlordDetails?.floors?.find(
          (f) => f.id === space.floor_id
        ) || null;
      const spaceData = {
        spaceId: space?.space_code || -1,
        building: buildingStore?.code || -1,
        floor: spaceFloor?.code || -1,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
        null,
        { root: true }
      );
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );

      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "request-offer",
          data: { space: { ...space, building: building } },
        }
      );
      document.body.classList.add("disable-scroll");
    },
    exploreSpace(spaceId, spaceProjectId) {
      const project =
        this.$store.state?.project?.projects?.find(
          (p) => p.id === spaceProjectId
        ) || null;
      if (project?.slug !== this.activeProjectSlug) {
        const projectConstants = this.$store.getters.constants.project;
        this.$store.dispatch(
          projectConstants.withNamespace(
            projectConstants.action.CHANGE_ACTIVE_PROJECT
          ),
          project.slug
        );
      }
      const routePageData = this.$route.meta.page;
      this.$router.push({
        name: routePageData.isEmbedReady
          ? "SpecificSpaceEmbed"
          : "SpecificSpace",
        params: {
          projectId: this.activeProjectSlug,
          spaceId,
        },
      });
    },
    handleCloseAvailability() {
      this.$refs["availability-drawer"]?.closeDrawer();
      this.drawerAvailabilityOpen = false;
      this.changeCameraTargetOffset();
    },
    handleCloseShortlist() {
      this.$refs["shortlist-drawer"]?.closeDrawer();
      this.drawerShortlistOpen = false;
      this.changeCameraTargetOffset();
    },
    handleCloseSpaceDetails() {
      this.$refs["spacedetails-drawer"]?.closeDrawer();
      this.spaceDetailsOpen = false;
      this.changeCameraTargetOffset();
    },
    handleExpandAvailability() {
      this.$refs["availability-drawer"]?.toggleDrawer();
      this.drawerAvailabilityOpen = !this.drawerAvailabilityOpen;
      if (this.drawerAvailabilityOpen) {
        this.handleCloseShortlist();
        this.handleCloseSpaceDetails();
      }
      this.changeCameraTargetOffset();
    },
    handleExpandShortlist() {
      this.$refs["shortlist-drawer"]?.toggleDrawer();
      this.drawerShortlistOpen = !this.drawerShortlistOpen;
      if (this.drawerShortlistOpen) {
        this.handleCloseAvailability();
        this.handleCloseSpaceDetails();
      }
      this.changeCameraTargetOffset();
    },
    handleExpandSpaceDetails() {
      this.$refs["spacedetails-drawer"]?.toggleDrawer();
      this.spaceDetailsOpen = !this.spaceDetailsOpen;
      if (this.spaceDetailsOpen) {
        this.handleCloseAvailability();
        this.handleCloseShortlist();
      }
      this.changeCameraTargetOffset();
    },
    sidebarHideMobile() {
      if (window.innerWidth < 1000) {
        this.drawerAvailabilityOpen = false;
        this.spaceDetailsOpen = false;
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) end = dc.length;
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    goToNextSection() {
      const sections = this.$parent.$parent.sections;
      const targetSection = sections[this.orderNo];
      const targetString = targetSection.hashAnchor;
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
      this.headerHeight = document.getElementById("header-project")
        ? document.getElementById("header-project").clientHeight
        : 0;
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    toggleEmbedSidebar() {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
      );
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
        false
      );
      this.embedCartIsOpen = false;
    },
    toggleCartSidebar() {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
        false
      );
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      );
      this.embedCartIsOpen = !this.embedCartIsOpen;
    },
    getElementHalfWidth(target) {
      const sidebarElement = document.querySelector(target);
      if (sidebarElement instanceof HTMLElement) {
        const sidebarElementWidth = sidebarElement.offsetWidth;
        return sidebarElementWidth / 1.5;
      }
      return 0;
    },
    changeCameraTargetOffset() {
      if (!this.isFloorView || window.innerWidth < 767) return;

      const move =
        this.drawerShortlistOpen ||
        this.spaceDetailsOpen ||
        this.drawerAvailabilityOpen;
      const half = this.getElementHalfWidth(".drawer-content");
      this.sceneManager.changeCameraTargetScreenOffset(move ? -half : 0, 0);
    },
  },
  mounted() {
    let self = this;
    this.$refs.building.buildingStore.subscribe((payload, state) => {
      this.isFloorView = state.currentView === CurrentView.FLOOR;
      this.loadingScreenActive = state.loadingScreenActive;
    });
    window.addEventListener("resize", this.resizeListener);
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
    this.sceneManager = BabylonSceneManager;
    setTimeout(() => {
      this.changeCameraTargetOffset();
    }, 1500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  beforeMount() {
    this.sidebarHideMobile();
  },
};
</script>

<style lang="scss">
.section-building {
  position: relative;
  overflow: hidden;
  margin: 0 -0.625rem;
  background: #f7f7f7;
  z-index: 1;
  &.z-index {
    z-index: 99999999;
  }
  .atom-info-popup {
    &.building-canvas-info-desktop {
      margin-left: -21rem;
      top: 6rem;
      transform: translateX(-50%);
    }
    &.building-2D-3D-info-desktop {
      bottom: 0.1rem;
      top: initial;
      transform: none;
      left: 1.25rem;
      &:before {
        content: "";
        display: block;
        border-style: solid;
        height: 0;
        left: 7.1rem;
        bottom: 100%;
        width: 0;
        position: absolute;
        border-color: transparent transparent #e2eaec transparent;
        border-width: 0 0.375rem 0.5rem 0.375rem;
      }
    }
    &.building-VT-info-desktop {
      display: none;
      bottom: 0.1rem;
      top: initial;
      transform: none;
      left: 1.25rem;
      &:before {
        content: "";
        display: block;
        border-style: solid;
        height: 0;
        left: 7.1rem;
        bottom: 100%;
        width: 0;
        position: absolute;
        border-color: transparent transparent #e2eaec transparent;
        border-width: 0 0.375rem 0.5rem 0.375rem;
      }
    }
    @media only screen and (max-width: 767px) {
      &.building-filter-info {
        left: initial;
        top: 4.688rem;
        right: 0.625rem;
        transform: none;
        text-align: left;
        &:before {
          content: "";
          display: block;
          border-style: solid;
          height: 0;
          right: 1.188rem;
          bottom: 100%;
          width: 0;
          position: absolute;
          border-color: transparent transparent #e2eaec transparent;
          border-width: 0 0.375rem 0.5rem 0.375rem;
        }
      }
      &.building-language-info {
        left: 0.625rem;
        top: 4.688rem;
        right: initial;
        transform: none;
        text-align: left;
        &:before {
          content: "";
          display: block;
          border-style: solid;
          height: 0;
          left: 1.688rem;
          bottom: 100%;
          width: 0;
          position: absolute;
          border-color: transparent transparent #e2eaec transparent;
          border-width: 0 0.375rem 0.5rem 0.375rem;
        }
      }
      &.building-highlight-info {
        display: none;
        top: 75px;
        transform: translateX(-50%);
        z-index: 9991;
      }
      &.building-apartment-info {
        left: initial;
        right: 50%;
        bottom: 14.375rem;
        transform: translateX(50%);
        margin-right: -2.063rem;
        top: initial;
        &:before {
          content: "";
          display: block;
          border-style: solid;
          height: 0;
          right: 1.375rem;
          top: 100%;
          width: 0;
          position: absolute;
          border-color: #e2eaec transparent transparent transparent;
          border-width: 0.5rem 0.375rem 0 0.375rem;
        }
      }
      &.building-2D-3D-info {
        display: none;
        bottom: 10.313rem;
        top: initial;
        transform: translateX(-50%);
        left: 50%;
        margin-left: -0.375rem;
        &:before {
          content: "";
          display: block;
          border-style: solid;
          height: 0;
          left: 4.688rem;
          top: 100%;
          width: 0;
          position: absolute;
          border-color: #e2eaec transparent transparent transparent;
          border-width: 0.5rem 0.375rem 0 0.375rem;
        }
      }
      &.building-VT-info {
        display: none;
        bottom: 10.313rem;
        top: initial;
        transform: translateX(-50%);
        left: 50%;
        &:before {
          content: "";
          display: block;
          border-style: solid;
          height: 0;
          left: 8.563rem;
          top: 100%;
          width: 0;
          position: absolute;
          border-color: #e2eaec transparent transparent transparent;
          border-width: 0.5rem 0.375rem 0 0.375rem;
        }
      }
    }
  }
  .organism-building {
    transition: all 0.2s ease-in-out 0s;
    &.active {
      width: calc(100% - 30rem);
      @media only screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  .orientation-nav {
    position: absolute;
    bottom: 3.35rem;
    left: 1.25rem;
    z-index: 5;
    @media only screen and (max-width: 767px) {
      top: initial;
      bottom: 4.188rem;
      left: 50%;
      transform: translateX(-50%);
      width: 20.875rem;
      max-width: 95%;
      &.nav-space-details-open {
        .mobile-app-info {
          background: #e5eaed;
        }
      }
    }
    .molecule-building-orientation-bar {
      background: $white;
      border-radius: 6.25rem;
      padding: 0.375rem;
      &.nav-space-details-open {
        @media only screen and (max-width: 767px) {
          background: #e5eaed;
        }
      }
      @media only screen and (max-width: 767px) {
        border-radius: 0;
        padding: 0.375rem;
      }
      .info-space {
        display: inline-block;
        vertical-align: middle;
        margin: 0 1.5rem 0 0.75rem;
        @media only screen and (max-width: 767px) {
          margin: 0 0.938rem 0 0.5rem;
        }
        p,
        span {
          @include font-main(0.75rem, $black, $regular, 0.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem, $black, $regular, 1.188rem);
          }
          b {
            @include font-main(0.75rem, $black, $bold, 0.875rem);
            @media only screen and (max-width: 767px) {
              @include font-main(0.875rem, $black, $bold, 1.188rem);
            }
          }
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .vertical-line {
        display: inline-block;
        vertical-align: middle;
        height: 1.25rem;
        width: 0.125rem;
        background: black;
        border-radius: 0.125rem;
        margin-right: 1rem;
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
      .content-buttons {
        display: inline-block;
        vertical-align: middle;
        @media only screen and (max-width: 767px) {
          display: none !important;
        }
        .explore-btn {
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.25rem;
          background: $black;
          border-color: $black;
          .icon {
            display: none;
            color: $white !important;
            margin-right: 0.5rem;
          }
          &:hover {
            background: #23cd9a;
            .icon {
              display: inline-block;
            }
            span {
              color: $white;
            }
          }
        }
        .request-offer-btn {
          border-radius: 0.25rem;
          &.button-green {
            border: none;
            background: #23cd9a;
            .icon {
              display: none;
              margin-right: 0.5rem;
            }
            span {
              color: $white;
            }
            &:hover {
              background: $black;
              .icon {
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
          }
        }
        .button-atom {
          border-radius: 6.25rem;
          padding: 0.25rem 0.45rem;
          height: 2.5rem;
          min-width: 12rem;
          text-transform: none;
          width: 100%;
          @media only screen and (max-width: 767px) {
            height: 1.875rem;
          }
          .icon {
            @media only screen and (max-width: 767px) {
              font-size: 0.75rem !important;
            }
          }
          span {
            text-transform: none;
            @media only screen and (max-width: 767px) {
              font-size: 0.75rem;
              line-height: 1.125rem;
            }
          }
        }
      }
    }
  }
  .molecule-filters {
    .filters {
      padding: 0 0 1rem;
    }
  }
  .drawer-buttons {
    cursor: pointer;
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease-in-out 0s;
    z-index: 999999;
    @media only screen and (max-width: 767px) {
      top: 1rem;
      right: 0.625rem;
      z-index: 1;
    }
    &.active {
      right: 31rem;
      @media only screen and (max-width: 767px) {
        right: 0.625rem;
      }
    }
  }
  .drawer-button {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out 0s;
    z-index: 999;
    position: relative;
    margin-bottom: 1rem;
    @media only screen and (max-width: 767px) {
      &.home-button {
        display: none;
      }
    }
    .number {
      position: absolute;
      top: -0.313rem;
      right: 0;
      transform: translate(25%);
      background: rgba(35, 205, 154, 1);
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.875rem;
    }
    .icon {
      font-size: 0.8rem !important;
      transition: none;
      &:before {
        margin: 0;
        width: auto;
      }
      @media only screen and (max-width: 767px) {
        font-size: 0.875rem !important;
      }
      &.icon-xmark {
        font-size: 1.3rem !important;
        @media only screen and (max-width: 767px) {
          font-size: 0.875rem !important;
        }
      }
    }
    &.drawer-button-black {
      background: $black;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.625rem;
      }
    }
    &.drawer-button-white {
      background: $white;
    }
    &.active {
      background: rgba(0, 0, 0, 0.5);
    }
    &.drawer-button-black:hover {
      background: rgba(0, 0, 0, 0.9);
      @media only screen and (max-width: 1200px) {
        background: $black;
      }
      .icon {
        color: $white !important;
        @media only screen and (max-width: 1200px) {
          color: $white !important;
        }
      }
    }
    &.drawer-button-white:hover {
      background: rgba(255, 255, 255, 0.9);
      @media only screen and (max-width: 1200px) {
        background: $white;
      }
      .icon {
        color: $black !important;
        @media only screen and (max-width: 1200px) {
          color: $black !important;
        }
      }
    }
  }
  .home-button-mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      position: absolute;
      right: 50%;
      bottom: 4.5rem;
      margin: 0;
      width: 2.5rem;
      height: 2.5rem;
      background: rgba(0, 0, 0, 0.04);
      transition: none;
      transform: translateX(50%);
      margin-right: -8.313rem;
      .icon {
        color: $black !important;
        font-size: 0.625rem !important;
        transition: none;
      }
      &.active {
        background: $black;
        .icon {
          color: $white !important;
          transform: rotate(180deg);
        }
      }
    }
  }
  &.embed-ready {
    .organism-sidebar-building {
      bottom: 4.688rem;
      top: initial;
      right: 50%;
      border-radius: 0.25rem;
      transform: translateX(50%);
      z-index: 9;
      @media only screen and (max-width: 1200px) {
        width: 21.563rem !important;
      }
      @media only screen and (max-width: 345px) {
        width: 96% !important;
      }
    }
    .organism-cart-spaces-building {
      position: absolute;
      bottom: 4.688rem;
      right: 50%;
      width: 25.938rem;
      transform: translateX(50%);
      @media only screen and (max-width: 1200px) {
        width: 21.563rem;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }
      .cart-spaces {
        position: relative;
        border-radius: 0.25rem !important;
        width: 100% !important;
        bottom: 0;
      }
    }
    .embed-controls {
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto 0.313rem;
      width: 25.938rem;
      display: flex;
      @media only screen and (max-width: 1200px) {
        width: 21.563rem;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }
      .control {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 0.25rem;
        height: 4.063rem;
        &.embed-menu-trigger {
          flex: 1;
          margin-right: 0.156rem;
        }
        &.embed-cart-trigger {
          flex: 1;
          margin-left: 0.156rem;
        }
        .description {
          position: relative;
          display: block;
          width: 100%;
          padding: 0.625rem;
          cursor: pointer;
          .custom-arrow {
            position: absolute;
            right: 0.625rem;
            top: 0.75rem;
            border-color: transparent transparent $black;
            border-style: solid;
            border-width: 0 0.313rem 0.375rem;
            height: 0;
            width: 0;
            display: inline-block;
            vertical-align: middle;
            transition: all 0.2s ease-in-out 0s;
          }
          p {
            margin-bottom: 0.438rem;
            @include font-main(0.875rem, $black, $regular, 1.125rem);
            &:last-child {
              margin-bottom: 0;
            }
            &.green-color {
              color: #4cc66d !important;
            }
            .icon {
              font-size: 0.938rem !important;
              transition: all 0.2s ease-in-out 0s;
            }
            b {
              @include font-main(0.875rem, $black, $bold, 1.125rem);
            }
          }
        }
        &:hover {
          .description {
            .custom-arrow {
              border-color: transparent transparent #4cc66d;
            }
          }
        }
        &.active {
          background: $black;
          .description {
            .custom-arrow {
              border-color: $white transparent transparent;
              border-width: 0.375rem 0.313rem 0;
            }
            p {
              color: $white;
              .icon {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }
  .organism-loader-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .scroll-element {
    position: absolute;
    bottom: 0;
    left: 12.563rem;
    padding: 0.25rem 2rem;
    background: $black;
    border-radius: 6.25rem;
    cursor: pointer;
    @include font-main(0.875rem, $white, $regular, 1.063rem);
    @media only screen and (max-width: 767px) {
      width: 20.875rem;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1.875rem;
      text-align: center;
      max-width: 95%;
      padding: 0.313rem 0.938rem;
    }
  }
  .footer-bright {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.313rem 1.25rem;
    background: rgba(255, 255, 255, 0.4);
    @include font-main(0.75rem, #494949, $regular, 0.875rem);
  }
}
</style>
