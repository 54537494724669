var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "atom-collapsible-item": true, active: _vm.isOpen } },
    [
      _c(
        "div",
        { staticClass: "item-header", on: { click: _vm.toggle } },
        [
          _c(
            "div",
            { staticClass: "title-wrapper" },
            [
              _vm._t("title"),
              _c(
                "div",
                {
                  staticClass: "icon-button",
                  attrs: { "aria-expanded": _vm.isOpen }
                },
                [
                  _vm.showIconText
                    ? _c("span", { staticClass: "expand-text" }, [
                        _vm._v(_vm._s(_vm.computedText))
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "icon-wrapper",
                      class: { active: _vm.isOpen }
                    },
                    [
                      _c("AtomIcon", {
                        attrs: { icon: "sort-down", size: 14, color: "#000000" }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            2
          ),
          _vm._t("extra-header-info")
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          staticClass: "item-content"
        },
        [_vm._t("content")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }