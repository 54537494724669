var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-filters" }, [
    _c("div", { staticClass: "title-and-info" }, [
      _c("div", { staticClass: "filters-title-and-button" }, [
        _c(
          "div",
          { staticClass: "filters-title" },
          [
            _c(
              "p",
              [
                _c("AtomIcon", { attrs: { icon: "filter-solid", size: 26 } }),
                _vm._v("\n          Filters\n          "),
                _vm.activeFiltersLength > 0
                  ? _c("span", { staticClass: "number" }, [
                      _vm._v("/ " + _vm._s(_vm.activeFiltersLength))
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c("AtomButton", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeFiltersLength > 0,
                  expression: "activeFiltersLength > 0"
                }
              ],
              staticClass: "rounded-button",
              attrs: {
                label: "Clear All",
                "on-click": _vm.handleClearAllFilters
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "filters-trigger",
            on: { click: _vm.toggleShowFilters }
          },
          [
            !_vm.showFilters
              ? _c(
                  "p",
                  [
                    _vm._v("\n          Show Filters\n          "),
                    _c("AtomIcon", { attrs: { icon: "sort-down", size: 26 } })
                  ],
                  1
                )
              : _c(
                  "p",
                  { staticClass: "inverse-arrow" },
                  [
                    _vm._v("\n          Hide Filters\n          "),
                    _c("AtomIcon", { attrs: { icon: "sort-down", size: 26 } })
                  ],
                  1
                )
          ]
        )
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "filters clearfix row",
        class: { visible: _vm.showFilters }
      },
      [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "row" }, [
            Object.keys(_vm.buildingOptions).length >= 2
              ? _c("div", { staticClass: "col col-padding-4px" }, [
                  _c(
                    "div",
                    { staticClass: "select-style" },
                    [
                      _c("AtomSelect", {
                        attrs: {
                          id: "building-select",
                          options: _vm.buildingOptions,
                          "has-label": "",
                          "label-text": "Building",
                          "select-props": {
                            search: false,
                            placeholder: "Select a building"
                          },
                          value: _vm.filters.building
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleFilterChange($event, "building")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            Object.keys(_vm.floorOptions).length >= 1
              ? _c("div", { staticClass: "col col-padding-4px" }, [
                  _c(
                    "div",
                    { staticClass: "select-style" },
                    [
                      _c("AtomSelect", {
                        attrs: {
                          id: "floor-select",
                          options: _vm.floorOptions,
                          "has-label": "",
                          "label-text": "Floor Info",
                          "select-props": {
                            search: false,
                            placeholder: "Select a floor"
                          },
                          value: _vm.filters.floor
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleFilterChange($event, "floor")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "row" }, [
            Object.keys(_vm.workspacesOptions).length >= 1
              ? _c("div", { staticClass: "col col-padding-4px" }, [
                  _c(
                    "div",
                    { staticClass: "select-style" },
                    [
                      _c("AtomSelect", {
                        attrs: {
                          id: "space-select",
                          options: _vm.spaceTypeOptions,
                          "has-label": "",
                          "label-text": "Space Type",
                          "select-props": {
                            search: false,
                            placeholder: "Select space"
                          },
                          value: _vm.filters.spaceType
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleFilterChange($event, "spaceType")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            Object.keys(_vm.workspacesOptions).length >= 1
              ? _c("div", { staticClass: "col col-padding-4px" }, [
                  _c(
                    "div",
                    { staticClass: "select-style" },
                    [
                      _c("AtomSelect", {
                        attrs: {
                          id: "workspaces-select",
                          options: _vm.workspacesOptions,
                          "has-label": "",
                          "label-text": "Workstations",
                          "select-props": {
                            search: false,
                            placeholder: "Select workspaces"
                          },
                          value: _vm.filters.workspaces
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleFilterChange($event, "workspaces")
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ])
      ]
    ),
    _c("div", { staticClass: "results-info" }, [
      _c("div", [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(_vm.filteredSpaces.length + " results found")
          }
        })
      ]),
      _vm._v("\n    Scroll Down\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }