var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "picture-popup" }, [
      _c("img", { attrs: { src: _vm.pinInfo.imageSrc } })
    ]),
    _c("div", { staticClass: "text-popup" }, [
      _c("h4", [_vm._v(_vm._s(_vm.pinInfo.pinTitle))]),
      _c("p", [_vm._v(_vm._s(_vm.pinInfo.pinDescription))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }