<template>
  <div :class="{'atom-collapsible-item': true, 'active': isOpen}">
    <div class="item-header" @click="toggle">
      <div class="title-wrapper">
        <slot name="title"></slot>
        <div class="icon-button" :aria-expanded="isOpen">
          <span class="expand-text" v-if="showIconText">{{ computedText }}</span>
          <span class="icon-wrapper" :class="{'active': isOpen}">
            <AtomIcon icon="sort-down" :size="14" color="#000000"/>
          </span>
        </div>
      </div>
      <slot name="extra-header-info"></slot>
    </div>
    <div class="item-content" v-show="isOpen">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../AtomIcon";

export default {
  name: "AtomCollapsibleItem",
  components: { AtomIcon },
  props: {
    item: {
      required: false,
      type: Object,
      default: () => {
      }
    },
    isDefaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    showIconText: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      isOpen: this.$props.isDefaultOpen
    }
  },
  watch: {
    isDefaultOpen(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.isOpen = newVal;
      }
    }
  },
  computed: {
    computedText() {
      return this.isOpen ? 'minimize' : 'expand';
    }
  },
  methods: {
    toggle(emit = true) {
      this.isOpen = !this.isOpen;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
    open(emit = true) {
      this.isOpen = true;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
    close(emit = true) {
      this.isOpen = false;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
  }
}
</script>

<style lang="scss">
.atom-collapsible-item {
  .item-header {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .title-wrapper {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 767px) {
        padding: 0.188rem;
      }
      .icon-button {
        cursor: pointer;
        margin: 0.625rem;
        display: flex;
        .expand-text {
          @media only screen and (max-width: 767px) {
            font-size: 0.875rem;
            line-height: 1rem;
          }
        }
        .icon-wrapper {
          display: flex;
          transition: all 0.2s ease-in-out 0s;
          height: fit-content;
          top: -0.063rem;
          position: relative;
          @media only screen and (max-width: 767px) {
            top: -0.125rem;
          }
          .icon {
            font-size: 0.875rem !important;
          }
          &.active {
            transform: rotate(-180deg);
            top: 0.25rem;
          }
        }
        &:hover {
          span {
            font-weight: $semiBold;
            transition: all 0.3s ease-in-out 0s;
          }
        }
      }
    }
  }
  .item-content {
    padding: 0.5rem;
  }
}
</style>