var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "specs-category" }, [
    _c("div", { staticClass: "category" }, [
      _c(
        "div",
        {
          class: { "category-title-components": true, active: _vm.isActive },
          on: { click: _vm.handleClick }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.categoryName))]),
          _c("AtomIcon", {
            staticClass: "plus-icon",
            attrs: { color: "#423687", size: 22, icon: "plus" }
          }),
          _c("AtomIcon", {
            staticClass: "minus-icon",
            attrs: { color: "#423687", size: 22, icon: "minus" }
          })
        ],
        1
      ),
      _c(
        "div",
        { class: { "category-items": true, active: _vm.isActive } },
        [_vm._t("default")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }