import {
    ArcRotateCamera,
    Camera,
    Tools,
    Vector3,
    Scene,
    CubicEase,
    Animation,
    EasingFunction,
    FreeCamera, AssetsManager
} from "babylonjs";
import {device} from './../../../../../helpers/mobile/index'

export type DefaultCameraSettings = {
    alpha: number,
    beta: number,
    radius: number,
    target: Vector3,
    radiusLowerLimit: number,
    radiusUpperLimit: number
    fov: number,
    panningDistanceLimit: number
}

class Helper {
    _lodTexturesProcessed: {init: any, lod: any}[];
    lodTexturesUpdate = (remaining: number, total: number, percentage: number) => {};
    constructor() {
        this._lodTexturesProcessed = []
    }

    createVirtualTourCamera(name: string, position: Vector3, target: Vector3, scene: Scene) {
        let camera = scene.getCameraByName(name) as FreeCamera;
        if (!camera) {
            camera = new FreeCamera(
                name,
                position,
                scene,

            );
        }
        camera.minZ = 0.1;
        camera.maxZ = 1000;
        camera.setTarget(target);
        camera.speed = 0.1;
        (camera as any).touchAngularSensibility = -6000;

        return camera;
    }

    createDefaultCamera(name: string, settings: DefaultCameraSettings, scene: Scene, canvas: HTMLCanvasElement) {
        let camera = scene.getCameraByName(name) as ArcRotateCamera;
        if(!camera){
            camera = new ArcRotateCamera(
                name,
                settings.alpha,
                settings.beta,
                settings.radius,
                settings.target,
                scene
            );
        }

        camera.panningDistanceLimit = 150;
        // camera.upperBetaLimit = (Math.PI / 2) * 1;
        camera.upperBetaLimit = Tools.ToRadians(90);

        let activeAC = true;
        let pSensibility = 5000;

        //mobile settings
        if (!device.isDesktop()){
            activeAC = false;
            pSensibility = 30; ///3000
            camera.useNaturalPinchZoom = true;
            camera.pinchToPanMaxDistance = 24;
            camera.panningAxis = new Vector3(1, 0, 1)
            // if (settings.lockHeightPanning === false){
            //     camera.panningAxis = new Vector3(1, 1, 1)
            // }
            camera.panningDistanceLimit = 50;
            if (settings.panningDistanceLimit){
                camera.panningDistanceLimit = settings.panningDistanceLimit;
            }



        }

        camera.lowerRadiusLimit = settings.radiusLowerLimit;
        camera.upperRadiusLimit = settings.radiusUpperLimit;

        camera.minZ = 10;
        camera.layerMask= 1;
        camera.fov = settings.fov;
        camera.fovMode = Camera.FOVMODE_HORIZONTAL_FIXED;
        camera.panningSensibility = pSensibility;

        scene.activeCamera?.attachControl(canvas, activeAC);

        return camera;
    }

    move(camera: Camera, position: Vector3|false, target: Vector3|null, alpha:number|false, beta:number|false, radius:number|false, positionCallback = ()=>{}, targetCallback = ()=>{}) {
        if (position) {
            this.easeAnimation(camera, 'position', position, positionCallback);
        }
        if (target) {
            this.easeAnimation(camera, 'target', target, targetCallback);
        }
        if (alpha !== false) {
            this.easeAnimation(camera, 'alpha', alpha, positionCallback);
        }
        if (beta !== false) {
            this.easeAnimation(camera, 'beta', beta, positionCallback);
        }
        if (radius !== false) {
            this.easeAnimation(camera, 'radius', radius, positionCallback);
        }
    }

    easeAnimation(camera: any, property: string, newValue: any, callback = (camera: any)=>{} ) {
        const ease = new CubicEase();
        ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        Animation.CreateAndStartAnimation('cameraAnimation'+property, camera, property, 30, 20, eval('camera.'+property), newValue, 0, ease, ()=>{callback(camera)},);
    }

    lodTextures(scene: Scene, path: string, extension: string = '.ktx2', ){
        const self = this;
        const texturesToProcess: { texture: any; materials: any [] }[] = [];
        const materials = scene.materials;
        for (let i = 0; i < materials.length; i++) {
            if(materials[i].name.indexOf('spriteMat') !== -1) continue;
            const textures = materials[i].getActiveTextures()
            if (textures) {
                for (let k = 0; k < textures.length; k++) {
                    if(!texturesToProcess[textures[k].uniqueId]){
                        texturesToProcess[textures[k].uniqueId] = {
                            'texture': textures[k],
                            'materials': []
                        }
                    }
                    texturesToProcess[textures[k].uniqueId].materials.push(materials[i]);
                }
            }
        }

        if(texturesToProcess){
            const texturesAssetsManager = new AssetsManager(scene);

            texturesToProcess.forEach( ( process, uid) => {
                if(process.texture.url?.substring(process.texture.url.indexOf(path) + path.length, process.texture.url.indexOf(path)+ path.length + 2) !== 'hi') {
                    const hiTexture = path + 'hi' + process.texture.url.substring(process.texture.url.indexOf(path) + path.length, process.texture.url.lastIndexOf('.')) + extension;
                    texturesAssetsManager.addTextureTask(uid.toString(), hiTexture).onSuccess = ((task) => {
                        texturesToProcess[parseInt(task.name)].materials.forEach((material) => {
                            if (material.albedoTexture?.uniqueId === texturesToProcess[parseInt(task.name)].texture.uniqueId) {
                                task.texture.hasAlpha = material.albedoTexture.hasAlpha;
                                material.albedoTexture = task.texture;
                            }
                            if (material.emissiveTexture?.uniqueId === texturesToProcess[parseInt(task.name)].texture.uniqueId)
                                material.albedoTexture = task.texture;
                            if (material.metallicTexture?.uniqueId === texturesToProcess[parseInt(task.name)].texture.uniqueId)
                                material.metallicTexture = task.texture;
                            if (material.metallicRoughnessTexture?.uniqueId === texturesToProcess[parseInt(task.name)].texture.uniqueId)
                                material.metallicRoughnessTexture = task.texture;
                        })
                    })
                }
            })
            texturesAssetsManager.onProgress = function(remainingCount, totalCount, lastFinishedTask) {
                self.lodTexturesUpdate(remainingCount, totalCount, Math.floor(100 - (remainingCount * 100 / totalCount)))
            }

            texturesAssetsManager.load()
        }
    }

    sliderInit() {

    }

}

export default new Helper();