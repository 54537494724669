import {DefaultState} from "./index";
import {
    Engine,
    Scene,
    MeshBuilder,
    Animation,
    CubicEase,
    ArcRotateCamera,
    StandardMaterial,
    DynamicTexture,
    CubeTexture,
    Nullable,
    AbstractMesh,
    Tools,
    Vector3,
    TransformNode
} from "babylonjs";
import {EngineHelper, SceneHelper} from "@/components/organisms/project/building/store/helpers";
import {FloorHighlightsHelper} from "@/components/organisms/project/building/store/FloorHighlightsHelper";

type BuildingInteractionHelperOpts = {
    settings: any,
    space: any
};

class BuildingInteractionHelper {
    _isVue: boolean;
    engine: Engine | undefined;
    scene: Scene | undefined;
    hiddenBuilding?: string | null;
    commonTops: Record<string, any>;
    settings: Record<string, any>;


    constructor({settings, space }: BuildingInteractionHelperOpts, engine: EngineHelper | null, scene: SceneHelper | null) {
        this._isVue = true;
        this.engine = engine?.get();
        this.scene = scene?.get();
        this.hiddenBuilding = null;
        const buildingSettings = settings?.building;
        const spaceBuilding = space?.building;
        this.settings = {
            camera: {
                alpha: Tools.ToRadians(buildingSettings.camera.alpha),
                beta: Tools.ToRadians(buildingSettings.camera.beta),
                radius: buildingSettings.camera.radius,
                fov: buildingSettings.camera.fov,
                target: new Vector3(...buildingSettings.camera.target),
                radiusUpperLimit: buildingSettings.camera.radiusUpperLimit,
                radiusLowerLimit: buildingSettings.camera.radiusLowerLimit
            },
            highlights: {
                nodeName: 'highlights',
                writeFloorOnCommon: buildingSettings.highlights.writeFloorOnCommon,
                showCommon: buildingSettings.highlights.showCommon,
                moveHighlights: buildingSettings.highlights.moveHighlights,
                buildings: {
                    [spaceBuilding]: {
                        alpha: Tools.ToRadians(buildingSettings.highlights.buildings[spaceBuilding].alpha),
                        beta: Tools.ToRadians(buildingSettings.highlights.buildings[spaceBuilding].beta),
                        radius: buildingSettings.highlights.buildings[spaceBuilding].radius,
                        target: new Vector3(...buildingSettings.highlights.buildings[spaceBuilding].target)
                    }
                },
            },
        },
        this.commonTops = {};

    }

    setHiddenBuilding(buildingCode: string){
        this.hiddenBuilding = buildingCode;
    }

    showUnit(spaces: string, floor: string, floorModel: string, building: string, buildingsCollection: any[]) {
        if (!this.scene) {
            throw new Error('Scene is null when calling BuildingInteractionHelper.showUnit');
        }
        const buildingCode = buildingsCollection.find((b: any) => building === b.code && b.isHidden === false)?.code || null; // get the code of building that has property isHidden = false
        this.setHiddenBuilding(buildingCode); // set the required property used to hide the building
        const hideBuildings = buildingsCollection.filter((b: any) => b.isHidden === true); // get all buildings with isHidden = true
        if (hideBuildings && hideBuildings.length > 0) {
            hideBuildings.forEach((hb: any) => { // for each building hide the builingCase
                this.scene!.getTransformNodeByName(hb.code + '_case')!.setEnabled(true);
                const floors = this.scene!.getTransformNodeByName(hb.code + '_floors')!.getChildMeshes(false);
                floors.forEach((floor) => {
                    floor.visibility = 1;
                })
            })
        }
        this.scene.getTransformNodeByName(this.hiddenBuilding + '_case')!.setEnabled(false); // for the current clicked building, show the buildingCase
        const floors = this.scene.getTransformNodeByName(this.hiddenBuilding + '_floors')!.getChildMeshes(false);
        floors.forEach((floor) => {
            floor.visibility = .15
        });

        let selectedFloor: any = this.scene.getMeshByName(this.hiddenBuilding + '_' + floor);
        if (selectedFloor) {
            selectedFloor.visibility = 1;
        } else {
            selectedFloor = this.scene.getTransformNodeByName(this.hiddenBuilding + '_' + floor);
            if (selectedFloor) {
                const selectedFloorMeshes = selectedFloor.getChildMeshes(true);
                selectedFloorMeshes.forEach((selMesh: AbstractMesh) => {
                    selMesh.visibility = 1;
                })
            }
        }
        const highlights = this.scene.getTransformNodeByName(this.settings.highlights.nodeName)!.getChildMeshes();
        highlights.forEach((highlight) => {
            highlight.setEnabled(false);
            // highlight.isVisible = false;
        })

        let floorHeight: number = 0;

        if (this.settings.highlights.showCommon || this.settings.highlights.moveHighlights) {
            const floorBounding = selectedFloor.getBoundingInfo().boundingBox.vectorsWorld;
            floorHeight = floorBounding[1].y - (floorBounding[0].y);
        }

        if (this.settings.highlights!.showCommon) {
            const common = this.scene.getMeshByName(this.hiddenBuilding + '_common');
            if (!common) {
                return;
            }

            common.setEnabled(true);
            common.position.y += selectedFloor.absolutePosition.y - common.absolutePosition.y + floorHeight;


            if (this.settings.highlights!.writeFloorOnCommon) {
                const commonSize = common.getBoundingInfo().boundingBox.extendSize;
                //Set font type
                const font_type = "Arial";
                //Set text
                const text = "Floor " + floor.replace(/^f+/i, '');
                const size = 10;

                if (this.commonTops![building] === undefined) {
                    this.commonTops![building] = {};
                    this.commonTops[building].mesh = MeshBuilder.CreatePlane(building + '_common_top', {
                        width: commonSize.x * 2 * common!.scaling.x,
                        height: commonSize.z * 2 * common!.scaling.z
                    }, this.scene);
                    this.commonTops[building].mesh.parent = common!.parent;
                    this.commonTops[building].mesh.rotation.x = Math.PI / 2;
                    this.commonTops[building].mesh.position.x = common!.position.x;
                    this.commonTops[building].mesh.position.z = common!.position.z;

                    //Set width and height for dynamic texture using same multiplier
                    this.commonTops[building].DTWidth = commonSize.x * 160 * common.scaling.x;
                    this.commonTops[building].DTHeight = commonSize.z * 160 * common.scaling.z;

                    //Create dynamic texture
                    this.commonTops[building].texture = new DynamicTexture(building + "_floorNo", {
                        width: Math.round(this.commonTops[building].DTWidth),
                        height: Math.round(this.commonTops[building].DTHeight)
                    }, this.scene, true);
                    this.commonTops[building].material = new StandardMaterial(building + "_floorNo", this.scene);
                    this.commonTops[building].material.diffuseTexture = this.commonTops[building].texture;
                    this.commonTops[building].material.diffuseTexture.hasAlpha = true;
                    this.commonTops[building].mesh.material = this.commonTops[building].material;
                }
                this.commonTops[building].mesh.setEnabled(true);

                const ctx = this.commonTops[building].texture.getContext();
                ctx.font = size + "px " + font_type;
                const textWidth = ctx.measureText(text).width;
                const ratio = textWidth / size;

                //set font to be actually used to write text on dynamic texture
                const font_size = Math.floor(this.commonTops[building].DTWidth / (ratio * 2)); //size of multiplier (1) can be adjusted, increase for smaller text
                const font = font_size + "px " + font_type;

                //Draw text
                ctx.clearRect(0, 0, this.commonTops[building].DTWidth, this.commonTops[building].DTHeight);
                this.commonTops[building].texture.drawText(text, null, null, font, "#ffffff", "transparent", false);
                this.commonTops[building].mesh.position.y = common.position.y + commonSize.y * 2 * common.scaling.y + 0.01;
            }
        }

        const spacesArray = spaces.split(';');

        spacesArray.forEach((space) => {
            if (!this.scene) return;
            let spaceHighlight = this.scene.getMeshByName(floorModel.replace('.gltf', '') + '_' + space);
            if (!spaceHighlight) {
                spaceHighlight = this.scene.getMeshByName(building + "_" + floor + "_" + space);
                if (!spaceHighlight) {
                    return;
                }
            }
            if (this.settings.highlights.moveHighlights) {
                spaceHighlight.position.y += selectedFloor.absolutePosition.y - spaceHighlight.absolutePosition.y + floorHeight;
            }
            spaceHighlight.setEnabled(true);
            // spaceHighlight.isVisible = true;
        })
        if (this.settings.highlights.buildings[building]) {
            this.animateCameraTo(this.settings.highlights.buildings[building].alpha, this.settings.highlights.buildings[building].beta, this.settings.highlights.buildings[building].radius, this.settings.highlights.buildings[building].target);
        }
    }

    resetVisibility() {
        if (this.hiddenBuilding) {
            this.scene!.getTransformNodeByName(this.hiddenBuilding + '_case')!.setEnabled(true);

            const floors = this.scene!.getTransformNodeByName(this.hiddenBuilding + '_floors')!.getChildMeshes(true);
            floors.forEach((floor) => {
                floor.visibility = 1;
            })
        }
        const highlights = this.scene!.getTransformNodeByName(this.settings.highlights.nodeName)!.getChildMeshes();
        highlights.forEach((highlight) => {
            highlight.setEnabled(false);
        });

        this.animateCameraTo(this.settings.camera.alpha, this.settings.camera.beta, this.settings.camera.radius, this.settings.camera.target);
    }

    animateCameraTo(alpha: number, beta: number, radius: number, cameraTarget: number, speed = 30, frameCount = 30) {
        const ease = new CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
        const activeCam = this.scene!.activeCamera as ArcRotateCamera;
        if(!(Math.abs(activeCam.alpha-alpha) <= Math.PI)){
            if(activeCam.alpha >= 0){
                alpha = alpha + 2*Math.PI;
            } else {
                alpha = alpha - 2*Math.PI;
            }
        }

        if (activeCam) {
            Animation.CreateAndStartAnimation('at9', activeCam, 'target', speed, frameCount, activeCam.target, cameraTarget, 0, ease);
            Animation.CreateAndStartAnimation('at6', activeCam, 'alpha', speed, frameCount, activeCam.alpha - (Math.trunc((activeCam.alpha / (2 * Math.PI)))) * 2 * Math.PI, alpha, 0, ease);
            Animation.CreateAndStartAnimation('at7', activeCam, 'beta', speed, frameCount, activeCam.beta, beta, 0, ease);
            Animation.CreateAndStartAnimation('at8', activeCam, 'radius', speed, frameCount, activeCam.radius, radius, 0, ease);
            Animation.CreateAndStartAnimation('at8', activeCam, 'lowerRadiusLimit', speed, frameCount, activeCam.radius, radius, 0, ease);
            Animation.CreateAndStartAnimation('at8', activeCam, 'upperRadiusLimit', speed, frameCount, activeCam.radius, radius, 0, ease);
        }

    };
}

export default BuildingInteractionHelper;

export {
    BuildingInteractionHelperOpts
};