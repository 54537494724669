<template>
  <div>
    <div class="picture-popup">
      <img :src="pinInfo.imageSrc">
    </div>
    <div class="text-popup">
      <h4>{{pinInfo.pinTitle}}</h4>
      <p>{{pinInfo.pinDescription}}</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "AtomPinIconText",
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function (){
        return {
          pinTitle: "",
          pinDescription: ""
        }
      }
    },
  }
}
</script>

<style scoped>

</style>