var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-content-slider-brochure" },
    [
      _c(
        "MoleculeContentSliderBrochure",
        { ref: "slider" },
        _vm._l(_vm.sliderContentData, function(sliderContent, index) {
          return _c("AtomContentSliderBrochure", { key: index }, [
            _c(
              "div",
              { staticClass: "content-slide" },
              _vm._l(sliderContent.lines, function(line, index) {
                return _c("p", { key: index }, [_vm._v(_vm._s(line))])
              }),
              0
            )
          ])
        }),
        1
      ),
      _c(
        "MoleculeContentThumbSliderBrochure",
        _vm._l(_vm.sliderContentData, function(sliderContent, index) {
          return _c("AtomContentThumbSliderBrochure", {
            key: index,
            on: { click: _vm.handleThumbChange }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }