<template>
  <div class="organism-team">
    <MoleculeTeam>
      <AtomTeam v-for="(member, index) in teamData" :image="resourceUrl(member.image)" :name="member.name" :urlLinkedin="member.urlLinkedin" :nr="member.nr" :position="member.position" :email="member.email" :key="index" />
    </MoleculeTeam>
  </div>
</template>

<script>
import MoleculeTeam from "../../molecules/portfolio/MoleculeTeam";
import AtomTeam from "../../atoms/portfolio/AtomTeam";
export default {
  name: "OrganismTeam",
  components: {AtomTeam, MoleculeTeam},
  props: {
    teamData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-team {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }
</style>