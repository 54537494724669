<template>
  <router-link v-if="to" tag="div" class="menu-item-atom" :to="to">
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
    <span>{{ label }}</span>
  </router-link>
  <div v-else class="menu-item-atom" @click="onClick(tabId)">
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";

export default {
  name: "AtomMenuItem",
  components: { AtomIcon },
  props: {
    label: {
      required: false,
      type: String,
      default: "Menu Item",
    },
    icon: {
      required: false,
      type: String,
      default: "",
    },
    tabId: {
      required: false,
      type: String,
      default: "",
    },
    to: {
      required: false,
      type: Object,
      default: null,
    },
    onClick: {
      required: false,
      type: Function,
      default: function () {
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item-atom {
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0 2px;

  cursor: pointer;

  .icon {
    margin-right: 10px;
    font-size: 16px !important;
  }
}
</style>