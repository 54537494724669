var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.enabled !== false
    ? _c(
        "div",
        {
          class:
            ((_obj = { section: true, "section-features": true }),
            (_obj["section" + _vm.orderNo] = true),
            _obj),
          style: _vm.inlineStyles
        },
        [
          _c("a", {
            staticClass: "navigation-target",
            attrs: { id: _vm.hash, href: "javascript:void(0);" }
          }),
          _c(
            "div",
            { staticClass: "top-title" },
            [
              _c("AtomTitle", {
                attrs: {
                  color2: "#fff",
                  title: _vm.data.title,
                  subtitle: _vm.data.subtitle
                }
              }),
              _c("AtomVerticalLine")
            ],
            1
          ),
          _c("OrganismListCircle", {
            attrs: { "list-circle-data": _vm.data.items }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }