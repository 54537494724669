var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "router-link",
        { staticClass: "menu-item-atom", attrs: { tag: "div", to: _vm.to } },
        [
          _vm.icon
            ? _c("AtomIcon", { attrs: { icon: _vm.icon, size: 14 } })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.label))])
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "menu-item-atom",
          on: {
            click: function($event) {
              return _vm.onClick(_vm.tabId)
            }
          }
        },
        [
          _vm.icon
            ? _c("AtomIcon", { attrs: { icon: _vm.icon, size: 14 } })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.label))])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }