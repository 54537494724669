<template>
  <div class="organism-availability-drawer">
    <OrganismDrawer
      ref="drawer"
      :default-open="defaultOpen"
      @drawer:close="$emit('drawer:close')"
    >
      <MoleculeHorizontalMenu
        class="horizontal-menu"
        sm
        :tab-data="tabData"
        :selected-option="activeTab"
        @tab-change="handleTabChange"
      />
      <div v-show="activeTab === 'coworking'">
        <div class="top-content">
          <MoleculeFilters />
        </div>
        <div
          class="bottom-content"
          v-if="Object.keys(finalStructureSpaces).length"
        >
          <AtomCollapsibleItem
            class="parent-box"
            v-for="building in finalStructureSpaces"
            :key="`building_${building.id}`"
            :item="building"
            :ref="`building_${building.id}`"
          >
            <template v-slot:title>
              <div class="title-box">
                <p class="title-text">{{ building.title }}</p>
                <span class="circle-sum float-right">{{
                  building.noSpacesResult
                }}</span>
              </div>
            </template>
            <template v-slot:content>
              <AtomCollapsibleItem
                :class="{
                  'child-box': true,
                  'spaces-leased': floor.fullyLeased,
                }"
                v-for="floor in building.child"
                :key="`floor_${floor.id}`"
                :ref="`floor_${floor.id}`"
              >
                <template v-slot:title>
                  <div class="title-box" style="width: 100%">
                    <div style="display: flex; align-items: center; flex: 3">
                      <span class="title title-child">{{ floor.title }}</span>
                      <span class="subtitle">{{ floor.subtitle }}</span>
                    </div>
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex: 1;
                      "
                    >
                      <span class="fully-leased">{{ floor.fullyLeased }}</span>
                      <span class="circle-sum">{{ floor.noSpacesResult }}</span>
                    </div>
                  </div>
                </template>
                <template v-slot:content>
                  <div
                    v-for="(spaceTypeData, spaceTypeName) in floor.child"
                    :key="`${floor.id}-${spaceTypeName}`"
                  >
                    <span class="category-title">
                      {{ spaceTypeName }}
                    </span>

                    <div
                      :key="`spaces-type-data-${childKey}`"
                      v-for="(spacesArr, childKey) in spaceTypeData"
                    >
                      <div
                        v-if="
                          spacesArr.length > 1 &&
                          getFirstAvailableItem(spacesArr)
                        "
                      >
                        <!--                        <AtomCollapsibleItem-->
                        <!--                          :class="{-->
                        <!--                            'subchild-box': true,-->
                        <!--                            'space-leased': !spacesArr.some(-->
                        <!--                              (sp) =>-->
                        <!--                                sp.spaceDetails.available ||-->
                        <!--                                sp.spaceDetails.is_public-->
                        <!--                            ),-->
                        <!--                          }"-->
                        <!--                          :ref="`${building.buildingDetails.code}_${-->
                        <!--                            getFirstAvailableItem(spacesArr).id-->
                        <!--                          }_group`"-->
                        <!--                          :is-default-open="-->
                        <!--                            checkCollapseEvent(-->
                        <!--                              `${building.buildingDetails.code}_${-->
                        <!--                                getFirstAvailableItem(spacesArr).spaceDetails.id-->
                        <!--                              }`,-->
                        <!--                              true,-->
                        <!--                              true-->
                        <!--                            )-->
                        <!--                          "-->
                        <!--                          @toggle="-->
                        <!--                            viewOrHideSpace(-->
                        <!--                              $event,-->
                        <!--                              getFirstAvailableItem(spacesArr).spaceDetails-->
                        <!--                                .space_code,-->
                        <!--                              building.buildingDetails.code,-->
                        <!--                              null,-->
                        <!--                              getFirstAvailableItem(spacesArr).spaceDetails,-->
                        <!--                              true,-->
                        <!--                              true-->
                        <!--                            )-->
                        <!--                          "-->
                        <!--                        >-->
                        <!--                          <template v-slot:title>-->
                        <!--                            <div-->
                        <!--                              class="title-box"-->
                        <!--                              style="-->
                        <!--                                width: 100%;-->
                        <!--                                display: flex;-->
                        <!--                                align-items: center;-->
                        <!--                                justify-content: space-between;-->
                        <!--                              "-->
                        <!--                            >-->
                        <!--                              <span class="title">{{-->
                        <!--                                getFirstAvailableItem(spacesArr).title.split(-->
                        <!--                                  "-"-->
                        <!--                                )[0]-->
                        <!--                              }}</span>-->
                        <!--                              &lt;!&ndash;                              <span v-if="false"> - LEASED</span>&ndash;&gt;-->
                        <!--                              <span class="circle-sum">{{-->
                        <!--                                spacesArr.length-->
                        <!--                              }}</span>-->
                        <!--                            </div>-->
                        <!--                          </template>-->
                        <!--                          <template v-slot:content>-->
                        <AtomCollapsibleItem
                          v-for="(nestedSpaceInfo, kkey) in spacesArr"
                          :key="`nested-space-info-${kkey}`"
                          :class="{
                            'subchild-box': true,
                            'space-leased': nestedSpaceInfo.isLeased,
                          }"
                          :ref="`${building.buildingDetails.code}_${nestedSpaceInfo.spaceDetails.space_code}_${nestedSpaceInfo.spaceDetails.id}`"
                          :is-default-open="
                            checkCollapseEvent(
                              `${building.buildingDetails.code}_${nestedSpaceInfo.spaceDetails.space_code}_${nestedSpaceInfo.spaceDetails.id}`,
                              true
                            )
                          "
                          @toggle="
                            viewOrHideSpace(
                              $event,
                              nestedSpaceInfo.spaceDetails.space_code,
                              building.buildingDetails.code,
                              null,
                              nestedSpaceInfo.spaceDetails,
                              true
                            )
                          "
                        >
                          <template v-slot:title>
                            <div class="title-box">
                              <span class="title">
                                {{ nestedSpaceInfo.title }}</span
                              >
                              <span class="surface"
                                ><b>{{ nestedSpaceInfo.spaceDetails.sqm }}</b>
                                sqm</span
                              >
                              <span
                                v-if="!nestedSpaceInfo.isLeased"
                                class="surface"
                                ><b>{{
                                  nestedSpaceInfo.spaceDetails.workplaces
                                }}</b>
                                {{
                                  nestedSpaceInfo.spaceDetails.workplaces > 1
                                    ? "workplaces"
                                    : "workplace"
                                }}</span
                              >
                              <span v-if="nestedSpaceInfo.isLeased">
                                - {{ nestedSpaceInfo.isLeased }}</span
                              >
                            </div>
                          </template>
                          <template v-slot:content>
                            <div>
                              <div class="content-box">
                                <div
                                  class="content-buttons"
                                  v-if="
                                    (nestedSpaceInfo.spaceDetails.available ||
                                      nestedSpaceInfo.spaceDetails.is_public) &&
                                    shortlistState
                                  "
                                >
                                  <AtomButton
                                    class="explore-btn"
                                    icon="vr"
                                    label="Explore in 3D"
                                    custom-label="Explore"
                                    :on-click="
                                      () => exploreSpace(nestedSpaceInfo.id)
                                    "
                                    activate-hover-event
                                  />
                                </div>
                                <div
                                  class="content-buttons"
                                  v-if="
                                    (nestedSpaceInfo.spaceDetails.available ||
                                      nestedSpaceInfo.spaceDetails.is_public) &&
                                    !shortlistState
                                  "
                                >
                                  <div
                                    v-if="
                                      allowViewIn3DButton(
                                        nestedSpaceInfo.spaceDetails,
                                        currentSpace.floor_id
                                      )
                                    "
                                  >
                                    <AtomButton
                                      class="explore-btn"
                                      icon="vr"
                                      label="Explore in 3D"
                                      custom-label="Explore"
                                      :on-click="
                                        () =>
                                          exploreSpace(
                                            nestedSpaceInfo.id,
                                            nestedSpaceInfo.spaceDetails
                                              .project_id
                                          )
                                      "
                                      activate-hover-event
                                    />
                                  </div>
                                  <div
                                    v-if="
                                      allowRequestOfferButton(
                                        nestedSpaceInfo.spaceDetails,
                                        currentSpace.floor_id
                                      )
                                    "
                                  >
                                    <AtomButton
                                      class="request-offer-btn button-green"
                                      icon="plane-paper"
                                      label="Request Offer"
                                      custom-label="Request"
                                      :on-click="
                                        () =>
                                          openRequestOfferModal(
                                            nestedSpaceInfo.spaceDetails,
                                            building.buildingDetails
                                          )
                                      "
                                      activate-hover-event
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </AtomCollapsibleItem>
                        <!--                          </template>-->
                        <!--                        </AtomCollapsibleItem>-->
                      </div>
                      <div v-if="spacesArr.length === 1">
                        <AtomCollapsibleItem
                          :class="{
                            'subchild-box': true,
                            'space-leased': spacesArr[0].isLeased,
                          }"
                          :ref="`${building.buildingDetails.code}_${spacesArr[0].spaceDetails.space_code}`"
                          :is-default-open="
                            checkCollapseEvent(
                              `${building.buildingDetails.code}_${spacesArr[0].spaceDetails.space_code}`
                            )
                          "
                          @toggle="
                            viewOrHideSpace(
                              $event,
                              spacesArr[0].spaceDetails.space_code,
                              building.buildingDetails.code,
                              null,
                              spacesArr[0].spaceDetails,
                              false
                            )
                          "
                        >
                          <template v-slot:title>
                            <div class="title-box">
                              <span class="title">{{
                                spacesArr[0].title
                              }}</span>
                              <span class="surface"
                                ><b>{{ spacesArr[0].spaceDetails.sqm }}</b>
                                sqm</span
                              >
                              <span
                                v-if="!spacesArr[0].isLeased"
                                class="surface"
                                ><b>{{
                                  spacesArr[0].spaceDetails.workplaces
                                }}</b>
                                {{
                                  spacesArr[0].spaceDetails.workplaces > 1
                                    ? "workplaces"
                                    : "workplace"
                                }}
                              </span>
                              <span v-if="spacesArr[0].isLeased">
                                - {{ spacesArr[0].isLeased }}</span
                              >
                            </div>
                          </template>
                          <template v-slot:content>
                            <div class="content-box">
                              <div
                                class="content-buttons"
                                v-if="
                                  spacesArr[0].spaceDetails.available ||
                                  spacesArr[0].spaceDetails.is_public
                                "
                              >
                                <div
                                  v-if="
                                    allowViewIn3DButton(
                                      spacesArr[0].spaceDetails,
                                      currentSpace.floor_id
                                    )
                                  "
                                >
                                  <AtomButton
                                    class="explore-btn"
                                    icon="vr"
                                    label="Explore in 3D"
                                    custom-label="Explore"
                                    :on-click="
                                      () =>
                                        exploreSpace(
                                          spacesArr[0].id,
                                          spacesArr[0].spaceDetails.project_id
                                        )
                                    "
                                    activate-hover-event
                                  />
                                </div>
                                <div
                                  v-if="
                                    allowRequestOfferButton(
                                      spacesArr[0].spaceDetails,
                                      currentSpace.floor_id
                                    )
                                  "
                                >
                                  <AtomButton
                                    class="request-offer-btn button-green"
                                    icon="plane-paper"
                                    label="Request Offer"
                                    custom-label="Request"
                                    :on-click="
                                      () =>
                                        openRequestOfferModal(
                                          spacesArr[0].spaceDetails,
                                          building.buildingDetails
                                        )
                                    "
                                    activate-hover-event
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </AtomCollapsibleItem>
                      </div>
                    </div>
                  </div>
                </template>
              </AtomCollapsibleItem>
            </template>
          </AtomCollapsibleItem>
        </div>
      </div>
      <div v-if="activeTab === 'project-info'" class="project-info">
        <MoleculePopupResult :project="currentProject" />
      </div>
    </OrganismDrawer>
  </div>
</template>

<script>
import OrganismDrawer from "../OrganismDrawer";
import MoleculeHorizontalMenu from "../../../molecules/common/MoleculeHorizontalMenu";
import AtomButton from "../../../atoms/common/AtomButton";
import MoleculeFilters from "../../../molecules/common/availability/MoleculeFilters";
import { BabylonInitializeHelper } from "../../project/building/store/helpers";
import buildingStore from "../../../organisms/project/building/store";
import AtomCollapsibleItem from "../../../atoms/common/availability/AtomCollapsibleItem";
import MoleculePopupResult from "@/components/molecules/project/MoleculePopupResult";

export default {
  name: "OrganismAvailabilityDrawer",
  components: {
    MoleculePopupResult,
    AtomCollapsibleItem,
    MoleculeFilters,
    AtomButton,
    MoleculeHorizontalMenu,
    OrganismDrawer,
  },
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "coworking",
      selectedAmenities: [],
      drawerFiltersOpen: true,
      drawerShortlistOpen: false,
      clickedHighlight: "",
      activeSpaceId: -1,
    };
  },
  mounted() {
    this.initActiveSpace();
  },
  computed: {
    tabData() {
      const tabData = [];
      tabData.push({
        label: "Co-working",
        tabId: "coworking",
      });
      tabData.push({
        label: "Project Info",
        tabId: "project-info",
      });
      return tabData;
    },
    activeProjectSlug() {
      return this.$store.state.project?.activeProject || null;
    },
    currentProject() {
      return this.$store.state.base.landlordDetails?.projects.find((item) => {
        return item.slug === this.activeProjectSlug;
      });
    },
    activeProject() {
      return (
        this.$store.state.base.landlordDetails?.projects.find(
          (item) => item.slug === this.activeProjectSlug
        ) || null
      );
    },
    spaces() {
      return (
        this.$store.state.base.landlordDetails?.spaces?.filter(
          (sp) => sp.project_id === this.activeProject?.id || -1
        ) || []
      );
    },
    floors() {
      return (
        this.$store.state.base.landlordDetails?.floors?.filter((fl) =>
          this.buildings.some((bl) => bl.id === fl.building_id)
        ) || []
      );
    },
    buildings() {
      return (
        this.$store.state.base.landlordDetails?.buildings?.filter(
          (bl) => bl.project_id === this.activeProject?.id || -1
        ) || []
      );
    },
    buildingStore() {
      return buildingStore;
    },
    availableSpaces() {
      const buildingObj = {};
      const floorObj = {};
      this.spaces.forEach((sp) => {
        if (!buildingObj[sp.building_id]) {
          buildingObj[sp.building_id] = {
            available: 0,
            total: 0,
          };
        }
        if (!floorObj[sp.floor_id]) {
          floorObj[sp.floor_id] = {
            available: 0,
            total: 0,
          };
        }
        buildingObj[sp.building_id].total++;
        floorObj[sp.floor_id].total++;
        if (sp.available) {
          buildingObj[sp.building_id].available++;
          floorObj[sp.floor_id].available++;
        }
      });
      return {
        building: buildingObj,
        floor: floorObj,
      };
    },
    babylonHelper() {
      return BabylonInitializeHelper;
    },
    floorInteractions() {
      return this.babylonHelper.floorInteractionHelper();
    },
    buildingInteractions() {
      return this.babylonHelper.buildingInteractionHelper();
    },
    filters() {
      return this.$store.state.project.filters;
    },
    filteredSpaces() {
      let filteredSpaces = [...this.spaces];
      if (this.filters.building !== -1) {
        filteredSpaces = filteredSpaces.filter(
          (s) => Number(s.building_id) === Number(this.filters.building)
        );
      }
      if (this.filters.floor !== -1) {
        filteredSpaces = filteredSpaces?.filter(
          (s) => Number(s.floor_id) === Number(this.filters.floor)
        );
      }
      if (this.filters.workspaces !== -1) {
        filteredSpaces = filteredSpaces?.filter(
          (s) => Number(s?.workplaces || 0) === Number(this.filters.workspaces)
        );
      }

      if (this.filters.spaceType !== -1) {
        filteredSpaces = filteredSpaces?.filter((s) =>
          this.filters.spaceType.includes(s?.space_type.name)
        );
      }
      // if (this.filters.amenities?.length > 0) {
      //   filteredSpaces = filteredSpaces.filter((s) => {
      //     const result = s.amenities.map(am => am.id).filter(am => this.filters.amenities.includes(am.toString()));
      //     return result.length > 0;
      //   });
      // }
      return filteredSpaces;
    },
    finalStructureSpaces() {
      const obj = {};
      this.filteredSpaces?.forEach((sp) => {
        if (!obj[sp.building_id]) {
          const building = this.buildings.find((b) => b.id === sp.building_id);
          obj[sp.building_id] = {
            id: sp.building_id,
            title: building?.name || "",
            noSpacesResult: 0,
            child: {},
            buildingDetails: building,
          };
        }
        if (!obj[sp.building_id].child[sp.floor_id]) {
          const floor = this.floors?.find((f) => f.id === sp.floor_id) || null;
          obj[sp.building_id].child[sp.floor_id] = {
            id: sp.floor_id,
            title: `Floor ${floor.no}` || "",
            subtitle: "", //`${this.availableSpaces.floor[sp.floor_id].available} Units Available`,
            noSpacesResult: 0,
            fullyLeased:
              this.availableSpaces.floor[sp.floor_id].available === 0 &&
              !sp.is_public
                ? "FULLY LEASED"
                : "",
            child: {},
            floorDetails: floor,
          };
        }

        let spaceType = "Other Type";
        if (
          sp.space_type &&
          [
            "Team office",
            "Management office",
            "Executive office",
            "Default office",
          ].includes(sp.space_type.name)
        ) {
          // team, management, executive offices space types
          spaceType = "Office";
        } else if (sp.space_type && sp.space_type.name === "Meeting room") {
          spaceType = "Meeting Room";
        } else if (sp.space_type && sp.space_type.name === "Open Space") {
          spaceType = "Open Space";
        }

        const isLeased =
          sp.available === true ? sp.is_public !== true : sp.is_public !== true;
        const spaceObject = {
          id: sp.id,
          title: sp.alias ? `Space ${sp.alias}` : `Space ${sp.space_code}`,
          surface: `${sp.sqm} sqm`,
          workplaces: `${sp.workplaces} workspaces`,
          isLeased: isLeased ? "LEASED" : "",
          spaceDetails: sp,
        };

        const typeGroup =
          obj[sp.building_id].child[sp.floor_id].child[spaceType] || [];

        // Find existing group for this space_code
        const existingGroup = typeGroup.find(
          (group) =>
            Array.isArray(group) &&
            group[0].spaceDetails.space_code === sp.space_code
        );

        if (existingGroup) {
          // Add the space to the existing group
          existingGroup.push(spaceObject);
        } else {
          // Create a new group for this space_code
          typeGroup.push([spaceObject]);
        }
        obj[sp.building_id].child[sp.floor_id].child[spaceType] = typeGroup;

        obj[sp.building_id].noSpacesResult++;
        obj[sp.building_id].child[sp.floor_id].noSpacesResult++;
      });

      // Object.values(obj).forEach((building) => {
      //   Object.values(building.child).forEach((floor) => {
      //     Object.values(floor.child).forEach((typeGroup) => {
      //       typeGroup.forEach((group) => {
      //         // Sort the grouped spaces by space_code and alias
      //         this.sortItems(group, "alias", "space_code");
      //       });
      //       // Sort the groups themselves by alias (optional, to organize arrays of arrays)
      //       typeGroup.sort((a, b) =>
      //         a[0].spaceDetails.alias.localeCompare(b[0].spaceDetails.alias)
      //       );
      //     });
      //   });
      // });
      console.log(obj);
      return obj;
    },
    requestOfferSpaces() {
      return this.$store.state?.requestOffer?.spaces || [];
    },
    landlordDetails() {
      return this.$store.state.base.landlordDetails;
    },
    activeSpace() {
      return (
        parseInt(this.$route.params.spaceId) ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot
      );
    },
    currentSpace() {
      return (
        this.landlordDetails?.spaces.find((s) => s.id === this.activeSpace) ||
        {}
      );
    },
    shortlistState() {
      return this.$store.getters?.getShortlist || false;
    },
    currentSpaceIdStore() {
      return this.$store.state.space?.currentSpaceId || -1;
    },
    collapsedSpace() {
      // let space = {};
      // if (this.currentSpaceIdStore === this.activeSpaceId) {
      //   space =
      //     this.landlordDetails?.spaces.find(
      //       (s) => s.id === parseInt(this.currentSpaceIdStore)
      //     ) || {};
      // } else {
      //   space =
      //     this.landlordDetails?.spaces.find(
      //       (s) => s.id === parseInt(this.activeSpaceId)
      //     ) || {};
      // }
      return (
        this.landlordDetails?.spaces.find(
          (s) => s.id === parseInt(this.activeSpaceId)
        ) || {}
      );
    },
  },
  watch: {
    // spaces(val) {
    //   this.filteredSpaces = val;
    // },
    spaces: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.filteredSpaces = newVal;
        }
      },
    },
    currentSpaceIdStore(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activeSpaceId = newVal;
      }
    },
    activeSpaceId(newVal, oldVal) {
      console.log("activeSpaceId watcher...");
      console.log("newVal", newVal);
      console.log("oldVal", oldVal);
    },
  },
  methods: {
    getFirstAvailableItem(spaces) {
      return spaces.find(
        (space) => space.spaceDetails.is_public && space.spaceDetails.available
      );
    },
    checkCollapseEvent(spaceRef, useId = false, parent = false) {
      if (!this.collapsedSpace) return false;
      const building =
        this.buildings?.find((b) => b.id === this.collapsedSpace.building_id) ||
        null;
      if (!building) return false;
      const code = !useId
        ? this.collapsedSpace.space_code
        : `${this.collapsedSpace.space_code}_${this.collapsedSpace.id}`;

      let spaceCollapsedCode = `${building.code}_${code}`;
      if (parent) {
        spaceCollapsedCode += "_parent";
      }
      return spaceRef === spaceCollapsedCode;
    },
    allowViewIn3DButton(space, currentSpaceFloorId) {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      if (!routeNames.includes(this.$route.name)) return true;
      return !(
        this.activeProject.id === space.project_id &&
        space.floor_id === currentSpaceFloorId
      );
    },
    allowRequestOfferButton(space, currentSpaceFloorId) {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      if (!routeNames.includes(this.$route.name)) return true;
      return (
        this.activeProject.id === space.project_id &&
        space.floor_id === currentSpaceFloorId
      );
    },
    initActiveSpace() {
      const urlSpace =
        this.$route.params.spaceId ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot;
      if (!urlSpace) return;
      const spaceConstants = this.$store.getters.constants.space;
      this.$store.dispatch(
        spaceConstants.withNamespace(spaceConstants.action.TOGGLE_SPACE_VIEW),
        true
      );
      this.$store.dispatch(
        spaceConstants.withNamespace(
          spaceConstants.action.CHANGE_CURRENT_SPACE
        ),
        Number(urlSpace)
      );
    },
    openRequestOfferModal(space, building) {
      const spaceFloor =
        this.landlordDetails?.floors?.find((f) => f.id === space.floor_id) ||
        null;
      const spaceData = {
        spaceId: space.space_code,
        building: building.code,
        floor: spaceFloor.code,
      };
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
        null,
        { root: true }
      );
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.ADD_SPACE),
        spaceData
      );

      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "request-offer",
          data: { space: { ...space, building } },
        }
      );
      document.body.classList.add("disable-scroll");
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) end = dc.length;
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    viewOrHideSpace(
      open,
      spaceCode,
      buildingCode,
      floorCode,
      space,
      useId = false,
      group = false
    ) {
      const routeNames = [
        "Space",
        "SpecificProjectSpace",
        "SpecificProjectSpaceEmbed",
        "SpecificSpace",
        "SpecificSpaceEmbed",
      ];
      if (routeNames.includes(this.$route.name)) {
        const spaceConstants = this.$store.getters.constants.space;
        if (open) {
          this.activeSpaceId = space.id;
          this.$store.dispatch(
            spaceConstants.withNamespace(
              spaceConstants.action.TOGGLE_SPACE_VIEW
            ),
            true
          );
          this.$store.dispatch(
            spaceConstants.withNamespace(
              spaceConstants.action.CHANGE_CURRENT_SPACE
            ),
            space.id
          );
          this.clickedHighlight = !useId
            ? `${buildingCode}_${spaceCode}`
            : `${buildingCode}_${spaceCode}_${space.id}`;
          if (group) {
            this.clickedHighlight += "_group";
          }

          if (useId) {
            this.$store.dispatch(
              spaceConstants.withNamespace(
                spaceConstants.action.CHANGE_CURRENT_SPACE
              ),
              space.id
            );
          }

          if (window.innerWidth < 768) {
            if (document.querySelector(".building-highlight-info")) {
              document.querySelector(".building-highlight-info").style.display =
                "block";
            }
          }
        } else {
          this.$store.dispatch(
            spaceConstants.withNamespace(
              spaceConstants.action.CHANGE_CURRENT_SPACE
            ),
            null
          );
          this.$store.dispatch(
            spaceConstants.withNamespace(
              spaceConstants.action.TOGGLE_SPACE_VIEW
            ),
            false
          );
          this.resetView();
          this.clickedHighlight = "";
          if (window.innerWidth < 768) {
            if (document.querySelector(".building-highlight-info")) {
              document.querySelector(".building-highlight-info").remove();
            }
          }
        }
      }
      if (this.defaultOpen && this.clickedHighlight) {
        this.$nextTick(() => {
          this.$refs[this.clickedHighlight][0].$el.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        });
      }
    },
    resetView() {
      // this.buildingInteractions.resetHighlightVisibility();
      // this.babylonHelper._activeHighlight = null;
      // this.babylonHelper.floorHighlightHelper().disablePins(true);
    },
    viewSpace(spaceCode, buildingCode, floorCode, space) {
      this.buildingStore.dispatch("viewSpace", {
        space: spaceCode,
        building: buildingCode,
        floor: floorCode,
        spaceData: space,
      });
      space.building = buildingCode;
      // this.babylonHelper.setSpace(space);
      // this.babylonHelper._activeHighlight = `highlight_${ buildingCode }_${ space.space_code }`;
      // this.babylonHelper.floorHighlightHelper().disablePins(false);
      // this.babylonHelper.buildingInteractionHelper().showHighlightUnit(`${ buildingCode }_${ spaceCode }`, buildingCode);
    },
    handleTabChange(tab) {
      this.activeTab = tab;
    },
    exploreSpace(spaceId, spaceProjectId) {
      const project =
        this.$store.state?.project?.projects?.find(
          (p) => p.id === spaceProjectId
        ) || null;
      if (project?.slug !== this.activeProjectSlug) {
        const projectConstants = this.$store.getters.constants.project;
        this.$store.dispatch(
          projectConstants.withNamespace(
            projectConstants.action.CHANGE_ACTIVE_PROJECT
          ),
          project.slug
        );
      }
      const routePageData = this.$route.meta.page;
      this.$router.push({
        name: routePageData.isEmbedReady
          ? "SpecificSpaceEmbed"
          : "SpecificSpace",
        params: {
          projectId: this.activeProjectSlug,
          spaceId,
        },
      });
    },
    toggleDrawer() {
      this.$refs["drawer"]?.toggleFilter();
      if (this.$refs[`building_${this.$store.state.space.space.building_id}`]) {
        this.$refs[
          `building_${this.$store.state.space.space.building_id}`
        ][0]?.open();
      }
      if (this.$refs[`floor_${this.$store.state.space.space.floor_id}`]) {
        this.$refs[
          `floor_${this.$store.state.space.space.floor_id}`
        ][0]?.open();
      }
    },
    closeDrawer() {
      this.$refs["drawer"]?.close();
    },
    openDrawer() {
      this.$refs["drawer"]?.open();
    },
    sortItems(group, key1, key2) {
      group.naturalSort((a, b) => {
        if (a.spaceDetails[key1] === b.spaceDetails[key1]) {
          return a.spaceDetails[key2].localeCompare(b.spaceDetails[key2]); // Sort by key2 if key1 are the same
        }
        return a.spaceDetails[key1].localeCompare(b.spaceDetails[key1]); // Otherwise, sort by key1
      });
    },
    // Helper function for natural sorting
    naturalSort(a, b) {
      const regex = /(\D+)(\d+)/; // Match alphabetic and numeric parts
      const [, aPrefix, aNumber] = a.match(regex);
      const [, bPrefix, bNumber] = b.match(regex);

      // First compare alphabetic parts
      if (aPrefix !== bPrefix) return aPrefix.localeCompare(bPrefix);

      // If alphabetic parts are the same, compare numeric parts as numbers
      return parseInt(aNumber, 10) - parseInt(bNumber, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.organism-availability-drawer {
  z-index: 99999;

  .molecule-filters {
    margin-bottom: 1rem;
  }

  &::v-deep {
    .drawer-content {
      .drawer-content-wrapper {
        overflow-y: auto;
        height: calc(100vh - 1.2rem);
        @media only screen and (max-width: 767px) {
          height: calc(100vh - 15%);
        }
      }
    }

    .atom-collapsible-item {
      &.parent-box {
        background: $darkerWhite;
        margin-bottom: 0.25rem;

        .item-header {
          padding: 0.5rem 0.75rem 0.5rem 1.25rem;
          @media only screen and (max-width: 767px) {
            padding: 0 0 0 0.5rem;
          }

          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .title-text {
              @include font-main(1.375rem, $black, $regular, 1.625rem);
              @media only screen and (max-width: 767px) {
                @include font-main(1.125rem, $black, $regular, 1.375rem);
              }
            }

            .circle-sum {
              display: flex;
              justify-content: center;
              align-items: center;
              background: $black;
              border-radius: 1.875rem;
              margin-left: 0.625rem;
              padding: 0.125rem 0.5rem;
              @include font-main(0.875rem, $white, $bold, 1.1rem);
            }
          }
        }

        .item-content {
          margin: 0 0.5rem;
          @media only screen and (max-width: 767px) {
            margin: 0;
          }
        }

        &.active {
          background: $lightGrey;
        }
      }

      &.child-box {
        background: $white;
        margin-bottom: 0.25rem;

        .category-title {
          font-weight: $medium;
          display: block;
          margin: 0.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 0.875rem;
          }
        }

        &.active {
          .item-header {
            .title-box {
              .title-child {
                color: $white;
                background: $black;
              }
            }
          }
        }

        .item-header {
          padding: 0;

          &:hover {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }

          .title-box {
            .title {
              background: $white;
              color: $black;
              padding: 0.313rem 0.5rem;
              gap: 0.25rem;
              border-radius: 0.125rem;
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                font-size: 0.875rem;
              }
            }

            span {
              @include font-main(0.875rem, $black, $regular, 1.188rem);

              b {
                @include font-main(1rem, $black, $medium, 1.188rem);
              }
            }
          }

          .icon-button {
            margin-right: 0.5rem;

            .expand-text {
              display: none;
            }
          }
        }

        .item-content {
          padding: 0.5rem 0;
        }
      }

      &.subchild-box {
        background: $darkerWhite;
        margin-bottom: 0.25rem;

        .item-header {
          &:hover {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }

          .title-box {
            .title {
              background: $white;
              color: $black;
              padding: 0.313rem 0.5rem;
              gap: 0.25rem;
              border-radius: 0.125rem;
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                font-size: 0.75rem;
              }
            }

            span {
              @media only screen and (max-width: 767px) {
                font-size: 0.75rem;
              }
            }

            .surface {
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                font-size: 0.75rem;
              }

              .icon {
                font-size: 0.875rem !important;
              }
            }

            .cardinal-point {
              margin-right: 0.125rem;
              @media only screen and (max-width: 767px) {
                font-size: 0.75rem;
              }

              .icon {
                font-size: 0.875rem !important;
              }
            }
          }
        }

        .item-content {
          .content-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;

            .space-box {
              display: flex;
              align-items: center;
              margin: 0.5rem 0.625rem;
              @media only screen and (max-width: 767px) {
                margin: 0.5rem 0;
              }

              .space-image-box {
                img {
                  width: 5.938rem;
                }
              }

              .space-content-box {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-left: 0.625rem;

                .space-price {
                  margin-top: 0.625rem;
                  @media only screen and (max-width: 767px) {
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    strong {
                      font-size: 0.75rem;
                      line-height: 0.875rem;
                    }
                  }
                }
              }
            }

            .content-buttons {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 0.125rem;
                flex: 1;
              }

              .button-atom {
                padding: 0.25rem 0.45rem;
                height: 2.5rem;
                text-transform: none;
                width: 100%;
                @media only screen and (max-width: 767px) {
                  height: 1.875rem;
                }

                .icon {
                  @media only screen and (max-width: 767px) {
                    font-size: 0.75rem !important;
                  }
                }

                span {
                  text-transform: none;
                  @media only screen and (max-width: 767px) {
                    font-size: 0.75rem;
                    line-height: 1.125rem;
                  }
                }
              }
            }
          }

          .content-buttons {
            .explore-btn {
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.25rem;
              background: $black;
              border-color: $black;

              .icon {
                display: none;
                color: $white !important;
                margin-right: 0.5rem;
              }

              &:hover {
                background: #23cd9a;

                .icon {
                  display: inline-block;
                }

                span {
                  color: $white;
                }
              }
            }

            .shortlist-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.25rem;
              background: $white;
              border: none;

              .icon {
                color: $black !important;
                margin-right: 0.5rem;
              }

              span {
                color: $black;
              }

              &:hover {
                background: #23cd9a;

                .icon {
                  color: $white !important;
                }

                span {
                  color: $white;
                }
              }
            }

            .remove-shortlist-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.25rem;
              background: $white;
              border: none;

              .icon {
                color: $black !important;
                margin-right: 0.5rem;
              }

              span {
                color: $black;
              }

              &:hover {
                background: #ef3075;

                .icon {
                  color: $white !important;
                }

                span {
                  color: $white;
                }
              }
            }

            .request-offer-btn {
              border-radius: 0.25rem;

              &.button-green {
                border: none;
                background: #23cd9a;

                .icon {
                  display: none;
                  margin-right: 0.5rem;
                }

                span {
                  color: $white;
                }

                &:hover {
                  background: $black;

                  .icon {
                    display: inline-block;
                    margin-right: 0.5rem;
                  }
                }
              }
            }
          }
        }

        &.active {
          background: $lightGrey;

          .item-header {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }
        }
      }

      &.space-leased {
        pointer-events: none;
        opacity: 0.7;
        background: repeating-linear-gradient(
          -55deg,
          #ffffff,
          #ffffff 0.125rem,
          #e5eaed 0.125rem,
          #e5eaed 0.313rem
        );

        .item-header {
          .icon-button {
            display: none !important;
          }
        }
      }

      &.spaces-leased {
        opacity: 0.7;
        background: #f5f7f8;

        .space-leased {
          opacity: 1;
        }
      }
    }

    .project-info {
      .project-title-and-address.action-disabled {
        display: none;
      }

      .project-spaces .btn-black {
        display: none;
        background: red;
      }
    }
  }

  .horizontal-menu {
    padding: 0.5rem 0.5rem 0 0.5rem;
    margin-bottom: 1.125rem;
    height: auto !important;
  }

  .top-content {
    background: $white;
    padding: 0 0.5rem;
  }

  .not-found,
  .no-result {
    padding: 0.625rem;
  }

  .fully-leased {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      font-size: 0.875rem;
    }
  }
}
</style>
