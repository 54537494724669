<template>
  <div class="molecule-google-map">
    <div
      ref="map"
      class="google-map"
      :data-marker-id="activeCenterMarkerId"
    ></div>
  </div>
</template>

<script>
import * as $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader";
import config from "../../../config";

export default {
  name: "MoleculeGoogleMap",
  props: {
    mapConfig: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    places: {
      required: false,
      type: [],
      default: function () {
        return [];
      },
    },
    locations: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
    activeCenterMarkerId: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sdkInitialize: false,
      google: null,
      map: null,
      zoom: 15,
      markers: {
        all: [],
      },
      directionsMarkerDefinition: null,
      staticMarkerDefinition: null,
      activeMarker: null,
      directionsService: null,
      directionsDisplay: null,
      geocoder: null,
      bounds: null,
    };
  },
  mounted() {
    const self = this;
    new Loader({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["directions", "geocoder"],
    })
      .load()
      .then((google) => {
        self.google = google;
        self.initMap();
      })
      .catch((e) => console.log);
  },
  updated() {
    this.destroyMap();
    this.markers.all = [];
    this.initMap();
  },
  methods: {
    destroyMap() {
      this.google.maps.event.clearInstanceListeners(window);
      this.google.maps.event.clearInstanceListeners(document);
      this.google.maps.event.clearInstanceListeners(this.$refs.map);
    },
    initMap() {
      const self = this;
      let lat = this.mapConfig.centerPoint.lat;
      let lng = this.mapConfig.centerPoint.lng;
      if (this.activeCenterMarkerId) {
        const center = this.mapConfig.staticMarkers.find(
          (item) => item.id === this.activeCenterMarkerId
        );
        lat = center.lat;
        lng = center.lng;
      }
      const style = this.mapConfig.styleConfig || [];
      const mapOptions = {
        center: {
          lat: lat,
          lng: lng,
        },
        styles: style,
        zoom: this.zoom,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: window.innerWidth > 1000,
      };
      this.map = new this.google.maps.Map(this.$refs.map, mapOptions);
      this.google.maps.event.addListener(this.map, "zoom_changed", function () {
        const zoomChangeBoundsListener = self.google.maps.event.addListener(
          self.map,
          "bounds_changed",
          function (event) {
            const maxZoom = 16;
            if (self.map.getZoom() > maxZoom && self.map.initialZoom === true) {
              // Change max/min zoom here
              self.map.setZoom(maxZoom);
              self.map.initialZoom = false;
            }
            self.google.maps.event.removeListener(zoomChangeBoundsListener);
          }
        );
      });
      this.map.initialZoom = true;

      this.directionsService = new this.google.maps.DirectionsService();
      this.directionsDisplay = new this.google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: "black",
          strokeWeight: 6,
        },
        //preserveViewport: true,
        suppressMarkers: true,
      });
      this.directionsDisplay.setMap(this.map);
      this.geocoder = new this.google.maps.Geocoder();
      this.bounds = new this.google.maps.LatLngBounds();

      this.setupMarkerDefinitions();
      this.generateMarkers();
    },
    fitMarkersToMap(specificMarkers = null) {
      try {
        this.bounds = new this.google.maps.LatLngBounds();
        const markers = specificMarkers
          ? specificMarkers
          : [...this.markers.all];
        markers.forEach((marker) => {
          this.bounds.extend(marker.latlng);
        });
        this.map.fitBounds(this.bounds);
      } catch (e) {
        console.log(e);
      }
    },
    addStaticMarker(lat, lng, id, title, subtitle, icon, selectable = true) {
      return new this.staticMarkerDefinition(
        new this.google.maps.LatLng(lat, lng),
        this.map,
        {
          id: id,
          title: title,
          subtitle: subtitle,
          icon: icon,
          selectable: selectable,
          category: "static-marker",
        }
      );
    },
    addDirectionMarker(
      lat,
      lng,
      id,
      nr,
      icon,
      targetId,
      category,
      transportationType,
      selectable = true
    ) {
      return new this.directionsMarkerDefinition(
        new this.google.maps.LatLng(lat, lng),
        this.map,
        {
          id: id,
          label: nr,
          icon: icon,
          targetId: targetId,
          selectable: selectable,
          category: category,
          transportationType: transportationType,
        }
      );
    },
    generateMarkers() {
      if (this.activeCenterMarkerId) {
        const marker = this.mapConfig.staticMarkers.find(
          (item) => item.id === this.activeCenterMarkerId
        );
        const newMk = this.addStaticMarker(
          marker.lat,
          marker.lng,
          marker.id,
          marker.title,
          marker.subtitle,
          marker.icon,
          true
        );
        newMk.activate();
        this.activeMarker = newMk;
        this.markers.all.push(newMk);
      } else {
        this.mapConfig.staticMarkers.forEach((mk) => {
          const newMk = this.addStaticMarker(
            mk.lat,
            mk.lng,
            mk.id,
            mk.title,
            mk.subtitle,
            mk.icon,
            true
          );

          if (this.$parent.$props.portfolioData) {
            // TODO: refactoring
            const projects = this.$parent.$props.portfolioData.projects;
            const firstProject = projects[0];
            setTimeout(() => {
              if (firstProject.id === mk.id) {
                newMk.activate();
                this.activeMarker = newMk;
              }
            }, 1000);
          }

          this.markers.all.push(newMk);
        });
      }

      if (this.activeCenterMarkerId && this.places) {
        this.places.forEach((categoryData) => {
          categoryData.routes.forEach((mk) => {
            const newMk = this.addDirectionMarker(
              mk.lat,
              mk.lng,
              `${categoryData.category}_${mk.id}`,
              mk.order,
              mk.icon,
              this.activeCenterMarkerId,
              categoryData.category,
              mk.mode,
              true
            );
            this.markers.all.push(newMk);
          });
        });
        return;
      } else {
        this.mapConfig.directionalMarkers.forEach((mk) => {
          const newMk = this.addDirectionMarker(
            mk.lat,
            mk.lng,
            mk.id,
            mk.nr,
            mk.icon,
            mk.fromId,
            "unknown",
            "walking",
            true
          );
          this.markers.all.push(newMk);
        });
      }
      this.fitMarkersToMap();
    },
    setupMarkerDefinitions() {
      const componentScope = this;
      const DirectionsMarker = function (latlng, map, args) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(map);
      };

      DirectionsMarker.prototype = new componentScope.google.maps.OverlayView();

      DirectionsMarker.prototype.draw = function () {
        let self = this;
        let div = this.div;

        if (!div) {
          div = this.div = document.createElement("div");
          div.className = "custom-marker";
          div.setAttribute("data-marker", self.args.id);
          div.setAttribute("data-category", self.args.category);
          div.setAttribute("data-transportation", self.args.transportationType);

          if (
            typeof self.args.selectable !== "undefined" &&
            self.args.selectable === true
          ) {
            let selectElement = document.createElement("span");
            selectElement.className = "select";
            div.appendChild(selectElement);
          }

          if (typeof self.args.label !== "undefined") {
            div.append(self.args.label);
          }

          if (typeof self.args.icon !== "undefined") {
            let oImg = document.createElement("img");
            oImg.setAttribute("src", self.args.icon);
            div.append(oImg);
          }

          if (typeof self.args.marker_id !== "undefined") {
            div.dataset.marker_id = self.args.marker_id;
          }

          div.style.display = "none";

          componentScope.google.maps.event.addDomListener(
            div,
            "click",
            function (event) {
              componentScope.google.maps.event.trigger(self, "click");
              let element = $(
                ".routeTo[data-marker=" +
                  self.args.category +
                  "-" +
                  self.args.id +
                  "]"
              );
              $(".routeTo").removeClass("active");
              componentScope.resetOldHtmlMarker();
              element.addClass("active");
              componentScope.calcRoute(self, element.data("mode"));

              componentScope.markers.all.forEach((mk) => {
                mk.deactivate();
                if (mk.args.id === self.args.id) {
                  mk.activate();
                  this.activeMarker = mk;
                }
              });
              let list = [
                ...document.getElementsByClassName("atom-location-type"),
              ];
              list.forEach((el) => {
                if (el.classList.contains("active")) {
                  const getSiblings = function (elem) {
                    return Array.prototype.filter.call(
                      elem.parentNode.children,
                      function (sibling) {
                        return sibling !== elem;
                      }
                    );
                  };
                  const target = el.querySelector(
                    `[data-marker=${self.args.id}]`
                  );
                  target.classList.toggle("atom-route-active");
                  getSiblings(target).forEach((sibling) => {
                    sibling.classList.remove("atom-route-active");
                  });
                }
              });

              if (!element.parents(".parent").hasClass("active")) {
                element.parents(".parent").addClass("active");
                element
                  .parents(".parent")
                  .find(".changeInfoBoxes .circle")
                  .html("-");
                element.parents(".parent").find(".streetShow").slideDown(300);
              }
            }
          );

          let panes = this.getPanes();
          panes.overlayImage.appendChild(div);
        }

        let point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
          div.style.left = point.x + "px";
          div.style.top = point.y + "px";
        }
      };

      DirectionsMarker.prototype.remove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      };

      DirectionsMarker.prototype.getPosition = function () {
        return this.latlng;
      };

      DirectionsMarker.prototype.hide = function () {
        if (this.div) {
          this.div.style.display = "none";
        }
      };

      DirectionsMarker.prototype.show = function () {
        if (this.div) {
          this.div.style.display = "block";
        }
      };

      DirectionsMarker.prototype.activate = function () {
        if (this.div) {
          this.div.classList.add("active");
        }
      };

      DirectionsMarker.prototype.deactivate = function () {
        if (this.div) {
          this.div.classList.remove("active");
        }
      };

      DirectionsMarker.prototype.resetHtml = function () {
        if (this.div) {
          $(this.div).find(".addTime").empty();
        }
      };

      this.directionsMarkerDefinition = DirectionsMarker;

      const StaticMarker = function (latlng, map, args) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(map);
      };

      StaticMarker.prototype = new componentScope.google.maps.OverlayView();
      StaticMarker.prototype.draw = function () {
        let self = this;
        let div = this.div;

        if (!div) {
          div = this.div = document.createElement("div");
          div.className = "static-marker";
          if (typeof self.args.marker_id !== "undefined") {
            div.dataset.marker_id = self.args.marker_id;
          }

          if (
            typeof self.args.title !== "undefined" ||
            typeof self.args.subtitle !== "undefined"
          ) {
            let title = document.createElement("span");
            title.className = "title-marker";
            title.innerHTML +=
              "<b>" + self.args.title + "</b>" + self.args.subtitle;
            div.append(title);
          }

          componentScope.google.maps.event.addDomListener(
            div,
            "click",
            function (event) {
              componentScope.google.maps.event.trigger(self, "click");

              // make list item active accordingly
              componentScope.markers.all.forEach((mk) => {
                mk.deactivate();
                if (mk.args.id === self.args.id) {
                  mk.activate();
                  this.activeMarker = mk;
                }
              });
              let list = [
                ...document.getElementsByClassName("atom-project-portfolio"),
              ];
              list.forEach((el) => {
                el.classList.remove("active");
                if (el.id === self.args.id) {
                  el.classList.add("active");
                  this.activeMarker = el;
                }
              });
            }
          );

          let panes = this.getPanes();
          panes.overlayImage.appendChild(div);
        }

        let point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
          div.style.left = point.x + "px";
          div.style.top = point.y + "px";
        }
      };

      StaticMarker.prototype.remove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      };

      StaticMarker.prototype.getPosition = function () {
        return this.latlng;
      };

      StaticMarker.prototype.activate = function () {
        if (this.div) {
          this.div.classList.add("active");
        }
      };

      StaticMarker.prototype.deactivate = function () {
        if (this.div) {
          this.div.classList.remove("active");
        }
      };

      this.staticMarkerDefinition = StaticMarker;
    },
    resetOldHtmlMarker() {
      if (!this.activeCenterMarkerId && this.activeMarker) {
        this.activeMarker.resetHtml();
      }
    },
    calcRoute(marker, travelType = "WALKING") {
      this.removeDirections();
      $(".distanceFrom .resultText").hide();
      $(".distanceFrom .resultText span").html("");
      let travelMode = this.google.maps.TravelMode.DRIVING;

      switch (travelType.toLowerCase()) {
        case "bicycling":
          travelMode = this.google.maps.TravelMode.BICYCLING;
          break;
        case "walking":
          travelMode = this.google.maps.TravelMode.WALKING;
          break;
        case "transit":
          travelMode = this.google.maps.TravelMode.TRANSIT;
          break;
      }

      let destination;
      if (typeof marker === "object") {
        destination = marker;
      } else {
        destination = this.markers.all.find((item) => item.args.id === marker);
      }

      let origin = this.activeCenterMarkerId
        ? this.markers.all.find(
            (item) => item.args.id === this.activeCenterMarkerId
          )
        : this.markers.all.find(
            (item) => item.args.id === destination.args.targetId
          );
      let request = {
        origin: origin.getPosition(),
        destination: destination.getPosition(),
        travelMode: travelMode,
      };
      const componentScope = this;
      let start, end;
      this.directionsService.route(request, function (response, status) {
        if (status === componentScope.google.maps.DirectionsStatus.OK) {
          componentScope.directionsDisplay.setDirections(response);
          componentScope.directionsDisplay.setMap(componentScope.map);
          componentScope.activeMarker = destination;
          componentScope.activeMarker.activate();
        } else {
          // alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
        }
      });
    },
    removeDirections() {
      this.directionsDisplay.setMap(null);
      if (this.activeMarker) {
        this.activeMarker.deactivate();
      }
    },
    cleanActiveMarker() {
      if (!this.activeMarker) return;
      this.activeMarker.div.classList.remove("active");
    },
    cleanActiveListItems() {
      if (!this.activeMarker) return;
      let list = [...document.getElementsByClassName("atom-project-portfolio")];
      list.forEach((el) => {
        el.classList.remove("active");
        if (el.id === this.activeMarker.args.id) {
          el.classList.add("active");
        }
      });

      this.markers.all.forEach((mk) => {
        mk.deactivate();
        if (mk.args.id === this.activeMarker.args.id) {
          mk.activate();
          this.activeMarker = mk;
        }
      });
      this.fitMarkersToMap(this.markers.all);
    },
    triggerMarkerById(markerId) {
      this.cleanActiveListItems();
      this.cleanActiveMarker();
      this.activeMarker = this.markers.all.find(
        (item) => item.args.id === markerId
      );
      if (!this.activeMarker) return;
      this.activeMarker.div.classList.add("active");
    },
    displayMarkersByCategory(filters) {
      const markersToHide =
        this.markers.all.filter(
          (mk) =>
            mk.args.category !== filters.selectedCategory &&
            mk.args.id !== this.activeCenterMarkerId
        ) ?? [];
      markersToHide.forEach((mk) => {
        mk.div.style.display = "none";
      });
      const markersToHide2 =
        this.markers.all.filter(
          (mk) =>
            mk.args.category === filters.selectedCategory &&
            mk.args.transportationType !== filters.transportationType &&
            mk.args.id !== this.activeCenterMarkerId
        ) ?? [];
      markersToHide2.forEach((mk) => {
        mk.div.style.display = "none";
      });
      const markersToShow =
        this.markers.all.filter(
          (mk) =>
            (mk.args.category === filters.selectedCategory &&
              mk.args.transportationType === filters.transportationType) ||
            mk.args.category === "static-marker"
        ) ?? [];
      markersToShow.forEach((mk) => {
        mk.div.style.display = "block";
      });
      this.removeDirections();
      this.fitMarkersToMap(markersToShow);
    },
    distanceFrom(address) {
      const self = this;
      this.removeDirections();
      this.resetOldHtmlMarker();
      let searchResult = {
        success: true,
        text: "",
        addressText: "",
        directionsResponse: {},
        queryResults: null,
      };
      this.geocoder.geocode({ address: address }, function (results, status) {
        if (status === "OK") {
          searchResult.queryResults = results;
          let origin = self.markers.all.find(
            (e) => e.args.category === "static-marker"
          );
          let request = {
            origin: origin.getPosition(),
            destination: results[0].geometry.location,
            travelMode: self.google.maps.TravelMode.DRIVING,
          };
          self.directionsService.route(request, function (response, status) {
            if (status == self.google.maps.DirectionsStatus.OK) {
              self.directionsDisplay.setDirections(response);
              self.directionsDisplay.setMap(self.map);
              searchResult.success = true;
              searchResult.text = `${response.routes[0].legs[0].duration.text} - ${response.routes[0].legs[0].distance.text}`;
              searchResult.addressText = `${results[0].formatted_address}`;
              searchResult.directionsResponse = response;
            } else {
              searchResult.success = false;
              searchResult.text =
                "The address was not found please enter the zip code";
              searchResult.directionsResponse = response;
            }
          });
        } else {
          searchResult.success = false;
          searchResult.text = "This address entered is invalid";
          searchResult.directionsResponse = {};
        }
      });
      return searchResult;
    },
  },
};
</script>

<style lang="scss">
.molecule-google-map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 767px) {
    position: relative;
    height: 18.75rem;
  }

  .google-map {
    width: 100%;
    height: 100%;
  }
}

.center-marker {
  background: red url("../../../assets/images/placeholder100x36.jpg") no-repeat
    center center;
  background-size: 1.625rem;
  width: 3.375rem;
  height: 3.438rem;
  position: absolute;
  border-radius: 0.625rem;
  transform: translate(-50%, -100%);
  z-index: 1;

  &:after {
    content: "";
    border-color: red transparent transparent transparent;
    border-style: solid;
    border-width: 0.438rem 0.625rem 0 0.625rem;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    bottom: -0.438rem;
    width: 0;
  }
}

.static-marker {
  background-color: $main-color;
  width: auto;
  height: auto;
  position: absolute;
  padding: 0.313rem;
  transform: translate(-50%, calc(-100% - 0.938rem));
  z-index: 1;
  transition: background-color 0.2s ease-in-out 0s;

  .title-marker {
    display: block;
    transform: translate(0%, 0%);
    @include font-main(0.375rem, $white, $semiBold, 0.438rem);
    b {
      display: block;
      text-transform: uppercase;
      transition: background-color 0.2s ease-in-out 0s;
      @include font-main(0.875rem, $white, $bold, 1.063rem);
    }
  }

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: $main-color;
    border-width: 0.625rem;
    margin-left: -0.625rem;
    transition: border-top-color 0.2s ease-in-out 0s;
  }

  &:hover {
    background-color: $second-color;
    &:after {
      border-top-color: $second-color;
    }
  }

  &.active {
    background-color: $second-color;
    width: auto;
    height: auto;
    padding: 0.625rem;
    z-index: 2;
    &:after {
      border-top-color: $second-color;
    }
    .title-marker {
      @include font-main(0.5rem, $white, $semiBold, 0.625rem);
      b {
        @include font-main(1rem, $white, $bold, 1.25rem);
      }
    }
  }
}

.custom-marker {
  position: absolute;
  transform: translate(-50%, -100%);
  cursor: pointer;
  background: $main-color-opacity-08;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  white-space: nowrap;
  text-align: center;
  @include font-main(1rem, $white, $bold, 2.625rem);

  &.active {
    z-index: 9;
    background: $main-color;
  }

  img {
    max-width: 2.5rem;
    max-height: 2.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    @include prefix(
      transform,
      translate(-50%, -50%),
      ("webkit", "moz", "ms", "o")
    );
  }
}
</style>
