<template>
  <div class="specs-category">
    <div class="category">
      <div :class="{'category-title-components': true, 'active': isActive }" @click="handleClick">
        <span>{{ categoryName }}</span>
        <AtomIcon class="plus-icon" color="#423687" :size="22" icon="plus"/>
        <AtomIcon class="minus-icon" color="#423687" :size="22" icon="minus"/>
      </div>
      <div :class="{'category-items': true, 'active': isActive}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../common/AtomIcon";
export default {
  name: "AtomCategorySpecs",
  components: {AtomIcon},
  props: {
    categoryName: {
      required: true,
      type: String,
      default: ''
    },
    categoryId: {
      required: true,
      type: String,
      default: ''
    },
    activeCategory: {
      required: true,
      type: String,
      default: 'category-0'
    }
  },
  computed: {
    isActive() {
      return this.activeCategory === this.categoryId;
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.categoryId);
    }
  }
}
</script>

<style lang="scss" scoped>
  .specs-category {
    .category-title-components {
      cursor: pointer;
      position: relative;
      padding: 0.938rem 1.25rem;
      border-bottom: 0.063rem solid rgba(0,0,0,0.05);
      @media only screen and (max-width: 767px) {
        padding: 0.938rem 1.25rem;
      }
      span {
        display: block;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        @include font-main(1rem, $black, $regular, 1.188rem);
      }
      .icon {
        position: absolute;
        right: 1.25rem;
        top: 50%;
        font-size: 1.375rem !important;
        color: $main-color !important;
        transform: translateY(-50%);
        @media only screen and (max-width: 767px) {
          right: 1.25rem;
          font-size: 1.375rem !important;
        }
        &.icon-minus {
          display: none;
        }
      }
      &.active {
        background: rgba(134, 123, 194, 0.04);
        .icon {
          &.icon-plus {
            display: none;
          }
          &.icon-minus {
            display: block;
          }
        }
      }
    }
    .category-items {
      display: none;
      border-bottom: 0.063rem solid rgba(0,0,0,0.05);
      &.active {
        display: block;
      }
    }
  }
</style>