var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "lazyload",
            rawName: "v-lazyload",
            value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrl },
            expression: "{handle: handleLazyLoad, url: resourceUrl}"
          }
        ],
        staticClass: "pin-img-wrapper"
      },
      [
        _vm.resourceUrl
          ? _c("img", { attrs: { src: _vm.resourceUrl, alt: "pin image" } })
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "simple-wrapper" }, [
      _vm.pinInfo.pinTitle
        ? _c("h4", { domProps: { innerHTML: _vm._s(_vm.pinInfo.pinTitle) } })
        : _vm._e(),
      _vm.pinInfo.pinDescription
        ? _c("p", {
            domProps: { innerHTML: _vm._s(_vm.pinInfo.pinDescription) }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }