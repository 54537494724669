var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col col-xs-12 col-3 atom-padding" }, [
    _c("div", { staticClass: "atom-team" }, [
      _c("div", { staticClass: "atom-gradient-background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "lazyload",
                rawName: "v-lazyload",
                value: { handle: _vm.handleLazyLoad, url: _vm.assetUrl },
                expression: "{handle: handleLazyLoad, url: assetUrl}"
              }
            ],
            staticClass: "image-team"
          },
          [
            !_vm.isInView
              ? _c("AtomLazyLoader", { attrs: { height: 393 } })
              : _vm._e(),
            _c("picture", [
              _c("source", { attrs: { srcset: _vm.assetUrl } }),
              _c("img", { attrs: { src: _vm.assetUrl, alt: _vm.name } })
            ])
          ],
          1
        ),
        _c("div", { staticClass: "row content-info align-items-center" }, [
          _c("div", { staticClass: "col col-7" }, [
            _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))])
          ]),
          _c("div", { staticClass: "col col-5 align-items-end" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "inline-block" }, [
                _c(
                  "a",
                  {
                    staticClass: "phone-icon icon-transparent",
                    attrs: { href: "tel:" + _vm.nr }
                  },
                  [
                    _c("span", { staticClass: "nr-hover" }, [
                      _vm._v(_vm._s(_vm.nr))
                    ]),
                    _c("AtomIcon", {
                      attrs: { icon: "phone", color: "#fff", size: 20 }
                    })
                  ],
                  1
                )
              ]),
              _vm.urlLinkedin !== ""
                ? _c("div", { staticClass: "inline-block" }, [
                    _c(
                      "a",
                      {
                        staticClass: "linkedin-icon icon-transparent",
                        attrs: { target: "_blank", href: "" + _vm.urlLinkedin }
                      },
                      [
                        _c("AtomIcon", {
                          attrs: { icon: "linkedin", color: "#fff", size: 20 }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "col col-12" }, [
            _c("span", [_vm._v(_vm._s(_vm.position))]),
            _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
              _vm._v(_vm._s(_vm.email))
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }