<template>
  <div ref="menu" :class="{ 'organism-menu-bar': true, scrolled: isScrolled }">
    <div @click="scrollTopMethod">
      <router-link
        tag="div"
        :to="{ name: !getSkipBuildingInit ? 'Home' : 'Space' }"
      >
        <AtomLogo v-if="isScrolled" />
        <AtomLogo v-else white />
      </router-link>
    </div>
    <MoleculeMenuTrigger />
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import MoleculeMenuTrigger from "../molecules/MoleculeMenuTrigger";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../helpers/util";

export default {
  name: "OrganismMenuBar",
  components: { MoleculeMenuTrigger, AtomLogo },
  data() {
    return {
      isScrolled: false,
    };
  },
  computed: {
    getSkipBuildingInit() {
      return this.$store.getters.getSkipBuildingInit;
    },
  },
  mounted() {
    this.isScrolled = window.scrollY > 40;
    document.addEventListener("scroll", this.initScrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.initScrollListener);
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    },
    scrollTopMethod() {
      const sections = this.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[0];
        const targetString = convertUCWordsOrCamelCaseToDashCase(
          targetSection.type
        );
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  top: 4.375rem;
  left: 0;
  right: 0;
  padding: 0.625rem 5.625rem;
  z-index: 9;
  transition: 0.5s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    top: 0;
    padding: 10px 20px;
  }
  &.scrolled {
    top: 0;
    background: $white;
    .atom-logo {
      img {
        max-width: 5.625rem;
        max-height: 4.652rem;
        @media only screen and (max-width: 767px) {
          max-width: 52px !important;
          max-height: 52px !important;
        }
      }
    }
    .molecule-menu-trigger {
      border-color: $black;
      width: 3.125rem;
      height: 3.125rem;
      @media only screen and (max-width: 767px) {
        width: 52px;
        height: 52px;
      }
      &:hover {
        background: $black;
        .icon {
          color: $white !important;
        }
      }
      &.active {
        border-color: $white;
        &:hover {
          background: transparent;
          .icon {
            color: $black !important;
          }
        }
      }
      .icon {
        font-size: 1.25rem !important;
        color: $black !important;
        @media only screen and (max-width: 767px) {
          font-size: 18px !important;
        }
      }
    }
  }
}
</style>
