var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "organism-building",
      style: { height: _vm.windowHeight + "px" }
    },
    [
      _c(
        "div",
        {
          class: { "in-floor": _vm.isFloorView },
          style: { height: _vm.windowHeight + "px" },
          attrs: { id: "canvas-wrapper" }
        },
        [
          _c("canvas", {
            ref: "canvas",
            style: { height: _vm.windowHeight + "px" },
            attrs: { id: "project-canvas" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }