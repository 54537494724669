import { FreeCameraKeyboardWalkInput } from "@/components/organisms/project/building/store/3d/builders/FreeCameraKeyboardWalkInput";

export class InteractionManager {
  engine: BABYLON.Engine;
  constructor(engine: BABYLON.Engine) {
    this.engine = engine;
  }

  addDefaultRotationControls(camera: BABYLON.ArcRotateCamera) {
    camera.attachControl(this.engine.getRenderingCanvas());
    camera.inputs.remove(camera.inputs.attached.mousewheel);
  }

  addVirtualTourControls(camera: BABYLON.UniversalCamera, options: unknown) {
    //// Set camera properties
    Object.assign(camera, options);

    //// Customize keyboard
    const keyboard = camera.inputs.attached
      .keyboard as BABYLON.FreeCameraKeyboardMoveInput;

    keyboard.keysUp = [38, 87];
    keyboard.keysDown = [40, 83];
    keyboard.keysLeft = [81];
    keyboard.keysRight = [69];
    keyboard.keysRotateLeft = [39, 68];
    keyboard.keysRotateRight = [37, 65];

    camera.invertRotation = true;
    const walkInput = new FreeCameraKeyboardWalkInput();
    walkInput.camera = camera;
    camera.inputs.remove(camera.inputs.attached.touch);
    camera.inputs.add(walkInput);
  }

  setVisibilityOfNodes(objects: string[], scene: BABYLON.Scene, visibility: number) {
    this.parseNodes(objects, scene, {
      visibility: visibility,
    });
  }
  enableNodes(
    objects: string[],
    scene: BABYLON.Scene,
    isVisible: boolean,
    isPickable: boolean,
    enablePointerMoveEvents: boolean
  ) {
    this.parseNodes(objects, scene, {
      isVisible: isVisible,
      isPickable: isPickable,
      enablePointerMoveEvents: enablePointerMoveEvents,
    });
  }
  parseNodes(
    objects: string[],
    scene: BABYLON.Scene,
    options: Record<string, unknown>
  ) {
    for (const object of objects) {
      const node = scene.getNodeByName(object);
      if (!node) return console.error("Node not defined", object);
      const meshes = node.getChildMeshes();
      for (const mesh of meshes) {
        Object.assign(mesh, options);
      }
    }
  }

  addNodesToPointer(
    objects: string[],
    scene: BABYLON.Scene,
    isPickable: boolean,
    enablePointerMoveEvents: boolean
  ) {
    this.parseNodes(objects, scene, {
      isPickable: isPickable,
      enablePointerMoveEvents: enablePointerMoveEvents,
    });
  }

  attachControl(camera: BABYLON.ArcRotateCamera | BABYLON.UniversalCamera) {
    camera.attachControl(this.engine.getRenderingCanvas());
  }

  detachControl(camera: BABYLON.ArcRotateCamera | BABYLON.UniversalCamera) {
    camera.detachControl();
  }
}
