<template>
  <div class="technical-specs-info">
      <div class="image-technical-specs" v-lazyload="{handle: handleLazyLoad, url: resourceUrl}">
        <AtomLazyLoader :height="450" v-if="!imageLoaded" />
        <picture>
          <source :srcset="resourceUrl">
          <img :src="resourceUrl" alt="Technical Specifications">
        </picture>
        <div class="organism-technical-specs">
          <AtomBulletSpecsNoCategory v-for="(bullet, index) in bulletTechnicalData" :show-top="bullet.showTop" :show-bottom="bullet.showBottom" :show-right="bullet.showRight" :show-left="bullet.showLeft" :show-center="bullet.showCenter" :image="bullet.image" :category="bullet.category.label" :text="bullet.text" :top-postion="bullet.top" :right-postion="bullet.right" :bottom-postion="bullet.bottom" :left-postion="bullet.left" :active-bullet="activeBullet" :bullet-id="`item-${bullet.id}`" :key="index" @change="$event => handleBulletChange($event, bullet.category)" />
        </div>
      </div>
      <div class="info-mobile">
        <AtomCategorySpecs v-for="(category, i) in specsByCategory" :category-id="`id-${category.slug}`" :active-category="activeCategory" :category-name="category.label" :key="i" @change="handleCategoryChange">
          <AtomSpecsInfo v-for="(bullet, index) in category.items" :ref="`item-${bullet.id}`" :id="bullet.id" :text="bullet.text" :active-info="activeBullet" :info-id="`item-${bullet.id}`" :key="index" @change="handleBulletChange" />
        </AtomCategorySpecs>
      </div>
  </div>
</template>

<script>
import AtomBulletSpecsNoCategory from "../../atoms/common/AtomBulletSpecsNoCategory";
import AtomCategorySpecs from "../../atoms/project/AtomCategorySpecs";
import AtomSpecsInfo from "../../atoms/common/AtomSpecsInfo";
import AtomLazyLoader from "../../atoms/common/AtomLazyLoader";

export default {
  name: "OrganismTechnicalSpecsV2",
  components: {AtomLazyLoader, AtomSpecsInfo, AtomCategorySpecs, AtomBulletSpecsNoCategory},
  props: {
    bulletTechnicalData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
    imageSpecs: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    resourceUrl() {
      if (this.imageSpecs.includes("://")) {
        return this.imageSpecs;
      }
      return `${this.$store.getters.cdnBase}/${this.imageSpecs}`;
    },
    specsByCategory() {
      const categories = [];
      this.bulletTechnicalData.forEach(i => {
        const item = {...i};
        let category = categories.find(e => e.slug === item.category.slug);
        if (!category) {
          category = {...item.category, items: []};
          categories.push(category);
        }
        delete item.category;
        category.items.push(item);
      });
      return categories;
    }
  },
  data() {
    return {
      activeBullet: 'item-bullet1',
      activeCategory: 'category-space',
      isActive: false,
      isInView: false,
      imageLoaded: false,
    }
  },
  methods: {
    handleBulletChange(id, category) {
      this.activeBullet = id;
      this.$nextTick(() => {
        if (category) {
          this.handleCategoryChange(`id-${category.slug}`);
          setTimeout(() => {
            this.$refs[`${id}`][0].$el?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "nearest",
            });
          }, 100);
        }
      });
    },
    handleCategoryChange(id) {
      this.activeCategory = id;
      this.$emit('category-change', id);
    },
    handleLazyLoad() {
      this.isInView = true;
      const imageElement = new Image;
      if (imageElement) {
        imageElement.addEventListener("load", () => {
          this.imageLoaded = true;
        });
        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = this.resourceUrl;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
}
</script>

<style lang="scss" scoped>
  .technical-specs-info {
    .row {
      flex-direction: row-reverse;
    }
    .organism-technical-specs {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 5;
    }
    .info-mobile {
      display: none;
      overflow-y: auto;
      @media only screen and (max-width: 767px) {
        display: block;
        margin: 0.625rem 0 1.25rem;
        max-height: 18.438rem !important;
      }
    }
  }
</style>