<template>
  <div :class="{'atom-specs-info': true, 'active': isActive}" @click="handleClick">
    <p v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: "AtomSpecsInfo",
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: ""
    },
    text: {
      required: false,
      type: String,
      default: ""
    },
    infoId: {
      required: true,
      type: String,
      default: ''
    },
    activeInfo: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeInfo === this.infoId;
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.infoId);
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-specs-info {
    cursor: pointer;
    padding: 0.625rem 1.875rem;
    background: rgba(134, 123, 194, 0.04);
    transition: all .2s ease-in-out 0s;
    @media only screen and (max-width: 900px) {
      padding: 0.25rem 1.25rem;
    }
    &:last-child {
      border: none;
    }
    p {
      transition: all .2s ease-in-out 0s;
      @include font-main(1rem, $black, $regular, 1.5rem);
      @media only screen and (max-width: 767px) {
        @include font-main(0.875rem !important, $black, $regular, 1.125rem);
      }
      &::v-deep {
        a {
          color: #007dba;
          @media only screen and (max-width: 767px) {
            @include font-main(0.875rem !important, #007dba, $regular, 1.125rem);
          }
        }
      }
    }
    &.active, &:hover {
      background: $main-color;
      p {
        color: $white;
      }
    }
  }
</style>