const deviceType = () => {
    // Enhanced checks for iPad on iOS 13 and above
    const isIpadOnIOS13OrAbove = () =>
        navigator.userAgent.match(/Macintosh/i) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1;

    // Basic check for tablets
    const isTabletBasic = () =>
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent);

    // Additional checks for mobile devices
    const isMobile = () =>
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            navigator.userAgent
        );

    // Enhanced checks for Windows tablets
    const isWindowsTablet = () =>
        /windows/i.test(navigator.userAgent) &&
        /touch/i.test(navigator.userAgent) &&
        !/phone/i.test(navigator.userAgent);

    // Combining checks for a comprehensive tablet detection
    const isTablet = () => isTabletBasic() || isIpadOnIOS13OrAbove() || isWindowsTablet();

    // Decision-making based on the above checks
    if (isTablet()) {
        return 'tablet';
    } else if (isMobile()) {
        return 'mobile';
    } else {
        return 'desktop';
    }
};

const isMobile = () => {
    return deviceType() === "mobile";
}

const isTablet = () => {
    return deviceType() === "tablet";
}

const isDesktop = () => {
    return deviceType() === "desktop";
}

const isMobileiOS = () => {
    return /iPhone|iPod/.test(navigator.userAgent);
}

const isTabletiOS = () => {
    return /iPad/.test(navigator.userAgent) && (navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
}

const isIOSDevice = () => {
  return isMobileiOS() || isTabletiOS();
}

export { deviceType, isTablet, isMobile, isDesktop, isIOSDevice };
