var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMobileSelectedSpace ||
    (_vm.isEmbedReady && _vm.selectedSpace.floor)
    ? _c(
        "div",
        {
          staticClass: "organism-selected-space-mobile",
          class: { "embed-selected-space": _vm.isEmbedReady }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-8" }, [
              _c("div", { staticClass: "space-info" }, [
                !_vm.spaceIsRequested(_vm.selectedSpace.id)
                  ? _c(
                      "span",
                      { staticClass: "line-element" },
                      [_c("AtomIcon", { attrs: { icon: "minus" } })],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "line-element line-checked" },
                      [_c("AtomIcon", { attrs: { icon: "ok" } })],
                      1
                    ),
                _c("span", { staticClass: "sqm-info" }, [
                  _c("b", [_vm._v(_vm._s(_vm.selectedSpace.sqm))]),
                  _vm._v(" " + _vm._s(_vm.unitOfMeasureString) + " GLA")
                ]),
                _c("span", { staticClass: "people-info" }, [
                  _c("b", [_vm._v(_vm._s(_vm.spacePeople))]),
                  _vm._v(" People")
                ])
              ])
            ]),
            _c("div", { staticClass: "col col-4 text-right" }, [
              _c("span", { staticClass: "floor-info" }, [
                _vm._v("Floor "),
                _c("b", [_vm._v(_vm._s(_vm.selectedSpace.floor.no))])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col col-6" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn-edit",
                    attrs: {
                      tag: "a",
                      to: {
                        name: _vm.isEmbedReady
                          ? "SpecificSpaceEmbed"
                          : "SpecificSpace",
                        params: {
                          projectId: _vm.activeProject,
                          spaceId: _vm.selectedSpace.id
                        }
                      }
                    }
                  },
                  [
                    _c("AtomIcon", { attrs: { icon: "cube-1", size: 15 } }),
                    _vm._v(" 3D Explore")
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col col-6" }, [
              _c("div", { staticClass: "wrapper-btn" }, [
                !_vm.spaceIsRequested(_vm.selectedSpace.id)
                  ? _c(
                      "a",
                      {
                        staticClass: "btn-select btn-select-show",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.addSpaceToCart(_vm.selectedSpace)
                          }
                        }
                      },
                      [
                        _c("AtomIcon", {
                          attrs: { icon: "list-checked", size: 15 }
                        }),
                        _vm._v(" Add to my list")
                      ],
                      1
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "btn-select btn-remove-item",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.removeSpaceFromCart(_vm.selectedSpace)
                          }
                        }
                      },
                      [
                        _c("AtomIcon", { attrs: { icon: "minus", size: 15 } }),
                        _vm._v("  Remove from list")
                      ],
                      1
                    )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }