<template>
  <div>
    <div class="pin-img-wrapper" v-lazyload="{handle: handleLazyLoad, url: resourceUrl}">
      <img v-if="resourceUrl" :src="resourceUrl" alt="pin image">
    </div>
    <div class="simple-wrapper">
      <h4 v-if="pinInfo.pinTitle" v-html="pinInfo.pinTitle"></h4>
      <p v-if="pinInfo.pinDescription" v-html="pinInfo.pinDescription"></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "AtomPinImage",
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function (){
        return {
          pinTitle: "",
          pinDescription: "",
          imageSrc: ""
        }
      }
    },
  },
  computed: {
    resourceUrl() {
      if (!this.isInView) return '';
      if (this.pinInfo.imageSrc.includes("://")) {
        return this.pinInfo.imageSrc;
      }
      return `${this.$store.getters.cdnBase}/${this.pinInfo.imageSrc}`;
    },
  },
  data() {
    return {
      isInView: false,
    }
  },
  mounted() {
    this.isInView = true;
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss">
  .pin-img-wrapper{
    text-align: center;
    img {
      display: inline-block;
      border-radius: 5px 5px 0 0;
    }
  }
</style>