var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "molecule-menu-trigger": true, active: _vm.isOpen },
      on: { click: _vm.toggleSidebar }
    },
    [
      _c("AtomIcon", { attrs: { icon: "bars3", size: 32, color: "#000" } }),
      _c("AtomIcon", { attrs: { icon: "xmark", size: 32, color: "#000" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }