<template>
  <div class="section section-few-facts" :class="{[`section${orderNo}`]: true}" v-if="data.enabled !== false">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
    </div>
    <AtomFacts :facts="data.facts" :columns="data.columns" />
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomFacts from "../../components/atoms/common/AtomFacts";

export default {
  name: "SectionFewFacts",
  components: {AtomFacts, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="scss">
.section-few-facts {
  border-bottom: 0.063rem solid #ecf5f8;
  text-align: center;
  padding: 4.688rem 3.75rem 3.75rem;
  @media only screen and (max-width: 900px) {
    padding: 4.688rem 0.625rem 3.75rem;
  }
  @media only screen and (max-width: 767px) {
    padding: 2.188rem 0.625rem 1.875rem;
  }
  .atom-title {
    margin-bottom: 1.875rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 1.25rem;
    }
    h2 {
      text-transform: none;
      @include font-main(3.25rem, $black !important, $regular, 3.875rem);
      @media only screen and (max-width: 767px) {
        @include font-main(1.375rem, $black !important, $regular, 1.625rem);
      }
      b {
        @include font-main(3.25rem, $black, $bold, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1.375rem, $black, $bold, 1.625rem);
        }
      }
    }
    h3 {
      @include font-main(2.75rem, $black !important, $light, 3.25rem);
      @media only screen and (max-width: 767px) {
        @include font-main(1rem, $black !important, $light, 1.375rem);
      }
    }
  }
}
</style>