<template>
  <div :class="{section: true, 'section-explore-building': true, [`section${orderNo}`]: true}" v-if="data.enabled !== false">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.title">
      <AtomTitle color="rgba(0,0,0,0.6)" color2="#000" :title="data.title" :subtitle="data.subtitle" />
    </div>
    <OrganismExplore :explore-card="data.items" :columns="data.columns" />
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import OrganismExplore from "../../components/organisms/common/OrganismExplore";
export default {
  name: "SectionExploreBuilding",
  components: {OrganismExplore, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  }
}
</script>

<style lang="scss">
  .section-explore-building {
    padding: 2rem 0 1.5rem;
    @media only screen and (max-width: 767px) {
      padding: 20px 0 10px;
    }
    .atom-title {
      padding: 0 3.75rem;
      margin-bottom: 3.125rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
        padding: 0 10px;
      }
      h2 {
        color: $black !important;
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        text-transform: none;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
        }
        b {
          font-size: 3.25rem;
          line-height: 3.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
      h3 {
        color: $black !important;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
</style>