var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parent-project" }, [
    _c("div", { staticClass: "project-more-details" }, [
      _vm.project && _vm.project.image
        ? _c("div", { staticClass: "project-image" }, [
            _c("img", {
              attrs: {
                src: _vm.resourceUrl(_vm.project.image),
                alt: _vm.project.name
              }
            })
          ])
        : _vm._e(),
      _c("div", { staticClass: "project-spaces" }, [
        _c("div", { staticClass: "buildings-info" }, [
          _c("div", { staticClass: "row list-info" }, [
            _c("div", { staticClass: "col col-adaptive list-column" }, [
              _c("p", { staticClass: "title" }, [_vm._v("Buildings")]),
              _c("p", { staticClass: "total-number" }, [
                _vm._v(_vm._s(_vm.buildings.length))
              ])
            ]),
            _c("div", { staticClass: "col col-adaptive list-column" }, [
              _c("p", { staticClass: "title" }, [_vm._v("Floors")]),
              _c("p", { staticClass: "total-number" }, [
                _vm._v(_vm._s(this.getBuildingsMaxFloors()))
              ])
            ])
          ])
        ]),
        _vm._m(0),
        _vm.project && _vm.project.address
          ? _c("div", { staticClass: "description-building" }, [
              _c("p", { domProps: { innerHTML: _vm._s(_vm.project.address) } })
            ])
          : _vm._e(),
        _vm._m(1),
        _vm.project && _vm.project.description
          ? _c("div", { staticClass: "description-building" }, [
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.project.description) }
              })
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "apartments-info" }, [
      _c("h4", [_vm._v("Address")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "apartments-info" }, [
      _c("h4", [_vm._v("Description")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }