<template>
  <div class="organism-building" :style="{ height: `${windowHeight}px` }">
    <div
      id="canvas-wrapper"
      :class="{ 'in-floor': isFloorView }"
      :style="{ height: `${windowHeight}px` }"
    >
      <canvas
        ref="canvas"
        id="project-canvas"
        :style="{ height: `${windowHeight}px` }"
      />
    </div>
  </div>
</template>

<script>
import buildingStore, { CurrentView } from "./store";
import config from "../../../../config";
import { BabylonSceneManager } from "@/components/organisms/project/building/store/3d/managers/SceneManager";
import { Color4 } from "babylonjs";
import { isDesktop } from "@/helpers/mobile/DeviceType";

export default {
  name: "OrganismBuilding",
  components: {},
  props: {
    customModel: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    isInitialized() {
      return buildingStore.state.isInitialized;
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    isFloorView() {
      return this.buildingStore.state.currentView === CurrentView.FLOOR;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    buildingLodEnabled() {
      return this.generalConfig.modelDisplaySettings[this.activeProject]
        .building.lodEnabled;
    },
    floorLodEnabled() {
      return this.generalConfig.modelDisplaySettings[this.activeProject].floor
        .lodEnabled;
    },
    selectedSpace() {
      return (
        Number(this.$route.params.spaceId) ||
        this.$store.getters.getFirstAvailableSpaceIdWhenBuildingModelIsNot
      );
    },
    space() {
      return (
        this.$store.state.base?.landlordDetails?.spaces?.find(
          (sp) => sp.id === this.selectedSpace
        ) || { floor: {} }
      );
    },
    landlordDetails() {
      return this.$store.state.base.landlordDetails;
    },
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        window.addEventListener("resize", this.resizeListener);
        if (this.$route.query.v === "debug") {
          this.sceneManager.scene.debugLayer.show({overlay: true});
        }
      }
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      pinData: [],
    };
  },
  beforeCreate() {
    this.sceneManager = BabylonSceneManager;
  },
  beforeDestroy() {
    this.buildingStore.dispatch("destroy");
    this.sceneManager.destroy();
    window.removeEventListener("resize", this.resizeListener);
  },
  mounted() {
    //// Create scene manager
    this.sceneManager.init(this.$refs.canvas);
    //// Add light
    this.sceneManager.addLight();
  },
  methods: {
    initializeBuilding3D() {
      if (this.$store.getters.getActiveProjectSettings.building.pinsData) {
        this.pinData = this.$store.getters.getActiveProjectSettings.building.pinsData;
      }
      const payload = {
        pinsData: this.pinData,
        canvasReference: this.$refs.canvas,
        client: config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
        lodEnabled: this.buildingLodEnabled,
      };
      this.buildingStore.dispatch("destroy");
      this.buildingStore.dispatch("initBuilding", payload);
      //// Model URL
      const url = `${payload.cdnBase}/objects/${payload.client}/${
        payload.activeProject
      }/project/${
        payload.settings.building.versionPath
          ? `${payload.settings.building.versionPath}/`
          : ""
      }`;
      //// Create camera for the scene manager;
      this.sceneManager.addArcRotateCamera(0, 0, 0, {
        x: payload.settings.building.camera.target[0],
        y: payload.settings.building.camera.target[1],
        z: payload.settings.building.camera.target[2],
      });
      this.sceneManager.set3dCameraViewProperties({
        fov: payload.settings.building.camera.fov - 0.35,
        lowerRadiusLimit: payload.settings.building.camera.radiusLowerLimit,
        upperRadiusLimit: payload.settings.building.camera.radiusUpperLimit,
        alpha: payload.settings.building.camera.alpha,
        beta: payload.settings.building.camera.beta,
        radius: payload.settings.building.camera.radius,
        upperBetaLimit: 1.5708,
        minZ: 10,
        panningSensibility: 30,
        layerMask: 0x30000000,
      });
      //// Load scene
      this.sceneManager.load(
        "project",
        url,
        "building.gltf",
        () => {
          this.buildingStore.commit("setLoadingAssetsStatus", true);
          //// Set highlights
          this.sceneManager.disableNode("highlights");
          //// Add pins
          this.sceneManager.initPinManager(payload.cdnBase, this.$store);
          this.sceneManager.addPins(
            payload.pinsData,
            "exterior",
            "",
            "",
            0x30000000
          );
          //// Add events
          this.sceneManager.addBuildingEvents();
          this.sceneManager.add3DCameraObservables();
          //// Start rendering
          this.sceneManager.startRendering(this.sceneManager);
          this.sceneManager.resize();
        },
        ["case"]
      );
      //// Load hdr image
      this.sceneManager.loadHDR(
        `${payload.cdnBase}${payload.settings.building.hdr.texture}`
      );
      //// Set any scene properties
      this.sceneManager.setSceneProperties({
        clearColor: new Color4(0, 0, 0, 0),
      });
      this.sceneManager.loadingManager.startLoading();
    },
    async initializeFloor3D(spaceId) {
      const space = this.spaces.find((s) => s.id === spaceId);
      if (!space) return;

      if (this.$store.getters.getActiveProjectSettings.floor.pinsData) {
        this.pinData = this.$store.getters.getActiveProjectSettings.floor.pinsData;
      }

      const imageProcessing = {
        toneMapping: 0,
        contrast: 1.0,
        exposure: 1.0,
        iblIntensity: 1,
      };

      const building = this.buildings.find((b) => b.id === space.building_id);
      const floor = this.floors.find((f) => f.id === space.floor_id);
      const payload = {
        pinsData: this.pinData,
        space: space.space_code,
        building: building.code,
        floor: floor.code,
        floorType: floor.floor_type.model,
        spaceData: space,
        triggerShowUnitOnBuilding: false,
        canvasReference: this.$refs.canvas,
        client: config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
        lodEnabled: this.floorLodEnabled,
        projects: this.$store.state.project.projects,
        buildings: this.$store.state.project.buildings,
        floors: this.$store.state.project.project.floors,
      };
      this.buildingStore.dispatch("destroy");
      this.buildingStore.dispatch("viewSpace", payload);
      this.buildingStore.dispatch("initFloor", payload);

      this.sceneManager.clearScene();
      if (!this.sceneManager.scene.activeCamera) {
        //// Create camera for the scene manager;
        this.sceneManager.addArcRotateCamera(0, 0, 0, {
          x: payload.settings.floor.camera.target[0],
          y: payload.settings.floor.camera.target[1],
          z: payload.settings.floor.camera.target[2],
        });
      }
      let radius = payload.settings.floor.camera.radius;
      let radiusUpperLimit = payload.settings.floor.camera.radiusUpperLimit;
      if (!isDesktop() && payload.settings.floor.camera.radiusMobile) {
        radius = payload.settings.floor.camera.radiusMobile;
        radiusUpperLimit = payload.settings.floor.camera.radiusMobile;
      }
      this.sceneManager.set3dCameraViewProperties({
        alpha: payload.settings.floor.camera.alpha,
        beta: payload.settings.floor.camera.beta,
        fov: payload.settings.floor.camera.fov,
        panningDistanceLimit:
          payload.settings.floor.camera.panningDistanceLimit,
        lowerRadiusLimit: payload.settings.floor.camera.radiusLowerLimit,
        upperRadiusLimit: radiusUpperLimit,
        radius: radius,
        upperBetaLimit: 1.5708,
        minZ: 1,
        layerMask: 0x30000000,
      });
      this.sceneManager.set3dCameraTarget({
        x: payload.settings.floor.camera.target[0],
        y: payload.settings.floor.camera.target[1],
        z: payload.settings.floor.camera.target[2],
      });
      this.sceneManager.setZoomLimit(radiusUpperLimit);
      //// Create camera for the new scene
      this.sceneManager.addUnivesalCamera(
        {
          x: payload.spaceData.camera_position_x,
          y: payload.spaceData.camera_position_y,
          z: payload.spaceData.camera_position_z,
        },
        {
          x: payload.spaceData.focus_target_x,
          y: payload.spaceData.focus_target_y,
          z: payload.spaceData.focus_target_z,
        }
      );
      const angularSensibility = isDesktop() ? 2000 : 1000;
      this.sceneManager.setVirtualTourProperties({
        speed: payload.settings.floor.virtualTour.camera.speed ?? 0.25,
        angularSensibility: angularSensibility,
        minZ: 0,
        layerMask: 0x30000000,
        fov: payload.settings.floor.virtualTour.camera.fov ?? 0.8,
      });
      this.sceneManager.addPointerCircle(payload.building + "_walkable_floors");
      //// Add light
      this.sceneManager.addLight();
      // //// Model URL
      const url = `${payload.cdnBase}/objects/${payload.client}/${
        payload.activeProject
      }/${
        payload.settings.floor.versionPath
          ? `${payload.settings.floor.versionPath}/`
          : ""
      }`;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const urlMobile = url + `Mobile_Version/`;
      let existMobileVersion = false;
      if (!isDesktop()) {
        const urlFile = urlMobile + payload.floorType;
        existMobileVersion = await this.sceneManager.fileExistsWithWorker(
          urlFile
        );
      }
      const usedUrl = existMobileVersion ? urlMobile : url;

      //// Load Model
      this.sceneManager.load(
        payload.floorType,
        usedUrl,
        payload.floorType,
        () => {
          //// Load fitouts after the loading the floor
          self.buildingStore.commit("setLoadingAssetsStatus", true);
          self.buildingStore.commit("setFitoutData", {
            initialized: true,
          });
          self.sceneManager.applyImageProcessing(
            payload.settings.floor.imageProcessing ?? imageProcessing
          );
          //// Set any scene properties
          self.sceneManager.setSceneProperties({
            clearColor: new Color4(0, 0, 0, 0),
          });
          self.sceneManager.initMinimap(payload.building + "_" + payload.floor);
          self.sceneManager.addVirtualTourControls();
          //// Add pins
          self.sceneManager.initPinManager(payload.cdnBase, this.$store);
          self.sceneManager.addPins(
            payload.pinsData,
            "interior",
            payload.floor.split("floor")[1],
            payload.building,
            0x20000000
          );
          //// Set nodes
          self.sceneManager.disableNode("ceiling_main", "ceiling_");
          self.sceneManager.setNodesMask(["ceiling_main"], 0x20000000);
          //// Cookie functions
          this.addCookieHoverSpace = this.addCookieHoverSpace.bind(this);
          this.addCookieClickSpace = this.addCookieClickSpace.bind(this);
          self.sceneManager.setCookieFunctions(
            this.addCookieHoverSpace,
            this.addCookieClickSpace
          );
          //// Add events
          self.sceneManager.addFloorEvents();
          // Set up highlights
          self.sceneManager.setVisibility(["highlights"], 0);
          self.sceneManager.pointerCircleVisible(false);
          self.sceneManager.resize();
          self.sceneManager.switchCamera("default");
          self.sceneManager.add3DCameraObservables();
          self.sceneManager.addFirstPersonCameraObservables();
          //// Set pointer y position
          self.sceneManager.setPointerY(payload.building + "_walkable_floors");
          if (self.space && self.space.space_code) {
            self.sceneManager.highlightSpace(self.space.space_code);
          }
          //// Set public highlights
          self.sceneManager.setHighlightsIsPublic(
            payload.project.spaces,
            payload.building,
            payload.floor
          );
          //// Init minimap
          self.sceneManager.initMinimap(payload.building + "_" + payload.floor);

          // ambient occlusion to lightmap
          self.sceneManager.updateLightmapFromAO();

          //// Load env image
          self.sceneManager.loadHDR(
            `${payload.cdnBase}${payload.settings.floor.hdr.env}`
          );

          // Load skybox
          if (payload.settings.floor.virtualTour.skyboxTexture) {
            self.sceneManager.loadSkybox(
              `${payload.cdnBase}${payload.settings.floor.virtualTour.skyboxTexture}`,
              0x20000000
            );
          }

          //// Start rendering
          self.sceneManager.startRendering(this.sceneManager);
          self.sceneManager.resize();
        },
        [payload.building + "_walkable_floors", "highlights"]
      );
      this.sceneManager.loadingManager.startLoading();
    },
    initializeCustomModel3D(pinsInfo) {
      const payload = {
        canvasReference: this.$refs.canvas,
        cdnBase: this.cdnBase,
        settings: {
          floor: this.customModel,
        },
        pinsInfo: pinsInfo,
        space: this.customModel.space,
        building: this.customModel.building,
        floor: this.customModel.floor,
        floorType: this.customModel.floorType,
        triggerShowUnitOnBuilding: this.customModel.triggerShowUnitOnBuilding,
        client: this.customModel.client,
        activeProject: this.customModel.activeProject,
        spaceData: this.customModel.spaceData,
      };
      this.buildingStore.dispatch("destroy");
      this.babylonHelper.destroy();
      this.babylonHelper.initCustomModel(payload, () => {
        this.buildingStore.commit("setLoadingAssetsStatus", true);
        this.buildingStore.commit("setFitoutData", {
          initialized: true,
          data: { ...this.babylonHelper.fitoutDetails(), _isVue: true },
        });
      });
      this.babylonHelper.run();
    },
    resizeListener() {
      this.windowHeight = window.innerHeight;
      this.sceneManager.resize();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) end = dc.length;
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    addCookieHoverSpace() {
      if (
        this.getCookie("cookieHideInfoPopupCanvasSpaceHover") === null &&
        document.querySelector(".space-hover-info")
      ) {
        document.querySelector(".space-hover-info").remove();
        document.cookie =
          "cookieHideInfoPopupCanvasSpaceHover=hideInfoPopupCanvasSpaceHover; expires=Sun, 1 Jan 9033 00:00:00 UTC; path=/";
      }
    },
    addCookieClickSpace() {
      if (
        this.getCookie("cookieHideInfoPopupCanvasSpaceClick") === null &&
        document.querySelector(".space-click-info")
      ) {
        document.querySelector(".space-click-info").remove();
        document.cookie =
          "cookieHideInfoPopupCanvasSpaceClick=hideInfoPopupCanvasSpaceClick; expires=Sun, 1 Jan 9033 00:00:00 UTC; path=/";
      }
    },
  },
};
</script>

<style lang="scss">
.organism-building {
  #canvas-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 25.938rem);
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    &.in-floor {
      width: calc(100vw);
    }
    canvas {
      flex: 1;
    }
  }
}

/*vor fi sterse*/

#inspector-host {
  position: absolute !important;
  left: 0;
  bottom: auto !important;
  width: 100% !important;
  z-index: auto !important;
}
#sceneExplorer {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  left: 0 !important;
}
#actionTabs,
#inspector-host {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  right: 0;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 5rem;
}

.row-floor-active-test {
  padding: 0.313rem;
  border: 1px solid gold;
  cursor: pointer;
  margin: 0 0.313rem;
}
</style>
