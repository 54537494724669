var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-wrapper" }, [
    _vm.pinInfo.pinTitle
      ? _c("h4", { domProps: { innerHTML: _vm._s(_vm.pinInfo.pinTitle) } })
      : _vm._e(),
    _vm.pinInfo.pinDescription
      ? _c("p", { domProps: { innerHTML: _vm._s(_vm.pinInfo.pinDescription) } })
      : _vm._e(),
    _vm.pinInfo.buttonLink
      ? _c(
          "div",
          { staticClass: "button-wrapper" },
          [
            _c("AtomCustomButton", {
              attrs: {
                label: "View more",
                icon: "right",
                href: _vm.pinInfo.buttonLink
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }