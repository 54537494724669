var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-location" },
    [
      _c(
        "AtomImageTitle",
        { attrs: { image: _vm.locationData.image } },
        [
          _c("AtomTitle", {
            attrs: {
              color: "#FFFFFF",
              color2: "#fff",
              title: _vm.locationData.title,
              subtitle: _vm.locationData.address
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-toggle", on: { click: _vm.toggleSidebar } },
        [
          _c(
            "span",
            [
              _vm._v(
                _vm._s(_vm.showSidebar ? "Hide" : "Show") +
                  " points of interest "
              ),
              _c("AtomIcon", {
                attrs: {
                  color: "#423687",
                  size: 18,
                  icon: _vm.showSidebar ? "minus" : "plus"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.activeProject
        ? _c("MoleculeGoogleMap", {
            ref: "map",
            attrs: {
              "map-config": _vm.locationData.map,
              places: _vm.locationData.places,
              "active-center-marker-id": _vm.activeProject
            }
          })
        : _vm._e(),
      _c("div", { class: { sidebar: true, active: _vm.showSidebar } }, [
        _c(
          "div",
          { staticClass: "sidebar-locations" },
          [
            _c("MoleculeSidebarRoutes", {
              attrs: { places: _vm.locationData.places }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-sidebar", on: { click: _vm.focusSearch } },
          [
            _c("AtomTitle", {
              attrs: {
                color: "#272626",
                color2: "#272626",
                title: "Distance from:"
              }
            }),
            _vm.hasSearchResult
              ? _c("div", { staticClass: "result-text" }, [
                  _vm._v(_vm._s(_vm.searchResult.addressText))
                ])
              : _vm._e(),
            _vm.hasSearchResult
              ? _c(
                  "div",
                  { staticClass: "result-text" },
                  [
                    _c("AtomIcon", { attrs: { icon: "driving" } }),
                    _vm._v(" " + _vm._s(_vm.searchResult.text))
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasSearchError
              ? _c("div", { staticClass: "result-text" }, [
                  _vm._v(_vm._s(_vm.searchResult.text))
                ])
              : _vm._e(),
            _c("AtomTitle", {
              attrs: {
                color: "#272626",
                color2: "#272626",
                subtitle:
                  "Please follow this format: " + _vm.locationData.address
              }
            }),
            _c("AtomInput", {
              ref: "searchInput",
              staticClass: "search-input",
              attrs: {
                placeholder: "Insert address here",
                value: _vm.queriedAddress
              },
              on: {
                input: function($event) {
                  _vm.queriedAddress = $event
                }
              }
            }),
            _c("AtomButton", {
              attrs: {
                label: "check",
                disabled: _vm.searchDisabled,
                "on-click": _vm.handleSearch
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }