var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.section
    ? _c("div")
    : _vm.section === "Brochure"
    ? _c("SectionBrochure", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Video"
    ? _c("SectionVideo", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "BackgroundImage"
    ? _c("SectionBackgroundImage", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "InfoAndImage"
    ? _c("SectionInfoAndImage", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "PostAndImage"
    ? _c("SectionPostAndImage", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "DiscoverArea"
    ? _c("SectionDiscoverArea", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "FewFacts"
    ? _c("SectionFewFacts", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "PurposeAndValues"
    ? _c("SectionPurposeAndValues", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "IconsDescriptions"
    ? _c("SectionIconsDescriptions", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Explore"
    ? _c("SectionExploreBuilding", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Statements"
    ? _c("SectionStatements", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Contact"
    ? _c("SectionContact", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Sustainability"
    ? _c("SectionSustainability", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Slider"
    ? _c("SectionSlider", {
        attrs: { data: _vm.activeDataSet, "order-no": _vm.orderNo }
      })
    : _vm.section === "CustomModel"
    ? _c("SectionCustomModel", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "ImageSide"
    ? _c("SectionImageSide", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "WhyUs"
    ? _c("SectionWhyUs", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Tenants"
    ? _c("SectionTenants", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }