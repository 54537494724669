var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-contact": true,
          "section-contact-v2": true
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("OrganismContactForm"),
      !_vm.data.contactFormOnly
        ? _c(
            "div",
            { staticClass: "row contacts-wrapper align-items-center" },
            [
              _c("div", { staticClass: "col col-xs-12 col-sm-12 col-6 text" }, [
                _c("div", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(_vm.data.title) }
                }),
                _c("div", {
                  staticClass: "subtitle",
                  domProps: { innerHTML: _vm._s(_vm.data.subtitle) }
                }),
                _c("div", { staticClass: "social" }, [
                  _vm.data.socialTitle
                    ? _c("div", {
                        staticClass: "title",
                        domProps: { innerHTML: _vm._s(_vm.data.socialTitle) }
                      })
                    : _vm._e(),
                  _vm.data.instagramUrl
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.data.instagramUrl,
                            target: "_blank"
                          }
                        },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              icon: "instagram",
                              size: 40,
                              color: "#FFF"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.data.linkedinUrl
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.data.linkedinUrl,
                            target: "_blank"
                          }
                        },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              icon: "linkedin-squared",
                              size: 40,
                              color: "#FFF"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.data.facebookUrl
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.data.facebookUrl,
                            target: "_blank"
                          }
                        },
                        [
                          _c("AtomIcon", {
                            attrs: {
                              icon: "facebook-squared",
                              size: 40,
                              color: "#FFF"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm._l(_vm.data.contacts, function(member, index) {
                return _c("AtomContact", {
                  key: index,
                  staticClass: "col col-xs-12 col-sm-6 col-3",
                  attrs: {
                    image: member.image,
                    name: member.name,
                    urlLinkedin: member.urlLinkedin,
                    nr: member.nr,
                    position: member.position,
                    email: member.email
                  }
                })
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }