<template>
  <div class="section section-gallery section-gallery-v2" :class="{[`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <div class="row">
        <div class="col col-xs-12 col-9">
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
        </div>
        <div class="col col-xs-12 col-3">
          <div class="text-right">
            <a v-if="data.brochure" class="button-atom" :href="`${resourceUrl(data.brochure)}`" target="_blank"><span>Download Brochure</span></a>
            <div v-if="!matterport && currentSpace && spaceFloor.floor_plan_url" class="button-atom" @click="download(spaceFloor.floor_plan_url, 'floorPlan.jpg')"><span>Download Floor Plan</span></div>
          </div>
        </div>
      </div>
    </div>
    <OrganismImageSliderV2 :slider-data="content" />
  </div>
</template>

<script>
import OrganismImageSliderV2 from "../../../../components/organisms/common/OrganismImageSliderV2";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import buildingStore from '../../../../components/organisms/project/building/store';
import AtomIcon from "@/components/atoms/common/AtomIcon";


export default {
  name: "SectionGalleryV2",
  components: {AtomTitle, OrganismImageSliderV2},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    matterport() {
      return this.$store.state.base.meta.general.matterportEnabled;
    },
    currentSpace() {
      if (!this.buildingStore.state.space) return {};
      return this.buildingStore.state.space.spaceData;
    },
    spaceFloor() {
      return this.$store.state.project.project.floors.find(f => f.id === this.currentSpace.floor_id);
    },
    content(){
      return this.data
    },
    images() {
      return this.data.images;
    },
    videos() {
      return this.data.videos;
    },
  },
  methods: {
    async download(url, filename) {
      const projectConstants = this.$store.getters.constants.project;
      const {
        result,
        error
      } = await this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.DOWNLOAD_FILE), {
        id: this.currentSpace.id,
        project_slug: this.activeProject.slug
      });

      if (error) {
        console.log(error);
      } else {
        let link = document.createElement("a");
        link.setAttribute("id", "download-link");
        link.setAttribute("href", result);
        link.setAttribute(
            "download",
            filename
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  }
}
</script>

<style lang="scss">
.section-gallery-v2 {
  padding: 5rem 0;
  @media only screen and (max-width: 900px) {
    padding: 2.5rem 0;
  }
  .top-title {
    padding: 0 3.75rem;
    margin-bottom: 2.25rem;
    @media only screen and (max-width: 900px) {
      padding: 0 0.625rem;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 1.875rem;
    }
    .atom-title {
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.938rem;
      }
      h2 {
        text-transform: none;
        @include font-main(3.25rem, $black !important, $regular, 3.875rem);
        @media only screen and (max-width: 767px) {
          @include font-main(
                  1.375rem,
                  $black !important,
                  $regular,
                  1.625rem
          );
        }
        b {
          @include font-main(3.25rem, $black !important, $bold, 3.875rem);
          @media only screen and (max-width: 767px) {
            @include font-main(1.375rem, $black !important, $bold, 1.625rem);
          }
        }
      }
      h3 {
        @include font-main(1.375rem, $black !important, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          @include font-main(
                  0.875rem,
                  $black !important,
                  $regular,
                  1.188rem
          );
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: 400;
      border-color: $blue;
      @media only screen and (max-width: 767px) {
        padding: 0.5rem 0.938rem;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $blue;
        span {
          color: $white;
        }
      }
    }
  }
}
</style>