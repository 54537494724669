<template>
  <div class="organism-contact-form">
    <AtomTitle color="#2F3030" color2="rgba(67,54,135,0.4)" title="Leave us a message" subtitle="Leave us a message and will get back to you as soon as possible" />
    <MoleculeContactForm/>
  </div>
</template>

<script>
import AtomTitle from "../../atoms/common/AtomTitle";
import MoleculeContactForm from "../../molecules/common/MoleculeContactForm";
export default {
  name: "OrganismContactForm",
  components: {MoleculeContactForm, AtomTitle}

}
</script>

<style lang="scss">
.organism-contact-form {
  padding: 4.063rem 4.375rem;
  @media only screen and (max-width: 900px) {
    padding: 1.875rem 1.25rem;
  }
  .atom-title {
    h2 {
      @include font-main(1.375rem, $black, $medium, 1.625rem);
      @media only screen and (max-width: 767px) {
        @include font-main(1.375rem, $black, $medium, 1.625rem);
      }
    }
    h3 {
      margin-top: 0.375rem;
      text-transform: initial;
      @include font-main(0.75rem, $black, $regular, 0.875rem);
      @media only screen and (max-width: 767px) {
        margin-top: 0.5rem;
        @include font-main(0.875rem, $black, $regular, 1.125rem);
      }
    }
  }
  .atom-check-box {
    @media only screen and (max-width: 767px) {
      margin-bottom: 1.875rem;
    }
  }
}
</style>