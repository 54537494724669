var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-atom-wrapper",
      class: { "has-icon": _vm.hasIcon, "has-label": _vm.hasLabel }
    },
    [
      _vm.hasLabel ? _c("label", [_vm._v(_vm._s(_vm.labelText))]) : _vm._e(),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          ref: "selectTarget",
          staticClass: "select2select",
          attrs: {
            id: "" + _vm.id,
            "data-minimum-results-for-search": _vm.selectProps.search
              ? true
              : -1,
            multiple: _vm.multiple,
            disabled: _vm.disabled,
            "data-instance": _vm.instance
          },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.inputVal = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [_c("option")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }