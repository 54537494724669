var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "wrapper-sidebar" },
    _vm._l(_vm.places, function(location, index) {
      return _c(
        "AtomLocationType",
        {
          key: index,
          attrs: {
            category: location.category,
            "category-label": location.categoryLabel,
            totalNumber: location.totalNumber
          },
          on: { "category-toggle": _vm.handleCategoryToggle }
        },
        [
          _c(
            "ul",
            { staticClass: "street-show" },
            _vm._l(location.routes, function(routeTo, index) {
              return _c("AtomRoute", {
                key: index,
                attrs: {
                  markerId: location.category + "_" + routeTo.id,
                  mode: routeTo.mode,
                  order: routeTo.order,
                  name: routeTo.name,
                  time: routeTo.time,
                  distance: routeTo.distance
                }
              })
            }),
            1
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }