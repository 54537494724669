import { PinContentManager } from "@/components/organisms/project/building/store/3d/builders/PinContentManager";
import { AnimationBuilder } from "@/components/organisms/project/building/store/3d/builders/AnimationBuilder";

export class Pin {
  pinMesh: BABYLON.Mesh;
  isActive = false;
  scene: BABYLON.Scene;
  pinData: {
    text: string;
    title?: string;
    imageSrc?: string;
    videoSrc?: string;
  };
  pinContentManager: PinContentManager;
  pinContentType: string;
  pinMetadata: Record<string, unknown>;

  constructor(
    radius: number,
    scene: BABYLON.Scene,
    pinTexture: BABYLON.Texture,
    pinData: {
      text: string;
      title?: string;
      imageSrc?: string;
      videoSrc?: string;
    },
    pinContentManager: PinContentManager,
    pinContentType = "default",
    pinMetadata: Record<string, unknown>
  ) {
    this.scene = scene;
    this.pinMesh = BABYLON.MeshBuilder.CreateDisc(
      "pin",
      {
        radius: radius,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      },
      this.scene
    );
    //// Material
    this.pinMesh.material = new BABYLON.StandardMaterial("PinMat", this.scene);
    (this.pinMesh.material as BABYLON.StandardMaterial).emissiveTexture = pinTexture;
    (this.pinMesh.material as BABYLON.StandardMaterial).opacityTexture = pinTexture;
    this.pinMesh.material.backFaceCulling = false;
    (this.pinMesh.material as BABYLON.StandardMaterial).disableLighting = true;
    this.pinMesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
    this.pinMesh.enablePointerMoveEvents = true;
    this.pinMesh.isPickable = true;
    this.pinMesh.metadata = { pin: this };

    this.pinData = pinData;
    this.pinContentManager = pinContentManager;

    this.pinContentType = pinContentType;
    this.pinMetadata = pinMetadata;
  }

  setPosition(x: number, y: number, z: number) {
    this.pinMesh.position.set(x, y, z);
  }

  setScale(scale: number) {
    this.pinMesh.scaling.set(scale, scale, scale);
  }

  rotate(
    animationBuilder: AnimationBuilder,
    scene: BABYLON.Scene,
    fromValue: number,
    toValue: number
  ) {
    const anim = animationBuilder.createAnimation(
      "rotation.z",
      fromValue,
      toValue
    );
    if (this.pinMesh.animations.length > 0) {
      delete this.pinMesh.animations[0];
    }
    this.pinMesh.animations = [anim];
    scene.beginAnimation(
      this.pinMesh,
      animationBuilder.fromFrame,
      animationBuilder.toFrame,
      animationBuilder.isLoop,
      animationBuilder.speedRatio
    );
  }

  highlight(isHighlight: boolean) {
    if (!this.pinContentManager)
      console.error("Pin Content Manager not defined");
    if (isHighlight) {
      (this.pinMesh.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(
        0.298,
        0.776,
        0.427
      );
    } else {
      (this.pinMesh.material as BABYLON.StandardMaterial).emissiveColor = new BABYLON.Color3(
        0,
        0,
        0
      );
    }
  }

  generateTextBox(
    data: {
      pinClasses: string[];
      imgClasses: string[];
      videoClasses: string[];
      titleClasses: string[];
      textClasses: string[];
      requestButtonClasses?: string[];
      id: string | number;
    },
    yOffset: number
  ) {
    this.pinContentManager.generateBox(...data.pinClasses);
    //// Add image if it exits
    if (this.pinData.imageSrc) {
      this.pinContentManager.addImg(this.pinData.imageSrc, ...data.imgClasses);
    }
    if (this.pinData.videoSrc) {
      this.pinContentManager.addVideo(
        this.pinData.videoSrc,
        ...data.videoClasses
      );
    }
    if (this.pinData.title && this.pinData.title != "") {
      this.pinContentManager.addTitle(this.pinData.title, ...data.titleClasses);
    }
    this.pinContentManager.addText(this.pinData.text, ...data.textClasses);

    if (data.requestButtonClasses) {
      this.pinContentManager.addRequestButton(...data.requestButtonClasses);
    }

    if (this.pinMetadata) {
      const spaceId = this.pinMetadata.spaceCode as string;
      const floorCode = this.pinMetadata.floor as string;
      const buildingSlug = this.pinMetadata.building as string;
      const args: {
        spaceId: string;
        floorCode: string;
        buildingSlug: string;
      } = { spaceId, floorCode, buildingSlug };
      this.pinContentManager.addAttributes(args);
    }
    this.pinContentManager.addRequestOfferBtnClickEvent();

    if (!this.pinData.imageSrc) {
      this.pinContentManager.show(this.pinMesh, yOffset);
    }
  }

  showContent() {
    switch (this.pinContentType) {
      case "interior":
        this.generateTextBox(
          {
            pinClasses: ["pinInfo", "pinInfoVT"],
            imgClasses: ["pin-img-wrapper"],
            videoClasses: ["video-pin"],
            titleClasses: ["pinTextTitle"],
            textClasses: ["pinText"],
            requestButtonClasses: ["button-request-offer"],
            id: -1,
          },
          -200
        );
        break;
      default:
        this.generateTextBox(
          {
            pinClasses: ["pinInfo", "pin-info-wrapper"],
            imgClasses: ["pin-img-wrapper"],
            videoClasses: ["video-pin"],
            titleClasses: ["simple-wrapper"],
            textClasses: ["simple-wrapper"],
            id: -1,
          },
          -75
        );
    }
  }

  trigger(options: Record<string, unknown>, isHighlighted: boolean) {
    this.highlight(isHighlighted);
    const fromValue = isHighlighted ? 0 : Math.PI / 4;
    const toValue = isHighlighted ? Math.PI / 4 : 0;
    this.rotate(
      options.animationBuilder as AnimationBuilder,
      options.scene as BABYLON.Scene,
      fromValue,
      toValue
    );
    if (isHighlighted) {
      this.showContent();
    } else {
      this.pinContentManager.dispose();
    }
  }

  resetOrientation(position: BABYLON.Vector3) {
    this.pinMesh.lookAt(position);
  }
}
