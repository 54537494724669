var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentVariant, {
    tag: "component",
    attrs: { data: _vm.data, hash: _vm.hash, "order-no": _vm.orderNo }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }