var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-image-list",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _vm.data.title || _vm.data.subtitle
        ? _c("div", { staticClass: "heading" }, [
            _vm.data.title
              ? _c("div", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(_vm.data.title) }
                })
              : _vm._e(),
            _vm.data.subtitle
              ? _c("div", {
                  staticClass: "subtitle",
                  domProps: { innerHTML: _vm._s(_vm.data.subtitle) }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "listing" },
        _vm._l(_vm.items, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", {
              staticClass: "icon",
              style: {
                backgroundImage: "url(" + _vm.resourceUrl(item.image) + ")"
              }
            }),
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.text))])
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }